import { BSON } from 'realm-web';
import { Client, STATUS } from '../../../utils/constants';

export enum Actions {
    CreateAgentClient = '[Clients] Create',
}

/*----------- Create Agent Client -----------------*/

export function createAgentClientRequested(
    agentId: BSON.ObjectID,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    callback?: Function,
) {
    return {
        type: Actions.CreateAgentClient,
        status: STATUS.Requested,
        agentId,
        firstName,
        lastName,
        phoneNumber,
        callback,
    };
}

export function createAgentClientSucceeded(client?: Client) {
    return {
        type: Actions.CreateAgentClient,
        status: STATUS.Succeeded,
        client,
    };
}

export function createAgentClientFailed(error: string) {
    return {
        type: Actions.CreateAgentClient,
        status: STATUS.Failed,
        error,
    };
}
