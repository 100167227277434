/*
 * Reusable BUTTON component
 */
import React, { ReactChild } from 'react';

/*------- Components -------------*/
import Loader from '../Loader';

/*------- Assets---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*------- MISC ----------*/
import { noop } from '../../utils/common';
interface Props {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    title: string;
    widthType: 'Auto' | 'Full' | 'Small' | 'Ineherit';
    disabled: boolean;
    loading: boolean;
    type: 'default' | 'danger' | 'white';
    customStyle: string;
    children: ReactChild;
}
export default function Button(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*---- Constants ----- */
    const { title, disabled, onClick, widthType, loading, type, customStyle, children } = props;

    return (
        <button
            className={`${
                type === 'default'
                    ? styleSheet.default
                    : type === 'white'
                    ? styleSheet.white
                    : styleSheet.danger
            } ${
                widthType === 'Full'
                    ? styleSheet.widthFull
                    : widthType === 'Auto'
                    ? styleSheet.widthAuto
                    : widthType === 'Small'
                    ? styleSheet.widthSmall
                    : ''
            } ${loading ? styleSheet.loading : disabled ? styleSheet.disabled : ''} ${customStyle}`}
            onClick={onClick}
            disabled={disabled || loading}
        >
            {loading ? (
                <Loader />
            ) : (
                <span
                    className={`${widthType === 'Small' ? styleSheet.smallText : styleSheet.text}`}
                >
                    {title ?? children}
                </span>
            )}
        </button>
    );
}

Button.defaultProps = {
    onClick: noop,
    title: 'Button',
    widthType: 'Full',
    disabled: false,
    loading: false,
    type: 'default',
    customStyle: '',
    children: <></>,
};
