/*
 * Listing details screen
 */

import React from 'react';

/*------ Components ------*/
import TypedGoogleMap from 'google-map-react';
import Carousel from 'react-multi-carousel';

/*------ Assets --------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Icons -------------*/
import BedIcon from '../../../../images/icons/property/bed.svg';
import BathIcon from '../../../../images/icons/property/bath.svg';
import SqftIcon from '../../../../images/icons/property/sqft.svg';
import BigMapMarker from '../../../../images/icons/bigMapMarker.svg';

/*------ Constants / Types -------*/
import { Listing } from '../../../../utils/constants';

/*------ Utils -----------*/
import { formatPhoneNumberForDisplay, isMobile } from '../../../../utils/common';
import {
    formatCurrency,
    formatMinutes,
    formatShowingType,
} from '../../../../utils/common/transforms';
import 'react-multi-carousel/lib/styles.css';

interface Props {
    header: React.ReactNode;
    listing: Listing;
}

export default function ListingDetailsComponent(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Constants --------*/
    const GoogleMap = TypedGoogleMap as any;
    const mapClassName = isMobile() ? styleSheet.mapMobileContainer : styleSheet.mapContainer;

    const { listing, header } = props;
    const connected = !!listing?.agentListing?.listingId;
    const card1 = [
        {
            key: 'Year Built',
            value: listing.property?.yearBuilt ?? 'N/A',
        },
        {
            key: 'Lot Area',
            value: listing.property?.acres + ' Acres' ?? 'N/A',
        },
        {
            key: 'Type',
            value: listing.property?.type ?? 'N/A',
        },
        {
            key: 'Status',
            value: listing.mlsList?.length ? listing.mlsList[0]?.standardStatus : 'N/A',
        },
    ];
    const card2 = [
        {
            key: 'Taxes',
            value: listing.tax?.taxAnnualAmount
                ? '$' + formatCurrency(listing.tax?.taxAnnualAmount) + '/yr'
                : 'N/A',
        },
        {
            key: 'Parking',
            value: listing.property?.parking?.spaces
                ? listing.property?.parking?.spaces + ' Spaces'
                : 'N/A',
        },
        {
            key: 'HOA',
            value: listing.association?.fee ? '$' + listing.association.fee : 'N/A',
        },
    ];
    const card3 = [
        {
            key: 'Elementary',
            value: listing.school?.elementarySchool ?? 'N/A',
        },
        {
            key: 'Middle',
            value: listing.school?.middleSchool ?? 'N/A',
        },
        {
            key: 'High',
            value: listing.school?.highSchool ?? 'N/A',
        },
        {
            key: 'District',
            value: listing.school?.district ?? 'N/A',
        },
    ];

    /*------ Functions --------*/

    const listingBedBath = (
        <div className={styleSheet.listingBedBathContainer}>
            <div className={styleSheet.iconAndText}>
                <img className={styleSheet.icon} src={BedIcon} />{' '}
                {listing?.property?.bedrooms ?? 'N/A'} bed
            </div>

            <div className={styleSheet.verticalSeparator} />

            <div className={styleSheet.iconAndText}>
                <img className={styleSheet.icon} src={BathIcon} />{' '}
                {listing?.property?.bathrooms ?? 'N/A'} bath
            </div>

            <div className={styleSheet.verticalSeparator} />

            <div className={styleSheet.iconAndText}>
                <img className={styleSheet.icon} src={SqftIcon} />{' '}
                {listing?.property?.area ?? 'N/A'} sqft.
            </div>
        </div>
    );

    const showingDetailsSection = (
        <div>
            {connected ? (
                <div className={styleSheet.detailSectionContainer}>
                    <div className={styleSheet.detailContainer}>
                        Showing Type:
                        <div className={styleSheet.greyTag}>
                            {formatShowingType(listing?.agentListing?.type)}
                        </div>
                    </div>
                    <div className={styleSheet.verticalSeparator} />
                    <div className={styleSheet.detailContainer}>
                        Notice Required:
                        <div className={styleSheet.greyTag}>
                            {formatMinutes(listing?.agentListing?.noticeRequired)}
                        </div>
                    </div>
                    <div className={styleSheet.verticalSeparator} />
                    <div className={styleSheet.detailContainer}>
                        Max. Showing Duration:
                        <div className={styleSheet.greyTag}>
                            {formatMinutes(listing?.agentListing?.maxDuration)}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styleSheet.redTag}>
                    This Listing is Managed Outside of Showingly
                </div>
            )}
        </div>
    );

    const card = (metaDataArr: any[]) => (
        <div className={styleSheet.card}>
            {metaDataArr.map(({ key, value }) => {
                return (
                    <div className={styleSheet.dataRow}>
                        <span className={styleSheet.key}>{key}: </span>
                        <span className={styleSheet.value}> {value?.toString()}</span>
                    </div>
                );
            })}
        </div>
    );

    const RenderBigMarker = (lat: any, lng: any) => (
        <img style={{ marginLeft: -15, marginTop: -25 }} src={BigMapMarker} />
    );

    const mapContainer = (
        <div className={mapClassName}>
            <GoogleMap
                // @ts-ignore
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
                    libraries: ['places'],
                }}
                zoom={11}
                center={{ lat: listing.geo?.lat, lng: listing.geo?.lng }}
            >
                <RenderBigMarker lat={listing.geo?.lat} lng={listing.geo?.lng} />
            </GoogleMap>
        </div>
    );

    /*------ Mobile Components --------*/

    const responsive = {
        mobile: {
            breakpoint: { max: 430, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30,
        },
    };

    const imageSectionMobile = (
        <div>
            <Carousel
                additionalTransfrom={0}
                arrows
                className=""
                keyBoardControl
                showDots={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                swipeable
            >
                {listing.photos?.map((image: string) => {
                    return (
                        <img
                            src={image}
                            className={styleSheet.image}
                            // no-referrer helps render images from providers that don't give us a referrer in the link (Actris).
                            referrerPolicy={'no-referrer'}
                        />
                    );
                })}
            </Carousel>
        </div>
    );

    const showingDetailsSectionMobile = (
        <div>
            {connected ? (
                <div className={styleSheet.table}>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.detailContainer}`}>
                            Showing Type:
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.greyTagMobile}>
                                {formatShowingType(listing.agentListing?.type)}
                            </span>
                        </div>
                    </div>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.detailContainer}`}>
                            Notice Required:
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.greyTagMobile}>
                                {formatMinutes(listing.agentListing?.noticeRequired)}
                            </span>
                        </div>
                    </div>
                    <div className={styleSheet.tableRow}>
                        <div className={`${styleSheet.tableCol} ${styleSheet.detailContainer}`}>
                            Max. Showing Duration:
                        </div>
                        <div className={`${styleSheet.tableCol} ${styleSheet.textRight}`}>
                            <span className={styleSheet.greyTagMobile}>
                                {formatMinutes(listing.agentListing?.maxDuration)}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styleSheet.redTag}>
                    This Listing is Managed Outside of Showingly
                </div>
            )}
        </div>
    );

    const listingInfoSectionMobile = (
        <div>
            <div className={styleSheet.listingInfoSectionContainer}>{listingBedBath}</div>
            <div className={styleSheet.listingInfoSectionContainer}>
                {showingDetailsSectionMobile}
            </div>
        </div>
    );

    /*------ Web Components ----------*/

    const imageSection = (
        <div className={styleSheet.imageSectionContainer}>
            {listing?.photos?.map((image: string) => {
                return (
                    <img
                        src={image}
                        className={styleSheet.image}
                        // no-referrer helps render images from providers that don't give us a referrer in the link (Actris).
                        referrerPolicy={'no-referrer'}
                    />
                );
            })}
            {listing?.photos?.map((image: string) => {
                return (
                    <img
                        src={image}
                        className={styleSheet.image}
                        // no-referrer helps render images from providers that don't give us a referrer in the link (Actris).
                        referrerPolicy={'no-referrer'}
                    />
                );
            })}
            {listing?.photos?.map((image: string) => {
                return (
                    <img
                        src={image}
                        className={styleSheet.image}
                        // no-referrer helps render images from providers that don't give us a referrer in the link (Actris).
                        referrerPolicy={'no-referrer'}
                    />
                );
            })}
        </div>
    );

    const listingInfoSection = (
        <div className={styleSheet.listingInfoSectionContainer}>
            {listingBedBath}
            {showingDetailsSection}
        </div>
    );

    const publicRemarksSection = (
        <div className={styleSheet.publicRemarksSectionContainer}>
            <div className={styleSheet.greyTitle}>Public Remarks</div>
            <div className={styleSheet.textStyleJustify}>{listing.remarks}</div>
        </div>
    );

    const metaDataSection = (
        <div className={styleSheet.metaDataSectionContainer}>
            {mapContainer}
            {card(card1)}
            {card(card2)}
            {card(card3)}
        </div>
    );

    const listedBySection = (
        <div className={styleSheet.listedBySectionContainer}>
            Listed by{' '}
            <span className={styleSheet.listingAgent}>
                {' '}
                {listing.agent?.firstName} {listing.agent?.lastName}{' '}
            </span>
            {listing.agentListing?.agents[0].phoneNumber ? (
                <>
                    <div className={styleSheet.verticalSeparator} />{' '}
                    <span>
                        +1{' '}
                        {formatPhoneNumberForDisplay(listing.agentListing?.agents[0].phoneNumber)}
                    </span>
                </>
            ) : null}
            {listing.name ? (
                <>
                    <div className={styleSheet.verticalSeparator} />
                    <span>{listing.name}</span>
                </>
            ) : null}
        </div>
    );

    const mlsNumberSection = (
        <div>
            {listing.mlsList?.map((mls: any) => {
                return (
                    <>
                        <div className={styleSheet.mlsNumber}>MLS#: {mls?.listingId}</div>
                        {mls?.disclaimer ? (
                            <div className={styleSheet.disclaimer}>{mls?.disclaimer}</div>
                        ) : null}
                    </>
                );
            })}
        </div>
    );

    // const newsSection = <ConnectedNewsSection />;
    const newsSection = <></>;

    return isMobile() ? (
        <div className={styleSheet.rootMobile}>
            {header}
            {imageSectionMobile}
            <div className={styleSheet.separator} />
            {listingInfoSectionMobile}
            <div className={styleSheet.separator} />
            {publicRemarksSection}
            <div className={styleSheet.separator} />
            {mapContainer}
            <div className={styleSheet.separator} />
            {listedBySection}
            {mlsNumberSection}
            {newsSection}
        </div>
    ) : (
        <div className={styleSheet.root}>
            {header}
            {imageSection}
            <div className={styleSheet.separator} />
            {listingInfoSection}
            <div className={styleSheet.separator} />
            {publicRemarksSection}
            {metaDataSection}
            {listedBySection}
            <div className={styleSheet.separator} />
            {mlsNumberSection}
            {newsSection}
        </div>
    );
}

ListingDetailsComponent.defaultProps = {
    header: <></>,
    listing: {},
};
