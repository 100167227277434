import { AgentListing, TablePage } from './types';
import { MapListingObject } from '../SearchMap/types';
import { Listing, STATUS } from '../../../utils/constants';
import { BSON } from 'realm-web';
import { SortTypeEnum } from '../../../components/DataTable/types';

export enum Actions {
    AddClients = '[Listings] Add Clients',
    ReserValues = '[Listings] Reset Values',
    FetchListing = '[Listings] Fetch listing',
    GetAllListing = '[Listings] Fetch listings',
    ConnectListing = '[Listings] Connect Listing',
    SelectedListing = '[Listings] Selected Listing',
    SetListingConfig = '[Listings] Set Listing Config',
    SearchAgent = '[Listings] Search agent on listing',
    StoreLockboxPhoto = '[Listings] store Lockbox Photo',
    ChangeSelectedView = '[Listings] Change Selected View',
    SearchConsumer = '[Listings] Search consumer on listing',
    FetchAgentByMlsId = '[Listing] Fetch Agent by the mls id',
    FetchSelectedListing = '[Listings] Fetch Selected listing',
    FetchTotalPages = '[Listings] Fetch information for Pagination',
}

/*----- Redux Action Logic For get all the listings -----*/
export function fetchListingsRequested(
    pageNumber: number,
    forceReload: boolean,
    textSearch?: string,
    sort?: object,
    sortIndex?: number,
    sortType?: SortTypeEnum,
) {
    return {
        type: Actions.GetAllListing,
        status: STATUS.Requested,
        pageNumber,
        forceReload,
        textSearch,
        sort,
        sortIndex,
        sortType,
    };
}

export function fetchListingsSucceeded(
    listings: TablePage[],
    currentPage: number,
    totalPages?: number,
    globalRecords?: number,
    textSearch?: string,
    sort?: any,
    sortIndex?: number,
    sortType?: SortTypeEnum,
) {
    return {
        type: Actions.GetAllListing,
        status: STATUS.Succeeded,
        listings,
        currentPage,
        totalPages,
        globalRecords,
        textSearch,
        sort,
        sortIndex,
        sortType,
    };
}

export function fetchListingsFailed(errors: string[] = []) {
    return {
        type: Actions.GetAllListing,
        status: STATUS.Failed,
        errors,
    };
}
/*----- End of Redux Action Logic For get all the listings -----*/

/*------ Fetch a single listing ---------*/
export function fetchListingRequested(mlsListingId: string, mlsName: string, isEditing?: boolean) {
    return {
        type: Actions.FetchListing,
        status: STATUS.Requested,
        mlsListingId,
        mlsName,
        isEditing,
    };
}
export function fetchListingSucceeded(listing: Listing | MapListingObject) {
    return {
        type: Actions.FetchListing,
        status: STATUS.Succeeded,
        listing,
    };
}
export function fetchListingFailed(error: string) {
    return {
        type: Actions.FetchListing,
        status: STATUS.Failed,
        error,
    };
}

export function fetchSelectedListingRequested(listingId: string, isEditing?: boolean | undefined) {
    return {
        type: Actions.FetchSelectedListing,
        status: STATUS.Requested,
        listingId,
        isEditing,
    };
}

export function fetchSelectedListingFailed(errors: string[] = []) {
    return {
        type: Actions.FetchSelectedListing,
        status: STATUS.Failed,
        errors,
    };
}

export function setConfigDetails(listing: Listing, pageNumber: number) {
    localStorage.setItem('configNumberPage', '' + pageNumber);
    return {
        type: Actions.SetListingConfig,
        listing,
        pageNumber,
    };
}

/*------ End of Fetch a single listing ---------*/

/*---------- Search Agents on Listings ----------*/
export function textSearchAgentsRequested(
    searchText: string = '',
    marketName: string,
    brokerageId: string,
) {
    return {
        type: Actions.SearchAgent,
        status: STATUS.Requested,
        searchText,
        marketName,
        brokerageId,
    };
}

export function textSearchAgentsSucceeded(agents: any[] = []) {
    return {
        type: Actions.SearchAgent,
        status: STATUS.Succeeded,
        agents,
    };
}

export function textSearchAgentsFailed(errors: string[] = []) {
    return {
        type: Actions.SearchAgent,
        status: STATUS.Failed,
        errors,
    };
}
/*---------- END of Search Agent on Listings ----------*/

/*---------- Search consumer on Listings ----------*/
export function textSearchConsumersRequested(searchText: string = '', agentId: BSON.ObjectId) {
    return {
        type: Actions.SearchConsumer,
        status: STATUS.Requested,
        searchText,
        agentId,
    };
}

export function textSearchConsumersSucceeded(consumers: any[] = []) {
    return {
        type: Actions.SearchConsumer,
        status: STATUS.Succeeded,
        consumers,
    };
}

export function textSearchConsumersFailed(errors: string[] = []) {
    return {
        type: Actions.SearchConsumer,
        status: STATUS.Failed,
        errors,
    };
}
/*---------- END of Search Consumer on Listings ----------*/

/*---------- Add clients ----------*/
export function addClientRequested(client: any, agentId: BSON.ObjectID) {
    return {
        type: Actions.AddClients,
        status: STATUS.Requested,
        client,
        agentId,
    };
}

export function addClientSucceeded(newClient: any) {
    return {
        type: Actions.AddClients,
        status: STATUS.Succeeded,
        newClient,
    };
}

export function addClientFailed(errors: string[] = []) {
    return {
        type: Actions.AddClients,
        status: STATUS.Failed,
        errors,
    };
}
/*---------- END of Add clients----------*/

/*---------- Connect Listings ----------*/
export function connectListingRequested(agentListing: AgentListing, listing: Listing) {
    return {
        type: Actions.ConnectListing,
        status: STATUS.Requested,
        agentListing,
        listing,
    };
}

export function connectListingSucceeded() {
    return {
        type: Actions.ConnectListing,
        status: STATUS.Succeeded,
    };
}

export function connectListingFailed(errors: string[] = []) {
    return {
        type: Actions.ConnectListing,
        status: STATUS.Failed,
        errors,
    };
}
/*---------- End of Connect Listings ----------*/

/*---------- store Lockbox Photo ----------*/
export function storeLockboxPhotoRequested(file: {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}) {
    return {
        type: Actions.StoreLockboxPhoto,
        status: STATUS.Requested,
        file,
    };
}

export function storeLockboxPhotoSucceeded(newContentData: {
    image: { as: string; path: string; uri: string };
}) {
    return {
        type: Actions.StoreLockboxPhoto,
        status: STATUS.Succeeded,
        newContentData,
    };
}

export function storeLockboxPhotoFailed(errors: string[] = []) {
    return {
        type: Actions.StoreLockboxPhoto,
        status: STATUS.Failed,
        errors,
    };
}
/*---------- End of Store Lockbox Photo ----------*/

/*----- Get The Agent by the Mls Id -----*/
export function fetchMlsAgentRequested(agentMlsId: string, brokerageMlsId: string) {
    return {
        type: Actions.FetchAgentByMlsId,
        status: STATUS.Requested,
        agentMlsId,
        brokerageMlsId,
    };
}

export function fetchMlsAgentSucceeded(listingAgents: any[]) {
    return {
        type: Actions.FetchAgentByMlsId,
        status: STATUS.Succeeded,
        listingAgents,
    };
}

export function fetchMlsAgentFailed(errors: string[] = []) {
    return {
        type: Actions.FetchAgentByMlsId,
        status: STATUS.Failed,
        errors,
    };
}
/*----- End of Get The Agent by the Mls Id -----*/

/*----------- Fetch Page -----------------*/

export function fetchTotalPagesRequested(brokerageMlsId: string[]) {
    return {
        type: Actions.FetchTotalPages,
        status: STATUS.Requested,
        brokerageMlsId,
    };
}

export function fetchTotalPagesSucceeded(pages: number, totalRecords: number) {
    return {
        type: Actions.FetchTotalPages,
        status: STATUS.Succeeded,
        pages,
        totalRecords,
    };
}

export function fetchTotalPagesFailed(error: string) {
    return {
        type: Actions.FetchTotalPages,
        status: STATUS.Failed,
        error,
    };
}
/*----------- End of Fetch Page -----------------*/

/*----------  ----------*/
export function resetValues() {
    return {
        type: Actions.ReserValues,
    };
}
export function setSelectedListing(listing: any) {
    return {
        type: Actions.SelectedListing,
        listing,
    };
}

export const changeSelectedView = (view: string) => {
    if (view === 'Overview' || view === 'Listing Details' || view === 'Configuration') {
        return {
            type: Actions.ChangeSelectedView,
            view,
        };
    }
};
/*---------- End of  ----------*/
