export default (theme: any) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'space-between',
        },
        button: {
            backgroundColor: theme.palette.blue,
            borderRadius: 5,
            width: 270,
            height: 40,
            color: theme.palette.white,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        dividerContainer: {
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            display: 'flex',
        },
        otherDividerContainer: {
            flexDirection: 'column',
            flex: 1,
            alignItems: 'flex-end',
            display: 'flex',
        },
        divider: {
            height: 22,
            width: 2,
            borderRadius: 2,
            backgroundColor: '#e9e9e9',
        },
        digitInputContainer: {
            flex: 1,
            backgroundColor: theme.palette.background,
            height: 40,
            borderRadius: 4,
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            position: 'relative',
            paddingHorizontal: 3,
            overflow: 'hidden',
        },
        errorBorder: {
            border: `2px solid ${theme.palette.red}`,
        },
        digitInput: {
            position: 'absolute',
            top: 3,
            height: 27,
            paddingLeft: 0,
            paddingRight: 0,
            opacity: 0,
            textAlign: 'center',
            color: '#bcbcbc',
            fontSize: 17,
        },
        threeDividerContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'space-around',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            paddingHorizontal: 3,
        },
        twoDividerContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            paddingHorizontal: 3,
        },
        inputRoot: {
            height: 40,
            cursor: 'pointer',
            '&:active': {
                opacity: 0.5,
            },
            position: 'relative',
            marginBottom: 20,
        },
        '@keyframes blinker': {
            from: { opacity: 2 },
            to: { opacity: 0 },
        },
        blink: {
            animationName: '$blinker',
            animationDuration: '1s',
            animationTimingFunction: 'steps(2, start)',
            animationIterationCount: 'infinite',
        },
    };
};
