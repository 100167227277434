export default (theme) => {
    return {
        tag: {
            ...theme.paddingHorizontal(5),
            ...theme.paddingVertical(3),
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            fontSize: 14,
            width: 'max-content',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            ...theme.marginVertical(4),
            ...theme.marginHorizontal(4),
        },

        cancel: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 20,
            height: 20,
            borderRadius: 42,
            cursor: 'pointer',
            ...theme.marginHorizontal(4),
        },
    };
};
