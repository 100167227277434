import { Actions } from './actions';
import { STATUS } from '../../../utils/constants';
import { SortTypeEnum } from '../../../components/DataTable/types';

export const initialState = {
    /*-------- Fetch -----------*/
    loading: false,
    users: [],
    totalPages: 0,
    currentPage: 1,
    paginationLoading: false,
    error: '',
    textSearch: '',
    sort: null,
    sortIndex: 0,
    sortType: SortTypeEnum.ASC,

    /*------- Create new brokerage user ----------*/
    createdUserId: null,
    newUserLoading: false,
    newUserError: '',

    /*--------- Edit user ------------------*/
    editUserLoading: false,
    editUserError: '',

    /*--------- Delete user ------------------*/
    deleteUserLoading: false,
    deleteUserError: '',

    /*--------- Resend user invite ------------------*/
    resendInviteLoading: false,
    resendInviteError: '',
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case Actions.FetchUsers: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                        error: '',
                    };
                }
                case STATUS.Succeeded: {
                    const {
                        users,
                        currentPage,
                        totalPages,
                        textSearch,
                        sort,
                        sortIndex,
                        sortType,
                    } = action;

                    return {
                        ...state,
                        loading: false,
                        users,
                        currentPage,
                        totalPages,
                        textSearch,
                        sort,
                        sortIndex,
                        sortType,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loading: false,
                        error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.InviteUser: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        newUserLoading: true,
                        newUserError: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { userId } = action;
                    return {
                        ...state,
                        newUserLoading: false,
                        createdUserId: userId,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        newUserLoading: false,
                        newUserError: error,
                    };
                }
                case STATUS.Clean: {
                    return {
                        ...state,
                        createdUserId: null,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.UpdateUser: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        editUserLoading: true,
                        editUserError: '',
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        editUserLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        editUserLoading: false,
                        editUserError: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.DeleteUser: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        deleteUserLoading: true,
                        deleteUserError: '',
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        deleteUserLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        deleteUserLoading: false,
                        deleteUserError: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.ResendInvite: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        resendInviteLoading: true,
                        resendInviteError: '',
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        resendInviteLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        resendInviteLoading: false,
                        resendInviteError: error,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
