import React, { ReactNode } from 'react';
import theme from '../../theme';

interface Props {
    // All style props coordinate with the name of the style in figma: https://www.figma.com/file/Q26187had5IZxXYR3BEn6a/Design-System?node-id=2%3A93
    textStyle: 'h1' | 'h2' | 'h3' | 'h4' | 'p1' | 'p2' | 'p3' | 'p4' | 'b1' | 'b2' | 'b3' | 'b4';
    children: ReactNode;
    color?: string;
    style?: any;
    className?: any;
    onClick?: any;
}
const Typography = (props: Props) => {
    const styles = {
        h1: {
            fontSize: 54,
            ...theme.typography.semiBold,
        },
        h2: {
            fontSize: 32,
            ...theme.typography.semiBold,
        },
        h3: {
            fontSize: 26,
            ...theme.typography.semiBold,
        },
        h4: {
            fontSize: 18,
            ...theme.typography.semiBold,
        },
        p1: {
            fontSize: 16,
            ...theme.typography.semiBold,
        },
        p2: {
            fontSize: 14,
            ...theme.typography.semiBold,
        },
        p3: {
            fontSize: 12,
            ...theme.typography.semiBold,
        },
        p4: {
            fontSize: 10,
            ...theme.typography.semiBold,
        },
        b1: {
            fontSize: 16,
            ...theme.typography.medium,
        },
        b2: {
            fontSize: 14,
            ...theme.typography.medium,
        },
        b3: {
            fontSize: 12,
            ...theme.typography.medium,
        },
        b4: {
            fontSize: 10,
            ...theme.typography.medium,
        },
    };

    const clickProps = props?.onClick ? { onClick: props.onClick } : {};

    return (
        <span
            style={{
                ...styles[props.textStyle],
                color: props.color ?? theme.palette.black,
                wordBreak: 'break-word',
                ...props.style,
            }}
            className={props.className}
            {...clickProps}
        >
            {props.children}
        </span>
    );
};
export default Typography;
