import React, { useEffect, useState } from 'react';

/*------- Components -----------*/
import Loader from '../Loader';

/*------- Types ---------------*/
import { SortTypeEnum, TableHeader, TableData } from './types';

/*-------------------- Assets --------------------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Icons ------------*/
import ArrowDownWhiteIcon from '../../images/icons/arrow_down_white.svg';
import HorizontalLineIcon from '../../images/icons/horizontal_line.svg';

/*--------- Misc ------------------*/
import { noop } from '../../utils/common';

interface Props {
    headers: TableHeader[];
    rows: TableData;
    loading: boolean;
    onSort: Function;
    sortingEnabled: boolean;
    height: string;
    wrapperStyles?: string;
    headerStyle?: string;
    itemStyle?: string;
    onHoverClick?: Function;
    customSortIndex?: number;
    customSortType?: SortTypeEnum;
}

export default function DataTable(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*------ Constants ---------*/
    const {
        headers,
        rows,
        loading,
        onSort,
        sortingEnabled,
        wrapperStyles,
        headerStyle,
        itemStyle,
        height,
        onHoverClick,
        customSortIndex,
        customSortType,
    } = props;

    /*------- State ------------*/
    const [tableRows, setTableRows] = useState<TableData>([]);

    // Column sorting
    const [sortIndex, setSortIndex] = useState<number | null>(customSortIndex);
    const [sortType, setSortType] = useState<SortTypeEnum>(customSortType);

    /*------- Effects ---------*/
    useEffect(() => {
        // Store the rows into the state in order to sort the rows.
        setTableRows(rows);
    }, [rows]);

    // Handle sort
    useEffect(() => {
        onSort(sortIndex, sortType);
    }, [sortIndex, sortType]);

    /*------- Functions ----------*/
    const handleSortColumn = (columnIndex: number) => {
        // Check if the column is already sorted
        if (sortIndex === columnIndex) {
            // Toggle sort type
            if (sortType === SortTypeEnum.ASC) {
                setSortType(SortTypeEnum.DESC);
            } else {
                setSortType(SortTypeEnum.ASC);
            }
        } else {
            setSortIndex(columnIndex);
        }
    };

    return (
        <div className={`${styleSheet.wrapper} ${wrapperStyles}`}>
            <table className={styleSheet.table} style={{ height: height }}>
                <thead style={{ display: 'table', width: '100%' }}>
                    <tr className={`${styleSheet.header} ${headerStyle}`}>
                        {headers.map((header, index) => (
                            <th
                                align={header.align ?? 'left'}
                                style={{
                                    width: header.width ?? 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {header.icon ? (
                                    <img src={header.icon} style={{ verticalAlign: 'middle' }} />
                                ) : (
                                    <></>
                                )}
                                <span>{header.name}</span>

                                {/* Sort widget */}
                                {header.orderable &&
                                    sortingEnabled &&
                                    (sortIndex === index ? (
                                        <span
                                            className={styleSheet.sortWidgetActive}
                                            onClick={() => {
                                                handleSortColumn(index);
                                            }}
                                        >
                                            <img
                                                src={ArrowDownWhiteIcon}
                                                height={5}
                                                style={{
                                                    transform:
                                                        sortType === SortTypeEnum.ASC
                                                            ? ''
                                                            : 'rotate(180deg)',
                                                }}
                                            />
                                        </span>
                                    ) : (
                                        <span
                                            className={styleSheet.sortWidgetInactive}
                                            onClick={() => {
                                                handleSortColumn(index);
                                            }}
                                        >
                                            <img src={HorizontalLineIcon} height={2.5} />
                                        </span>
                                    ))}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{ display: 'table', width: '100%' }}>
                    {loading ? (
                        <tr>
                            <td colSpan={headers.length} align={'center'}>
                                <div className={styleSheet.loaderContainer}>
                                    <Loader />
                                    <h3>Loading data</h3>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {tableRows.length === 0 && (
                                <tr>
                                    <td colSpan={headers.length} align={'center'}>
                                        <h3>No data</h3>
                                    </td>
                                </tr>
                            )}

                            {tableRows.map((row) => (
                                <tr
                                    className={`${styleSheet.row} ${itemStyle} ${
                                        onHoverClick && styleSheet.hover
                                    }`}
                                    onClick={() => {
                                        onHoverClick && onHoverClick(row);
                                    }}
                                >
                                    {row.map((item, index) => (
                                        <td
                                            className={styleSheet.rowElement}
                                            style={{
                                                width: headers[index].width ?? 'auto',
                                                textAlign: headers[index].itemTextAlign ?? 'left',
                                            }}
                                        >
                                            {item}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
}

DataTable.defaultProps = {
    headers: [],
    rows: [],
    loading: false,
    onSort: noop,
    sortingEnabled: true,
    height: 'auto',
    wrapperStyles: '',
    headerStyle: '',
    itemStyle: '',
    customSortIndex: null,
    customSortType: SortTypeEnum.ASC,
};
