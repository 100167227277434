import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

/*----- Components --------*/
import AnimatedDrawer from '../../../../components/AnimatedDrawer';
import AnimatedDrawerHeader from '../../../../components/AnimatedDrawerHeader';
import Loader from '../../../../components/Loader';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';

/*--------- Redux -----------*/
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientsRequested, setSelectedClient } from '../actions';
import { getClients, getSelectedAgent, isClientsLoading } from '../selectors';

/*--------- Styles -----------*/
import theme from '../../../../theme';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*------- Assets -------*/

/*-------- Constants -------*/
import { Agent, Client } from '../../../../utils/constants';

/*-------- Utils -------*/
import { isMobile, noop } from '../../../../utils/common';
import Button from '../../../../components/Button';
import Typography from '../../../../components/Typography';
import { maskPhoneNumber, unmaskPhoneNumber } from '../../../../utils/common/masks';
import { isValidPhoneNumber } from '../../../../utils/common/validations';
import { isEmpty } from 'lodash';
import { createAgentClientRequested } from '../../Clients/actions';
import { isCreateClientLoading } from '../../Clients/selectors';

interface Props {
    visible: boolean;
    onClose: Function;
    backgroundOpacity: number;
}

export default function AddClientDrawer(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const styleSheet = useStyles({ ...props, theme });

    /*-------- State -----------*/
    const [searchText, setSearchText] = useState<string>('');
    const [isCreatingNewUser, setIsCreatingNewUser] = useState<boolean>(false);
    const [screenHeight, setScreenHeight] = useState<number>(0);
    const [userData, setUserData] = useState<any>({});
    const [invalidFields, setInvalidFields] = useState<any>({});

    /*----- Constants -----*/
    const { onClose, backgroundOpacity, visible } = props;

    const closeDrawerRef = useRef<any>(null);
    const dispatch = useDispatch();
    const isMob = isMobile();

    const userTypeClass = isMob ? styleSheet.userTypeMobile : styleSheet.userType;
    const buttonClassName = isMob
        ? styleSheet.scheduleButtonsContainerMobile
        : styleSheet.scheduleButtonsContainer;

    const inputOptions: any = {
        firstName: {
            placeholder: 'First Name',
            onChange: (e: string) => setUserData({ ...userData, firstName: e }),
        },
        lastName: {
            placeholder: 'Last Name',
            onChange: (e: string) => setUserData({ ...userData, lastName: e }),
        },
        phone: {
            placeholder: 'Phone Number',
            onChange: (e: string) => setUserData({ ...userData, phone: maskPhoneNumber(e) }),
        },
    };

    /*-------- Selectors --------*/
    const loading = useSelector<any, boolean>(isClientsLoading);
    const clients = useSelector<any, Client[]>(getClients);
    const selectedAgent = useSelector<any, Agent>(getSelectedAgent);
    const loadingCreateClient = useSelector<any, boolean>(isCreateClientLoading);

    /*-------- Effects ----------*/
    useEffect(() => {
        if (selectedAgent?.stitchUserId || selectedAgent?.tempUserId) {
            dispatch(fetchClientsRequested(selectedAgent.stitchUserId || selectedAgent.tempUserId));
        }
    }, [selectedAgent]);

    /**
     * Make sure that the client scroll view resizes when the screen
     * is resized.
     */
    useLayoutEffect(() => {
        window.addEventListener('resize', () => setScreenHeight(window.innerHeight));
        setScreenHeight(window.innerHeight);
        return () =>
            window.removeEventListener('resize', () => setScreenHeight(window.innerHeight));
    }, []);

    /*------- Functions --------*/

    // string match clients with search text
    const filteredClients = (text: string) => {
        if (!text?.length || !clients?.length) return clients;
        const filter: string = text.toLocaleLowerCase();
        const sorted: any = clients.filter((a: any) => {
            var name = `${a.firstName} ${a.lastName}`;
            name = name.toLocaleLowerCase();
            return name.includes(filter);
        });

        return sorted;
    };

    // check that the user has filled in all necessary fields
    const disabledConditions = () => {
        // check the necessary fields exist
        var invalid: any = {};

        Object.keys(inputOptions).map((e: any) => {
            if (!userData[e]?.length) {
                invalid[e] = true;
            }
        });

        if (!isValidPhoneNumber(userData['phone'] ?? '')) {
            invalid['phone'] = true;
        }

        setInvalidFields(invalid);

        if (isEmpty(invalid)) {
            dispatch(
                createAgentClientRequested(
                    selectedAgent?._id,
                    userData?.firstName,
                    userData?.lastName,
                    unmaskPhoneNumber(userData?.phone),
                    () => {
                        // Data cleanup
                        setUserData({});
                        setIsCreatingNewUser(false);
                        dispatch(
                            fetchClientsRequested(
                                selectedAgent.stitchUserId || selectedAgent.tempUserId,
                            ),
                        );
                    },
                ),
            );
        }
    };

    const searchBarComponent = (
        <div className={styleSheet.searchBarContainer}>
            <input
                placeholder={'Search for Existing Client'}
                onChange={(e: any) => {
                    setSearchText(e.target.value);
                }}
                className={styleSheet.searchBar}
            />
        </div>
    );

    const clientsComponent = (
        <>
            {loading ? (
                <div className={styleSheet.loaderContainer}>
                    <Loader size={50} />
                    <p>Searching clients</p>
                </div>
            ) : (
                <>
                    <div
                        className={styleSheet.clientsContainer}
                        style={clients?.length ? { height: screenHeight - 250 } : {}}
                    >
                        {clients?.length ? (
                            filteredClients(searchText).map((e: any) => {
                                return (
                                    <ClickableWithFeedback
                                        onClick={() => {
                                            dispatch(setSelectedClient(e));
                                            closeDrawerRef.current.closeDrawer();
                                        }}
                                    >
                                        <div className={styleSheet.clientOption}>
                                            {`${e.firstName} ${e.lastName}`}
                                        </div>
                                    </ClickableWithFeedback>
                                );
                            })
                        ) : (
                            <div className={styleSheet.clientOption}>No clients</div>
                        )}
                    </div>
                    <div className={styleSheet.addClientButton}>
                        <ClickableWithFeedback onClick={() => setIsCreatingNewUser(true)}>
                            Create New Client
                        </ClickableWithFeedback>
                    </div>
                </>
            )}
        </>
    );

    const bottomButton = (
        <div className={buttonClassName}>
            {loadingCreateClient ? (
                <Loader />
            ) : (
                <Button
                    onClick={() => {
                        disabledConditions();
                    }}
                    title={'Submit'}
                    customStyle={styleSheet.buttonSubmit}
                />
            )}
        </div>
    );

    const getInputOption = (type: string) => {
        var optStyle = inputOptions[type].style || {};
        if (invalidFields[type]) {
            optStyle = {
                ...optStyle,
                border: '1px solid red',
            };
        }
        return (
            <input
                className={styleSheet.input}
                placeholder={inputOptions[type].placeholder}
                onChange={(e: any) => inputOptions[type].onChange(e.target.value)}
                onFocus={inputOptions[type].onFocus || null}
                style={optStyle}
                value={userData[type]}
            />
        );
    };

    if (!visible) {
        return <></>;
    }

    return (
        <AnimatedDrawer
            onClose={onClose}
            ref={closeDrawerRef}
            backgroundOpacity={backgroundOpacity}
        >
            <>
                <AnimatedDrawerHeader
                    title={'Add a Client'}
                    onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
                />

                {isCreatingNewUser ? (
                    <>
                        <div className={styleSheet.addClientButton}>
                            <ClickableWithFeedback onClick={() => setIsCreatingNewUser(false)}>
                                Back to Search for Client
                            </ClickableWithFeedback>
                        </div>
                        <div className={userTypeClass}>
                            <div className={styleSheet.container}>
                                {/* Name */}
                                <Typography
                                    textStyle="p1"
                                    color={theme.palette.mediumGrey}
                                    className={styleSheet.typography}
                                >
                                    Name
                                </Typography>
                                <div className={styleSheet.metaRow}>
                                    {getInputOption('firstName')}
                                    {getInputOption('lastName')}
                                </div>
                                {/* Contact */}
                                <Typography
                                    textStyle="p1"
                                    color={theme.palette.mediumGrey}
                                    className={styleSheet.typography}
                                >
                                    Contact
                                </Typography>
                                <div className={styleSheet.metaRow}>{getInputOption('phone')}</div>
                            </div>
                        </div>
                        {bottomButton}
                    </>
                ) : (
                    <>
                        {searchBarComponent}
                        {clientsComponent}
                    </>
                )}
            </>
        </AnimatedDrawer>
    );
}

AddClientDrawer.defaultProps = {
    visible: false,
    onClose: noop,
    backgroundOpacity: 0,
};
