import { Actions } from './actions';
import { STATUS } from '../../../utils/constants';

export const initialState = {
    /*-------- Create Agent Client -----------*/
    createClientLoading: false,
    createdClient: null,
    errorCreateClient: '',
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case Actions.CreateAgentClient: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        createClientLoading: true,
                        errorCreateClient: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { client } = action;
                    return {
                        ...state,
                        createClientLoading: false,
                        client: client,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        createClientLoading: false,
                        errorCreateClient: error,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
