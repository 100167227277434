export default (theme) => {
    return {
        breadcumbContainer: {
            backgroundColor: theme.palette.white,
            padding: '20px 40px',

            ...theme.typography.semiBold,
            fontSize: 18,
        },

        container: {
            margin: '16px 16px 0px 16px',
            display: 'flex',
            backgroundColor: theme.palette.white,
            height: '82vh',
        },
        searchContainer: {
            display: 'flex',
            justifyContent: 'center',
            border: {
                color: theme.palette.separatorGrey,
                width: 2,
                style: 'none none solid none',
            },
        },

        searchBarInputStyles: {
            border: 'none',
            backgroundColor: theme.palette.background,

            ...theme.paddingHorizontal(25),
            ...theme.marginVertical(15),

            width: 330,
            height: 30,
            borderRadius: 5,

            fontSize: 16,
            textAlign: 'left',
            color: theme.palette.black,
        },

        listingsOverlayMap: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.background,
            overflowX: 'hidden',
            alignSelf: 'center',

            height: '100%',
            width: '100%',
        },

        mapView: {
            width: '70%',
            height: '100%',
        },

        previewCardContainer: {
            paddingTop: 8,
            paddingBottom: 16,
            cursor: 'pointer',
            backgroundColor: '#f6f6f6',
            width: '100%',
            borderLeft: '4px solid transparent',
        },
        previewCardContainerSelected: {
            paddingTop: 8,
            paddingBottom: 16,
            cursor: 'pointer',
            backgroundColor: 'rgba(72, 164, 255, 0.15)',
            width: '100%',
            paddingLeft: 44,
            borderLeft: '4px solid #48a4ff',
        },
    };
};
