import { createSelector } from 'reselect';

export const getClientState = (state: any) => state.clients;

/*-------------- Create Client ----------------*/
export const getCreatedClient = createSelector(getClientState, (state: any) => state.createdClient);
export const isCreateClientLoading = createSelector(
    getClientState,
    (state: any) => state.createClientLoading,
);
export const getCreateClientError = createSelector(
    getClientState,
    (state: any) => state.errorCreateClient,
);
