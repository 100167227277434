export default (theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            width: 60,
            height: 'auto',
            ...theme.marginHorizontal(10),
            borderRadius: 20,

            fontSize: 18,
            color: theme.palette.blue,
            backgroundColor: theme.palette.semiLightBlue,
            cursor: 'pointer',
        },
    };
};
