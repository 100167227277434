export default (theme: any) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'space-between',
        },
        button: {
            backgroundColor: '#48A4FF',
            borderRadius: 5,
            width: 270,
            height: 40,
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
    };
};
