/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { addClientRequested } from '../../actions';
import { getAddClientLoading, getNewClient } from '../../selectors';

/*-------- Components ---------*/
import Tag from '../../../../../components/Tag';
import Switch from '../../../../../components/Switch';
import Loader from '../../../../../components/Loader';
import Typography from '../../../../../components/Typography';
import AnimatedModal from '../../../../../components/AnimatedModal';
import ButtonComponent from '../../../../../components/ButtonComponent';
import ClickableWithFeedback from '../../../../../components/ClickableWithFeedback';
import UserSearchDrawer from './UserSearchDrawer';

/*-------- Utils ---------*/
import { formatEmailForDisplay, formatPhoneNumberForDisplay } from '../../../../../utils/common';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../../theme';

/*-------- Icons ---------*/
import xIcon from '../../../../../images/icons/xBlack.svg';
import CheckedIcon from '../../../../../images/icons/checkedIcon.svg';
import UncheckedIcon from '../../../../../images/icons/uncheckedIcon.svg';

interface Props {
    users: any;
    userType: 'agents' | 'consumers';
    agentListing: any;
    setAgentListing: Function;
    isDataLoading: boolean;
    marketName: string;
    brokerageId: string;
}
const UserSection = ({
    users,
    userType,
    agentListing,
    setAgentListing,
    isDataLoading,
    marketName,
    brokerageId,
}: Props) => {
    const addClientLoading = useSelector(getAddClientLoading);
    const newClientOnListing = useSelector(getNewClient);
    const dispatch = useDispatch();
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const [addUser, setAddUser] = useState(false);
    const [addNewUser, setAddNewUser] = useState(false);
    const [newClient, setNewClient] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
    });
    const [displayValue, setDisplayValue] = useState('');

    const includedUsers = users?.map((user: any) => JSON.stringify(user?._id));

    const goAndShow: boolean = agentListing.type === 'go_and_show';

    document?.getElementById('clientPhone')?.addEventListener('input', function (e: any) {
        var el = e?.target?.value;
        var x = el.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        setDisplayValue(!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
    });

    useEffect(() => {
        if (!addClientLoading && newClientOnListing) {
            let consumers = [...agentListing.consumers, newClientOnListing];
            setAgentListing({ ...agentListing, consumers });
            setAddNewUser(false);
        }
    }, [newClientOnListing]);

    useEffect(() => {
        // This handles if the user pastes a phone number from their clipboard.
        // It will set the client obj properly.
        if (displayValue.length && !newClient.phoneNumber.length) {
            setNewClient({ ...newClient, phoneNumber: displayValue });
        }
    }, [displayValue]);

    const disabledAddClient = () => {
        if (!newClient.firstName.length) {
            return true;
        }
        if (!newClient.lastName.length) {
            return true;
        }
        if (newClient.phoneNumber.length !== 14) {
            return true;
        }
        return false;
    };

    // Removes the user specified in the user prop form the array
    // specified in agentOrUser prop
    const removeUser = (index: number) => {
        const arrayToRemoveFrom = agentListing[userType] || [];
        if (index >= 0) {
            arrayToRemoveFrom?.splice(index, 1);
            agentListing[userType] = [...arrayToRemoveFrom];
            setAgentListing({ ...agentListing });
        }
    };

    const getIsListingAgent = (index: number) => userType === 'agents' && index === 0;

    const UserCard = ({ user, index }: { user: any; index: number }) => {
        return (
            <div className={styleSheet.card}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography textStyle={'p1'}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    {!getIsListingAgent(index) ? (
                        <ClickableWithFeedback onClick={() => removeUser(index)}>
                            <Typography textStyle={'p3'} color={indexTheme.palette.red}>
                                Remove
                            </Typography>
                        </ClickableWithFeedback>
                    ) : null}
                </div>
                <Typography textStyle={'b1'}>{formatEmailForDisplay(user.email)}</Typography>
                <Typography textStyle={'b1'}>
                    +1 {formatPhoneNumberForDisplay(user.phoneNumber)}
                </Typography>
                <div className={styleSheet.separator} />
                {!goAndShow && (
                    <>
                        <div style={{ ...indexTheme.rowStyles }}>
                            <Typography textStyle={'b1'}>Can Approve Showings</Typography>
                            <Switch
                                containerStyling={styleSheet.switchStyling}
                                checked={user?.canApprove}
                                onToggle={() => {
                                    let newArray = [...users];
                                    newArray[index] = {
                                        ...users[index],
                                        canApprove: !users[index].canApprove,
                                    };
                                    setAgentListing({
                                        ...agentListing,
                                        [userType]: newArray,
                                    });
                                }}
                                disabled={user.disabledToggle || false}
                            />
                        </div>
                        <div className={styleSheet.separator} />
                    </>
                )}
                <Typography
                    textStyle={'b1'}
                    style={{ marginBottom: 9 }}
                    color={indexTheme.palette.mediumGrey}
                >
                    Notification Preference:
                </Typography>
                <div
                    style={{ justifyContent: goAndShow ? 'flex-start' : 'space-between' }}
                    className={styleSheet.textEmailContainer}
                >
                    {['text', 'email'].map((item: string) => (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ClickableWithFeedback
                                onClick={() => {
                                    let newArray = [...users];
                                    if (users[index].notificationSettings.includes(item)) {
                                        // If text is already set
                                        const newNotificationsSettings = [
                                            ...users[index].notificationSettings,
                                        ];
                                        newNotificationsSettings.splice(
                                            newNotificationsSettings.indexOf(item),
                                            1,
                                        );
                                        newArray[index] = {
                                            ...users[index],
                                            notificationSettings: newNotificationsSettings,
                                        };
                                    } else {
                                        // If text is not already set
                                        const newNotificationsSettings = [
                                            ...users[index].notificationSettings,
                                            item,
                                        ];
                                        newArray[index] = {
                                            ...users[index],
                                            notificationSettings: newNotificationsSettings,
                                        };
                                    }
                                    // setAgentListing
                                    setAgentListing({ ...agentListing, [userType]: newArray });
                                }}
                                style={{ marginRight: 10 }}
                            >
                                <img
                                    src={
                                        user.notificationSettings.includes(item)
                                            ? CheckedIcon
                                            : UncheckedIcon
                                    }
                                />
                            </ClickableWithFeedback>
                            <Typography style={{ marginRight: 10 }} textStyle={'b2'}>
                                {item.substr(0, 1).toUpperCase() + item.substr(1, item.length)}
                            </Typography>
                        </div>
                    ))}
                    {!goAndShow && (
                        <Tag
                            background={true}
                            onClick={() => {}}
                            style={{ width: 120 }}
                            color={user.canApprove ? 'green' : 'red'}
                            text={user.canApprove ? 'Can Approve' : "Can't Approve"}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <Typography textStyle={'h4'}>{userType === 'agents' ? 'Agents' : 'Clients'}</Typography>

            <div className={styleSheet.cardContainer}>
                {isDataLoading ? (
                    <div style={{ marginTop: 50 }}>
                        <Loader />
                    </div>
                ) : (
                    <>
                        {users?.map((user: any, index: number) => (
                            <UserCard user={user} index={index} />
                        ))}
                        <div
                            className={styleSheet.card}
                            style={{ alignItems: 'center', minHeight: goAndShow ? 150 : 202 }}
                        >
                            <ButtonComponent
                                color={indexTheme.palette.blue}
                                onClick={() => {
                                    setAddUser(true);
                                }}
                                width={257}
                            >
                                Connect{' '}
                                {userType === 'agents' ? 'Co-Listing Agent' : 'Seller Client'}
                            </ButtonComponent>
                        </div>
                    </>
                )}
            </div>
            {addUser ? (
                <UserSearchDrawer
                    userType={userType}
                    onCloseDrawer={() => setAddUser(false)}
                    onPressSet={(newUser: any) => {
                        setAgentListing({
                            ...agentListing,
                            [userType]: [...agentListing[userType], newUser],
                        });
                        setAddUser(false);
                    }}
                    includedUsers={includedUsers}
                    onPressConnectNew={() => {
                        setAddUser(false);
                        setAddNewUser(true);
                    }}
                    marketName={marketName}
                    brokerageId={brokerageId}
                    agentListingId={agentListing.agents ? agentListing.agents[0]._id : ''}
                />
            ) : addNewUser ? (
                <AnimatedModal
                    style={{ minHeight: 310, margin: 'auto', top: null }}
                    set={setAddNewUser}
                    modalVisible={addNewUser}
                >
                    <div className={styleSheet.header}>
                        <Typography textStyle={'p1'} color={indexTheme.palette.mediumGrey}>
                            Add New User
                        </Typography>
                        <ClickableWithFeedback
                            onClick={() => {
                                setAddNewUser(false);
                                setNewClient({ firstName: '', lastName: '', phoneNumber: '' });
                            }}
                        >
                            <img src={xIcon} />
                        </ClickableWithFeedback>
                    </div>
                    <div className={styleSheet.addNewUserModal}>
                        <input
                            value={newClient.firstName}
                            onChange={(e) =>
                                setNewClient({ ...newClient, firstName: e.target.value })
                            }
                            placeholder="First Name"
                            className={styleSheet.inputContainer}
                        />
                        <input
                            value={newClient.lastName}
                            onChange={(e) =>
                                setNewClient({ ...newClient, lastName: e.target.value })
                            }
                            placeholder="Last Name"
                            className={styleSheet.inputContainer}
                        />
                        <input
                            value={displayValue}
                            onChange={(e) => {
                                if (e.target.value.length <= 14) {
                                    setNewClient({ ...newClient, phoneNumber: e.target.value });
                                }
                            }}
                            placeholder="Phone Number"
                            className={styleSheet.inputContainer}
                            id="clientPhone"
                        />
                        {addClientLoading ? (
                            <Loader />
                        ) : (
                            <ButtonComponent
                                onClick={() =>
                                    dispatch(
                                        addClientRequested(newClient, agentListing?.agents[0]._id),
                                    )
                                }
                                fill
                                color={indexTheme.palette.blue}
                                width={257}
                                disabled={disabledAddClient() && !isDataLoading}
                            >
                                {isDataLoading ? <Loader /> : <> Connect Client </>}
                            </ButtonComponent>
                        )}
                    </div>
                </AnimatedModal>
            ) : null}
        </>
    );
};
export default UserSection;
