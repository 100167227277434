/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

/*----- Redux imports -------*/
import { useSelector } from 'react-redux';
import { getProfile, getSelectedBrokerage } from '../../domains/main/Profile/selectors';

/*----- Components imports -------*/
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Users from '../../domains/main/Users';
import Agents from '../../domains/main/Agents';
import Profile from '../../domains/main/Profile';
import Showings from '../../domains/main/Showings';
import Listings from '../../domains/main/Listings';
import Loader from '../../components/Loader';
import Dashboard from '../../domains/main/Dashboard';
import SearchMap from '../../domains/main/SearchMap';
import ActivityFeed from '../../domains/main/Activity';
import ListingDetails from '../../domains/main/Listings/DetailsScreen';
import ShowingDetails from '../../domains/main/Showings/ShowingDetails';
import SelectedListingScreen from '../../domains/main/Listings/SelectedListingScreen';

/*----- Assets imports -------*/
import styles from './styles.module.css';
import AgentProfile from '../../domains/main/Agents/AgentProfile';
import ConfigureListing from '../../domains/main/Listings/ConfigureListing';
import InactiveBrokerageSuite from '../../components/InactiveBrokerageSuite';

function AuthenticatedApp() {
    /*----- Constants -------*/
    const history = useHistory();

    /*----- Selectors -------*/
    const profileData = useSelector(getProfile);
    const selectedBrokerage = useSelector(getSelectedBrokerage);

    /*----- Use Effect -------*/
    // no matter what, when the app refreshes or the user logs in, send the user to the dashboard
    useEffect(() => {
        // Testing
        // history.push('/listingDetails/odessa/TestListing');
    }, [history]);

    if (selectedBrokerage !== null && !selectedBrokerage?.brokerageSuite) {
        return <InactiveBrokerageSuite />;
    }

    return (
        <div className={styles.root}>
            <Navbar />
            <Sidebar />
            <div className={styles.container}>
                {profileData && selectedBrokerage ? (
                    <Switch>
                        {/* Users */}
                        <Route exact path="/users" render={() => <Users />} />

                        {/* Dashboard */}
                        {/* <Route exact path="/dashboard" render={() => <Dashboard />} /> */}

                        {/* Showings */}
                        <Route exact path="/showings" render={() => <Showings />} />

                        {/* Listings */}
                        <Route exact path="/listings" render={() => <Listings />} />

                        {/* Agents */}
                        <Route exact path="/agents" render={() => <Agents />} />
                        <Route
                            exact
                            path="/agents/profile/:agentId"
                            render={() => <AgentProfile />}
                        />

                        {/* Search Map */}
                        <Route exact path="/search" render={() => <SearchMap />} />

                        {/* Listings */}
                        <Route exact path="/listings" render={() => <Listings />} />

                        {/* Listings Inner Page */}
                        <Route
                            exact
                            path="/listings/:mlsName/:mlsListingId"
                            render={() => <SelectedListingScreen />}
                        />

                        {/* Listing Configuration */}
                        <Route
                            exact
                            path="/listings/configure/:mlsName/:mlsListingId"
                            render={() => <ConfigureListing />}
                        />

                        {/* Listing details */}
                        <Route
                            exact
                            path="/listingDetails/:mlsName/:mlsListingId"
                            render={() => <ListingDetails />}
                        />

                        {/* Showing Details */}
                        <Route
                            path="/showings/details/:showingId"
                            render={() => <ShowingDetails />}
                        />

                        {/**/}
                        <Route path="/profile" render={() => <Profile />} />

                        {/* Activity Feed */}
                        {/* <Route exact path="/activity-feed" render={() => <ActivityFeed />} /> */}

                        {/* Default */}
                        <Route path="/" render={() => <Redirect to="/search" />} />
                    </Switch>
                ) : (
                    <div className={styles.pageLoader}>
                        <Loader />
                        <span>Fetching your data</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AuthenticatedApp;
