export default (theme) => {
    return {
        root: {
            top: 0,
            left: 0,
            position: 'fixed',
            backgroundColor: theme.palette.white,
            zIndex: 999,

            width: '98%',
            height: 80,
            ...theme.paddingHorizontal(20),

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            borderBottom: {
                color: theme.palette.separatorGrey,
                width: 1,
                style: 'solid',
            },
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        profileButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            width: 60,
            height: 'auto',
            ...theme.marginHorizontal(10),
            borderRadius: 20,

            fontSize: 18,
            color: theme.palette.blue,
            backgroundColor: theme.palette.semiLightBlue,
            cursor: 'pointer',
        },
        profileMenu: {
            padding: 12,
            minWidth: 250,
            color: theme.palette.white,
            fontSize: 14,
        },
        profileMenuList: {
            listStyle: 'none',
            padding: 0,
            marginBottom: 20,
            cursor: 'pointer',
        },
        profileMenuElement: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            ...theme.marginVertical(10),
            paddingBottom: 10,

            borderBottom: {
                color: theme.palette.separatorGrey,
                width: 1,
                style: 'solid',
            },

            textDecoration: 'none',
            color: theme.palette.black,
        },
        right: {
            marginLeft: 'auto',
        },
        marginLeft: {
            marginLeft: 15,
        },
    };
};
