import React, { useEffect, useState } from 'react';

/*-------- Redux ----------------*/
import { useDispatch, useSelector } from 'react-redux';
import { InviteUserClean, InviteUserRequested } from '../actions';
import { getCreatedUserId, isNewUserLoading } from '../selectors';
import { getProfileData } from '../../Profile/selectors';

/*--------- Assets --------------*/
import styles from './style';
import { createUseStyles, useTheme } from 'react-jss';

/*------- Icons ----------------*/
import CloseIcon from '../../../../images/icons/close_modal_blue.svg';

/*------- Components -----------*/
import { Modal as FakeModal } from '@material-ui/core';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';

/*------- Types / Constants ----------*/
import { InviteUserFormValues } from './types';
import { ObjectId } from 'mongodb';

/*------- Misc -------------*/
import { noop } from '../../../../utils/common';
import { maskPhoneNumber, unmaskPhoneNumber } from '../../../../utils/common/masks';
import { isValidEmail, isValidPhoneNumber } from '../../../../utils/common/validations';
import { ROLES } from '../constants';
import SelectInput from '../../../../components/SelectInput';
import { BrokerageUserRole } from '../../../../utils/constants';

interface Props {
    open: boolean;
    onClose: Function;
}

export default function InviteUserModal(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Redux ------------*/
    const dispatch = useDispatch();

    /*----- Selectors --------*/
    const loadingNewUser = useSelector<any, boolean>(isNewUserLoading);
    const createdUserId = useSelector<any, ObjectId>(getCreatedUserId);
    const profileData = useSelector<any, any>(getProfileData);

    /*-------- State ---------------*/
    const [values, setValues] = useState<InviteUserFormValues>({});

    /*-------- Constants -----------*/
    const { open, onClose } = props;
    const Modal = FakeModal as any;
    const validInput =
        values?.firstName?.length &&
        values?.lastName?.length &&
        isValidEmail(values?.email ?? '') &&
        isValidPhoneNumber(values?.phoneNumber ?? '');

    /*-------- Functions -----------*/
    // On show
    useEffect(() => {
        if (open) {
            dispatch(InviteUserClean());
            setValues({ role: 'Admin' });
        }
    }, [open]);

    const handleClose = () => {
        if (!loadingNewUser) {
            onClose();
        }
    };

    const handleChangeValue = (event: React.FormEvent<HTMLInputElement>) => {
        const value =
            event.currentTarget.name === 'phoneNumber'
                ? maskPhoneNumber(event.currentTarget.value ?? '')
                : event.currentTarget.value;
        setValues({
            ...values,
            [event.currentTarget.name]: value,
        });
    };

    const sendInvite = () => {
        if (
            profileData?.brokerageId &&
            values.firstName &&
            values.lastName &&
            values.email &&
            values.phoneNumber &&
            values.role
        ) {
            dispatch(
                InviteUserRequested(
                    profileData?.brokerageId,
                    values.firstName,
                    values.lastName,
                    values.email,
                    unmaskPhoneNumber(values.phoneNumber),
                    values.role,
                ),
            );
        }
    };

    return (
        <Modal open={open} onClose={handleClose} className={styleSheet.root}>
            <div className={styleSheet.container}>
                <ClickableWithFeedback className={styleSheet.closeButton} onClick={handleClose}>
                    <img src={CloseIcon} height={30} />
                </ClickableWithFeedback>

                {createdUserId === null ? (
                    <>
                        <p className={styleSheet.title}>Invite New User</p>

                        <div className={styleSheet.body}>
                            <div className={styleSheet.inputContent}>
                                <p className={styleSheet.inputTitle}>Name</p>
                                <div className={styleSheet.inputGroup}>
                                    <TextInput
                                        name={'firstName'}
                                        value={values?.firstName}
                                        placeholder={'First Name'}
                                        onChangeValue={(event: React.FormEvent<HTMLInputElement>) =>
                                            handleChangeValue(event)
                                        }
                                    />
                                    <TextInput
                                        name={'lastName'}
                                        value={values?.lastName}
                                        placeholder={'Last Name'}
                                        onChangeValue={(event: React.FormEvent<HTMLInputElement>) =>
                                            handleChangeValue(event)
                                        }
                                    />
                                </div>

                                <p className={styleSheet.inputTitle}>Contact</p>
                                <div className={styleSheet.inputGroup}>
                                    <TextInput
                                        name={'email'}
                                        value={values?.email}
                                        placeholder={'Email'}
                                        onChangeValue={(event: React.FormEvent<HTMLInputElement>) =>
                                            handleChangeValue(event)
                                        }
                                    />
                                    <TextInput
                                        name={'phoneNumber'}
                                        value={values?.phoneNumber}
                                        placeholder={'Phone Number'}
                                        onChangeValue={(event: React.FormEvent<HTMLInputElement>) =>
                                            handleChangeValue(event)
                                        }
                                    />
                                </div>
                                <div className={styleSheet.inputGroup}>
                                    <SelectInput
                                        onSelect={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleChangeValue(event);
                                        }}
                                        options={
                                            profileData?.role !== 'Super Admin'
                                                ? [{ text: 'Admin', value: 'Admin' }]
                                                : ROLES
                                        }
                                        selectedOption={values?.role}
                                        disabled={false}
                                    />
                                </div>
                            </div>

                            <div className={styleSheet.button}>
                                <Button
                                    title="Invite"
                                    widthType="Full"
                                    onClick={sendInvite}
                                    disabled={!validInput}
                                    loading={loadingNewUser}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <p className={styleSheet.title}>Awesome!</p>
                        <p className={styleSheet.subtitle}>
                            You’ve successfully sent an invitation!
                        </p>
                        <div className={styleSheet.body}>
                            <p className={styleSheet.semiGrey}>
                                You’ll receive a notification once the user has accepted your
                                invitation.
                            </p>
                            <p className={styleSheet.grey}>
                                Why not have some coffee in the meantime?
                            </p>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
}

InviteUserModal.defaultProps = {
    open: false,
    onClose: noop,
};
