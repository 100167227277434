import React from 'react';
import styles from './styles';

import { createUseStyles, useTheme } from 'react-jss';
import ClickableWithFeedback from '../ClickableWithFeedback';
import CloseIcon from '../../images/icons/circleCloseBlack.svg';

interface Props {
    onClosePress: Function;
    title: string;
}

const AnimatedDrawerHeader = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div className={styleSheet.headerContainer}>
            <ClickableWithFeedback
                onClick={() => {
                    props.onClosePress();
                }}
                className={styleSheet.closeIconContainer}
            >
                <img src={CloseIcon} className={styleSheet.closeIcon} />
            </ClickableWithFeedback>
            <div className={styleSheet.drawerHeaderText}>{props.title}</div>
        </div>
    );
};
export default AnimatedDrawerHeader;
