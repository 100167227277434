import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { RemoveScroll } from 'react-remove-scroll';
import { animated, useSpring } from 'react-spring';

interface Props {
    set: Function;
    modalVisible: boolean;
    children: any;
    style?: any;
    // Disable closing the modal on background click
    backgroundEventDisabled?: boolean;
}

const AnimatedModal = forwardRef(
    ({ set, modalVisible, children, style, backgroundEventDisabled = false }: Props, ref?) => {
        const useStyles = createUseStyles(styles);
        const theme = useTheme();
        const styleSheet = useStyles({ theme, style });

        const [init, setInit] = useState<boolean>(false);

        const closeModal = backgroundEventDisabled
            ? () => {}
            : () => {
                  setInit(false);
                  setTimeout(() => {
                      set(false);
                  }, 350);
              };

        useImperativeHandle(ref, () => ({ closeModal }));

        useEffect(() => {
            if (modalVisible) {
                setInit(true);
            }
            return () => {};
        }, [modalVisible]);

        const animatedStyleSheet = {
            backgroundFade: useSpring({
                backgroundColor: `rgba(0,0,0,${init ? 0.8 : 0})`,
            }),
            modalSlideUp: useSpring({
                marginTop: init ? 0 : 6000,
            }),
        };

        if (!modalVisible) return <></>;

        return (
            <RemoveScroll>
                <animated.div
                    onClick={closeModal}
                    className={styleSheet.root}
                    style={animatedStyleSheet.backgroundFade}
                >
                    <animated.div
                        onClick={(e) => {
                            e.stopPropagation(); // dont close the modal
                        }}
                        className={styleSheet.modal}
                        style={{ ...style, ...animatedStyleSheet.modalSlideUp }}
                    >
                        {children}
                    </animated.div>
                </animated.div>
            </RemoveScroll>
        );
    },
);
export default AnimatedModal;
