import { unmaskPhoneNumber } from './masks';

export const isValidEmail = (email: string) => {
    return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
};

export const isValidPhoneNumber = (phoneNumber: string) => {
    const number = unmaskPhoneNumber(phoneNumber);

    return number.match(/^\+1[0-9]{10}$/);
};
