export default (theme) => {
    return {
        root: {},
        selectionContainer: {
            backgroundColor: theme.palette.background,
            width: 257,
            height: 30,
            border: {
                radius: 5,
                width: 0,
            },
        },
        selection: {},
    };
};
