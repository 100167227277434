export default (theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            height: 65,
            ...theme.paddingHorizontal(15),
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.grey,
            borderStyle: 'solid',
            backgroundColor: theme.palette.white,
            padding: 30,
        },
        leftSide: {
            fontSize: 18,
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: theme.palette.mediumGrey,
            width: '100%',
        },
        searchBarContainer: {
            marginLeft: 'auto',
        },
        searchBar: {
            backgroundColor: theme.palette.background,
            height: 30,
            minWidth: 475,
            color: theme.palette.mediumGrey,
            fontSize: 18,
            textAlign: 'center',
            borderRadius: 5,
            borderWidth: 0,
        },
        button: {},
        header: {
            flex: 1,
            height: 100,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        row: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        searchInputContainer: {
            height: 40,
            width: 530,
            marginRight: 30,
        },
        heading: {
            fontSize: 18,
            marginLeft: 57,
            fontFamily: 'Poppins',
            justifySelf: 'flex-start',
            color: theme.palette.mediumGrey,
            fontFamily: 'Poppins-Medium',
        },
        numberOfPage: {
            width: 138,
            height: 26,
            fontSize: 15,
            marginRight: 65,
            borderRadius: 5,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Poppins',
            justifyContent: 'center',
            color: theme.palette.mediumGrey,
            backgroundColor: theme.palette.separatorGrey,
            fontFamily: 'Poppins-Medium',
        },
        blueText: {
            fontSize: 15,
            marginRight: 50,
            color: theme.palette.blue,
        },
        tableHeader: {
            height: 79,
        },
        wrapperStyles: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
        },
        headerStyle: {
            height: 79,
            borderBottom: `1px solid #d3d3d3`,
            backgroundColor: 'white',
            paddingLeft: 10,
        },
        rowitemStyle: {
            backgroundColor: 'white',
            borderTop: '10px solid #F6F6F6',
        },
        itemStyle: {
            textAlign: 'center',
        },
    };
};
