import { Actions } from './actions';
import { STATUS } from '../../../utils/constants';

export const initialState = {
    /*-------- Fetch Listings by GEO -----------*/
    geoloading: false,
    geoListings: [],
    geoError: '',

    /*------ Fetch Listings by Text Search / Id ------*/
    searchLoading: false,
    searchText: '',
    searchListings: [],
    searchError: '',
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case Actions.FetchGeoListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        geoLoading: true,
                        geoError: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { listings } = action;
                    return {
                        ...state,
                        geoLoading: false,
                        geoListings: listings,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        geoLoading: false,
                        geoError: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.FetchSearchListings: {
            switch (status) {
                case STATUS.Requested: {
                    const { searchedText } = action;
                    return {
                        ...state,
                        searchLoading: true,
                        searchText: searchedText,
                    };
                }
                case STATUS.Succeeded: {
                    const { listings } = action;
                    return {
                        ...state,
                        searchListings: listings,
                        searchLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        searchLoading: false,
                        searchError: error,
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}
