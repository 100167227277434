import React, { useEffect } from 'react';

/*--------- Assets --------------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*------- Icons ----------------*/
import CloseIcon from '../../../../images/icons/close_modal_gray.svg';

/*------- Components -----------*/
import { Modal as FakeModal } from '@material-ui/core';
import Button from '../../../../components/Button';

/*------- Misc -------------*/
import { noop } from '../../../../utils/common';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';

interface Props {
    open: boolean;
    onClose: Function;
}

export default function ContactUsModal(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- Constants -----------*/
    const { open, onClose } = props;
    const Modal = FakeModal as any;

    /*----------- Effect ------------*/
    useEffect(() => {}, []);

    /*-------- Functions -----------*/
    const handleClose = () => {
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose} className={styleSheet.root}>
            <div className={styleSheet.container}>
                <div className={styleSheet.header}>
                    <div className={styleSheet.title}>
                        <div className={styleSheet.col}>
                            <h3>Contact Us</h3>
                        </div>
                        <div className={styleSheet.col}>
                            <ClickableWithFeedback
                                className={styleSheet.closeButton}
                                onClick={handleClose}
                            >
                                <img src={CloseIcon} height={15} />
                            </ClickableWithFeedback>
                        </div>
                    </div>
                </div>
                <p className={styleSheet.textContainer}>
                    If you need to request a change to your Brokerage Suite Plan, or you are
                    experiencing a problem with your account, please get in touch with our team to
                    assist you. We have two ways to get in touch, feel free to choose whichever is
                    best for you.
                </p>
                <p className={styleSheet.infoContainer}>
                    <div className={styleSheet.infoContainerRow}>
                        <div className={`${styleSheet.col} ${styleSheet.right}`}>Email</div>
                        <div className={`${styleSheet.blueText} ${styleSheet.col}`}>
                            support@showingly.com
                        </div>
                    </div>
                    <div className={styleSheet.infoContainerRow}>
                        <div className={`${styleSheet.col} ${styleSheet.right}`}>Phone</div>
                        <div className={`${styleSheet.blueText} ${styleSheet.col}`}>
                            1-303-970-0454
                        </div>
                    </div>
                </p>
                <p className={styleSheet.textContainer}>
                    We appreciate you reaching out and look forward to helping you as soon as
                    possible.
                </p>
                <div className={styleSheet.center}>
                    <Button
                        customStyle={styleSheet.button}
                        title="Confirm"
                        onClick={() => handleClose()}
                    />
                </div>
            </div>
        </Modal>
    );
}

ContactUsModal.defaultProps = {
    open: false,
    onClose: noop,
};
