export default (theme) => {
    const paginationElement = {
        ...theme.marginHorizontal(2),
        ...theme.typography.semiBold,

        padding: '3px 10px',

        cursor: 'pointer',

        '&:hover': {
            backgroundColor: theme.palette.mediumGrey,
            color: theme.palette.white,
            borderRadius: 5,
        },
    };

    const flexCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return {
        wrapper: {
            ...theme.marginVertical(10),
            ...theme.marginHorizontal(10),
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            display: 'block',
            overflowY: 'auto',
            fontFamily: 'Poppins-Medium',
            '&::-webkit-scrollbar': {
                width: '6px',
                background: '#D3D3D3',
                height: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#857D76',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#BFBAB6',
            },
        },
        header: {
            fontFamily: 'Poppins-Medium',
            color: '#9A9A9A',
            paddingBottom: '10px',
            display: 'flex',
        },
        row: {
            border: {
                width: 2,
                radius: 2,
            },
            borderTop: 'solid #E9E9E9',
        },
        hover: {
            '&:hover': {
                backgroundColor: theme.palette.semiLightBlue,
                cursor: 'pointer',
            },
        },
        rowElement: {
            ...theme.paddingHorizontal(5),
            ...theme.paddingVertical(10),
        },
        sortWidgetActive: {
            display: 'inline-flex',
            justifyConten: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none',

            backgroundColor: theme.palette.blue,

            top: -3,
            height: 18,
            width: 10,
            ...theme.marginHorizontal(10),
            paddingRight: 3,
            paddingLeft: 4,
            borderRadius: 3,
        },
        sortWidgetInactive: {
            display: 'inline-flex',
            justifyConten: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none',

            backgroundColor: theme.palette.separatorGrey,

            top: -3,
            height: 18,
            width: 10,
            ...theme.marginHorizontal(10),
            paddingRight: 3,
            paddingLeft: 4,
            borderRadius: 3,
        },
        loaderContainer: {
            ...flexCenter,
            flexDirection: 'column',
            height: 500,
        },
        pagination: { ...flexCenter, marginTop: 40, listStyle: 'none', flexDirection: 'row' },
        selectedPaginationElement: {
            ...paginationElement,
            borderRadius: 5,

            backgroundColor: theme.palette.blue,
            color: theme.palette.white,
        },
        paginationElement: {
            ...paginationElement,
        },
        paginationArrowElement: {
            ...flexCenter,
            ...theme.marginHorizontal(2),
            backgroundColor: theme.palette.separatorGrey,
            borderRadius: 5,

            padding: '5px 8px',
            cursor: 'pointer',

            '&:hover': {
                backgroundColor: theme.palette.mediumGrey,
                color: theme.palette.white,
            },
        },
    };
};
