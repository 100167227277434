import { DateTime } from 'luxon';
import { BSON } from 'realm-web';
import { AgentDocument } from './common/constants';

export enum ListingScheduleType {
    ByAppointment = 'appt_required',
    GoNShow = 'go_and_show',
    Accompanied = 'accompanied',
}

export enum ListingScheduleTypeDisplay {
    appt_required = 'Appointment Required',
    go_and_show = 'Go and Show',
    accompanied = 'Accompanied',
}

export enum STATUS {
    Requested,
    Succeeded,
    Failed,
    Ignored,
    Clean,
}

export enum ShowingType {
    Agent = 'agent',
    Delegated = 'delegated',
    Lead = 'lead',
}

export enum ShowingStatus {
    Active = 'active',
    Cancelled = 'cancelled',
    Completed = 'completed',
    ConfirmedNonDescript = 'confirmed',
    ConfirmedInternal = 'confirmed_internal',
    ConfirmedExternal = 'confirmed_external',
    Denied = 'denied',
    PendingInternal = 'pending_internal',
    PendingExternal = 'pending_external',
    Queued = 'queued',
    Requested = 'requested',
}

export const STATUS_DISPLAY_LABELS = [
    { status: 'requested', label: 'Requested' },
    { status: 'pending_external', label: 'Pending' },
    { status: 'pending_internal', label: 'Pending' },
    { status: 'confirmed', label: 'Confirmed' },
    { status: 'cancelled', label: 'Cancelled' },
    { status: 'denied', label: 'Denied' },
    { status: 'completed', label: 'Completed' },
];

export type User = {
    _id: BSON.ObjectId;
    stitchUserId: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePhotoUpload?: {
        as: string;
        path: string;
        uri: string;
    };
};

export type Agent = User & {
    _id: BSON.ObjectId;
    tempUserId: string;
    status?: string;
    markets?: Array<{
        agentMlsId: string;
        brokerageId: string;
        marketId: BSON.ObjectId;
        marketName: string;
        mlsName: string;
    }>;
    brokerageName: string;
    soldListings?: Listing[];
    listingsData?: Listing[];
    hasFetched?: boolean;
};

export type Brokerage = {
    _id: BSON.ObjectID;
    name: string;
    markets: Array<{
        agentMlsId: string;
        brokerageId: string;
        marketId: BSON.ObjectId;
        market: string;
        mlsName: string;
    }>;
    plus: boolean;
    image: string;
    brokerageId: string;
    agentCount: number;
    lastCountModifiedTimestamp: Date;
    mlsName: string;
    feed: FeedItem[];
    customerStripeId: string;
    adminVerificationStatus: string | undefined;
    approvedEmails: string[] | undefined;
    subscription: Subscription;
    _id__bass_transaction: BSON.ObjectID;
};

export type Subscription = {
    active: boolean;
    id: string;
};

export type BrokerageStatsChartTypes = {
    type: 'vol' | 'units';
    year: number;
    values: Array<number>;
};
export type BrokerageStats = {
    _id: BSON.ObjectId;
    brokerageId: string;
    mlsName: string;
    activeClientCount: number;
    activeAgentCount: number;
    activeListingCount: number;
    agentPerc: number;
    activeListingPerc: number;
    brokerageObjectId: BSON.ObjectId;
    consumerPerc: number;
    currYearBuyUnits: BrokerageStatsChartTypes;
    currYearBuyVol: BrokerageStatsChartTypes;
    currYearSellUnits: BrokerageStatsChartTypes;
    currYearSellVol: BrokerageStatsChartTypes;
    currYearTotalUnits: BrokerageStatsChartTypes;
    currYearTotalVol: BrokerageStatsChartTypes;

    prevAvgPrice: number;
    prevBuyUnits: number;
    prevBuyVol: number;
    prevSellUnits: number;
    prevSellVol: number;
    prevTotalVolume: number;
    prevTotalUnits: number;
    prevYearAvgPricePerc: number;
    prevYearBuyUnits: BrokerageStatsChartTypes;
    prevYearBuyVol: BrokerageStatsChartTypes;
    prevYearSellUnits: BrokerageStatsChartTypes;
    prevYearSellVol: BrokerageStatsChartTypes;
    prevYearTotalUnits: BrokerageStatsChartTypes;
    prevYearTotalVol: BrokerageStatsChartTypes;
    prevYearUnitsPerc: number;
    prevYearVolPerc: number;

    ytdAvgPrice: number;
    ytdAvgPricePerc: number;

    ytdBuyUnits: number;
    ytdBuyVol: number;

    ytdSellUnits: number;
    ytdSellVol: number;
    ytdTotalVolume: number;
    ytdTotalUnits: number;

    ytdUnitsPerc: number;
    ytdVolPerc: number;

    loggedClientCount: number;

    market: string;
    loggedAgentCount: number;
    totalAgentCount: number;
    totalClientCount: number;
    buyUnits: any;
    buyVol: any;
    listUnits: any;
    listVol: any;
    totalUnits: any;
    totalVol: any;
};

export type AgentMetricItem = {
    count: number;
    vol: number;
    avg: number;
};
export type AgentMetrics = {
    MTD: AgentMetricItem;
    SixMonths: AgentMetricItem;
    ThirtyDays: AgentMetricItem;
    TwelveMonths: AgentMetricItem;
    TwentyFourMonths: AgentMetricItem;
    YTD: AgentMetricItem;
};

export type AgentStats = {
    _id: BSON.ObjectId;
    agentId: string;
    mlsName: string;
    activeClientCount: number;
    activeListingCount: number;
    agentPerc: number;
    activeListingPerc: number;
    brokerageObjectId: BSON.ObjectId;
    consumerPerc: number;
    currYearBuyUnits: BrokerageStatsChartTypes;
    currYearBuyVol: BrokerageStatsChartTypes;
    currYearSellUnits: BrokerageStatsChartTypes;
    currYearSellVol: BrokerageStatsChartTypes;
    currYearTotalUnits: BrokerageStatsChartTypes;
    currYearTotalVol: BrokerageStatsChartTypes;

    prevAvgPrice: number;
    prevBuyUnits: number;
    prevBuyVol: number;
    prevSellUnits: number;
    prevTotalVolume: number;
    prevTotalUnits: number;
    prevSellVol: number;
    prevYearAvgPricePerc: number;
    prevYearBuyUnits: BrokerageStatsChartTypes;
    prevYearBuyVol: BrokerageStatsChartTypes;
    prevYearSellUnits: BrokerageStatsChartTypes;
    prevYearSellVol: BrokerageStatsChartTypes;
    prevYearTotalUnits: BrokerageStatsChartTypes;
    prevYearTotalVol: BrokerageStatsChartTypes;
    prevYearUnitsPerc: number;
    prevYearVolPerc: number;

    ytdAvgPrice: number;
    ytdAvgPricePerc: number;

    ytdBuyUnits: number;
    ytdBuyVol: number;

    ytdSellUnits: number;
    ytdSellVol: number;

    ytdTotalVolume: number;
    ytdTotalUnits: number;

    ytdUnitsPerc: number;
    ytdVolPerc: number;

    loggedClientCount: number;
    totalClientCount: number;

    market: string;
};

export type Consumer = User & {
    _id: BSON.ObjectId;
    brokerageName: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePhotoUpload: {
        as: string;
        path: string;
    };
    stitchUserId: string;
};

// incomplete
export type Listing = {
    _id: BSON.ObjectId;
    photos: string[];
    address: {
        city: string;
        country: string;
        full: string;
        postalCode: string;
        state: string;
        streetName: string;
        streetNumber: number;
        streetNumberText: string;
        unit?: string;
    };
    listPrice?: number;
    property?: {
        bedrooms: number;
        bathrooms: number;
        area: number;
        subTypeText: string;
        yearBuilt: string;
        lotSizeArea: number;
        parking: {
            spaces?: number;
        };
        acres: number;
        type: string;
    };
    mls?: {
        status: string;
    };
    school?: {
        elementarySchool: string;
        middleSchool: string;
        highSchool: string;
        district: string;
    };
    office?: {
        contact?: {
            office: string;
            cell?: string;
        };
        name: string;
    };
    association?: {
        name: string;
        fee: number;
        frequency: string;
    };
    tax?: {
        taxAnnualAmount: number;
    };
    source: string;
    disclaimer: string;
    listingId?: string;
    agent: Agent;
    remarks: string;
    name: string;
    geo: {
        country: string;
        lat: number;
        lng: number;
        directions: string;
    };
    mlsList: any[];
    activityDate: Date;
    type: 'badge' | 'follower' | 'listing' | 'soldListing' | 'post';
    agentListing: AgentListing;
};

export type Badge = {
    _id: string | BSON.ObjectId;
    name: string;
    description: string;
    type: string;
    stat: string;
    requirement: number;
    weight: number;
    is_secret: any; // change
    hint: any; // change
    image: string;
    tier: string;
};

export type SelectedAgentSocial = {
    agentId: any;
    agentMlsId: string;
    name: string;
    firstName: string;
    lastName: string;
    photoUri: string;
    brokerage?: {
        name: string;
        numberOfUsers: number;
    };
    marketLocation: string;
    badges: Badge[];
    bio?: string;
    isCurrentUser: boolean; // indicates if user should be able to edit/upload
};

export type NestedBrokerageMarket = {
    brokerageId: string;
    mlsName: string;
    displayMlsName: string;
    market: string;
    marketObjectId: BSON.ObjectId;
    mlsStatus: string;
};

export type SocialUserSimple = {
    agentObjectId: BSON.ObjectId;
    firstName: string;
    lastName: string;
    profilePhotoUpload: {
        path: string;
        as: string;
        uri: string;
    };
};

export type BrokerageAndMarketData = {
    agentId: BSON.ObjectId;
    brokerage?: {
        name: string;
        market: string;
        image: string;
        agentCount?: number;
    };
    market?: {
        marketObjectId: BSON.ObjectId;
        marketName: string;
        mlsName: string;
        agentMlsId: string;
    };
};

export type SocialDocument = SocialUserSimple & {
    _id: BSON.ObjectId;
    followers: SocialUserSimple[];
    following: SocialUserSimple[];
    badges?: Badge[];
    brokerageAndMarketData?: BrokerageAndMarketData;
};

export type UnverifiedAgent = {
    agentId?: string;
    company?: string;
    email: string;
    firstName: string;
    lastName: string;
    occupationType?: string;
    otherOccupation?: string;
    phoneNumber: string;
};

export type Address = {
    full: string;
    city: string;
    state: string;
    zip: string;
};

export enum metricMember {
    'Last 30d' = 'ThirtyDays',
    '6m' = 'SixMonths',
    '12m' = 'TwelveMonths',
    '24m' = 'TwentyFourMonths',
    'MTD' = 'MTD',
    'YTD' = 'YTD',
}

export const MLS_AUTH_ENDPOINTS = {
    recolorado: {
        url: 'iam.recolorado.com/idp/profile/oidc/authorization',
        clientId: process.env.REACT_APP_RECOLORADO_CLIENT_ID,
    },
    sef: {
        url: 'sef.clareityiam.net/idp/profile/oidc/authorization',
        clientId: process.env.REACT_APP_SEF_CLIENT_ID,
    },
    neren: {
        url: 'mmsi-neren.auth0.com/authorize',
        clientId: process.env.REACT_APP_NEREN_CLIENT_ID,
    },
    sdar: { url: 'mmsi-sdar.auth0.com/authorize', clientId: process.env.REACT_APP_SDAR_CLIENT_ID },
};

export const listingClassRets: Record<string, string> = {
    '1': 'RE_1', // Residdential
    '2': 'LD_2', // Land
    '3': 'CS_3', // Comercial Sale
    '4': 'CL_4', // Commercial Lease
    '5': 'MF_5', // Multi Family
    '6': 'BF_6', // Boat Facility
    '7': 'RN_7', // Rental
};

export type Showing = {
    _id: BSON.ObjectID;
    id: string;
    firstName: string;
    lastName: string;
    dateRequested: Date;
    address: string;
    address2: string;
    email: string;
    phoneNumber: string;
    unverifiedUser: any;
    consumerId: string;
    consumer?: Consumer;
    agent?: Agent;
    agentId?: string;
    showingAssistant: Agent;
    listing?: Listing;
    listingId: BSON.ObjectId;
    pricing?: {
        delegation: {
            cost: number;
            currency: string;
            earnings?: number;
        };
    };
    status: ShowingStatus;
    type: ShowingType;
    start: Date;
    end: Date;

    cancelled?: Date;
    confirmed?: Date;
    update?: Date;
    feedback?: {
        ratings: {
            cleanliness: number;
            layout: number;
            curbAppeal: number;
        };
        showingRequestId: BSON.ObjectId;
        review: string;
    };

    // used to display time on multi showing property modal
    startTime?: string | Date;
    showingDuration?: number;
    blasts: object;
};

export type QueuedShowing = {
    listing: Listing;
    showingType: string;
    listingId: string;
    startDate: Date;
    endDate: Date;
    clientId?: string;
    utcOffset: number;
};

export type FEEDBACK = {
    ratings: {
        cleanliness: number;
        layout: number;
        curbAppeal: number;
    };
    showingRequestId: BSON.ObjectId;
    review: string;
};

export type ShowingRequest = {
    listingId: string;
};

type ClientPhoto = {
    as: string;
    path: string;
};

export type Client = {
    _id: BSON.ObjectId;
    agentId: BSON.ObjectId;
    connectionType: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePhotoUpload: ClientPhoto;
    signUpCompleted: boolean;
    status: string;
    stitchUserId: string;
    twilioVerification: object;
};

export enum SHOWING_ACTION {
    Fetch = '[Showing] Fetch',
    Create = '[Showing] Create',
    UpdateStatus = '[Showing] Update Status',
    UpdateClient = '[Showing] Update Client',
    Delegate = '[Showing] Delegate',
    AcceptPickUp = '[Showing] Accept Pickup',
    PurchaseLead = '[Showing] Purchase Lead',
    FetchPrices = '[Showing] Fetch D/L/P Prices',
    SetShowingClient = '[Showing] Set Current Client',
}

// different types a FeedItem item can be, created feed items need to be 1 of announcement, event, or post
export enum FeedItemContentType {
    Announcement = 'announcement',
    Event = 'event',
    Post = 'post',
    None = 'none',
}

// different postType a feed item can be but ONLY if the feedItem is of FeedItemContentType.Post
export enum EventTypeOptions {
    Meeting = 'Meeting',
    Training = 'Training',
    Festivity = 'Festivities',
    Other = 'Other',
    None = 'None',
}

export type AgentSimple = {
    agentId: BSON.ObjectId;
    firstName: string;
    lastName: string;
    profilePhoto: string;
};

// single item in the brokerageFeed
export type FeedItem = {
    _id?: BSON.ObjectId;
    created?: {
        brokerageUserId: BSON.ObjectId;
        agentUserId: BSON.ObjectId;
        name: string;
        photoUri: string;
        type: string;
        createdAt: Date;
    };
    start: Date;
    end: Date;
    description: string;
    image?: any;
    link?: string;
    eventType?: EventTypeOptions;
    title: string;
    type: FeedItemContentType;
    going: AgentSimple[];
    notGoing: AgentSimple[];
    upvotes: {};
    upvotesCount: number;
    comments: Comment[];
    upvoting?: boolean;
    unvoting?: boolean;
};

export type Comment = {
    agentObjectId: BSON.ObjectId;
    agent: AgentDocument;
    brokerageUser: BrokerageUser;
    date: Date;
    message: string;
};

export type BrokerageData = {
    _id: BSON.ObjectId;
    name: string;
    market: string;
    markets: any;
    plus: boolean;
    image: string;
    brokerageId: string;
    agentCount: number;
    showingManagementPricing: {
        apl: number;
        bpl: number;
    };
    showingManagement: {
        status: string;
        paymentSchedule: string;
        cost: number;
        start: any;
    };
};

export enum Approvals {
    Agent = 'agent',
    Client = 'client',
}

type UserOnAgentListing = {
    _id: BSON.ObjectId;
    canApprove: boolean;
    notificationSettings: Array<string>;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    profilePhotoUpload?: {
        as?: string;
        path?: string;
        uri?: string;
    };
    type?: 'Listing Agent' | 'Co-Listing Agent';
};

export enum ApprovalType {
    Agent = 'agent',
    Client = 'client',
    Both = 'both',
    Either = 'either',
    None = 'none',
}

export type AgentListing = {
    agents: Array<UserOnAgentListing>;
    consumers?: Array<UserOnAgentListing>;
    listingId: BSON.ObjectId;
    approvalSettings: {
        unverified: {
            allowed: boolean;
            hiddenId?: string;
        };
        approvalType: ApprovalType;
    };
    noticeRequired?: number;
    maxDuration?: number;
    allowOverlap?: boolean;
    availability: {
        recurring: {
            0: {
                startTime: Date | '';
                endTime: Date | '';
            };
            1: {
                startTime: Date | '';
                endTime: Date | '';
            };
            2: {
                startTime: Date | '';
                endTime: Date | '';
            };
            3: {
                startTime: Date | '';
                endTime: Date | '';
            };
            4: {
                startTime: Date | '';
                endTime: Date | '';
            };
            5: {
                startTime: Date | '';
                endTime: Date | '';
            };
            6: {
                startTime: Date | '';
                endTime: Date | '';
            };
        };
        rules?: Array<{
            start: Date;
            end: Date;
        }>;
    };
    approvals?: Array<Approvals>;
    type: string;
    lockboxLocation: string;
    lockboxType: string;
    lockCombo: string;
    lockComboExpiration?: Date;
    messageBlast?: string;
    showingInstructions?: string;
    lockboxPhotoUpload?: {
        as: string | null;
        path: string | null;
        uri: string | null;
    };
    showingsStartDate?: any;
    blockedTimes?: any;
};

export type BrokerageUser = User & {
    brokerageId: BSON.ObjectId;
    emailAlias: string;
    lastSession: Date;
    signUpCompleted: boolean;
    signUpDate: Date;
    status: string;
    role: BrokerageUserRole;
    userObjectId: BSON.ObjectId;
};

export type BrokerageUserRole = 'Viewer' | 'Admin' | 'Super Admin';

// Style type for custom styling portions of the comment modal
export type CustomCommentModalStyling = {
    rootStyle?: string; // Style the root container including positions, dimensions, etc.
    exitIcon?: string; // Pass the exit icon itself
    exitIconStyle?: string; // Style the icon, including position, dimensions, etc.
    lineStyle?: string; // Style the lines on the top and bottom of the modal
    commentsContainerStyle?: string; // Style the overall container that maintains all the comments
    commentContainerStyle?: string; // Style the individual comment containers
    commentStyle?: string; // Style the layout of elements within the comment
    commentPPStyle?: string; // Style the profile picture that pops up next to a comment
    commentDefaultPP?: string; // Pass a unique default profile picture
    authorInfoStyle?: string; // Style the layout of the overall comment with name, posted time, and comment
    authorNameAndPostedStyle?: string; // Style the layout of the user's name and the time since posted value on the comments
    authorNameStyle?: string; // Style the user's name whom posted the comment
    authorPostTimeStyle?: string; // Style the time displayed for time since the comment was posted
    authorCommentStyle?: string; // Style the actually comment text itself
    postAndTextBoxStyle?: string; // Style the container maintaining input box and post button
    inputBoxStyle?: string; // Style the input box where the comments are typed out
    postButtonStyle?: string; // Style the enabled post button on the bottom of the comment modal
    postButtonDisabledStyle?: string; // Style the disabled post button on the bottom of the comment modal
};
