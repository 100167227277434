/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Components ---------*/
import { DatePicker } from '../../../../components/DatePicker';
import Typography from '../../../../components/Typography';
import ButtonComponent from '../../../../components/ButtonComponent';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import AddRestriction from './AddRestriction';
import RadioButtons from './common/RadioButtons';
import RestrictionCard from './AddRestriction/RestrictionCard';
import ConfigureListingModule from './common/ConfigureListingModule';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';

/*-------- Icons ---------*/
import DropDown from '../../../../images/icons/greyDropDownIcon.svg';

/*-------- Constants ---------*/
import { DateTime } from 'luxon';

interface Props {
    agentListing: any;
    setAgentListing: any;
}
const PageFour = ({ agentListing, setAgentListing }: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Constants ---------*/
    const tomorrow = new Date();
    const dayAfterTomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const now = DateTime.fromJSDate(dayAfterTomorrow);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

    /*-------- States ---------*/
    const [isViewing, setIsViewing] = useState(false);
    const [editingInfo, setEditingInfo] = useState<any>({});
    const [selectedMoment, setSelectedMoment] = useState<any>(now);
    const [addRestrictionModal, setAddRestrictionModal] = useState(false);
    const [passedRestriction, setPassedRestriction] = useState<any>(null);
    const [selectedValue, setSelectedValue] = useState(
        agentListing?.showingsStartDate < dayAfterTomorrow
            ? agentListing?.showingsStartDate < tomorrow
                ? 'now'
                : 'tomorrow'
            : 'on',
    );

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (selectedValue === 'on') {
            setAgentListing({ ...agentListing, showingsStartDate: new Date(selectedMoment.ts) });
        }
    }, [selectedMoment]);

    /*-------- Functions ---------*/
    // handle month is incremented or decremented via the DatePicker
    const changeMonth = (incDec: 1 | -1) => {
        if (incDec === 1) {
            let newNow = selectedMoment.plus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        } else if (incDec === -1) {
            let newNow = selectedMoment.minus({ months: 1 });
            if (newNow.month === now.month && newNow.year === now.year) {
                newNow = newNow.set({ day: now.day });
            } else {
                newNow = newNow.set({ day: 1 });
            }
            setSelectedMoment(newNow);
        }
    };

    const showingsStartDateData = [
        {
            label: 'Now',
            value: 'now',
        },
        {
            label: 'Tomorrow',
            value: 'tomorrow',
        },
        {
            label: 'On',
            value: 'on',
            extraLabelContent: (
                <div style={{ marginLeft: 20 }}>
                    <div
                        onClick={() => {
                            if (selectedValue !== 'on') {
                                setSelectedValue('on');
                            }
                            setIsViewing(!isViewing);
                        }}
                        className={styleSheet.selectDateBox}
                    >
                        <Typography
                            textStyle="b1"
                            color={
                                selectedValue !== 'on'
                                    ? indexTheme.palette.lightGrey
                                    : indexTheme.palette.black
                            }
                        >
                            {selectedValue !== 'on' || !selectedMoment
                                ? 'Select a Date'
                                : selectedMoment.toFormat('DD')}
                        </Typography>
                        <ClickableWithFeedback
                            onClick={() => {
                                if (selectedValue !== 'on') {
                                    setSelectedValue('on');
                                }
                                setIsViewing(!isViewing);
                            }}
                        >
                            <img src={DropDown} />
                        </ClickableWithFeedback>
                    </div>
                    {isViewing ? (
                        <div className={styleSheet.datePickerContainer}>
                            <DatePicker
                                setSelectedDay={(date: any) => {
                                    setSelectedMoment(date);
                                    setIsViewing(false);
                                }}
                                selectedDay={selectedMoment}
                                changeMonth={(value: 1 | -1) => changeMonth(value)}
                                removePadding
                                alwaysSixRows
                            />
                        </div>
                    ) : null}
                </div>
            ),
        },
    ];

    return (
        <>
            <div>
                <ConfigureListingModule title={'Showings Start Date'}>
                    <RadioButtons
                        data={showingsStartDateData}
                        selectedValue={selectedValue}
                        onChangeValue={(value: any) => {
                            setIsViewing(false);
                            setSelectedValue(value);
                            switch (value) {
                                case 'now':
                                    return setAgentListing({
                                        ...agentListing,
                                        showingsStartDate: new Date(),
                                    });
                                case 'tomorrow':
                                    return setAgentListing({
                                        ...agentListing,
                                        showingsStartDate: tomorrow,
                                    });
                                case 'on':
                                    return setAgentListing({
                                        ...agentListing,
                                        showingsStartDate: dayAfterTomorrow,
                                    });
                            }
                        }}
                    />
                </ConfigureListingModule>
                <ConfigureListingModule title={'Allow double bookings?'}>
                    <div style={{ marginTop: -4 }}>
                        <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                            Multiple agents will be able to schedule showings within overlapping
                            time slots.
                        </Typography>
                        <RadioButtons
                            data={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            selectedValue={agentListing.allowOverlap}
                            onChangeValue={(value: boolean) => {
                                setAgentListing({ ...agentListing, allowOverlap: value });
                            }}
                        />
                    </div>
                </ConfigureListingModule>
                <ConfigureListingModule title={'Listing Syndication'}>
                    <div style={{ marginTop: -4 }}>
                        <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                            Display listing to unrepresented consumers.
                        </Typography>
                        <RadioButtons
                            data={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ]}
                            selectedValue={agentListing.showToUnrepresentedConsumers}
                            onChangeValue={(value: boolean) => {
                                setAgentListing({
                                    ...agentListing,
                                    showToUnrepresentedConsumers: value,
                                });
                            }}
                        />
                    </div>
                </ConfigureListingModule>
                <div
                    style={{
                        ...indexTheme.paddingVertical(30),
                        borderBottomColor: indexTheme.palette.grey,
                        borderBottomWidth: 1,
                        width: '100%',
                        borderBottomStyle: 'solid',
                    }}
                >
                    <Typography textStyle={'p1'}>
                        Daily showing availability is from 7:00am to 9:00pm. Add additional
                        restrictions below.
                    </Typography>
                </div>

                <ConfigureListingModule title={'Availability Restrictions'} optional>
                    {agentListing.blockedTimes &&
                        agentListing.blockedTimes.map((restriction: any, index: number) => {
                            return (
                                <RestrictionCard
                                    onClick={() => {
                                        setPassedRestriction(restriction);
                                        setEditingInfo({
                                            editing: true,
                                            index,
                                        });
                                        setAddRestrictionModal(true);
                                    }}
                                    restriction={restriction}
                                />
                            );
                        })}
                    <ButtonComponent
                        onClick={() => setAddRestrictionModal(true)}
                        color={indexTheme.palette.blue}
                        width={257}
                    >
                        Add Restriction
                    </ButtonComponent>
                </ConfigureListingModule>

                {addRestrictionModal && (
                    <AddRestriction
                        set={setAddRestrictionModal}
                        modalVisible={addRestrictionModal}
                        onPressAdd={(restriction: any) => {
                            const blockedTimes = agentListing.blockedTimes;
                            if (editingInfo.editing) {
                                blockedTimes.splice(editingInfo.index, 1, restriction);
                            } else {
                                blockedTimes.push(restriction);
                            }
                            setEditingInfo({});
                            setPassedRestriction(null);
                            setAgentListing({
                                ...agentListing,
                                blockedTimes,
                            });
                        }}
                        passedRestriction={passedRestriction}
                    />
                )}
            </div>
        </>
    );
};
export default PageFour;
