import * as Realm from 'realm-web';
import {
    AGENTS,
    BROKERAGES_COLLECTION,
    BROKERAGE_USERS_COLLECTION_NAME,
    PAYMENT_RECORDS,
    SHOWING_REQUESTS,
    USER_TYPE_AGENT,
} from './constants';
import sha256 from 'crypto-js/sha256';

export function getRealmClient() {
    return Realm.getApp(process.env.REACT_APP_STITCH_APP_ID ?? '');
}

export async function login(email, password) {
    const client = getRealmClient();
    const credentials = Realm.Credentials.emailPassword(email, password);
    const user = await client.logIn(credentials);
    return { client, user };
}

/**
 * Logout the current signed in user from Stitch.
 * This is applicable for both an anonymous logged in user and
 * a user logged in via email/password.
 *
 * @returns {Promise<void>}
 */
export async function logout() {
    const client = getRealmClient();
    return client.currentUser.logOut();
}

export function getCollection(collectionName) {
    const client = getRealmClient();
    const mongoClient = client.currentUser.mongoClient(
        process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME,
    );
    const db = mongoClient.db(process.env.REACT_APP_STITCH_DATABASE_NAME);
    return db.collection(collectionName);
}

/**
 * Anonymous stitch login so we can gain access to specific functions
 * @returns {Promise<StitchAppClient>}
 */
export async function anonLogin() {
    const client = getRealmClient();
    const credentials = Realm.Credentials.anonymous();
    const user = await client.logIn(credentials);
    return user;
}

export const generateSignature = () => {
    const secret = process.env.REACT_APP_CLIENT_VALIDATION;
    const timestamp = new Date().toISOString();
    const hash = sha256(`${timestamp} ${secret}`).toString();
    return { hash, timestamp };
};

/**
 * Call the given stitch function with its params
 * @param client Mongo Stitch Client returned from initialization
 * @param endpoint
 * @param params
 * @returns {Promise<*>}
 */
export function callStitchFunction(endpoint, ...params) {
    const client = Realm.App.getApp(process.env.REACT_APP_STITCH_APP_ID);
    return client.currentUser.functions.callFunction(endpoint, ...params);
}

export async function findRecords(collectionName, query = {}, projection = {}, sort = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getCollection(collectionName);

    return await collection.find(query, { projection, sort });
}

export async function findShowings(agentId = null) {
    return await callStitchFunction('findBrokerageShowings', agentId);
}

/**
 * Runs a general query stitch function on a named collection
 * with the given query and projection.
 *
 * @param collectionName The name of the collection to query against
 * @param query The query to provide to the findOne function.
 * @param projection
 * @param sort
 * @returns {Promise<Document | null>}
 */
export async function findRecord(collectionName, query = {}, projection = {}, sort = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getCollection(collectionName);

    return await collection.findOne(query, { projection, sort });
}

/**
 * Gets the brokerage user's record associated to the given stitch user, if any.
 *
 * @param user
 * @returns {Promise<Document | null>}
 */
export async function getBrokerageUserAndBrokerage(userId) {
    const query = {
        stitchUserId: userId,
    };
    const other = [
        {
            $lookup: {
                from: 'brokerages',
                localField: 'brokerageId',
                foreignField: '_id',
                as: 'brokerageData',
            },
        },
    ];
    const response = await callStitchFunction(
        'runGeneralQuery',
        BROKERAGE_USERS_COLLECTION_NAME,
        query,
        null,
        null,
        other,
    );
    const brokerageUserResult = {
        ...response[0],
        brokerageData: {
            ...response[0]?.brokerageData[0],
            feed: undefined,
        },
    };
    return brokerageUserResult;
}

/**
 * Gets the brokerage user's record associated to the given stitch user, if any.
 *
 * @param user
 * @returns {Promise<Document | null>}
 */
export async function updateBrokerageRecord(client, setData, isUnset = false) {
    try {
        const response = await callStitchFunction(
            client,
            'updateBrokerageAsBrokerageUser',
            setData,
            isUnset,
        );
        return response;
    } catch (error) {
        //
    }
}

export async function runGeneralQuery(client, collectionName, query) {
    try {
        const response = await callStitchFunction(client, 'runGeneralQuery', collectionName, query);
        return response;
    } catch (error) {
        //
    }
}

export async function addBrokrageCard(client, paymentMethodId, name, zip) {
    try {
        const response = await callStitchFunction(
            client,
            'setMyDefaultPaymentMethodBrokrage',
            paymentMethodId,
            name,
            zip,
        );
        return response;
    } catch (error) {
        //
    }
}

export async function createBrokerageUser(client, payload) {
    try {
        const response = await callStitchFunction(client, 'brokerageAccountCreate', payload);
        return response;
    } catch (error) {
        //
    }
}

/**
 * Creates a new Stitch User via email & password
 * @param {string} email
 * @param {string} password
 * @returns {Promise<boolean>}
 */
export async function requestUserRegistration(email, password) {
    // try {
    const authClient = Realm.App.getApp(process.env.REACT_APP_STITCH_APP_ID);
    return authClient.emailPasswordAuth.registerUser(email, password);
    //     const registerClient = await authClient.getProviderClient(
    //         UserPasswordAuthProviderClient.factory,
    //     );
    //     return await registerClient.registerWithEmail(email, password); // DO NOT REMOVE await key, because we need it to catch exceptions...
    // } catch (error) {
    //     return 'failure';
    // }
}

/**
 * Gets the brokerage user's record associated to the given stitch user, if any.
 *
 * @param user This is the stitch user id.
 * @returns {Promise<Document | null>}
 */
export async function getBrokerageUserRecord(user) {
    const brokerageUsersCollection = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    const query = { stitchUserId: user };
    return brokerageUsersCollection.findOne(query);
}

/**
 * Sends a request to stripe to delete user specific payment method
 * @returns {Object}
 */
export async function deleteMyPaymentMethod(cardId) {
    return callStitchFunction(
        // client || (await anonLogin()),
        'deleteMyCard',
        cardId,
        'brokerageUsers',
    );
}

/**
 * API call to `requestPhoneVerification` in Mongo Stitch to trigger
 * Twilio to send SMS code to this phoneNumber
 * @param {string|number} phoneNumber
 * @param {StitchAppClient} client if provided this will be used,
 *                          otherwise anonymous login will be performed
 *                          and the anonymous login's client will be used
 * @returns {Promise<T>}
 */
export async function requestPhoneVerification(phoneNumber) {
    return callStitchFunction('requestPhoneVerification', phoneNumber);
}

/**
 * API call to `sendEmail` in Mongo Stitch to trigger
 * an AWS SES service to send emails
 * @param {string} email
 * @param {StitchAppClient} client if provided this will be used,
 *                          otherwise anonymous login will be performed
 *                          and the anonymous login's client will be used
 * @returns {Promise<T>}
 */
export async function requestEmailVerification(email) {
    return callStitchFunction('sendEmail', email);
}

export async function updateShowingRequest(showingRequestData, unverifiedData) {
    return callStitchFunction(
        'updateShowingRequestStatusOrType',
        showingRequestData,
        unverifiedData,
    );
}

export async function createShowingFeedback(showingRequestId, feedback) {
    const showingRequestsCollection = getCollection(SHOWING_REQUESTS);
    return showingRequestsCollection.updateOne({ _id: showingRequestId }, { $set: { feedback } });
}

/*------- New Functions ------------*/
export async function updateBrokerageUser(userId, set, unset = null) {
    const query = { _id: userId };
    let update = {
        $set: {
            ...set,
        },
    };

    if (unset) {
        update = {
            ...update,
            $unset: {
                ...unset,
            },
        };
    }

    const brokerageUsers = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    return await brokerageUsers.updateOne(query, update);
}

export async function deleteBrokerageUser(userId) {
    const query = { _id: userId };
    const brokerageUsers = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    return await brokerageUsers.deleteOne(query);
}

/**
 * Search for agent's using a text search function
 *
 * @returns {Promise<T>}
 */
export async function textSearchAgents(searchText, matchQuery) {
    const projection = {
        _id: 1,
        stitchUserId: 1,
        firstName: 1,
        lastName: 1,
        profilePhotoUpload: 1,
        phoneNumber: 1,
        email: 1,
        markets: 1,
        status: 1,
        signUpCompleted: 1,
    };
    return callStitchFunction(
        'textSearchAgents',
        searchText,
        projection,
        matchQuery,
        null, // marketName
        false, // Show hidden
        false, // isIdSearch
    );
}
export async function findShowingRequest(query = {}, projection = {}) {
    return getCollection(SHOWING_REQUESTS).findOne(query, { projection });
}
/*------- Functions not being used in the current version ------------*/

export async function getStripePrice(client, priceId) {
    try {
        const priceResponse = await callStitchFunction(client, 'getStripePrice', priceId);
        return priceResponse;
    } catch (error) {
        //
    }
}

export async function getStripePaymentIntents(client) {
    try {
        const paymentIntentsResponse = await callStitchFunction(client, 'retrievePaymentIntents');
        return paymentIntentsResponse;
    } catch (error) {
        //
    }
}

export async function getStripeSubscription(client, subscriptionId) {
    try {
        const subResponse = await callStitchFunction(
            client,
            'getStripeSubscription',
            subscriptionId,
        );
        return subResponse;
    } catch (error) {
        //
    }
}

export async function getStripePromo(client, promoCode) {
    try {
        const promoResponse = await callStitchFunction(client, 'getStripePromo', promoCode);
        return promoResponse;
    } catch (error) {
        //
    }
}

export async function createBrokerageSubscription(payload) {
    try {
        const response = await callStitchFunction('createBrokerageSubscription', payload);
        return response;
    } catch (error) {
        //
    }
}
/**
 * Fetches brokerage user's payment methods (if any)
 * @param client Mongo Stitch Client returned from initialization
 * @returns {Object}
 */

export async function getBrokerageUserPaymentMethods(client) {
    return callStitchFunction(client, 'getMyPaymentMethods', 'brokerageUsers');
}

/**
 * Sends a request to stripe to update the customer default payment method
 * to the one they select
 * @param cardId id of the card which is requested as default source
 * @returns {Object}
 */

export async function updateMyDefaultPaymentMethod(client, cardId) {
    return callStitchFunction(client, 'updateMyDefaultPaymentMethod', cardId, 'brokerageUsers');
}

/**
 * Sends a request to stripe to get the customer invoice history
 * @returns {Array<Object>}
 */

export async function getUserInvoiceHistory(client, userId) {
    return callStitchFunction(client, 'fetchUserInvoiceHistory', userId);
}

export async function getAgentShowingHistoryRealm(userId) {
    return callStitchFunction(
        'getMyShowingHistory',
        USER_TYPE_AGENT,
        {
            $match: {
                agentId: userId,
                status: {
                    $in: ['completed', 'denied', 'cancelled', 'confirmed'],
                },
            },
        },
        5,
    );
}
/* Performs a find operation on the showing requests view with the given query,
 * to obtain map related data
 * @param query query object to use while finding the data.
 * @param projection The projection to provide to the find operation, if any.
 * @returns {Promise<Document[]>}
 */

export async function findShowingData(query, projection = {}) {
    const showingRequestsView = getCollection(SHOWING_REQUESTS);
    return showingRequestsView.find(query, projection).toArray();
}

export async function getAllBrokerageAdmins(brokerageId) {
    const query = { brokerageId: brokerageId };
    const brokerageUsers = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    return await brokerageUsers.find(query).toArray();
}

export async function setSuperAdmin(adminId, payload) {
    const query = { _id: adminId };
    const update = {
        $set: {
            isSuperAdmin: payload,
        },
    };
    const brokerageUsers = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    return await brokerageUsers.updateOne(query, update);
}

export async function removeAdmin(adminId) {
    const query = { _id: adminId };
    const brokerageUsers = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    return await brokerageUsers.deleteOne(query);
}

//Function to update a brokerage admin's name, querying by the admin's add and setting the admin's name
export async function updateBrokerageAdminName(updatedFirstName, updatedLastName, adminToUpdateId) {
    const fullName = updatedFirstName + ' ' + updatedLastName;
    const query = { _id: adminToUpdateId };
    const updateQuery = {
        $set: {
            firstName: updatedFirstName,
            lastName: updatedLastName,
            name: fullName,
        },
    };
    const brokerageUsers = getCollection(BROKERAGE_USERS_COLLECTION_NAME);
    return await brokerageUsers.updateOne(query, updateQuery);
}

export async function setShowingManagementSubscription(
    brokerageObjectId,
    paymentMethodId,
    showingManagement,
) {
    const query = {
        _id: brokerageObjectId,
    };
    const updateQuery = {
        $set: {
            paymentMethodId: paymentMethodId,
            showingManagement: {
                ...showingManagement,
                status: 'active',
                paymentSchedule: showingManagement?.paymentSchedule ?? 'monthlyBpl',
                start: new Date(),
            },
        },
    };
    const brokerages = getCollection(BROKERAGES_COLLECTION);
    return await brokerages.updateOne(query, updateQuery);
}

export async function insertPaymentRecord(args) {
    const paymentRecords = getCollection(PAYMENT_RECORDS);
    return await paymentRecords.insertOne(args);
}

export async function removePaymentMethodById(paymentMethodId, userCollection) {
    return callStitchFunction('removePaymentMethodById', paymentMethodId, userCollection);
}

export async function removePendingEmail(email, brokerageQuery) {
    const updateQuery = {
        $pull: {
            approvedEmails: {
                $in: [email],
            },
        },
    };
    const brokerages = getCollection(BROKERAGES_COLLECTION);
    return await brokerages.updateOne(brokerageQuery, updateQuery);
}

/*------- Functions not being used at all ------------*/

export async function generatePipeline(collection, totalPipeline) {
    return await collection.aggregate(totalPipeline).toArray();
}

export async function findAccounts(collectionName, query = {}, projection = {}, sort = {}) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getCollection(collectionName);
    return await collection.find(query, { projection, sort }).toArray();
}

export async function findAll(collectionName) {
    if (!collectionName) {
        throw new Error('collectionName is required!');
    }
    const collection = getCollection(collectionName);
    return await collection.find().limit(5).toArray();
}

export async function createStripeCustomer(payload) {
    try {
        const customerStripeId = await callStitchFunction('createStripeCustomer', payload);
        return customerStripeId;
    } catch (error) {
        //
    }
}

export async function getAgentRecord(user) {
    const agents = getCollection(AGENTS);
    const query = { stitchUserId: user.id };
    return agents.findOne(query);
}

export async function upsertAgentProfile(agentId, setData) {
    return callStitchFunction('updateAgentRecordAsBrokerage', agentId, setData);
}

export async function getListingRecords(userId) {
    return callStitchFunction('findConnectedOrClaimedListings', userId, null);
}

export function getDatabase() {
    if (process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME === undefined) {
        throw Error('Database service name is undefined');
    }
    if (process.env.REACT_APP_STITCH_DATABASE_NAME === undefined) {
        throw Error('Database name is undefined');
    }
    return Realm.App.getApp(process.env.REACT_APP_STITCH_APP_ID)
        .currentUser.mongoClient(process.env.REACT_APP_STITCH_DATABASE_SERVICE_NAME)
        .db(process.env.REACT_APP_STITCH_DATABASE_NAME);
}

export async function getSoldListings(userId) {
    return callStitchFunction('fetchSoldListings', userId);
}
