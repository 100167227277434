import React, { useState } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import Typography from '../Typography';
import PickerComponent from '../PickerComponent';
import ClickableWithFeedback from '../ClickableWithFeedback';

interface VerifySelectProps {
    codeSend: Function;
    selectedVerifyOption: string;
    selectVerifyOption: Function;
    verifyOptions: any[];
    buttonText: string;
}

const VerifySelect = (props: VerifySelectProps) => {
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });
    const { codeSend, selectedVerifyOption, selectVerifyOption, verifyOptions, buttonText } = props;

    const [clicked, setClicked] = useState<boolean>(false);

    const handleSend = () => {
        codeSend(selectedVerifyOption);
    };

    return (
        <div className={styleSheet.root}>
            <PickerComponent
                data={verifyOptions}
                selectedOption={selectedVerifyOption}
                onPressButton={selectVerifyOption}
                height={36}
                optionWidth={100}
            />
            <ClickableWithFeedback
                onClick={() => {
                    setClicked(true);
                    handleSend();
                }}
                noop={clicked}
                className={styleSheet.button}
            >
                <Typography textStyle="h4" color={'white'}>
                    {buttonText}
                </Typography>
            </ClickableWithFeedback>
        </div>
    );
};

export default VerifySelect;
