export default (theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'start',

            backgroundColor: theme.palette.background,
            borderRadius: 5,

            ...theme.paddingHorizontal(5),
            ...theme.paddingVertical(3),
        },

        input: {
            width: '100%',
            border: 'none',

            backgroundColor: theme.palette.background,

            color: theme.palette.lightGrey,
            fontSize: 15,
            fontFamily: 'Poppins-Medium'
        },

        icon: {
            ...theme.marginHorizontal(5),
        },
    };
};
