import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

interface Props {
    size?: number;
}

export default function Loader(props: Props) {
    /*---- Styles ------*/
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    /*----- Constants ------*/
    const { size = 0 } = props;
    const HEIGHT_WIDTH_RATIO = 1.75;
    const height = size;
    const width = size * HEIGHT_WIDTH_RATIO;

    const loadingIcon =
        'https://showingly-image-assets.s3.amazonaws.com/showinglyGeneralLoader.gif';

    return (
        <div className={styleSheet.loadingContainer} style={{ height, width }}>
            <img src={loadingIcon} style={{ height, width }} />
        </div>
    );
}

Loader.defaultProps = {
    size: 50,
};
