import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

/*----------Components----------*/
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Tag from '../../../../components/Tag';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import AnimatedModal from '../../../../components/AnimatedModal';
import Typography from '../../../../components/Typography';
import ScheduleScreen from '../../ShowingRequests/ScheduleScreen';

/*----------Redux-------------*/
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowingDetailsRequested, changeShowingStatusRequested } from '../actions';
import {
    getFeedbackSubmitting,
    getShowingDetails,
    isShowingsLoading,
    isUpdatingShowing,
} from '../selectors';
import { getListing } from '../../Listings/selectors';
import { getSelectedBrokerage } from '../../Profile/selectors';

/*---------- Utils ------------*/
import { BSON } from 'realm-web';
import {
    formatEmailForDisplay,
    formatPhoneNumberForDisplay,
    getStatusForDisplay,
    getStatusInfo,
    getTimeDateShowing,
} from '../../../../utils/common';
import { Brokerage, Listing } from '../../../../utils/constants';
import { formatCurrency } from '../../../../utils/common/transforms';
import { DateTime } from 'luxon';
import ArrowLeftIcon from '../../../../images/icons/arrow_left.svg';
import { ShowingParamsType } from '../types';

/*--------- Assets --------------*/
import GrayCal from '../../../../images/icons/grayCal.svg';
import GrayClock from '../../../../images/icons/grayClock.svg';
import close from '../../../../images/icons/close_modal_black.svg';

/*--------- Styles -----------*/
import { createUseStyles, useTheme } from 'react-jss';
import indexTheme from '../../../../theme';
import styles from './style';
import cssStyles from '../index.module.css';

/*---------Modal/Drawer-------------*/
import LeaveFeedbackDrawer from './Modals/LeaveFeedbackDrawer';
import DenyModal from './Modals/DenyModal';
import AddClientDrawer from '../../ShowingRequests/AddClientDrawer';
import AddAgentDrawer from '../../ShowingRequests/AddAgentDrawer';
import ModalConfirm from '../../../../components/ModalConfim';

interface LocationState {
    id: any;
    view: any;
}

interface Props {}

export default function ShowingDetails(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*------ Selectors --------*/
    const showingDetails = useSelector<any, any>(getShowingDetails);
    const isLoading = useSelector<any, boolean>(isShowingsLoading);
    const isShowingUpdating = useSelector<any, boolean>(isUpdatingShowing);
    const isFeedbackSubmitting = useSelector<any, boolean>(getFeedbackSubmitting);
    const listing = useSelector<any, any>(getListing);
    const brokerage = useSelector<any, any>(getSelectedBrokerage);

    /*--------Refs------------*/
    const tabRef = useRef() as React.RefObject<HTMLInputElement>;
    const lockboxModalRef = useRef<any>(null);
    const leaveFeedbackRef = useRef<any>(null);
    const refDeny = useRef<any>(null);
    const refFeedbackDrawer = useRef<any>(null);

    /*------ Constants --------*/
    const location = useLocation<LocationState>();
    const dispatch = useDispatch();
    const history = useHistory();
    const whiteButtons = ['Decline', 'Deny', 'Cancel'];
    const mainCardDetails: any = {};

    /*------- State -----------*/

    //contains all the detail cards
    const [cardContainerSize, setCardContainerSize] = useState<any>({
        width: window.innerWidth - 200,
        height: window.innerHeight - 81,
    });

    //lockbox photo
    const [showLockboxPhotoModal, setShowLockboxPhotoModal] = useState<boolean>(false);

    //leave feedback
    const [leavefeedbackDrawer, setLeaveFeedbackDrawer] = useState<boolean>(false);

    //deny modal
    const [denyModal, setModal] = useState<boolean>(false);

    //cancel modal
    const [cancelModal, setCancelModal] = useState<boolean>(false);

    //view feedabck
    const [feedbackDrawer, setFeedbackDrawer] = useState<boolean>(false);

    //schedule drawer
    const [showScheduleDrawer, setShowScheduleDrawer] = useState<boolean>(false);

    //client add drawer
    const [showAddClientDrawer, setShowAddClientDrawer] = useState<boolean>(false);

    //agent add drawer
    const [showAddAgentDrawer, setShowAddAgentDrawer] = useState<boolean>(false);

    //resize window container
    const [canResize, setCanResize] = useState(false);

    const selectedView = location.state?.view || { from: { pathname: '/' } };

    const { showingId } = useParams<ShowingParamsType>();

    /*------- Effects -----------*/
    useEffect(() => {
        const showingObjectId = new BSON.ObjectID(showingId);
        if (
            !showingDetails ||
            JSON.stringify(showingDetails._id) !== JSON.stringify(showingObjectId)
        ) {
            dispatch(fetchShowingDetailsRequested(showingObjectId));
        }
    }, []);

    // Debounce the resize method
    useEffect(() => {
        if (!canResize) {
            setTimeout(() => {
                setCanResize(true);
            }, 1000);
        }
    }, [canResize]);

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    const resize = () => {
        // STYLE_NOTE: SideBar and Headers taken into calculation of main content size
        setTimeout(() => {
            if (canResize) {
                setCardContainerSize({
                    width: window.innerWidth - 200,
                    height: window.innerHeight - 81,
                });
                setCanResize(false);
            }
        }, 500);
    };
    window.addEventListener('resize', resize);

    const isBrokeragesListing = (brokerage?: Brokerage, listing?: Listing) => {
        return listing?.mlsList?.some((m) =>
            brokerage?.markets?.some((m2) => m2.brokerageId === m.brokerageId),
        );
    };

    const assignMainCardContent = () => {
        switch (showingDetails?.status) {
            case 'pending_internal':
                if (selectedView === 'List Side') {
                    mainCardDetails.header = 'New Showing Request';
                    mainCardDetails.description = 'Please approve or deny this request';
                } else {
                    mainCardDetails.header = 'Almost there.';
                    mainCardDetails.description =
                        'The seller client or listing agent will review your request shortly';
                }
                break;
            case 'requested':
                mainCardDetails.header = 'Client Requested Showing';
                break;
            case 'pending_external':
                mainCardDetails.header = 'New Showing Request';
                mainCardDetails.description = 'Waiting for seller client approval';
                break;
            case 'confirmed':
                mainCardDetails.header = `You're all set.`;
                mainCardDetails.description = 'Your showing has been confirmed';
                break;
            case 'completed':
                mainCardDetails.header = 'All Done.';
                mainCardDetails.description = 'Your showing is complete';
                break;

            case 'denied':
                if (selectedView === 'List Side') {
                    mainCardDetails.header = 'Showing Denied';
                    mainCardDetails.description = 'This showing was successfully denied';
                } else {
                    if (showingDetails.denialReason?.length) {
                        mainCardDetails.header = 'Denial Reason';
                        mainCardDetails.description = showingDetails.denialReason;
                    } else {
                        mainCardDetails.header = 'Showing Denied';
                        mainCardDetails.description =
                            'The listing agent denied your showing. Please request another time to reschedule.';
                    }
                }
                break;
            case 'cancelled':
                mainCardDetails.header = 'No worries.';
                if (selectedView === 'List Side')
                    mainCardDetails.description = 'This showing has been successfully cancelled.';
                else
                    mainCardDetails.description =
                        'This showing has been cancelled. If you need to reschedule, click the reschedule button above.';
                break;
            case 'logged':
                mainCardDetails.header = `We've logged your showing`;
                mainCardDetails.description =
                    'This showing will appear in your showings page and your clients itinerary.';
                break;
            case 'declined':
                mainCardDetails.header = 'Declined Showing Request';
                break;
        }
    };
    const handleOnChange = (status: any) => {
        dispatch(changeShowingStatusRequested(showingDetails?._id, status));
    };

    const handleAccept = () => {
        const { listing: { agentListing: { type: showingType = null } = {} } = {} } =
            showingDetails || {};

        let statusToUpdate = 'logged';
        if (showingType) {
            if (showingType === 'appt_required') {
                statusToUpdate = 'pending_internal';
            } else {
                statusToUpdate = 'confirmed';
            }
        }
        handleOnChange(statusToUpdate);
    };

    const assignButtons = () => {
        const buttons = [];
        const negativeStatuses = ['cancelled', 'declined'];
        if (selectedView === 'Buy Side') {
            if (
                showingDetails?.status === 'pending_internal' ||
                showingDetails?.status === 'confirmed' ||
                showingDetails?.status === 'logged'
            ) {
                buttons.push(
                    {
                        text: 'Cancel',
                        onPress: () => {
                            setCancelModal(true);
                        },
                        small: true,
                        loading: { isShowingUpdating },
                    },
                    {
                        text: 'Update Showing',
                        onPress: () => {
                            setShowScheduleDrawer(true);
                        },
                    },
                );
            }
            if (showingDetails?.status === 'completed') {
                if (!showingDetails.feedback) {
                    buttons.push({
                        text: 'Leave Feedback',
                        onPress: () => {
                            setLeaveFeedbackDrawer(true);
                        },
                    });
                }
            }
            if (showingDetails?.status === 'denied')
                buttons.push({
                    text: 'Reschedule',
                    onPress: () => {
                        setShowScheduleDrawer(true);
                    },
                });
        } else if (selectedView === 'List Side') {
            if (showingDetails?.status === 'pending_internal') {
                buttons.push(
                    {
                        text: 'Deny',
                        onPress: () => {
                            setModal(true);
                        },
                        small: true,
                    },
                    {
                        text: 'Approve',
                        onPress: () => {
                            handleOnChange('confirmed');
                        },
                    },
                );
            }
            if (showingDetails?.status === 'completed') {
                if (showingDetails.feedback) {
                    buttons.push({
                        text: 'View Feedback',
                        onPress: () => {
                            setFeedbackDrawer(true);
                        },
                    });
                }
            }
            if (
                showingDetails?.status === 'pending_external' ||
                showingDetails?.status === 'confirmed'
            ) {
                buttons.push({
                    text: 'Cancel',
                    onPress: () => {
                        setCancelModal(true);
                    },

                    small: true,
                });
            }
        }

        if (showingDetails?.status === 'requested') {
            buttons.push(
                {
                    text: 'Decline',
                    onPress: () => {
                        handleOnChange('cancelled');
                    },
                    small: true,
                },
                {
                    text: 'Accept',
                    onPress: () => {
                        handleAccept();
                    },
                },
            );
        }

        if (negativeStatuses.includes(showingDetails?.status)) {
            buttons.push({
                text: 'Reschedule',
                onPress: () => {
                    setShowScheduleDrawer(true);
                },
            });
        }

        return buttons;
    };

    const assignCardType = () => {
        const overviewPageData = [];

        //Card 1 = Main header/description card
        const startTime = DateTime.fromJSDate(showingDetails?.start);
        const endTime = DateTime.fromJSDate(showingDetails?.end);

        const formattedShowingDate = startTime.toFormat('LLLL d, y');

        const formattedShowingTime = `${startTime.toFormat('h:mm')}${startTime
            .toFormat('a')
            .toLowerCase()} - ${endTime.toFormat('h:mm')}${endTime.toFormat('a').toLowerCase()}`;
        assignMainCardContent();
        overviewPageData.push(
            <div className={styleSheet.cardContent} id="mainCard">
                <div className={styleSheet.cardFirstContent}>
                    <Tag
                        text={
                            !showingDetails?.unverifiedUser
                                ? getStatusInfo(showingDetails?.status).text
                                : 'External Showing Request'
                        }
                        color={
                            !showingDetails?.unverifiedUser
                                ? getStatusInfo(showingDetails?.status).color
                                : 'yellow'
                        }
                        style={{ marginBottom: 13, marginLeft: 0 }}
                        background={true}
                        onClick={() => {}}
                    />
                    <span className={styleSheet.cardHeader}>{mainCardDetails.header}</span>
                    {mainCardDetails.description && (
                        <span className={styleSheet.cardDescription}>
                            {mainCardDetails.description}
                        </span>
                    )}
                </div>
                <div className={styleSheet.timeAndDate}>
                    <div className={styleSheet.iconAndText}>
                        <img className={styleSheet.icon} src={GrayCal} />
                        <span className={styleSheet.dateTimeText}>{formattedShowingDate}</span>
                    </div>
                    <div className={styleSheet.iconAndText} style={{ marginTop: 8 }}>
                        <img className={styleSheet.icon} src={GrayClock} />
                        <span className={styleSheet.dateTimeText}>{formattedShowingTime}</span>
                    </div>
                </div>
            </div>,
        );

        //Card 2 = Access Information Card (only for status confirmed)
        if (showingDetails?.status === 'confirmed') {
            const listingDetails = showingDetails?.listing?.agentListing;

            overviewPageData.push(
                <div className={styleSheet.cardContent} id="accessInfo">
                    <span className={styleSheet.smallCardHeader} style={{ marginBottom: 10 }}>
                        Access Information
                    </span>
                    <span className={styleSheet.infoHeader}>Type</span>
                    <span className={styleSheet.infoText}>
                        {listingDetails?.lockboxType === 'Other'
                            ? 'Manual Lock Box'
                            : listingDetails?.lockboxType}
                    </span>
                    <span className={styleSheet.infoHeader}>Location</span>
                    <span className={styleSheet.infoText}>{listingDetails?.lockboxLocation}</span>
                    {listingDetails?.lockboxPhotoUpload && (
                        <ClickableWithFeedback
                            className={styleSheet.viewLocationLink}
                            onClick={() => {
                                setShowLockboxPhotoModal(true);
                            }}
                        >
                            <span>View Lock Location</span>
                        </ClickableWithFeedback>
                    )}
                    <span className={styleSheet.infoHeader}>Code</span>
                    <span className={styleSheet.infoText}>{listingDetails?.lockCombo}</span>
                </div>,
            );
        }

        //Card 3 = Showing Instructions (for confirmed and complete)
        if (showingDetails?.status === 'completed' || showingDetails?.status === 'confirmed') {
            const showingInstructions = showingDetails?.listing?.agentListing?.showingInstructions;
            overviewPageData.push(
                <div className={styleSheet.cardContent} id="showingInstructions">
                    <span className={styleSheet.smallCardHeader} style={{ marginBottom: 10 }}>
                        Showing Instructions
                    </span>
                    <span className={styleSheet.cardDescription}>{showingInstructions}</span>
                </div>,
            );
        }

        //Card 3 = Reminder for status logged
        if (showingDetails?.status === 'logged') {
            overviewPageData.push(
                <div className={styleSheet.reminderCardContent} id="reminder">
                    <span className={styleSheet.reminderHeader}>Reminder:</span>
                    <span className={styleSheet.reminderText}>
                        Please make sure to schedule this showing using the listing agents’
                        preferred showing management platform.
                    </span>
                </div>,
            );
        }

        //Card 4 and 5 = Listing agent Information and co-Listing Agent Information
        const listingAgents = showingDetails?.listing?.agentListing?.agents;
        listingAgents?.map((listAg: any, index: number) => {
            overviewPageData.push(
                <div id={`listingAgent${index}`} className={styleSheet.agentContent}>
                    <div className={styleSheet.cardContent}>
                        <div className={styleSheet.cardFirstContent}>
                            <span className={styleSheet.smallCardHeader}>
                                {listAg.type || 'Listing Agent'}
                            </span>
                            {listAg.profilePhotoUpload?.uri && (
                                <img
                                    className={styleSheet.userPhoto}
                                    src={listAg.profilePhotoUpload.uri}
                                />
                            )}
                            <span className={styleSheet.smallCardHeader}>
                                {listAg.firstName} {listAg.lastName}
                            </span>
                            <span
                                className={styleSheet.cardDescription}
                                style={{ marginBottom: 10 }}
                            >
                                {formatEmailForDisplay(listAg.email)}
                            </span>
                            <span className={styleSheet.cardDescription}>
                                {formatPhoneNumberForDisplay(listAg.phoneNumber)}
                            </span>
                        </div>
                    </div>
                    {selectedView === 'List Side' &&
                        listAg.type === 'Listing Agent' &&
                        showingDetails?.consumer &&
                        clientTab('Seller')}
                </div>,
            );
        });

        //Card 6 = Buyer agent information if unverified user doesnt exist, otherwise unverified showing user
        if (!showingDetails?.unverifiedUser && showingDetails?.agent) {
            const buyerAgent = showingDetails?.agent;
            overviewPageData.push(
                <div id="buyerAgent" className={styleSheet.agentContent}>
                    <div className={styleSheet.cardContent}>
                        <div className={styleSheet.cardFirstContent}>
                            <span className={styleSheet.smallCardHeader}>Buyer Agent</span>
                            {/* {buyerAgent.profilePhotoUpload?.uri && (
                        <img
                            className={styleSheet.userPhoto}
                            src={listAg.profilePhotoUpload.uri}
                        />
                    )} */}
                            <span className={styleSheet.smallCardHeader}>
                                {buyerAgent.firstName} {buyerAgent.lastName}
                            </span>
                            <span
                                className={styleSheet.cardDescription}
                                style={{ marginBottom: 10 }}
                            >
                                {formatEmailForDisplay(buyerAgent.email)}
                            </span>
                            <span className={styleSheet.cardDescription}>
                                {formatPhoneNumberForDisplay(buyerAgent.phoneNumber)}
                            </span>
                        </div>
                    </div>
                    {selectedView === 'Buy Side' && showingDetails?.consumer && clientTab('Buyer')}
                </div>,
            );
        } else if (showingDetails?.unverifiedUser) {
            const unverified = showingDetails?.unverifiedUser;

            overviewPageData.push(
                <div className={styleSheet.cardContent} id="unverified">
                    <div className={styleSheet.cardFirstContent}>
                        <span className={styleSheet.smallCardHeader}>
                            {unverified?.occupationType}
                        </span>
                        {/* {buyerAgent.profilePhotoUpload?.uri && (
                    <img
                        className={styleSheet.userPhoto}
                        src={listAg.profilePhotoUpload.uri}
                    />
                )} */}
                        <span className={styleSheet.smallCardHeader}>
                            {unverified?.firstName} {unverified?.lastName}
                        </span>
                        <span className={styleSheet.cardDescription} style={{ marginBottom: 10 }}>
                            {formatEmailForDisplay(unverified?.email)}
                        </span>
                        <span className={styleSheet.cardDescription}>
                            {unverified?.phone
                                ? formatPhoneNumberForDisplay(unverified?.phone)
                                : unverified?.phone}
                        </span>
                        {unverified?.company ? (
                            <span className={styleSheet.cardDescription} style={{ marginTop: 30 }}>
                                Company:{' '}
                                <span className={styleSheet.compName}>{unverified?.company}</span>
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </div>
                </div>,
            );
        }

        return overviewPageData;
    };

    const clientTab = (client: any) => (
        <>
            {/* <span onClick={toggleTab}>
                <div className={styleSheet.clientTab}>
                    {`View ${client} clients`}

                    <img className={styleSheet.arrow} src={ArrowDown} />
                </div>
            </span> */}

            <input type="checkbox" name="toggle" id="toggle" className={cssStyles.toggle} />
            <label
                htmlFor="toggle"
                className={cssStyles.forToggle}
            >{`View ${client} Clients`}</label>
            <ClientDiv />
        </>
    );

    const ClientDiv = () => {
        const client = showingDetails?.consumer;
        return (
            <div className={cssStyles.message1}>
                <div className={styleSheet.mainDiv}>
                    <div className={styleSheet.nameCity} ref={tabRef}>
                        <span className={styleSheet.name}>
                            {client?.firstName} {client?.lastName}
                        </span>
                        {/* <span className={styleSheet.value}>Denver, CO</span> */}
                    </div>
                    <div className={styleSheet.personalDetails}>
                        <div className={styleSheet.emailPhone}>
                            <span className={styleSheet.heading}>Email</span>
                            <span className={styleSheet.heading}>Phone</span>
                        </div>
                        <div className={styleSheet.emailPhone}>
                            <span className={styleSheet.value}>
                                {formatEmailForDisplay(client?.email)}
                            </span>
                            <span className={styleSheet.value}>
                                {formatPhoneNumberForDisplay(client?.phoneNumber)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const header = showingDetails && (
        <div className={styleSheet.header}>
            <ClickableWithFeedback
                className={styleSheet.headerItem}
                onClick={() => history.goBack()}
            >
                Back
            </ClickableWithFeedback>
            <img src={ArrowLeftIcon} height={15} className={styleSheet.headerItem} />
            {`${getStatusForDisplay(showingDetails.status)}  Showing`}
            <div className={styleSheet.selectionContainer}></div>
        </div>
    );

    const listingInfoBar = (buttons: any) =>
        showingDetails && (
            <div className={styleSheet.listingInfoBar}>
                <div className={styleSheet.leftHeader}>
                    <span className={styleSheet.price}>
                        {'$' + formatCurrency(showingDetails.listing.listPrice)}
                    </span>
                    <div className={styleSheet.verticalSeparator} />
                    <span className={styleSheet.address1}>
                        {showingDetails.listing.address?.full}
                    </span>
                    <span className={styleSheet.address2}>
                        {showingDetails.listing.address?.city},{' '}
                        {showingDetails.listing.address?.state}{' '}
                        {showingDetails.listing.address?.postal}
                    </span>
                    {showingDetails.listing.mlsList?.length &&
                    showingDetails.listing.mlsList[0]?.standardStatus === 'Active' ? (
                        <span className={styleSheet.tag}>For Sale</span>
                    ) : null}
                </div>
                <div className={styleSheet.buttonContainer}>
                    {buttons.map((button: any) => {
                        return (
                            <Button
                                disabled={isShowingUpdating}
                                type={whiteButtons.includes(button.text) ? 'white' : 'default'}
                                widthType={button.small ? 'Small' : 'Full'}
                                onClick={button.onPress}
                                title={button.text}
                                customStyle={styleSheet.button}
                            />
                        );
                    })}
                    {/* )} */}
                </div>
            </div>
        );

    const cardContent = () => {
        const columnCount = Math.floor(cardContainerSize.width / 500);
        const overviewPageData = assignCardType();
        const agentListing = showingDetails?.agent?.agentListing;
        return (
            <>
                <div
                    className={styleSheet.cardsContainer}
                    style={{ width: cardContainerSize.width - 25 }}
                >
                    <AnimatedModal
                        set={setShowLockboxPhotoModal}
                        modalVisible={showLockboxPhotoModal}
                        style={{ top: null, margin: 'auto', width: 812 }}
                        ref={lockboxModalRef}
                    >
                        <div
                            className={styleSheet.header}
                            style={{ padding: 16, background: 'none' }}
                        >
                            <Typography color={indexTheme.palette.mediumGrey} textStyle={'p1'}>
                                View Lock Location
                            </Typography>
                            <img
                                className={styleSheet.closeButton}
                                src={close}
                                onClick={() => {
                                    lockboxModalRef?.current?.closeModal();
                                }}
                            />
                        </div>
                        <div
                            className={styleSheet.header}
                            style={{
                                ...indexTheme.paddingVertical(11),
                                ...indexTheme.paddingHorizontal(16),
                                background: 'none',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Typography color={indexTheme.palette.mediumGrey} textStyle={'p1'}>
                                Lock Location:
                            </Typography>
                            <Typography textStyle={'p1'}>
                                {agentListing?.lockboxLocation}
                            </Typography>
                        </div>
                        {agentListing?.lockboxPhotoUpload?.uri && (
                            <div className={styleSheet.lockboxPhotoContainer}>
                                <img
                                    src={agentListing?.lockboxPhotoUpload?.uri}
                                    className={styleSheet.lockboxPhoto}
                                />
                            </div>
                        )}
                    </AnimatedModal>
                    {overviewPageData.map((card: any) => {
                        return (
                            <div className={styleSheet.ifAgent}>
                                <div
                                    className={styleSheet.card}
                                    style={{
                                        width:
                                            columnCount === 1
                                                ? 600
                                                : window.innerWidth >= 1920
                                                ? 549
                                                : window.innerWidth >= 1707
                                                ? 478
                                                : 416,
                                    }}
                                >
                                    {card}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const topContent = (
        <>
            {header}

            {listingInfoBar(assignButtons())}
        </>
    );

    const photo = showingDetails?.consumer?.photo;
    const profilePic = photo?.uri
        ? { uri: photo.uri }
        : photo?.base64 && photo?.mime
        ? { uri: `data:${photo.mime};base64,${photo.base64}` }
        : {
              uri: 'https://showingly-image-assets.s3.amazonaws.com/Default+Unadded+Avatar.png',
          };
    const consumerToPass = showingDetails?.consumer
        ? {
              profilePicture: profilePic,
              firstName: showingDetails.consumer.firstName,
              lastName: showingDetails.consumer.lastName,
              stitchUserId: showingDetails.consumer.stitchUserId,
          }
        : null;
    const defaultValue = getTimeDateShowing(showingDetails);
    const content = (
        <>
            <ScheduleScreen
                listing={showingDetails?.listing}
                opType={'Reschedule'}
                defaultValue={defaultValue}
                onClose={() => {
                    setShowScheduleDrawer(false);
                    // if (showingDetails?.updatelog.detailMsg) {
                    //     setDetailMsgOpen(showing?.status);
                    // }
                }}
                clientDrawerInteract={setShowAddClientDrawer}
                agentDrawerInteract={setShowAddAgentDrawer}
                visible={showScheduleDrawer}
                rescheduleDetails={{
                    passedShowingId: showingDetails?._id,
                    passedAgentId: showingDetails?.agentId,
                    passedClient: consumerToPass,
                    passedShowingStatus: showingDetails?.status,
                    passedShowingType: showingDetails?.type,
                }}
            />
            <AddClientDrawer
                visible={showAddClientDrawer}
                onClose={() => setShowAddClientDrawer(false)}
                backgroundOpacity={0}
            />
            <AddAgentDrawer
                visible={showAddAgentDrawer}
                onClose={() => setShowAddAgentDrawer(false)}
                backgroundOpacity={0}
                onlyBrokerageAgents={!isBrokeragesListing(brokerage, listing)}
            />
            <DenyModal
                showing={showingDetails}
                ref={refDeny}
                set={setModal}
                modalVisible={denyModal}
                closeModal={() => refDeny?.current?.closeModal()}
                onSend={(denialReason: string) => {
                    dispatch(
                        changeShowingStatusRequested(showingDetails?._id, 'denied', denialReason),
                    );
                }}
                loading={isShowingUpdating}
            />

            <LeaveFeedbackDrawer
                showing={showingDetails}
                ref={leaveFeedbackRef}
                set={setLeaveFeedbackDrawer}
                drawerVisible={leavefeedbackDrawer}
                loading={isFeedbackSubmitting}
                changeStatus={() => {}}
                type={'Leave Feedback'}
            />

            <LeaveFeedbackDrawer
                showing={showingDetails}
                ref={refFeedbackDrawer}
                set={setFeedbackDrawer}
                drawerVisible={feedbackDrawer}
                type={'View Feedback'}
            />

            <ModalConfirm
                open={cancelModal}
                onClose={setCancelModal}
                title="Cancel Showing"
                text="Are you sure you want to cancel this showing?"
                onClickYes={() => {
                    dispatch(changeShowingStatusRequested(showingDetails?._id, 'cancelled'));
                    setCancelModal(false);
                }}
                onClickNo={() => setCancelModal(false)}
            />

            {topContent}
            {cardContent()}
        </>
    );

    return (
        <div className={styleSheet.root} style={{ height: cardContainerSize.height }}>
            {showingDetails && !isLoading ? (
                content
            ) : (
                <div className={styleSheet.loaderContainer}>
                    <Loader />
                </div>
            )}
        </div>
    );
}
