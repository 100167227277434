import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { sendVerificationMsgRequested, verifyCodeRequested } from '../actions';
import { getErrors, getPosibleClient } from '../selectors';

/*-------- Components ---------*/
import Typography from '../../../components/Typography';
import VerifyInput from '../../../components/VerifyInput';
import VerifySelect from '../../../components/VerifySelect';
import ButtonComponent from '../../../components/ButtonComponent';

/*-------- Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Constants ---------*/
import { BrokerageUser } from '../../../utils/constants';

interface Props {}

export default function VerificationMethod(props: {}) {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- Constants ---------*/
    const history = useHistory();
    const dispatch = useDispatch();

    /*-------- States ---------*/
    // Keeps track of the information the last verification code was requested with
    const [payload, setPayload] = useState<any>(null);
    const [enteredCode, setEnteredCode] = useState<string>('');
    const [reSendCode, setReSendCode] = useState<boolean>(false);
    const [codeSent, setCodeSent] = useState<string | null>(null);
    const [selectedVerifyOption, selectVerifyOption] = useState<string>('SMS');

    /*-------- Selectors ---------*/
    const errors = useSelector(getErrors);
    const user: BrokerageUser = useSelector(getPosibleClient);

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (!user) {
            history.replace('/');
        }
    }, [user]);

    /*-------- Functions ---------*/
    const goToPwd = () => {
        history.push('/auth/password');
    };

    const sendCode = (method: 'email' | 'phone', receiver: string, isResend: boolean) => {
        dispatch(sendVerificationMsgRequested(method, receiver, isResend));
    };
    const verifyCode = (
        method: 'email' | 'phone',
        code: any,
        emailOrPhone?: string,
        linkingAgentObjId?: any,
    ) => {
        dispatch(verifyCodeRequested(method, code, goToPwd, emailOrPhone, linkingAgentObjId));
    };

    const receiver =
        selectedVerifyOption === 'SMS'
            ? user?.phoneNumber || 'Placeholder non-number phone number that no one will have'
            : user?.email || 'Placeholder non-email that no one will have';
    const verificationMethods: any = {
        SMS: 'phone',
        Email: 'email',
    };

    return (
        <div className={styleSheet.root}>
            <h1 className={styleSheet.title}>First, we need to verify your account.</h1>
            <div className={`${styleSheet.content} ${!codeSent ? '' : styleSheet.borderBottom}`}>
                {!codeSent ? (
                    <>
                        <Typography
                            color={'gray'}
                            textStyle="b1"
                            style={{ fontSize: 18, paddingBottom: 20 }}
                        >
                            Select your preferred verification method.
                        </Typography>
                        <VerifySelect
                            codeSend={(codeType: string) => {
                                const selectedMethod = verificationMethods[codeType];
                                setPayload({
                                    selectedMethod,
                                    receiver,
                                });
                                sendCode(selectedMethod, receiver, false);
                                setCodeSent(codeType);
                            }}
                            selectedVerifyOption={selectedVerifyOption}
                            selectVerifyOption={(fo: any) => selectVerifyOption(fo)}
                            verifyOptions={['SMS', 'Email']}
                            buttonText={'Send Code'}
                        />
                    </>
                ) : (
                    <div className={styleSheet.middleBox}>
                        <VerifyInput
                            disabled={false}
                            enteredCode={enteredCode}
                            setEnteredCode={setEnteredCode}
                            style={{ padding: 5, marginTop: -15 }}
                            //TODO ask manny if it will be as on agent web, In case dont Delete it
                            /*
                                onCodeFilled={() => {
                                    verifyCode(payload?.selectedMethod, enteredCode);
                                }}*/
                            onCodeFilled={() => {}}
                            onError={errors[0] === '0100-1' ? true : false}
                        />
                        <Typography
                            color={theme.palette.black}
                            textStyle="b1"
                            style={{ fontSize: 18, marginTop: 52 }}
                        >
                            Didn't receive a code?
                        </Typography>
                        <br />
                        <Typography
                            color={theme.palette.mediumGrey}
                            textStyle="b2"
                            style={{ fontSize: 16, marginTop: 16 }}
                        >
                            Check your spam or junk folders and make sure your cell phone or email
                            is up to date in the MLS.
                        </Typography>
                        <Typography
                            color={reSendCode ? theme.palette.green : theme.palette.blue}
                            textStyle="b1"
                            style={{ fontSize: 16, marginTop: 16 }}
                            onClick={() => {
                                sendCode(payload?.selectedMethod, payload?.receiver, true);
                                setReSendCode(true);
                            }}
                        >
                            {reSendCode ? 'Code Resent' : 'Resend Code'}
                        </Typography>
                    </div>
                )}
            </div>
            {codeSent && (
                <div className={styleSheet.otherBottomBox}>
                    <ButtonComponent
                        onClick={() => verifyCode(payload?.selectedMethod, enteredCode)}
                        disabled={false}
                        fill
                        width={257}
                    >
                        Verify Code
                    </ButtonComponent>
                </div>
            )}
        </div>
    );
}

VerificationMethod.defaultProps = {};
