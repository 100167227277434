export const STATUS_FILTER = [
    'completed',
    'logged',
    'confirmed',
    'pending_internal',
    'requested',
    'cancelled',
    'denied',
];

export const SHOWINGS_SELECTED_VIEW = {
    ListSide: 'List Side',
    BuySide: 'Buy Side',
};

export const SHOWINGS_FILTER = {
    All: 'All',
    Upcoming: 'Upcoming',
    NeedsAction: 'Needs Action',
};

export const ITEMS_PER_PAGE = 20;
