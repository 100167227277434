import { createSelector } from 'reselect';

export const getAgentProfileState = (state: any) => state.selectedAgent;

/*-------- Agent ---------*/

export const getAgentProfile = createSelector(getAgentProfileState, (agent) => {
    return agent.agent;
});

export const getAgentLoadingStatus = createSelector(getAgentProfileState, (agent) => {
    return agent.loading;
});

export const getAgentFetchError = createSelector(getAgentProfileState, (agent) => {
    return agent.error;
});

/*-------- Listings ---------*/

export const getAgentListings = createSelector(getAgentProfileState, (activeListings) => {
    return activeListings;
});

export const getAgentListingLoadingStatus = createSelector(getAgentProfileState, (agent) => {
    return agent.listingLoading;
});

export const getAgentListingFetchError = createSelector(getAgentProfileState, (agent) => {
    return agent.listingError;
});

/*-------- Showings ---------*/

export const getAgentShowings = createSelector(getAgentProfileState, (showings) => {
    return showings;
});

export const getAgentShowingsLoadingStatus = createSelector(getAgentProfileState, (agent) => {
    return agent.showingsLoading;
});

export const getAgentShowingsFetchError = createSelector(getAgentProfileState, (agent) => {
    return agent.showingsError;
});

export const getShowingsCurrentPage = createSelector(getAgentProfileState, (agent) => {
    return agent.showingsCurrentPage;
});
