export default (theme: any) => {
    // In order to keep the cards Widgetized, only change these values to change the cards width.
    const CARD_WIDTH = 310;
    const CARD_MARGIN = 8;
    const CARD_PADDING = 16;
    const CARD_HEIGHT = 128;

    const cardStyles = {
        backgroundColor: theme.palette.white,
        height: CARD_HEIGHT,
        margin: CARD_MARGIN,
        padding: CARD_PADDING,
        borderRadius: 5,
        ...theme.cardShadow,
    };
    return {
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            ...theme.paddingHorizontal(8),
            paddingTop: 16,
            justifyContent: 'center',
        },
        card: {
            ...cardStyles,
            width: CARD_WIDTH,
            minWidth: 200,
        },
        largeCard: {
            ...cardStyles,
            // CARD_MARGIN + CARD_PADDING gets the spacing offset to make the large cards fit with the small cards
            width: (CARD_WIDTH + CARD_PADDING + CARD_MARGIN) * 2,
            minWidth: 400,
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...theme.typography.semiBold,
            color: theme.palette.blue,
            fontSize: 18,
            marginBottom: 2,
        },
        subText: {
            ...theme.typography.medium,
            fontSize: 16,
            color: theme.palette.mediumGrey,
        },
        miniSeparator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            width: 200,
            ...theme.marginVertical(10),
        },
        statSection: {
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        },
        mainStat: {
            ...theme.typography.semiBold,
            fontSize: 26,
            color: theme.palette.black,
        },
        statTitle: {
            ...theme.typography.medium,
            fontSize: 16,
            color: theme.palette.mediumGrey,
        },
        starStyle: {
            ...theme.marginHorizontal(5),
        },
        starRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 6,
        },
        comingSoonText: {
            ...theme.typography.semiBold,
            fontSize: 26,
            color: theme.palette.mediumGrey,
        },
        linkContainer: {
            ...theme.rowStyles,
            justifyContent: 'flex-start',
        },
    };
};
