import React, { forwardRef, useCallback, useEffect, useState } from 'react';

/*-------- Components ---------*/
import ClickableWithFeedback from '../../../../../components/ClickableWithFeedback';
import FilterDrawer from '../FilterDrawer';
import Marker from '../Marker';
import TypedGoogleMapReact from 'google-map-react';

/*-------- Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Icons -------------*/
import FilterIcon from '../../../../../images/icons/filter.svg';

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';
import { FetchGeoListingsRequested } from '../../actions';
import { getGeoListings, isGeoLoading } from '../../selectors';

/*------ Constants / Typess ---------*/
import { FilterOptionType, MapListingObject, Region } from '../../types';
import { DEFAULT_REGION } from '../../constants';

/*------------ MISC -----------*/

interface MapViewProps {
    searchListings: Array<MapListingObject>;
    onMapClicked: Function;
    onPressMarker: Function;
    containerStyles?: string;
    selectedListing?: any;
    center?: any;
}
interface MapProps {
    center: {
        lat: number;
        lng: number;
    };
    zoom: number;
}

const MapView = forwardRef((props: MapViewProps, ref) => {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, MapViewProps, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*---- Constants -----------*/
    const {
        searchListings,
        onPressMarker,
        onMapClicked,
        containerStyles,
        selectedListing,
        center,
    } = props;
    const GoogleMapReact = TypedGoogleMapReact as any;
    const dispatch = useDispatch();

    /*---- State -----------*/
    const [mapProps, setMapProps] = useState<MapProps>(DEFAULT_REGION);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [minMaxCoords, setMinMaxCoords] = useState<{
        minCoords: TypedGoogleMapReact.Coords | undefined;
        maxCoords: TypedGoogleMapReact.Coords | undefined;
    }>({
        minCoords: undefined,
        maxCoords: undefined,
    });
    const [filterOptions, setFilterOptions] = useState<FilterOptionType>({
        statuses: ['Active'],
        types: [],
        onlyConfigured: false,
        beds: 'Any',
        baths: 'Any',
        parking: 'Any',
        minPrice: -1,
        maxPrice: Number.MAX_VALUE,
        minSqft: -1,
        maxSqft: Number.MAX_VALUE,
    });

    /*---- Ref --------------*/

    /*---- Selectors -----------*/
    const listings: Array<MapListingObject> = useSelector(getGeoListings);
    const loading: boolean = useSelector(isGeoLoading);

    /*---- Callbacks -----------*/

    const markers = useCallback(() => {
        return listings.map((listingObj: MapListingObject) => {
            return (
                <Marker
                    key={`${listingObj._id}`}
                    lat={listingObj?.geo?.lat}
                    lng={listingObj?.geo?.lng}
                    onPressMarker={() => onPressMarker(listingObj)}
                    isSelected={`${listingObj._id}` === `${selectedListing?._id}`}
                />
            );
        });
    }, [listings, selectedListing]);

    /*---- Effects -----------*/
    // Mount - Check user location
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((pos) => {
                const mapProps = {
                    center: {
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude,
                    },
                    zoom: 10,
                };
                setMapProps(mapProps);
            });
        }
    }, []);

    useEffect(() => {
        if (center) {
            const mapProps = {
                center: {
                    lat: center.lat,
                    lng: center.lng,
                },
                zoom: 16,
            };
            setMapProps(mapProps);
        }
    }, [center]);

    useEffect(() => {
        fetchMapListings(
            {
                minCoords: minMaxCoords.minCoords,
                maxCoords: minMaxCoords.maxCoords,
            } as Region,
            searchListings,
            filterOptions,
        );
    }, [filterOptions]);

    /*---- Functions -----------*/
    const fetchMapListings = (
        region: Region,
        searchListings: Array<MapListingObject>,
        filter: any,
    ) => {
        dispatch(FetchGeoListingsRequested(region, searchListings, filter));
    };

    return (
        <div className={containerStyles}>
            {/* Filter Drawer */}
            <FilterDrawer
                filterVisible={filterVisible}
                setFilterVisible={setFilterVisible}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
            />
            {/* Filter Button */}
            <ClickableWithFeedback
                onClick={() => {
                    setFilterVisible(true);
                }}
                className={styleSheet.filterButton}
            >
                <img src={FilterIcon} alt={'Filter'} />
                <span className={styleSheet.filterText}>{`Filter`}</span>
            </ClickableWithFeedback>
            <GoogleMapReact
                // @ts-ignore
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
                    libraries: ['places'],
                }}
                defaultCenter={DEFAULT_REGION.center}
                defaultZoom={DEFAULT_REGION.zoom}
                zoom={mapProps && mapProps.zoom}
                center={mapProps && mapProps.center}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }: any) => {}}
                onChange={(value: TypedGoogleMapReact.ChangeEventValue) => {
                    const {
                        bounds: { ne: maxCoords, sw: minCoords },
                    } = value;
                    setMinMaxCoords({ minCoords, maxCoords });
                    fetchMapListings(
                        {
                            minCoords,
                            maxCoords,
                        },
                        searchListings,
                        filterOptions,
                    );
                }}
                onClick={() => {
                    onMapClicked(true);
                }}
            >
                {markers()}
                {loading && (
                    <div className={styleSheet.loadingBar}>
                        <span className={styleSheet.loadingText}>Searching listings...</span>
                    </div>
                )}
            </GoogleMapReact>
        </div>
    );
});

MapView.defaultProps = {
    searchListings: [],
};

export default MapView;
