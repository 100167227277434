/*
 * Reusable Profile Picture component
 * If there's no profile pic, the name initials will be displayed
 */

import React from 'react';

/*------ Assets --------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*------ Constants / Types -------*/
import { Agent, BrokerageUser } from '../../utils/constants';
const FONT_SIZE_RATIO = 2;

interface Props {
    size: number;
    user: Agent | BrokerageUser;
}

export default function ProfilePicture(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Constants --------*/
    const { size, user } = props;
    const initials = `${user.firstName[0]}${user.lastName[0]}`;

    return (
        <div
            className={styleSheet.root}
            style={{
                height: size,
                width: size,
                fontSize: Math.ceil(size / FONT_SIZE_RATIO),
            }}
        >
            {user.profilePhotoUpload ? (
                <img
                    style={{ height: size, width: size, borderRadius: size }}
                    src={user.profilePhotoUpload.uri}
                />
            ) : (
                <span>{initials}</span>
            )}
        </div>
    );
}

ProfilePicture.defaultProps = {
    agent: null,
    size: 50,
};
