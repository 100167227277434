export const TIME_HEIGHT_PER_MIN_RATIO = 25 / 15;

export const noticeTime = [
    { label: 'Select a Time', value: -1, default: true },
    { label: '7:00am', value: 700 },
    { label: '7:15am', value: 715 },
    { label: '7:30am', value: 730 },
    { label: '7:45am', value: 745 },
    { label: '8:00am', value: 800 },
    { label: '8:15am', value: 815 },
    { label: '8:30am', value: 830 },
    { label: '8:45am', value: 845 },
    { label: '9:00am', value: 900 },
    { label: '9:15am', value: 915 },
    { label: '9:30am', value: 930 },
    { label: '9:45am', value: 945 },
    { label: '10:00am', value: 1000 },
    { label: '10:15am', value: 1015 },
    { label: '10:30am', value: 1030 },
    { label: '10:45am', value: 1045 },
    { label: '11:00am', value: 1100 },
    { label: '11:15am', value: 1115 },
    { label: '11:30am', value: 1130 },
    { label: '11:45am', value: 1145 },
    { label: '12:00pm', value: 1200 },
    { label: '12:15pm', value: 1215 },
    { label: '12:30pm', value: 1230 },
    { label: '12:45pm', value: 1245 },
    { label: '1:00pm', value: 1300 },
    { label: '1:15pm', value: 1315 },
    { label: '1:30pm', value: 1330 },
    { label: '1:45pm', value: 1345 },
    { label: '2:00pm', value: 1400 },
    { label: '2:15pm', value: 1415 },
    { label: '2:30pm', value: 1430 },
    { label: '2:45pm', value: 1445 },
    { label: '3:00pm', value: 1500 },
    { label: '3:15pm', value: 1515 },
    { label: '3:30pm', value: 1530 },
    { label: '3:45pm', value: 1545 },
    { label: '4:00pm', value: 1600 },
    { label: '4:15pm', value: 1615 },
    { label: '4:30pm', value: 1630 },
    { label: '4:45pm', value: 1645 },
    { label: '5:00pm', value: 1700 },
    { label: '5:15pm', value: 1715 },
    { label: '5:30pm', value: 1730 },
    { label: '5:45pm', value: 1745 },
    { label: '6:00pm', value: 1800 },
    { label: '6:15pm', value: 1815 },
    { label: '6:30pm', value: 1830 },
    { label: '6:45pm', value: 1845 },
    { label: '7:00pm', value: 1900 },
    { label: '7:15pm', value: 1915 },
    { label: '7:30pm', value: 1930 },
    { label: '7:45pm', value: 1945 },
    { label: '8:00pm', value: 2000 },
    { label: '8:15pm', value: 2015 },
    { label: '8:30pm', value: 2030 },
    { label: '8:45pm', value: 2045 },
    { label: '9:00pm', value: 2100 },
    // { label: '9:15pm', value: 2115 },
    // { label: '9:30pm', value: 2130 },
    // { label: '9:45pm', value: 2145 },
];

export const noticeHours = [
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '1', value: 13 },
    { label: '2', value: 14 },
    { label: '3', value: 15 },
    { label: '4', value: 16 },
    { label: '5', value: 17 },
    { label: '6', value: 18 },
    { label: '7', value: 19 },
    { label: '8', value: 20 },
    { label: '9', value: 21 },
];

export const noticeMins = [
    { label: '00', value: 0 },
    { label: '15', value: 15 },
    { label: '30', value: 30 },
    { label: '45', value: 45 },
];

export const noticeMeridian = [
    { label: 'AM', value: 'am' },
    { label: 'PM', value: 'pm' },
];

export const duration: any[] = [
    { label: 'Select a Duration', value: -1, default: true },
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '1 hour', value: 60 },
    { label: '1 hour & 15 minutes', value: 75 },
    { label: '1 hour & 30 minutes', value: 90 },
    { label: '1 hour & 45 minutes', value: 105 },
    { label: '2 hours', value: 120 },
];

/**
 * Showings based on duration of 60 minutes. Screen displays
 * showings at a height of x pixels. This ratio converts minutes
 * to fit x number of pixels
 */
export const MINUTE_TO_PIXEL_RATIO = 100 / 60;

export const EARLIEST_TIME_AVAILABLE_HOURS = 7;

// used to declare border width of the unavailable slot card
export const UNAVAILABLE_SLOT_BORDER_WIDTH = 2;
