/*----- Redux imports -------*/
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as AgentsAction from './actions';
import { callStitchFunction, findRecord } from '../../../../store/api/sagas';

/*----- Utils imports -------*/
import { parseStitchServiceError } from '../../../../utils/common';
import {
    AGENTS,
    FETCH_AGENT_SHOWINGS,
    FETCH_AGENTS_ACTIVE_LISTINGS,
} from '../../../../store/api/constants';

/*----- Misc imports -------*/
import { getSelectedBrokerage } from '../../Profile/selectors';
import { getAgentProfile } from './selectors';
import { STATUS } from '../../../../utils/constants';
import { BSON } from 'realm-web';

export function* getAll(): Generator<any, any, any> {
    const brokerage = yield select(getSelectedBrokerage);
    const agent = yield select(getAgentProfile);

    try {
        const listings = yield call(
            callStitchFunction,
            FETCH_AGENTS_ACTIVE_LISTINGS,
            agent._id,
            brokerage._id,
        );
        yield put(AgentsAction.fetchSelectedAgentListingsSucceeded(listings));
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AgentsAction.fetchSelectedAgentListingsFailed(err));
    }
}

export function* getAllShowings(): Generator<any, any, any> {
    const agent = yield select(getAgentProfile);

    try {
        const showings = yield call(callStitchFunction, FETCH_AGENT_SHOWINGS, agent._id);

        yield put(AgentsAction.fetchSelectedAgentShowingsSucceeded(showings));
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AgentsAction.fetchSelectedAgentShowingsFailed(err));
    }
}

export function* getAgentProfileFromStich({ agentId }: any): Generator<any, any, any> {
    try {
        const agent = yield call(findRecord, AGENTS, {
            _id: new BSON.ObjectId(agentId),
        });
        if (agent) {
            yield put(AgentsAction.fetchSelectedAgentProfileSucceeded(agent));
        } else {
            yield put(AgentsAction.fetchSelectedAgentProfileFailed('Agent Not Found'));
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AgentsAction.fetchSelectedAgentProfileFailed(err));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === AgentsAction.SELECTED_AGENT_ACTION.GetSelectedAgentListings &&
                action.status === STATUS.Requested,
            getAll,
        ),

        yield takeLatest(
            (action: any) =>
                action.type === AgentsAction.SELECTED_AGENT_ACTION.FetchSelectedAgentShowings &&
                action.status === STATUS.Requested,
            getAllShowings,
        ),

        yield takeLatest(
            (action: any) =>
                action.type === AgentsAction.SELECTED_AGENT_ACTION.FetchAgentProfile &&
                action.status === STATUS.Requested,
            getAgentProfileFromStich,
        ),
    ]);
}
