const height = window.innerHeight;

export default (theme) => {
    const contentWidth = 632;
    return {
        root: {
            width: 674,
            height: 501,
            marginTop: '12%',
            marginLeft: '5%',
            borderRadius: 5,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.white,
        },
        title: {
            fontSize: 36,
            fontWeight: 600,
            paddingBottom: 30,
            fontStyle: 'normal',
            fontFamily: theme.typography.medium.fontFamily,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
        },
        content: {
            display: 'flex',
            alignItems: 'baseline',
            flexDirection: 'column',
            padding: 48,
            textAlign: 'left',
        },
        input: {
            border: 0,
            margin: 20,
            width: 536,
            height: 40,
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 5,
            fontStyle: 'normal',
            textAlign: 'center',
            background: theme.palette.background,
            fontFamily: theme.typography.semiBold.fontFamily,
        },
        button: {
            border: 0,
            width: 257,
            height: 40,
            fontSize: 18,
            fontWeight: 600,
            borderRadius: 5,
            color: theme.palette.white,
            fontStyle: 'normal',
            backgroundColor: theme.palette.blue,
            fontFamily: theme.typography.semiBold.fontFamily,
        },
        blueText: {
            fontSize: 16,
            fontWeight: 600,
            color: theme.palette.blue,
            fontStyle: 'normal',
            fontFamily: theme.typography.semiBold.fontFamily,
        },
        middleBox: {
            padding: 48,
            paddingTop: 60,
            paddingBottom: 20,
            height: 218,
            display: 'flex',
            borderBottom: `1px solid ${theme.palette.gray}`,
            flexDirection: 'column',
            /*width: contentWidth - 96,*/
        },
        otherBottomBox: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 48,
        },
        inputText: {
            width: 530,
            height: 40,
            borderWidth: 0,
            backgroundColor: theme.palette.background,
            fontFamily: theme.typography.semiBold.fontFamily,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 18,
            marginBottom: 14,
            textAlign: 'center'
        },
        inputBox: {
            display: 'flex',
            flexDirection: 'row',
        },
        icons: {
            padding: 9.5,
            width: 23,
            height: 23,
            background: theme.palette.background,
        },

        topBox: {
            // ...theme.paddingHorizontal(48),
            padding: 48,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            width: 536,
        },
        midBox: {
            padding: 48,
            paddingTop: 76,
            paddingBottom: 20,
            height: 250,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
            display: 'flex',
            flexDirection: 'column',
            width: contentWidth - 96,
        },
        bottomBox: {
            marginTop: 44,
        },

        fieldsContainer: {
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        fieldContainer: {
            display: 'flex',
            //width: 536,
            flexDirection: 'column',
            alignItems: 'baseline',
            justifyContent: 'center',
        },

        searchBar: (props) => ({
            height: 35,
            ...theme.paddingHorizontal(10),
            alignItems: 'center',
            width: props?.width ?? 238,
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'relative',
            borderRadius: 5,
            '&:active': {
                opacity: 0.7,
            },
            cursor: 'pointer',
        }),
        checkFieldsContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
            marginLeft: -6.5,
            paddingTop: 48,
        },
        checkField: {
            flexDirection: 'row',
            display: 'flex',
            paddingBottom: 15,
        },
        blueText: {
            '&:active': {
                opacity: 0.5,
            },
            paddingLeft: 3,
            cursor: 'pointer',
        },
        checkFieldTextContainer: {
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    };
};