import { createSelector } from 'reselect';

export const getShowingRequestState = (state: any) => state.showingRequests;

/*-------------- Showing Availability ----------------*/
export const getShowingAvailability = createSelector(
    getShowingRequestState,
    (state: any) => state.showingAvailability,
);
export const isShowingAvailabilityLoading = createSelector(
    getShowingRequestState,
    (state: any) => state.loadingShowingAvailability,
);
export const getShowingAvailabilityError = createSelector(
    getShowingRequestState,
    (state: any) => state.errorShowingAvailability,
);

/*------------- Unverified Agent Data ---------*/
export const getUnverifiedAgentData = createSelector(
    getShowingRequestState,
    (state: any) => state.unverifiedAgentData,
);

/*------------- Selected Agent ---------*/
export const getSelectedAgent = createSelector(
    getShowingRequestState,
    (state: any) => state.selectedAgent,
);

/*------------ Agent's clients --------*/

export const getClients = createSelector(getShowingRequestState, (state: any) => state.clients);
export const isClientsLoading = createSelector(
    getShowingRequestState,
    (state: any) => state.loadingCreateShowing,
);
export const getClientsError = createSelector(
    getShowingRequestState,
    (state: any) => state.errorClients,
);

/*------------ Agent's Schedule --------*/

export const getAgentSchedule = createSelector(
    getShowingRequestState,
    (state: any) => state.agentSchedule,
);
export const isAgentScheduleLoading = createSelector(
    getShowingRequestState,
    (state: any) => state.loadingAgentSchedule,
);
export const getAgentScheduleError = createSelector(
    getShowingRequestState,
    (state: any) => state.errorAgentSchedule,
);

/*------------- Selected Client ---------*/
export const getSelectedClient = createSelector(
    getShowingRequestState,
    (state: any) => state.selectedClient,
);

/*-------------- CREATE Showing ----------------*/
export const getCreatedShowingId = createSelector(
    getShowingRequestState,
    (state: any) => state.createdShowingId,
);
export const isCreateShowingLoading = createSelector(
    getShowingRequestState,
    (state: any) => state.loadingCreateShowing,
);
export const getCreateShowingError = createSelector(
    getShowingRequestState,
    (state: any) => state.errorCreateShowing,
);

/*-------------- RESCHEDULED Showing ----------------*/
export const getRescheduledShowingId = createSelector(
    getShowingRequestState,
    (state: any) => state.rescheduledShowing,
);