import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

interface SwitchProps {
    checked: boolean;
    onToggle: Function;
    containerStyling?: string;
    switchStyling?: string;
    disabled?: boolean;
}

const Switch = ({
    checked,
    containerStyling,
    onToggle,
    switchStyling,
    disabled = false,
}: SwitchProps) => {
    return (
        <div className={containerStyling}>
            <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => (disabled ? {} : onToggle())}
                />
                <span className={classnames(styles.slider, styles.round, switchStyling)}></span>
            </label>
        </div>
    );
};

export default Switch;
