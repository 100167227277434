export default (theme, props) => {
    const flexCenter = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    };

    const cardWidth = '100%';

    return {
        root: {
            backgroundColor: theme.palette.white,
            ...theme.paddingHorizontal(48),
            ...theme.paddingVertical(24),
            width: '79vw'
        },
        rootMobile: {
            backgroundColor: theme.palette.white,
            ...theme.paddingHorizontal(20),
            ...theme.paddingVertical(0),
        },
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            ...theme.marginVertical(16),
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        icon: {
            width: 26,
            height: 26,
            marginRight: 10,
        },
        greyTag: {
            backgroundColor: theme.palette.background,
            paddingLeft: 5,
            paddingRight: 5,
            ...theme.typography.semiBold,
            borderRadius: 5,
            color: theme.palette.mediumGrey,
            fontSize: 14,
            marginLeft: 26,
        },
        redTag: {
            backgroundColor: theme.palette.lightRed,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.red,
            fontSize: 14,
        },
        greyTagMobile: {
            backgroundColor: theme.palette.background,
            color: theme.palette.mediumGrey,
            ...theme.typography.semiBold,
            borderRadius: 5,
            fontSize: 14,
        },
        textRight: {
            textAlign: 'right',
        },
        greyTitle: {
            color: theme.palette.mediumGrey,
            marginBottom: 5,
        },
        textStyleJustify: {
            textAlign: 'justify',
        },

        /*----- Image section styles -------*/

        imageSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            overflowY: 'hidden',
            '&::-webkit-scrollbar': {
                width: 'auto',
                background: '#F1EEEB',
                height: '12px'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#857D76',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#BFBAB6',
            },
            // add height here so it does not bounce when loading in.
            height: 285,
        },
        image: {
            height: 265,
            width: 'auto',
            marginRight: 16,
        },

        /*------ Showing details container -------*/

        listingInfoSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        detailContainer: {
            ...flexCenter,
        },
        detailSectionContainer: {
            ...flexCenter,
        },
        listingBedBathContainer: {
            ...flexCenter,
        },
        iconAndText: {
            ...flexCenter,
        },

        /*------- Public remarks --------*/
        publicRemarksSectionContainer: {
            marginBottom: 16,
        },

        /*------ Metadata ---------*/
        metaDataSectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background,
            height: 160,
            ...theme.marginHorizontal(-48),
            ...theme.paddingVertical(16),
            ...theme.paddingHorizontal(48),
        },
        card: {
            backgroundColor: theme.palette.white,
            width: cardWidth,
            height: 128,
            borderRadius: 5,
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            ...theme.marginHorizontal(8),
        },
        dataRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 16,
        },
        key: {
            fontFamily: 'Poppins-SemiBold',
            fontSize: 14,
            marginRight: 5,
        },
        value: {
            fontSize: 14,
        },
        mapContainer: {
            height: 153,
            width: cardWidth,
            padding: 4,
            borderRadius: 5,
            backgroundColor: theme.palette.white,
            ...theme.marginHorizontal(8),
        },
        mapMobileContainer: {
            height: 153,
            width: 'auto',
            borderRadius: 5,
            backgroundColor: theme.palette.white,
        },

        /*------- Listed by ---------*/

        listedBySectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 16,
            alignItems: 'center',
            color: theme.palette.mediumGrey,
        },
        listingAgent: {
            ...theme.typography.semiBold,
            marginLeft: 16,
        },
        mlsNumber: {
            ...theme.typography.semiBold,
            color: theme.palette.mediumGrey,
            fontSize: 14,
        },
        disclaimer: {
            fontSize: 14,
            color: theme.palette.mediumGrey,
        },

        /*------- MLS Number and disclaimer -------*/

        mlsNumber: {
            fontFamily: 'Poppins-semiBold',
            color: theme.palette.mediumGrey,
            fontSize: 14,
        },

        disclaimer: {
            fontSize: 14,
            color: theme.palette.mediumGrey,
        },

        /*------- Table styles --------*/
        table: {
            display: 'table',
        },
        tableRow: {
            display: 'table-row',
        },
        tableCol: {
            display: 'table-cell',
        },
    };
};
