import { createSelector } from 'reselect';

export const getShowingsState = (state: any) => state.showings;

/*-------------- All Showings ----------------*/

export const getShowings = createSelector(getShowingsState, (state: any) => state.showings);

export const isShowingsLoading = createSelector(getShowingsState, (state: any) => state.loading);

/*-------------- Showing Details ----------------*/

export const getShowingDetails = createSelector(getShowingsState, (state: any) => state.showing);

/*-------------- Update Showing ----------------*/

export const isUpdatingShowing = createSelector(getShowingsState,(state: any) => state.updatedShowing);

export const getUpdateShowingError = createSelector(getShowingsState,(state: any) => state.updatedShowingError);

/*-------------- Submit Feedback ----------------*/

export const getFeedbackError = createSelector(getShowingsState,(state: any) => state.feedbackError);

export const getFeedbackSubmitting = createSelector(getShowingsState,(state: any) => state.submittingFeedback);

/*-------------- Get Current Page ----------------*/

export const getCurrentPage = createSelector(getShowingsState, (state: any) => state.currentPage)



