// Default Region for Denver as used on the Mobile App
export const DEFAULT_REGION = {
    center: {
        lat: 39.7392,
        lng: -104.9903,
    },
    zoom: 10,
};

export const DEFAULT_SEARCH_RESULT_LIMIT = 50;

/**
 * Projection that is used with the find operation on the listings
 * collection when a search is done.
 *
 * @type {{}}
 */
export const LISTINGS_FIND_PROJECTION_FOR_SEARCH = {
    projection: {
        _id: 1,
        listPrice: 1,
        geo: 1,
        address: 1,
        is_displayed: 1,
        property: 1,
        schools: 1,
        agentListing: 1,
        listingId: 1,
        photos: 1,
        agent: 1,
        mls: 1,
        mlsId: 1,
        office: 1,
        tax: 1,
        school: 1,
        association: 1,
        markets: 1,
        disclaimer: 1,
        source: 1,
        mlsList: 1,
        remarks: 1,
    },
    sort: { _id: -1 },
    limit: DEFAULT_SEARCH_RESULT_LIMIT,
};
