export default (theme) => {
    return {
        root: {
            position: 'fixed',
            backgroundColor: theme.palette.white,

            width: 200,
            height: '100%',

            marginTop: 80,

            borderRight: {
                color: theme.palette.separatorGrey,
                width: 2,
                style: 'solid',
            },

            borderTop: {
                color: theme.palette.separatorGrey,
                width: 1,
                style: 'solid',
            },
        },
        linkItem: {
            textDecoration: 'none',
        },
        listItemSelected: {
            backgroundColor: 'rgba(72, 164, 255, 0.15)!important',
        },
        iconAndName: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
        },
        icon: {
            justifySelf: 'center',
        },
        pageNameSelected: {
            fontSize: 16,
            color: theme.palette.blue,
            textAlign: 'center',
            fontFamily: 'Poppins-Medium'
        },
        pageName: {
            fontSize: 16,
            color: theme.palette.darkGrey,
            textAlign: 'center',
            fontFamily: 'Poppins-Medium'
        },
    };
};
