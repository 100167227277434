import { all, call } from 'redux-saga/effects';

// import agentSaga from '../domains/main/AgentDirectory/sagas';
// import authSaga from '../domains/auth/sagas';
import authSaga from '../domains/auth/sagas';
// import dashSaga from '../domains/main/Dashboard/sagas';
// import brokerageFeedSaga from '../domains/main/Brokerage/sagas';
import initSaga from '../init/saga';
// import profileSaga from '../domains/main/Profile/saga';
import manageUsersSaga from '../domains/main/Users/saga';

// V2
import listingSaga from '../domains/main/Listings/saga';
import showingsSaga from '../domains/main/Showings/saga';
import searchMapSaga from '../domains/main/SearchMap/saga';
import showingRequestsSaga from '../domains/main/ShowingRequests/saga';
import agentsSaga from '../domains/main/Agents/saga';
import selectedAgentSaga from '../domains/main/Agents/AgentProfile/saga';
import profileSaga from '../domains/main/Profile/saga';
import clientsSaga from '../domains/main/Clients/saga';
// import widgetSaga from '../components/Widgets/sagas';

export default function* rootSaga(): Generator<any, any, any> {
    yield all([
        // call(agentSaga),
        call(authSaga),
        // call(brokerageFeedSaga),
        call(listingSaga),
        // call(dashSaga),
        call(initSaga),
        call(manageUsersSaga),
        call(profileSaga),
        call(showingsSaga),
        call(searchMapSaga),
        call(showingRequestsSaga),
        call(agentsSaga),
        call(clientsSaga),
        call(selectedAgentSaga),
        // call(widgetSaga),
    ]);
}
