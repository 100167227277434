/*-------- Redux  ---------*/
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import * as AuthActions from './actions';
import * as AuthSelector from './selectors';
import {
    login,
    logout,
    anonLogin,
    findRecord,
    callStitchFunction,
    requestUserRegistration,
    requestEmailVerification,
    requestPhoneVerification,
    findRecords,
} from '../../store/api/sagas';

/*-------- Utils ---------*/
import { getStitchUser } from '../../utils/storage';
import { parseStitchServiceError, scrubAndFormatPhoneNumber, scrubEmail } from '../../utils/common';

/*-------- Constants ---------*/
import {
    BROKERAGE_USERS_COLLECTION_NAME,
    BROKERAGE_USERS_TYPE_NAME,
    BROKERAGES_COLLECTION,
    USER_TYPE_AGENT,
} from '../../store/api/constants';

/*-------- Misc  ---------*/
import { STATUS } from './types';
import { BSON } from 'realm-web';
//import { ObjectID } from 'mongodb';

export function* initAuth(): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const { loggedInProviderType } = user;
        const isRememberMe = localStorage.getItem('remember');
        if (isRememberMe === 'true' && loggedInProviderType !== 'anon-user' && user?.isLoggedIn) {
            const brokerageUser = yield call(findRecord, BROKERAGE_USERS_COLLECTION_NAME, {
                stitchUserId: user.id,
            });

            if (brokerageUser) {
                yield put(AuthActions.logInSucceeded(brokerageUser));
                yield put(AuthActions.initializationSucceeded());
            } else {
                yield put(AuthActions.initializationFailed());
            }
        } else {
            yield put(AuthActions.initializationFailed());
        }
    } catch (error) {
        yield put(AuthActions.initializationFailed());
    }
}

export function* LogIn({
    email,
    password,
}: ReturnType<typeof AuthActions.logInRequested>): Generator<any, any, any> {
    try {
        //Close the AnonLogin
        yield call(logout);
        sessionStorage.clear();

        //Formating and getting the data used for login
        const scrubbedEmail = email.trim().toLocaleLowerCase();
        const atIndex = scrubbedEmail.indexOf('@');
        const posibleClient = yield select(AuthSelector.getPosibleClient);
        const { emailAlias, brokerageId } = posibleClient;

        // Build aliased email
        const aliasedEmail = `${scrubbedEmail.slice(0, atIndex)}+${emailAlias}${scrubbedEmail.slice(
            atIndex,
        )}`;

        const loginRESP = yield call(login, aliasedEmail, password);
        if (loginRESP) {
            const brokerages = yield findRecords(BROKERAGES_COLLECTION, {
                _id: { $in: [brokerageId] },
            });
            if (brokerages?.length === 1) {
                if (brokerages[0]?.brokerageSuite) {
                    // Update user's last session
                    const data = {
                        lastSession: new Date(),
                    };
                    const response = yield call(
                        callStitchFunction,
                        'updateDocumentAsAdmin',
                        BROKERAGE_USERS_COLLECTION_NAME,
                        posibleClient._id,
                        data,
                    );
                    if (response) {
                        localStorage.setItem('remember', 'true');
                        yield put(AuthActions.logInSucceeded(posibleClient));
                    }
                } else {
                    //error, 1 brokerage and dont have accces
                    const err = 'BrokerageDontHaveAccess';
                    yield put(AuthActions.logInFailed([err]));
                    yield call(logOut);
                }
            } else {
                //TODO finish the multiple brokerage logic. -> navigate to multiple Brokerage account selection
            }
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AuthActions.logInFailed(['SomethingGoesWrong', err]));
    }
}

export function* logOut(): Generator<any, any, any> {
    try {
        localStorage.removeItem('remember');
        localStorage.removeItem('account');
        yield call(logout);
        yield put(AuthActions.logOutSucceeded());
    } catch (error) {
        yield put(AuthActions.logOutFailed());
    }
}

export function* searchTheEmail({
    email,
}: ReturnType<typeof AuthActions.searchEmailRequested>): Generator<any, any, any> {
    try {
        const scrubbedEmail = email.trim().toLocaleLowerCase();
        yield call(anonLogin);
        const isBrokerage = yield findRecord(BROKERAGE_USERS_COLLECTION_NAME, {
            email: scrubbedEmail,
        });
        if (isBrokerage) {
            yield put(AuthActions.searchEmailSucceeded(isBrokerage));
        } else {
            yield put(AuthActions.searchEmailFailed(['NoExistedUser']));
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AuthActions.searchEmailFailed([err]));
    }
}

/**
 * Request email code verification
 * @param onSuccessHandler
 * @param onFailureHandler
 * @param email
 * @param isResend
 * @return {IterableIterator<object>}
 */
export function* verifyEmail(
    onSuccessHandler: any,
    onFailureHandler: any,
    email: string,
    isResend = false,
): Generator<any, any, any> {
    /*
     * Update the Auth store with pending email
     * so other screens can access this value
     */
    const user = yield select(AuthSelector.getPosibleClient);
    const newUser = {
        ...user,
        pendingEmail: email,
    };
    //yield put(updatePendingUser(newUser));
    try {
        yield call(requestEmailVerification, email);
        yield put(onSuccessHandler(isResend));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(onFailureHandler());
        yield put(AuthActions.verifyCodeFailed([errorMessage]));
    }
}

/**
 * Scrub the phone number and request sms code verification
 * @param onSuccessHandler
 * @param onFailureHandler
 * @param phoneNumber
 * @param isResend
 * @return {IterableIterator<object>}
 */
export function* verifyPhoneNumber(
    onSuccessHandler: any,
    onFailureHandler: any,
    phoneNumber: string,
    isResend = false,
): Generator<any, any, any> {
    try {
        const posibleClient = yield select(AuthSelector.getPosibleClient);
        const currentPhoneNumber = posibleClient.phoneNumber;
        const parsedPhoneNumber = scrubAndFormatPhoneNumber(currentPhoneNumber, '');
        const newUser = {
            ...posibleClient,
            phoneNumber: parsedPhoneNumber,
        };
        yield call(requestPhoneVerification, parsedPhoneNumber);
        yield put(onSuccessHandler(isResend));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(AuthActions.verifyCodeFailed([errorMessage]));
        yield put(onFailureHandler());
    }
}

/**
 *
 * @param {method} 'phone' | 'email' to check what verification method to use
 * @param {receiver} receiving email or phonenumber
 * @param {isResend} has the verification been resent
 */
export function* sendVerification({
    method,
    receiver,
    isResend,
}: ReturnType<typeof AuthActions.sendVerificationMsgRequested>): Generator<any, any, any> {
    try {
        const pendingUser = yield select(AuthSelector.getPosibleClient);
        if (method === 'phone' && !pendingUser.pendingEmail) {
            yield call(
                verifyPhoneNumber,
                AuthActions.sendVerificationMsgSucceeded,
                AuthActions.sendVerificationMsgFailed,
                receiver,
                isResend,
            );
        } else if (method === 'email' || pendingUser?.email) {
            yield call(
                verifyEmail,
                AuthActions.sendVerificationMsgSucceeded,
                AuthActions.sendVerificationMsgFailed,
                receiver,
                isResend,
            );
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AuthActions.sendVerificationMsgFailed([err]));
    }
}

/**
 * Call common auth handler for verifying phone code
 * @param {string|number} phoneCode The code that was sent via SMS to the user's phone.
 * @returns {IterableIterator<object>}
 * @param {emailOrPhone} optional phone number or email, used when sending a verification isn't done through a pending user
 */
export function* verifyEntryCode({
    method,
    code,
    history,
    emailOrPhone,
    linkingAgentObjId,
}: ReturnType<typeof AuthActions.verifyCodeRequested>): Generator<any, any, any> {
    try {
        yield call(anonLogin);
        const pendingUser = yield select(AuthSelector.getPosibleClient);

        const stitchUser = yield select(getStitchUser);
        const stitchUserId = stitchUser?.id;
        const check =
            method === 'phone'
                ? yield call(
                      callStitchFunction,
                      'confirmPhoneVerification',
                      emailOrPhone ? emailOrPhone : pendingUser.phoneNumber,
                      code,
                      BROKERAGE_USERS_TYPE_NAME,
                      false,
                      stitchUserId ? stitchUserId : pendingUser.stitchUserId,
                      false,
                  )
                : yield call(
                      callStitchFunction,
                      'verifyEmail',
                      emailOrPhone ? emailOrPhone : pendingUser.email,
                      code,
                      USER_TYPE_AGENT,
                      true,
                  );
        if (check) {
            if (emailOrPhone && linkingAgentObjId) {
                // attempt Linked License Flow
                //yield put(linkAgentRequested(linkingAgentObjId));
                console.log('Code Error?');
            } else {
                history();
                //yield put(AuthActions.verifyCodeSucceeded());
            }
        } else {
            if (emailOrPhone) {
                yield put(
                    AuthActions.verifyCodeFailed([
                        'There was an error verifying your code',
                        'Code: 0100-3',
                    ]),
                );
            } else {
                yield put(
                    AuthActions.verifyCodeFailed([
                        'There was an error processing your account',
                        'Code: 0100-2',
                    ]),
                );
            }
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(AuthActions.verifyCodeFailed(['0100-1', error]));
    }
}

/**
 * Request new user to be created in Stitch (also triggers email confirmation to be sent)
 * Then add user to consumer collection.
 *
 * @param navigation
 * @param {string} email
 * @param {string} password
 * @param {string|number} marketName
 * @returns {IterableIterator<object>}
 */
//
export function* registerUser({
    userEmail,
    password,
    userObjectId,
}: any): Generator<any, any, any> {
    try {
        const pendingUser = yield select(AuthSelector.getPosibleClient);

        const scrubbedEmail = scrubEmail(userEmail);
        const alias = Math.ceil(Math.random() * 10000000).toString();

        // create email with Alias
        const atIndex = scrubbedEmail.indexOf('@');
        const aliasedEmail = `${scrubbedEmail.slice(0, atIndex)}+${alias}${scrubbedEmail.slice(
            atIndex,
        )}`;

        yield call(anonLogin);
        // create new realm user
        yield call(requestUserRegistration, aliasedEmail, password);

        // TODO: Fix remember me functionallity
        localStorage.removeItem('remember');
        localStorage.removeItem('account');
        yield call(logout);
        sessionStorage.clear();
        const rememberMe = true;
        localStorage.setItem('remember', rememberMe.toString());

        const loginRESP = yield call(login, aliasedEmail, password);
        const stitchUserId = loginRESP?.user?.id;
        const newData = {
            emailAlias: alias,
            stitchUserId,
            signUpDate: new Date(),
            lastSession: new Date(),
            signUpCompleted: true,
            status: 'verified',
        };
        const response = yield call(
            callStitchFunction,
            'updateDocumentAsAdmin',
            BROKERAGE_USERS_COLLECTION_NAME,
            userObjectId,
            newData,
        );

        if (response) {
            yield put(AuthActions.logInSucceeded({ ...newData, ...pendingUser }));
        } else {
            yield put(AuthActions.verifyCodeFailed(['Error trying to register user.']));
        }
    } catch (err) {
        const error = parseStitchServiceError(err);
        yield put(AuthActions.verifyCodeFailed(['0100-2', error]));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === AuthActions.AUTH_ACTION.LogIn && action.status === STATUS.Requested,
            LogIn,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AuthActions.AUTH_ACTION.Initialization &&
                action.status === STATUS.Requested,
            initAuth,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AuthActions.AUTH_ACTION.LogOut &&
                action.status === STATUS.Requested,
            logOut,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AuthActions.AUTH_ACTION.SearchEmail &&
                action.status === STATUS.Requested,
            searchTheEmail,
        ),
        yield takeLatest(
            (actions: any) =>
                actions.type === AuthActions.AUTH_ACTION.SendVerificationMsg &&
                actions.status === STATUS.Requested,
            sendVerification,
        ),
        yield takeLatest(
            (actions: any) =>
                actions.type === AuthActions.AUTH_ACTION.VerifyCode &&
                actions.status === STATUS.Requested,
            verifyEntryCode,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === AuthActions.AUTH_ACTION.Register &&
                action.status === STATUS.Requested,
            registerUser,
        ),
    ]);
}
