import React, { useEffect, useRef, useState } from 'react';

/*-------- Redux  ---------*/

import { useDispatch, useSelector } from 'react-redux';
import { submitFeedbackRequested } from '../../actions';
import { getFeedbackError, getFeedbackSubmitting, isUpdatingShowing } from '../../selectors';
import { ShowingRequest } from '../../types';

/*-------- Components ---------*/

import ToggleSwitch from '../../../../../components/ToggleSwitch';
import Button from '../../../../../components/Button';
import AnimatedDrawer from '../../../../../components/AnimatedDrawer';
import AnimatedDrawerHeader from '../../../../../components/AnimatedDrawerHeader';
import Loader from '../../../../../components/Loader';
import Typography from '../../../../../components/Typography';
import Stars from '../../../../../components/Stars';

/*-------- Utils ---------*/

import { formatEmailForDisplay, formatPhoneNumberForDisplay } from '../../../../../utils/common';
import { DateTime } from 'luxon';

/*-------- Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import indexTheme from '../../../../../theme';
import { CircularProgress } from '@material-ui/core';

/*-------- Icons ---------*/
import GrayCal from '../../../../../images/icons/grayCal.svg';
import GrayClock from '../../../../../images/icons/grayClock.svg';

/*-------- Constants ---------*/

/*-------- Misc  ---------*/

interface Props {
    set: Function;
    drawerVisible: boolean;
    ref: any;
    showing: ShowingRequest;
    changeStatus?: Function;
    loading?: boolean;
    type: any;
}
const feedbackStarStyle = { marginRight: 4, marginLeft: 0 };

const LeaveFeedbackDrawer = (props: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const dispatch = useDispatch();

    /*--------State-------*/
    const [message, setMessage] = useState<string>('');
    const [location, setLocation] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [interest, setInterest] = useState<number>(0);
    const [value, setValue] = useState<boolean>(false);

    /*--------Selectors-------*/
    const submitting = useSelector(getFeedbackSubmitting);
    const error = useSelector(getFeedbackError);

    /*--------Constants------*/
    const feedbackRef = useRef<any>(null);
    const { showing } = props;
    const agent = showing.listing?.agentListing?.agents
        ? showing.listing.agentListing.agents[0]
        : null;
    const startTime = DateTime.fromJSDate(showing?.start);
    const endTime = DateTime.fromJSDate(showing?.end);
    const formattedShowingDate = startTime.toFormat('LLLL d, y');
    const formattedShowingTime = `${startTime.toFormat('h:mm')}${startTime
        .toFormat('a')
        .toLowerCase()} - ${endTime.toFormat('h:mm')}${endTime.toFormat('a').toLowerCase()}`;

    function onStarClick(e: any, i: number, setter: Function) {
        var dim = e.currentTarget.getBoundingClientRect();
        setter(e.pageX - dim.left <= 12 ? i - 0.5 : i);
    }
    useEffect(() => {
        if (!submitting && !error) {
            props.ref?.current?.closeDrawer();
            props.set(false);
        }
    }, [submitting]);

    return props.drawerVisible ? (
        <AnimatedDrawer ref={feedbackRef} onClose={() => props.set(false)}>
            <>
                <AnimatedDrawerHeader
                    title={'Feedback'}
                    onClosePress={() => {
                        feedbackRef.current?.closeDrawer();
                    }}
                />
                <div className={styleSheet.feedbackDate}>
                    <Typography textStyle={'p2'}>{showing.listing.address.full} </Typography>
                    <Typography textStyle={'b2'}>
                        {showing.listing.address.city}, {showing.listing.address.state}{' '}
                        {showing.listing.address.postalCode}
                    </Typography>
                </div>
                {agent ? (
                    <div className={styleSheet.personalInfo}>
                        <div
                            className={styleSheet.feedbackDate}
                            style={{ ...indexTheme.marginHorizontal(0) }}
                        >
                            <Typography textStyle={'p2'}>
                                {agent?.firstName} {agent?.lastName}{' '}
                            </Typography>
                            <Typography textStyle={'b2'} color={indexTheme.palette.lightGrey}>
                                {showing.listing.name}
                            </Typography>
                        </div>
                        <div
                            className={styleSheet.feedbackDate}
                            style={{ ...indexTheme.marginHorizontal(0) }}
                        >
                            <Typography textStyle={'b2'}>
                                {formatEmailForDisplay(agent?.email)}
                            </Typography>
                            <Typography textStyle={'b2'}>
                                {formatPhoneNumberForDisplay(agent?.phoneNumber)}
                            </Typography>
                        </div>
                    </div>
                ) : null}
                <div className={styleSheet.showingTimeDetails} style={{ marginTop: 0 }}>
                    <div className={styleSheet.iconAndText}>
                        <img className={styleSheet.icon} src={GrayCal} />
                        <Typography
                            style={{ marginLeft: 15 }}
                            color={indexTheme.palette.mediumGrey}
                            textStyle={'p2'}
                        >
                            {formattedShowingDate}
                        </Typography>
                    </div>
                    <div className={styleSheet.iconAndText}>
                        <img className={styleSheet.icon} src={GrayClock} />
                        <Typography
                            style={{ marginLeft: 15 }}
                            color={indexTheme.palette.mediumGrey}
                            textStyle={'p2'}
                        >
                            {formattedShowingTime}
                        </Typography>
                    </div>
                </div>

                {props.type === 'View Feedback' ? (
                    <div className={styleSheet.feedbackStarForm}>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography textStyle={'b1'}>Price Accuracy</Typography>
                            <span className={styleSheet.feedbackSeparator}></span>
                            <span>
                                <Stars
                                    value={showing?.feedback?.ratings?.priceAccuracy}
                                    starStyle={feedbackStarStyle}
                                />
                            </span>
                        </div>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography textStyle={'b1'}>Location</Typography>
                            <span className={styleSheet.feedbackSeparator}></span>
                            <span>
                                <Stars
                                    value={showing?.feedback?.ratings?.location}
                                    starStyle={feedbackStarStyle}
                                />
                            </span>
                        </div>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography textStyle={'b1'}>Interest Level</Typography>
                            <span className={styleSheet.feedbackSeparator}></span>
                            <span>
                                <Stars
                                    value={showing?.feedback?.ratings?.interestLevel}
                                    starStyle={feedbackStarStyle}
                                />
                            </span>
                        </div>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography textStyle={'p1'}>Overall Rating</Typography>
                            <span className={styleSheet.feedbackSeparator}></span>
                            <span>
                                <Stars
                                    value={
                                        (showing?.feedback?.ratings?.priceAccuracy +
                                            showing?.feedback?.ratings?.location +
                                            showing?.feedback?.ratings?.interestLevel) /
                                        3
                                    }
                                    starStyle={feedbackStarStyle}
                                />
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className={styleSheet.feedbackStarForm}>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography style={{ marginLeft: 5 }} textStyle={'b2'}>
                                Price Accuracy
                            </Typography>
                            <span className={styleSheet.feedbackSeparator}></span>

                            <span>
                                <Stars
                                    value={price}
                                    starStyle={feedbackStarStyle}
                                    onStarClick={(e: any, i: number) => onStarClick(e, i, setPrice)}
                                />
                            </span>
                        </div>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography style={{ marginLeft: 5 }} textStyle={'b2'}>
                                Location
                            </Typography>
                            <span className={styleSheet.feedbackSeparator}></span>

                            <span>
                                <Stars
                                    value={location}
                                    starStyle={feedbackStarStyle}
                                    onStarClick={(e: any, i: number) =>
                                        onStarClick(e, i, setLocation)
                                    }
                                />
                            </span>
                        </div>
                        <div className={styleSheet.feedbackStarFormRow}>
                            <Typography style={{ marginLeft: 5 }} textStyle={'b2'}>
                                Interest Level
                            </Typography>
                            <span className={styleSheet.feedbackSeparator}></span>

                            <span>
                                <Stars
                                    value={interest}
                                    starStyle={feedbackStarStyle}
                                    onStarClick={(e: any, i: number) =>
                                        onStarClick(e, i, setInterest)
                                    }
                                />
                            </span>
                        </div>
                    </div>
                )}
                <div
                    className={styleSheet.showingTimeDetails}
                    style={{ marginTop: 0, borderBottom: 'none' }}
                >
                    <Typography textStyle={'p2'}>Additional Feedback</Typography>
                </div>

                {props.type === 'View Feedback' ? (
                    <Typography textStyle={'b2'} style={{ ...indexTheme.marginHorizontal(48) }}>
                        {showing?.feedback.review}
                    </Typography>
                ) : (
                    <div className={styleSheet.textAreaContainer}>
                        <textarea
                            className={styleSheet.textArea}
                            style={{ height: agent ? 92 : 136 }}
                            placeholder="What additional feedback would you like to give?"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            maxLength={250}
                        />
                        <div className={styleSheet.circularProgressContainer}>
                            <CircularProgress
                                variant="determinate"
                                size={28}
                                thickness={6}
                                style={{ color: '#d3d3d3' }}
                                value={100}
                            />
                            <CircularProgress
                                variant="determinate"
                                size={28}
                                thickness={6}
                                style={{ color: '#48A4FF', position: 'absolute', left: 0 }}
                                value={(message.length * 100) / 250}
                            />
                        </div>
                    </div>
                )}
                {/* Toggle Switch for viewable to sellers
                <div
                    className={styleSheet.feedbackStarFormRow}
                    style={{
                        ...indexTheme.marginHorizontal(48),
                        width: '85%',
                        borderBottom: 'none',
                        marginTop: !agent ? 17 : 0,
                    }}
                >
                    <Typography style={{ marginLeft: 5 }} textStyle={'b2'}>
                        Viewable by Sellers
                    </Typography>
                    <span className={styleSheet.feedbackSeparator}></span>

                    <span>
                        <ToggleSwitch
                            isOn={value}
                            handleToggle={() => setValue(!value)}
                            style={{ display: 'flex' }}
                        />
                    </span>
                </div> */}

                {props.type === 'Leave Feedback' ? (
                    <div className={styleSheet.bottomButtonContainer}>
                        {props.loading ? (
                            <Loader />
                        ) : (
                            <Button
                                disabled={submitting || message.length < 10}
                                type="default"
                                title="Send"
                                widthType="Auto"
                                onClick={() => {
                                    dispatch(
                                        submitFeedbackRequested(
                                            price,
                                            location,
                                            interest,
                                            message,
                                            showing._id,
                                        ),
                                    );
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </>
        </AnimatedDrawer>
    ) : null;
};
export default LeaveFeedbackDrawer;
