/*----- Redux imports -------*/
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ShowingRequestActions from './actions';
import * as ShowingsActions from '../Showings/actions';

/*------ Constants -----------*/
import { LISTINGS_COLLECTION, SHOWING_REQUESTS } from '../../../store/api/constants';

/*----- Utils imports -------*/
import { getUnavailableDates } from '../../../utils/common/schedulingHelpers';
import { parseStitchServiceError } from '../../../utils/common';

/*----- Misc imports -------*/
import { ShowingType, STATUS } from '../../../utils/constants';
import {
    callStitchFunction,
    findShowingRequest,
    generateSignature,
} from '../../../store/api/sagas';
import { getUnverifiedAgentData } from './selectors';
import { BSON } from 'realm-web';

export function* fetchShowingAvailability({ listingId, showingId }: any): Generator<any, any, any> {
    try {
        const firstDate: any = new Date();

        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);

        const showings = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query: {
                listingId,
                _id: {
                    $ne: showingId,
                },
                /**
                 * only if allowOverlap is not true on a connected listing
                 */
                'listing.agentListing.listingId': { $exists: true },
                'listing.agentListing.allowOverlap': { $ne: true },

                start: {
                    $gte: firstDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            },
            projection: {
                start: 1,
                end: 1,
                listing: 1,
            },
            signature: generateSignature(),
        });

        yield put(ShowingRequestActions.fetchShowingAvailabilitySucceeded(showings));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ShowingRequestActions.fetchShowingAvailabilityFailed(errorMessage));
    }
}

export function* createShowing({
    showingType,
    listingId,
    agentStitchUserId,
    startTime,
    endTime,
    utcOffset,
    clientStitchUserId,
}: any): Generator<any, any, any> {
    try {
        const showing = yield call(
            callStitchFunction,
            'createShowing',
            showingType,
            listingId,
            startTime,
            endTime,
            clientStitchUserId,
            utcOffset,
            null,
            agentStitchUserId,
        );

        yield put(ShowingRequestActions.createShowingSucceeded(showing._id));
        yield put(ShowingsActions.fetchShowingDetailsSucceeded(showing));
        yield put(ShowingsActions.fetchShowingsRequested());
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ShowingRequestActions.createShowingFailed(errorMessage));
    }
}

export function* createUnverifiedShowing({
    listingId,
    startTime,
    endTime,
}: any): Generator<any, any, any> {
    try {
        const unverifiedUser = yield select(getUnverifiedAgentData);
        const utcOffset = Math.floor(new Date().getTimezoneOffset() / 60);

        const unverifiedShowing = yield call(
            callStitchFunction,
            'createUnverifiedShowing',
            listingId,
            startTime,
            endTime,
            utcOffset,
            unverifiedUser,
        );

        yield put(ShowingRequestActions.createUnverifiedShowingSucceeded(unverifiedShowing._id));
        yield put(ShowingsActions.fetchShowingDetailsSucceeded(unverifiedShowing));
        yield put(ShowingsActions.fetchShowingsRequested());
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ShowingRequestActions.createUnverifiedShowingFailed(errorMessage));
    }
}

export function* fetchClients({ agentStitchId }: any): Generator<any, any, any> {
    try {
        const clients = yield call(callStitchFunction, 'fetchAgentClients', agentStitchId);
        yield put(ShowingRequestActions.fetchClientsSucceeded(clients));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ShowingRequestActions.fetchClientsFailed(errorMessage));
    }
}

export function* fetchAgentSchedule({ agentId }: any): Generator<any, any, any> {
    try {
        const firstDate: any = new Date();
        var lastDate: any = new Date();
        lastDate.setHours(0, 0, 0, 0);
        lastDate.setDate(lastDate.getDate() + 7);

        const showings = yield call(callStitchFunction, 'runGeneralQuery', {
            collectionName: SHOWING_REQUESTS,
            query: {
                agentId: agentId,
                start: {
                    $gte: firstDate,
                },
                status: { $nin: ['denied', 'cancelled', 'canceled'] },
            },
            projection: {
                start: 1,
                end: 1,
                type: 1,
                showingAssistantAgentUserId: 1,
                consumer: 1,
                status: 1,
            },
            signature: generateSignature(),
        });

        yield put(ShowingRequestActions.fetchAgentScheduleSucceeded(showings));
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ShowingRequestActions.fetchAgentScheduleFailed(errorMessage));
    }
}

/* --------------Reschedule Showing--------*/
/**
 * Reschedule a currently scheduled showing
 *
 * @param {showingType}
 * @param {showingId} _id of the document in showingRequests collection
 * @param {startTime}
 * @param {endTime}
 * @param {clientId} stitchUserId of client to be added
 * @param {previousShowingType}
 * @param {delegationPrice}
 */
function* rescheduleShowing({
    showingType,
    showingId,
    startTime,
    endTime,
    clientId,
    previousShowingType,
    delegationPrice,
}: ReturnType<typeof ShowingRequestActions.rescheduleShowingRequested>): Generator<any, any, any> {
    try {
        const utcOffset = Math.round(new Date().getTimezoneOffset() / 60);
        var showing = null;
        // if the type of the showing is NOT being CHANGED to delegated
        if (
            previousShowingType === ShowingType.Delegated ||
            showingType !== ShowingType.Delegated
        ) {
            showing = yield call(
                callStitchFunction,
                'rescheduleShowing',
                showingType,
                showingId,
                startTime,
                endTime,
                clientId,
                utcOffset,
                delegationPrice || null,
            );
        } else {
            const { upsertedId, modifiedCount } = yield call(
                callStitchFunction,
                'updateShowingRequestStatusOrType',
                {
                    id: showingId,
                    type: 'delegated',
                    start: startTime,
                    end: endTime,
                    delegationCost: delegationPrice,
                },
            );
            if (upsertedId || modifiedCount) {
                const updatedShowing = yield call(findShowingRequest, {
                    _id: new BSON.ObjectId(showingId),
                });
                if (updatedShowing?.agent?.profilePhotoUpload?.path)
                    updatedShowing.agent.photo = yield call(
                        callStitchFunction,
                        'getPhoto',
                        updatedShowing.agent.profilePhotoUpload.path,
                    );

                if (updatedShowing?.consumer?.profilePhotoUpload?.path)
                    updatedShowing.consumer.photo = yield call(
                        callStitchFunction,
                        'getPhoto',
                        updatedShowing.consumer.profilePhotoUpload.path,
                    );
                if (updatedShowing?.showingAssistant?.profilePhotoUpload?.path)
                    updatedShowing.showingAssistant.photo = yield call(
                        callStitchFunction,
                        'getPhoto',
                        updatedShowing.showingAssistant.profilePhotoUpload.path,
                    );
                showing = updatedShowing;
            } else {
                const mes = 'There was an error in updating this showing.';
                yield call(alert, {
                    type: 'error',
                    message: mes,
                });
                yield put(ShowingRequestActions.rescheduleShowingFailed(showingId, mes));
            }
        }

        yield put(ShowingRequestActions.rescheduleShowingSucceeded(showing._id));

        yield put(ShowingRequestActions.setSelectedClient(null));
        yield put(ShowingsActions.fetchShowingDetailsSucceeded(showing)); // update showing displayed on showing details screen
        yield put(ShowingsActions.fetchShowingsRequested());
    } catch (error: any) {
        const message = parseStitchServiceError(error);
        yield call(alert, {
            type: 'error',
            message,
        });
        yield put(ShowingRequestActions.rescheduleShowingFailed(showingId, error.message));
    }
}

/*------------Update Showing Request Status-------------*/
// TODO
/**
 *
 * @param {showingId} document _id of the showingRequest
 * @param {newShowingStatus} status to update the showing to
 * @param {callback} optional function to be called if the update succeeds
 */
function* updateShowingStatus({
    showingId,
    newShowingStatus,
    callback,
}: ReturnType<typeof ShowingRequestActions.updateShowingStatusRequested>): Generator<
    any,
    any,
    any
> {
    try {
        const { modifiedCount } = yield call(
            callStitchFunction,
            'updateShowingRequestStatusOrType',
            {
                id: showingId,
                status: newShowingStatus,
            },
        );
        if (modifiedCount) {
            // TODO - Modify stitch function to return showing instead of the showingId
            const showing: any = yield call(findShowingRequest, {
                _id: new BSON.ObjectId(showingId),
            });

            if (showing?.agent?.profilePhotoUpload?.path)
                showing.agent.photo = yield call(
                    callStitchFunction,
                    'getPhoto',
                    showing.agent.profilePhotoUpload.path,
                );
            if (showing?.consumer?.profilePhotoUpload?.path)
                showing.consumer.photo = yield call(
                    callStitchFunction,
                    'getPhoto',
                    showing.consumer.profilePhotoUpload.path,
                );
            if (showing?.showingAssistant?.profilePhotoUpload?.path)
                showing.showingAssistant.photo = yield call(
                    callStitchFunction,
                    'getPhoto',
                    showing.showingAssistant.profilePhotoUpload.path,
                );

            yield put(ShowingRequestActions.updateShowingStatusSucceeded(showing));
            if (callback) {
                yield call(callback);
            }
            yield put(ShowingsActions.fetchShowingDetailsSucceeded(showing)); // update showing displayed on showing details screen
            yield put(ShowingsActions.fetchShowingsRequested());
        } else
            yield put(
                ShowingRequestActions.updateShowingStatusFailed(showingId, 'Showing not updated.'),
            );
    } catch (error: any) {
        const message = parseStitchServiceError(error);
        yield call(alert, {
            type: 'error',
            message,
        });
        yield put(ShowingRequestActions.updateShowingStatusFailed(showingId, error.message));
    }
}
// #endregion updateShowingStatus

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.FetchShowingAvailability &&
                action.status === STATUS.Requested,
            fetchShowingAvailability,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.FetchAgentClients &&
                action.status === STATUS.Requested,
            fetchClients,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.CreateShowing &&
                action.status === STATUS.Requested,
            createShowing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.CreateUnverifiedShowing &&
                action.status === STATUS.Requested,
            createUnverifiedShowing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.FetchAgentSchedule &&
                action.status === STATUS.Requested,
            fetchAgentSchedule,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.RescheduleShowing &&
                action.status === STATUS.Requested,
            rescheduleShowing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingRequestActions.Actions.UpdateStatus &&
                action.status === STATUS.Requested,
            updateShowingStatus,
        ),
    ]);
}
