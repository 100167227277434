import React, { useEffect, useRef, useState } from 'react';
/*----- Redux -----*/
import { useDispatch, useSelector } from 'react-redux';
import { logOutRequested } from '../../../domains/auth/actions';
import { getProfileData } from '../../../domains/main/Profile/selectors';

/*---- Components ----------*/
import Button from '../../../components/Button';
import Separator from '../../../components/Separator';
import PopUpMenu from '../../../components/PopUpMenu';
import { Link, useHistory, useLocation } from 'react-router-dom';

/*------ Constants / Types -------*/
import { BrokerageUser } from '../../../utils/constants';

/*------ Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';
import AppLogo from '../../../images/logo.svg';
import NotificationsLogo from '../../../images/icons/notifications.svg';
import ArrowRightIcon from '../../../images/icons/arrow_right.svg';
import UserIcon from '../../../images/icons/user.svg';
import UsersIcon from '../../../images/icons/users.svg';

interface Props {}

export default function Navbar(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- Selectors ---------*/
    const user = useSelector<any, BrokerageUser>(getProfileData);

    /*------- Constants ---------*/
    const location = useLocation();
    const history = useHistory();
    const initials = user
        ? `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`
        : '';

    /*----- Dispatch -------*/
    const dispatch = useDispatch();

    /*------- State -------------*/
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    /*------- Use Effect --------*/
    // Everytime the route changes close the profile menu
    useEffect(() => {
        setOpenMenu(false);
    }, [location]);

    /*------- Functions ---------*/
    const toggleMenu = () => {
        setOpenMenu((prev) => !prev);
    };

    const Menu = () => (
        <PopUpMenu visible={openMenu} close={() => setOpenMenu(false)} verticalPosition="Bottom">
            <div className={styleSheet.profileMenu}>
                <ul className={styleSheet.profileMenuList}>
                    <li>
                        <Link to={'/profile'} className={styleSheet.profileMenuElement}>
                            <img src={UserIcon} />
                            <span className={styleSheet.marginLeft}>View Profile</span>
                            <img className={styleSheet.right} src={ArrowRightIcon} />
                        </Link>
                    </li>
                    <li>
                        <Link to={'/users'} className={styleSheet.profileMenuElement}>
                            <img src={UsersIcon} />
                            <span className={styleSheet.marginLeft}>Manage Users</span>
                            <img className={styleSheet.right} src={ArrowRightIcon} />
                        </Link>
                    </li>
                </ul>

                <Button
                    title="Log out"
                    onClick={() => {
                        dispatch(logOutRequested());
                    }}
                />
            </div>
        </PopUpMenu>
    );

    return (
        <div className={styleSheet.root}>
            <img src={AppLogo} height={40} />
            <div className={styleSheet.row}>
                <Button title="Schedule a Showing" onClick={() => history.push(`/search`)} />

                <Separator type="Vertical" />

                {/* <img src={NotificationsLogo} height={40} /> */}

                <div className={styleSheet.profileButton} onClick={toggleMenu}>
                    <span>{initials}</span>
                    <Menu />
                </div>
            </div>
        </div>
    );
}
