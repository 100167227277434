import { SELECTED_AGENT_ACTION } from './actions';
import { STATUS } from '../../../../utils/constants';

export const initialState = {
    agent: null,
    loading: false,
    error: '',

    activeListings: [],
    listingLoading: false,
    listingError: '',

    showings: [],
    showingsLoading: false,
    showingsError: '',
    showingsCurrentPage: {
        listSide: { all: 0, upcoming: 0, neddsAction: 0 },
        buySide: { all: 0, upcoming: 0, needsAction: 0 },
    },
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case SELECTED_AGENT_ACTION.SetSelectedAgent: {
            const { agent } = action;
            return {
                ...state,
                agent: agent,
                activeListings: [],
                showings: [],
            };
        }
        case SELECTED_AGENT_ACTION.GetSelectedAgent: {
            return {
                ...state,
            };
        }
        case SELECTED_AGENT_ACTION.GetSelectedAgentListings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        listingLoading: true,
                        error: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { activeListings } = action;
                    return {
                        ...state,
                        listingLoading: false,
                        activeListings,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        listingLoading: false,
                        error,
                    };
                }
                default:
                    return state;
            }
        }

        case SELECTED_AGENT_ACTION.FetchSelectedAgentShowings: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        showingsLoading: true,
                        showingsError: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { showings } = action;
                    return {
                        ...state,
                        showingsLoading: false,
                        showings,
                    };
                }
                case STATUS.Failed: {
                    const { showingsError } = action;
                    return {
                        ...state,
                        showingsLoading: false,
                        showingsError,
                    };
                }
                default:
                    return state;
            }
        }

        case SELECTED_AGENT_ACTION.SetShowingsCurrentPage: {
            switch (status) {
                case STATUS.Succeeded: {
                    const { page } = action;
                    return {
                        ...state,
                        showingsCurrentPage: page,
                    };
                }
                default:
                    return state;
            }
        }

        case SELECTED_AGENT_ACTION.FetchAgentProfile: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                        error: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { agent } = action;
                    return {
                        ...state,
                        loading: false,
                        agent,
                        activeListings: [],
                        showings: [],
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loading: false,
                        error,
                    };
                }
                default:
                    return state;
            }
        }

        case SELECTED_AGENT_ACTION.RemoveSelectedAgentListings: {
            return {
                ...state,
                activeListings: [],
                showings: [],
            };
        }

        default: {
            return state;
        }
    }
}
