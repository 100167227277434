export default (theme) => {
    const flexCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return {
        root: {
            ...flexCenter,
        },
        container: {
            backgroundColor: theme.palette.white,

            width: 600,
            padding: 25,
        },
        closeButton: {
            float: 'right',
        },
        title: {
            color: theme.palette.blue,
            ...theme.typography.semiBold,
            textAlign: 'center',
            fontSize: 28,
        },
        subtitle: {
            color: theme.palette.blue,
            ...theme.typography.medium,
            textAlign: 'center',
            fontSize: 28,
        },
        semiGrey: {
            color: theme.palette.lightGrey,
            ...theme.typography.medium,
            textAlign: 'center',
            fontSize: 18,
        },
        grey: {
            color: theme.palette.lightGrey,
            ...theme.typography.semiBold,
            textAlign: 'center',
            fontSize: 18,
        },
        body: {
            padding: '20px 70px',
            ...flexCenter,
            flexDirection: 'column',
        },
        inputContent: {
            marginBottom: 80,
            width: '100%',
        },
        inputTitle: {
            fontSize: 18,
            ...theme.typography.medium,
        },
        inputGroup: {
            width: '80%',
            margin: 'auto',
        },
        button: {
            width: '50%',
        },
    };
};
