import { ListingScheduleType } from '../constants';

/**
 * Formats a number with the input amount of decimal places, two by default.
 * @param amount
 * @param decimalCount The number of decimals to display
 * @returns {string}
 */
export const formatCurrency = (amount: any, decimalCount = 0) => {
    try {
        decimalCount = Math.abs(decimalCount);
        amount =
            isNaN(decimalCount) || decimalCount === 0
                ? amount
                : parseFloat(amount).toFixed(decimalCount);

        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch {
        return 0;
    }
};

export const formatShowingType = (showingType: ListingScheduleType | undefined | any) => {
    switch (showingType) {
        case ListingScheduleType.GoNShow:
            return 'Automatic Approval';
        case ListingScheduleType.ByAppointment:
            return 'Appointment Required';
        case ListingScheduleType.Accompanied:
            return 'Accompanied';
        default:
            return showingType;
    }
};

export const formatLockBoxType = (lockbox: string) => {
    switch (lockbox) {
        case 'Other':
            return 'Manual';

        default:
            return lockbox;
    }
};

export const formatMinutes = (minutes: any) => {
    if (minutes === 0) {
        return 'None';
    }
    if (minutes > 60) {
        let hours = minutes / 60;
        let splitHours = hours.toString().split('.');

        return `${splitHours[0]}h${splitHours[1] ? Number(`0.${splitHours[1]}`) * 60 + 'm' : ''}`;
    } else {
        return minutes + 'm';
    }
};

export const formatApprovalType = (approvalType: string, type: string) => {
    if (type === 'go_and_show') {
        return 'This listing has Automatic Approval configured.';
    }
    switch (approvalType) {
        case 'both':
            return 'Both the listing agent and seller must approve.';
        case 'agent':
            return 'Agent must approve.';
        case 'client':
            return 'Client must approve.';
        case 'either':
            return 'Either listing agent or seller can approve.';
        default:
            return approvalType;
    }
};
