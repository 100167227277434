export default (theme) => {
    return {
        radioButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            ...theme.marginVertical(7),
        },
        radioButtonText: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 16,
            marginTop: -3,
        },
    };
};
