export default (theme: any) => {
    return {
        starStyle: {
            ...theme.marginHorizontal(5),
        },
        starRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 6,
        },
    };
};
