export default (theme) => {
    const flexCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return {
        root: {
            ...flexCenter,
        },
        container: {
            backgroundColor: theme.palette.white,
            width: 450,
            borderRadius: 10,
            padding: 50,
            textAlign: 'center',
        },
        title: {
            ...theme.typography.semiBold,
            color: theme.palette.blue,
            fontSize: 20,
        },
        text: {
            color: theme.palette.mediumGrey,
            fontSize: 16,
        },
        buttonsContainer: {
            marginTop: 50,
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            ...theme.marginHorizontal(25),
        },
    };
};
