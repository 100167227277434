import { TableHeader } from '../../../components/DataTable/types';
import ComingSoonIcon from '../../../images/icons/coming_soon.svg';

export const ITEMS_PER_PAGE = 50; /* --->Length of item on the table*/

export const HEADERS: TableHeader[] = [
    { name: '', orderable: false, width: '3%', itemTextAlign: 'center' },
    { name: 'Name', orderable: true, width: '15%' },
    { name: 'Last Active', orderable: true, width: '15%' },
    {
        name: 'Current Listings',
        orderable: true,
        width: '15%',
        itemTextAlign: 'center',
        align: 'center',
    },
    {
        name: `Total Volume`,
        orderable: false,
        width: '15%',
        icon: ComingSoonIcon,
        itemTextAlign: 'center',
    },
    {
        name: 'Avg. Sold Price',
        orderable: false,
        width: '15%',
        icon: ComingSoonIcon,
        itemTextAlign: 'center',
    },
    {
        name: 'Total Units',
        orderable: false,
        width: '15%',
        icon: ComingSoonIcon,
        itemTextAlign: 'center',
    },
    { name: '', orderable: false, width: '4%' },
];
