import { Agent, STATUS } from '../../../utils/constants';
import { AgentsType } from './types';
import { SortTypeEnum } from '../../../components/DataTable/types';

export enum AGENTS_ACTION {
    GetAllAgentsRequested = '[Agents] Get all agents',
    SearchAgents = '[Agents] Search Agents',
    SetCurrentAgentsPage = '[Agents] Set Current Page',
}

/*----- Redux Action Logic For get all the listings -----*/
export const getAllAgentsRequested = (
    pageNumber: number,
    forceReload: boolean,
    textSearch: string,
    sort: object,
    sortIndex: number,
    sortType: SortTypeEnum,
) =>
    <const>{
        type: AGENTS_ACTION.GetAllAgentsRequested,
        status: STATUS.Requested,
        forceReload,
        pageNumber,
        textSearch,
        sort,
        sortIndex,
        sortType,
    };

export const getAllAgentsSucceeded = (
    agents: AgentsType[],
    currentPage: number,
    totalPages: number,
    globalRecords?: number,
    textSearch?: string,
    sort?: any,
    sortIndex?: number,
    sortType?: SortTypeEnum,
) =>
    <const>{
        type: AGENTS_ACTION.GetAllAgentsRequested,
        status: STATUS.Succeeded,
        agents,
        currentPage,
        totalPages,
        globalRecords,
        textSearch,
        sort,
        sortIndex,
        sortType,
    };

export const getAllAgentsFailed = (errors: string[] = []) =>
    <const>{
        type: AGENTS_ACTION.GetAllAgentsRequested,
        status: STATUS.Failed,
        errors,
    };

export const setCurrentAgentsPage = (currentPage: number) =>
    <const>{
        type: AGENTS_ACTION.SetCurrentAgentsPage,
        currentPage,
    };

type getAllListingsActions =
    | ReturnType<typeof getAllAgentsRequested>
    | ReturnType<typeof getAllAgentsSucceeded>
    | ReturnType<typeof getAllAgentsFailed>
    | ReturnType<typeof setCurrentAgentsPage>;
/*----- End of Redux Action Logic For get all the listings -----*/

/*----- Redux Action Logic For get all the listings -----*/
export const searchAgentsRequested = (search?: string, onlyBrokerageAgents?: boolean) =>
    <const>{
        type: AGENTS_ACTION.SearchAgents,
        status: STATUS.Requested,
        search,
        onlyBrokerageAgents,
    };

export const searchAgentsSucceeded = (agents: Agent[]) =>
    <const>{
        type: AGENTS_ACTION.SearchAgents,
        status: STATUS.Succeeded,
        agents,
    };

export const searchAgentsFailed = (error: string) =>
    <const>{
        type: AGENTS_ACTION.SearchAgents,
        status: STATUS.Failed,
        error,
    };

type searchAgentsActions =
    | ReturnType<typeof searchAgentsRequested>
    | ReturnType<typeof searchAgentsSucceeded>
    | ReturnType<typeof searchAgentsFailed>;
/*----- End of Redux Action Logic For get all the listings -----*/

/*----- Exportation of All the Actions -----*/
export type AgentsAction = getAllListingsActions | searchAgentsActions;
/*----- End of Exportation of All the Actions -----*/
