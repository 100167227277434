import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';

/*-------- Components ---------*/
import { SearchBar } from 'web-lib';
import TypedReactList from 'react-list';
import MapView from './Map/MapView';
import TallPropertyCard from '../../../components/TallPropertyCard';

/*-------- Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Icons -------------*/

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';
import { getGeoListings, getSearchListings, isSearchLoading } from './selectors';

/*------ Constants ---------*/

/*------------ MISC -----------*/
import { MapListingObject } from './types';
import { FetchSearchListingsRequested } from './actions';
import Loader from '../../../components/Loader';
import { useHistory } from 'react-router-dom';
import { fetchListingSucceeded } from '../Listings/actions';

interface Props {}

export default function SearchMap(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Selectors --------*/
    const mapListings = useSelector(getGeoListings);
    const searchListings = useSelector(getSearchListings);
    const searchLoading = useSelector(isSearchLoading);

    /*------- State -----------*/
    const [selectedListingMap, setSelectedListingMap] = useState<MapListingObject | null>();
    const [mlsIdSearch, setMlsIdSearch] = useState<boolean>(false);
    const [activeSearchText, setActiveSearchText] = useState<string>('');

    /*------ Constants --------*/
    const dispatch = useDispatch();
    const scrollRef = useRef<any>(null);
    const scrollRefSmooth = useRef<any>(null);
    const history = useHistory();
    const ReactList = TypedReactList as any;

    /*------- Effects ---------*/
    /**
     * Whenever the user searches for a new listing or presses one on the map,
     * smooth scroll to the top of the reactList container.
     *
     * Required as reactList doesn't support smooth scroll
     */
    useEffect(() => {
        scrollRefSmooth?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [selectedListingMap, activeSearchText]);

    useEffect(() => {
        setSelectedListingMap(searchListings?.length ? searchListings[0] : null);
    }, [searchListings]);

    /*------- Functions -------*/

    const onSearch = (searchText: any, mlsIdSearch?: boolean) =>
        dispatch(FetchSearchListingsRequested(searchText, mlsIdSearch));

    const previewListings = useCallback(() => {
        const scrollID = mapListings?.findIndex(
            (listing: MapListingObject) =>
                listing?._id.toString() === selectedListingMap?._id.toString(),
        );

        if (scrollID >= 0) {
            // Take the selected listing and put it at the front of the list.
            mapListings.splice(scrollID, 1);
            mapListings.unshift(selectedListingMap);
        }

        return (
            <ReactList
                ref={scrollRef}
                itemRenderer={(index: number, key: number | string) => renderItem(index, key)}
                length={mapListings?.length ?? 0}
                pageSize={mapListings?.length ?? 0}
                initialIndex={0}
                useStaticSize={true}
            />
        );
    }, [mapListings, selectedListingMap]);

    const renderItem = (index: number, key: number | string) => {
        const listing = mapListings[index];

        return (
            <React.Fragment key={key}>
                {/* Dummy div for scroll ref */}
                {index === 0 ? <div ref={scrollRefSmooth} /> : null}
                <div
                    className={styleSheet.previewCardContainer}
                    onClick={() => {
                        dispatch(fetchListingSucceeded(listing));
                        history.push(
                            `/listingDetails/${
                                listing?.mlsList?.length
                                    ? listing?.mlsList[0]?.mlsName +
                                      '/' +
                                      listing?.mlsList[0]?.listingId
                                    : ''
                            }`,
                        );
                    }}
                >
                    <TallPropertyCard listing={listing} />
                </div>
            </React.Fragment>
        );
    };

    return (
        <>
            {/* Page title */}
            <div className={styleSheet.breadcumbContainer}>Search & Schedule</div>

            {/* Content */}
            <div className={styleSheet.container}>
                <MapView
                    onPressMarker={(listing: MapListingObject) => {
                        setSelectedListingMap(listing);
                    }}
                    onMapClicked={() => {
                        setSelectedListingMap(null);
                    }}
                    containerStyles={styleSheet.mapView}
                    selectedListing={selectedListingMap}
                    center={searchListings?.length ? searchListings[0].geo : null}
                    searchListings={searchListings}
                />
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderWidth: 1,
                        borderColor: '#d3d3d3',
                    }}
                >
                    <div className={styleSheet.searchContainer}>
                        <SearchBar
                            inputStyles={styleSheet.searchBarInputStyles}
                            onSubmit={(searchText: any) => {
                                onSearch(searchText, mlsIdSearch);
                                setActiveSearchText(searchText);
                            }}
                            disabled={false}
                            placeholderText={`Search for Listings`}
                        />
                        {searchLoading && <Loader size={25} />}
                    </div>
                    <div className={styleSheet.listingsOverlayMap}>{previewListings()}</div>
                </div>
            </div>
        </>
    );
}

SearchMap.defaultProps = {};
