import { STATUS, ShowingType } from '../../../utils/constants';
import { BSON } from 'realm-web';
import { ShowingFilter } from './types';

export enum SHOWINGS_ACTION {
    Fetch = '[Showings] Fetch',
    FetchDetails = '[Showings] Fetch Details',
    UpdateShowingStatus = '[Showings] Update Showing Status',
    SubmitFeedback = '[Showings] Add New Feedback',
    RescheduleShowing = '[Showings] Reschedule Showing',
    SetCurrentPage = '[Showings] Set Current Page',
}

/*----------- Fetch Showings -----------------*/

export const fetchShowingsRequested = () =>
    <const>{
        type: SHOWINGS_ACTION.Fetch,
        status: STATUS.Requested,
    };

export const fetchShowingsSucceeded = (showings = []) =>
    <const>{
        type: SHOWINGS_ACTION.Fetch,
        status: STATUS.Succeeded,
        showings,
    };

export const fetchShowingsFailed = (error: string) =>
    <const>{
        type: SHOWINGS_ACTION.Fetch,
        status: STATUS.Failed,
        error,
    };

/*----------- Fetch Showing Details -----------------*/

export const fetchShowingDetailsRequested = (showingId: BSON.ObjectId) =>
    <const>{
        type: SHOWINGS_ACTION.FetchDetails,
        status: STATUS.Requested,
        showingId,
    };

export const fetchShowingDetailsSucceeded = (showing: any = []) =>
    <const>{
        type: SHOWINGS_ACTION.FetchDetails,
        status: STATUS.Succeeded,
        showing,
    };

export const fetchShowingDetailsFailed = (error: string) =>
    <const>{
        type: SHOWINGS_ACTION.FetchDetails,
        status: STATUS.Failed,
        error,
    };

/*----------- Update Showing Status -----------------*/

export const changeShowingStatusRequested = (
    id: BSON.ObjectId,
    showingStatus: 'confirmed' | 'denied' | 'cancelled' | 'pending_internal' | 'logged',
    denialReason?: string,
    sendLockInfo?: boolean,
    isUnverified?: boolean,
    callback?: any,
) =>
    <const>{
        type: SHOWINGS_ACTION.UpdateShowingStatus,
        status: STATUS.Requested,
        id,
        showingStatus,
        denialReason,
        sendLockInfo,
        isUnverified,
        callback,
    };

export const changeShowingStatusSucceeded = () =>
    <const>{
        type: SHOWINGS_ACTION.UpdateShowingStatus,
        status: STATUS.Succeeded,
    };
export const changeShowingStatusFailed = (error: any) =>
    <const>{
        type: SHOWINGS_ACTION.UpdateShowingStatus,
        status: STATUS.Failed,
        error,
    };

/*----------- Submit Feedback -----------------*/

export const submitFeedbackRequested = (
    priceAccuracyRating: number,
    locationRating: number,
    interestLevel: number,
    reviewText: string,
    showingId: any,
) =>
    <const>{
        type: SHOWINGS_ACTION.SubmitFeedback,
        status: STATUS.Requested,
        priceAccuracyRating,
        locationRating,
        interestLevel,
        reviewText,
        showingId,
    };

export const submitFeedbackSucceeded = () =>
    <const>{
        type: SHOWINGS_ACTION.SubmitFeedback,
        status: STATUS.Succeeded,
    };

export const submitFeedbackFailed = (error: string) =>
    <const>{
        type: SHOWINGS_ACTION.SubmitFeedback,
        status: STATUS.Failed,
        error,
    };

/*----------- Set Current Page-----------------*/
export const setCurrentPageSucceded = (page: ShowingFilter) =>
    <const>{
        type: SHOWINGS_ACTION.SetCurrentPage,
        status: STATUS.Succeeded,
        page,
    };
