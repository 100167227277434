import React from 'react';

/*---------------- Assets ----------------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import SearchIcon from '../../images/icons/search.svg';
import classnames from 'classnames';

/*-------------- Utils --------------------*/
import { noop } from '../../utils/common';

interface Props {
    placeholder: string;
    containerClass?: any;
    value: string;
    onChangeValue: Function;
}

export default function SearchInput(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Constants -------*/
    const { value, onChangeValue, placeholder, containerClass } = props;

    return (
        <div className={classnames(styleSheet.root, containerClass)}>
            <img className={styleSheet.icon} src={SearchIcon} />
            <input
                value={value}
                onChange={(e) => onChangeValue(e.target.value)}
                className={styleSheet.input}
                type={'text'}
                placeholder={placeholder}
            />
        </div>
    );
}

SearchInput.defaultProps = {
    placeholder: 'Search',
    containerClass: {},
    value: '',
    onChangeValue: noop,
};
