export default (theme) => {
    return {
        root: {
            position: 'absolute',

            width: 'auto',

            backgroundColor: theme.palette.white,
            borderRadius: 5,
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',

            zindex: 99,
        },
    };
};
