export default (theme) => {
    return {
        loaderContainer: {
            height: '100%',
            display: 'flex',

            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            fontSize: 20,
        },

        /*------- Header styles -------*/
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        leftHeader: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
        },
        price: {
            ...theme.typography.semiBold,
            fontSize: 26,
        },
        address1: {
            fontSize: 18,
            ...theme.typography.semiBold,
            marginRight: 6,
        },
        address2: {
            fontSize: 18,
            marginRight: 26,
        },
        tag: {
            backgroundColor: theme.palette.lightGreen,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.green,
            fontSize: 14,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        button: {
            ...theme.marginHorizontal(16),
            width: 250,
        },
    };
};
