import React, { useEffect, useRef, useState } from 'react';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { getAllAgentsRequested } from './actions';
import { setSelectedAgent } from './AgentProfile/actions';
import {
    getAllAgents,
    getCurrentPage,
    getTotalPages,
    isRequestInProgress,
    getGlobalRecords,
    getAgentSearchText,
    getAgentSortIndex,
    getAgentSortType,
} from './selectors';

/*-------- Components ---------*/
import { SortTypeEnum, TableData } from '../../../components/DataTable/types';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../components/DataTable';
import SearchInput from '../../../components/SearchInput';
import { Paginator } from 'web-lib';

/*-------- Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Icons -------------*/
import BlueCheckIcon from '../../../images/icons/blueCheck.svg';

/*-------- Misc  ---------*/
import { AgentsType } from './types';
import { minutesToTimeDisplay } from '../../../utils/common/dateTimeUtils';
import { HEADERS, ITEMS_PER_PAGE } from './constants';

export default function Agents() {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*----- Redux ------------*/
    const dispatch = useDispatch();

    /*-------- Constants ---------*/
    const itemsPerPage = ITEMS_PER_PAGE; /* --->Length of item on the table*/
    const history = useHistory();

    /*-------- Selectors ---------*/
    const agents = useSelector<any, any>(getAllAgents);
    const totalPages = useSelector<any, number>(getTotalPages);
    const currentPage = useSelector<any, number>(getCurrentPage);
    const isLoading = useSelector<any, boolean>(isRequestInProgress);

    const searchRedux = useSelector<string, string>(getAgentSearchText);
    const sortReduxIndex = useSelector<any>(getAgentSortIndex);
    const sortReduxType = useSelector<any>(getAgentSortType);

    /*-------- States ---------*/
    const [search, setSearch] = useState<string>(searchRedux);
    const [sort, setSort] = useState<any>(null);
    const [sortIndex, setSortIndex] = useState<any>(sortReduxIndex);
    const [sortType, setSortType] = useState<any>(sortReduxType);

    const paginatedAgents = agents.find((e) => e.page === currentPage)?.data ?? [];
    const globalRecords = useSelector<any, number>(getGlobalRecords);
    const mountRef = useRef<boolean>(false);

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (mountRef.current) {
            fetchAgents(1, true);
        }
    }, [search, sort]);

    useEffect(() => {
        mountRef.current = true;
        if (!paginatedAgents.length) {
            fetchAgents(currentPage, true);
        }
    }, []);

    /*-------- Functions ---------*/
    const fetchAgents = (page: number, refresh: boolean = false) => {
        dispatch(getAllAgentsRequested(page, refresh, search, sort, sortIndex, sortType));
    };

    const getActiveListingCount = (listings) => {
        return listings ? listings.activeListingCount : 0;
    };

    const getIcon = (agent) => {
        return agent.deviceTokens ? (
            <span>
                <img src={BlueCheckIcon} alt={'icon'} />
            </span>
        ) : (
            ''
        );
    };

    const createTableRow = (agent: AgentsType) => [
        getIcon(agent),
        `${agent.firstName} ${agent.lastName}`,
        `${agent.lastSession ? minutesToTimeDisplay(agent.lastSession) : '-'}`,
        `${getActiveListingCount(agent.listings[0])}`,
        ` - `,
        ` - `,
        ` - `,
        <span
            className={styleSheet.link}
            key={'agents/profile'}
            onClick={() => {
                localStorage.setItem('tabPage', '' + 0);
                dispatch(setSelectedAgent(agent));
                {
                    history.push(`agents/profile/${agent._id}`);
                }
            }}
        >
            View
        </span>,
    ];

    const transformUsersToTableRow = (agents: AgentsType[]): TableData => {
        return agents.map((agent) => createTableRow(agent));
    };

    const handleTableSort = (sortIndex: number, sortType: SortTypeEnum) => {
        if (sortIndex) {
            const sort = sortType === SortTypeEnum.ASC ? 1 : -1;
            switch (sortIndex) {
                case 1:
                    // Sort by firstName
                    setSort({ firstName: sort });
                    break;
                case 2:
                    // Sort by Last Session
                    setSort({ lastSession: sort });
                    break;
                case 3:
                    // Sort by Current Listings
                    setSort({ 'listings.activeListingCount': sort });
                    break;
                default:
                    return 0;
            }
            setSortIndex(sortIndex);
            setSortType(sortType);
        }
    };

    const Header = (
        <div className={styleSheet.root}>
            <div className={styleSheet.header}>
                <h4 className={styleSheet.heading}>Agents</h4>
                <div className={styleSheet.row}>
                    <SearchInput
                        value={search}
                        onChangeValue={setSearch}
                        placeholder={'Search Agents'}
                        containerClass={styleSheet.searchInputContainer}
                    />
                </div>

                <span className={styleSheet.numberOfPage}>
                    {!isLoading && paginatedAgents?.length > 0
                        ? `${currentPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE + 1} - ${
                              currentPage * ITEMS_PER_PAGE -
                              ITEMS_PER_PAGE +
                              paginatedAgents?.length
                          } of ${globalRecords}`
                        : paginatedAgents?.length == 0
                        ? 'No data'
                        : 'Loading'}
                </span>
            </div>
        </div>
    );

    return (
        <>
            {Header}
            <DataTable
                headers={HEADERS}
                rows={transformUsersToTableRow(paginatedAgents)}
                loading={isLoading}
                onSort={handleTableSort}
                customSortIndex={sortIndex}
                customSortType={sortType}
                sortingEnabled={search.length === 0}
                wrapperStyles={styleSheet.wrapperStyles}
                headerStyle={styleSheet.headerStyle}
                itemStyle={styleSheet.itemStyle}
                height={'65vh'}
            />
            <Paginator
                isLoading={isLoading}
                numberOfPages={totalPages}
                handleOnChange={(pageNumber) => {
                    fetchAgents(pageNumber + 1);
                }}
                currentPageNumber={currentPage - 1}
            />
        </>
    );
}
