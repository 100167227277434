/*
 * Explanation
component for status filter, uses the pop-up menu component to style a menu 
where showings can be filtered by their current status
 */

/*----- React imports -------*/
//import React from 'react';

import React, { useEffect, useRef, useState } from 'react';
import { STATUS_FILTER } from '../../domains/main/Showings/constants';
import { getStatusInfo } from '../../utils/common';
import Button from '../Button';
import PopUpMenu from '../PopUpMenu';
import Separator from '../Separator';
import Tag from '../Tag';

/*-------- Assets ---------*/
import styles from './index.module.css';
import Dots from './../../images/icons/dots.svg';
import { useLocation } from 'react-router-dom';

/*----- Define the types of the props here -------*/
interface Props {
    handlePage: Function;
    currentPage: number;
    setIncludedStatuses: Function;
    height?: any;
    style?: any;
}

let includedStatuses = [...STATUS_FILTER];
let lastLocation = '';
let excludedStatuses: any[] = [];

export default function StatusFilter(props: Props) {
    /*-------- Constants ---------*/
    const { handlePage, currentPage, setIncludedStatuses, height, style } = props;
    const statusFilterRef = useRef() as React.RefObject<HTMLInputElement>;
    const { pathname } = useLocation();
    const splitedPathname = pathname.split('/');

    /*-------- States ---------*/
    //any statuses to exclude from filter?
    const [excludeDiv, setExcludeDiv] = useState<boolean>(false);

    //latest included status
    const [includeStatus, setIncludedStatus] = useState<boolean>(false);

    //status filter visible
    const [statusFilter, setStatusFilter] = useState<boolean>(false);

    /*-------- Use Effect ---------*/
    //If has change the location the values are reset
    useEffect(() => {
        if (lastLocation !== splitedPathname[1]) {
            excludedStatuses = [];
            includedStatuses = [...STATUS_FILTER];
            lastLocation = splitedPathname[1];
        }
    }, [splitedPathname]);

    /*-------- Functions ---------*/
    const StatusFilterShow = () => {
        return (
            <PopUpMenu
                visible={statusFilter}
                close={() => setStatusFilter(false)}
                horizontalPosition="Right"
                verticalPosition="Bottom"
                customRef={statusFilterRef}
                style={{ width: 280 }}
            >
                <div className={styles.popUpContainer}>
                    <div className={styles.header}>Show only:</div>
                    <PopUpMenuChildren />
                    <Separator type="Horizontal" />
                    {excludeDiv ? <StatusDiv /> : null}
                    <div className={styles.button}>
                        <Button
                            title="Confirm"
                            widthType="Full"
                            type="default"
                            onClick={() => {
                                toggleStatusFilter();
                                excludedStatuses.length > 0
                                    ? handlePage(0)
                                    : handlePage(currentPage);
                                setIncludedStatuses(includedStatuses);
                            }}
                        />
                    </div>
                </div>
            </PopUpMenu>
        );
    };

    const toggleIncludedStatus = () => {
        setIncludedStatus((prev) => !prev);
    };

    const toggleStatusFilter = () => {
        setStatusFilter((prev) => !prev);
    };

    const PopUpMenuChildren = () => {
        excludedStatuses.length === 0 ? setExcludeDiv(false) : setExcludeDiv(true);
        return (
            <div className={styles.statusDiv}>
                {includedStatuses.map((filter, index) => {
                    return (
                        <Tag
                            //sending text to display on the tag
                            text={getStatusInfo(filter).text}
                            color={getStatusInfo(filter).color}
                            background={true}
                            style={{
                                fontSize: 12,
                                fontFamily: 'Poppins-medium',
                                justifyContent: 'space-around',
                                width: 'auto',
                            }}
                            filter={true}
                            onClick={() => {
                                includedStatuses.splice(index, 1);
                                excludedStatuses.push(filter);
                                toggleIncludedStatus();
                            }}
                        />
                    );
                })}
            </div>
        );
    };

    const StatusDiv = () => {
        excludedStatuses.length === 0 ? setExcludeDiv(false) : setExcludeDiv(true);
        return (
            <div>
                <div className={styles.header}>Add Category</div>
                <div className={styles.statusDiv}>
                    {excludedStatuses.map((filter, index) => {
                        return (
                            <Tag
                                text={getStatusInfo(filter).text}
                                color={getStatusInfo(filter).color}
                                background={false}
                                filter={false}
                                style={{
                                    fontSize: 12,
                                    fontFamily: 'Poppins-medium',
                                    border: `1px solid`,
                                    borderRadius: 5,
                                    width: 'auto',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    excludedStatuses.splice(index, 1);
                                    includedStatuses.push(filter);
                                    console.log('exc', excludedStatuses);
                                    toggleIncludedStatus();
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <>
            <span
                onClick={toggleStatusFilter}
                ref={statusFilterRef}
                style={{ cursor: 'pointer', marginLeft: '15px' }}
            >
                <img src={Dots} height={height ? height : 5} style={{ ...style }} />
            </span>
            <StatusFilterShow />
        </>
    );
}
