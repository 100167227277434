import React, { useState, useEffect } from 'react';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { registerUserRequested } from '../actions';
import { getPosibleClient, isLoginInProgress } from '../selectors';

/*-------- Components ---------*/
import Typography from '../../../components/Typography';
import ButtonComponent from '../../../components/ButtonComponent';

/*-------- Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Icons ---------*/
import eye from '../../../images/icons/pwdEyeIcon.svg';
import dashEye from '../../../images/icons/showPwdEyeIcon.svg';

/*-------- Constants ---------*/
import { useHistory } from 'react-router';

/*-------- Misc  ---------*/
import { BrokerageUser } from '../../../utils/constants';

interface Props {}

export default function PwdCreation(props: Props) {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- States ---------*/
    const [passText, setPassText] = useState<string>('');
    const [confirmText, setConfirmText] = useState<string>('');
    const [isPassVisible, setPassVisibility] = useState<boolean>(false);
    const [isConfirmVisible, setConfirmVisibility] = useState<boolean>(false);

    /*-------- Selectors ---------*/
    const user: BrokerageUser = useSelector(getPosibleClient);
    const isLoading: boolean = useSelector<any, boolean>(isLoginInProgress);

    /*-------- Constants ---------*/
    const history = useHistory();
    const dispatch = useDispatch();
    const passTextValid = passText?.length >= 6;

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (!user) {
            history.replace('/');
        }
    }, [user]);

    /*-------- Functions ---------*/
    const focusConfirm = () => {
        document.getElementById('confirm')?.focus();
    };

    const focusPass = () => {
        document.getElementById('pass')?.focus();
    };

    const registerUser = () => {
        // Need to send aliased email, alias is stored apart from the email.
        dispatch(registerUserRequested(user.email, passText, user._id));
    };

    return (
        <div className={styleSheet.root}>
            <h1 className={styleSheet.title}> Enter a new password </h1>
            <div className={styleSheet.content}>
                <Typography
                    color={theme.palette.black}
                    textStyle="b1"
                    style={{ fontSize: 18, paddingBottom: 20, marginTop: -20 }}
                >
                    Enter Password
                </Typography>
                <div className={styleSheet.inputBox}>
                    <input
                        id="pass"
                        name="pass"
                        type={isPassVisible ? 'show' : 'password'}
                        value={passText}
                        autoComplete={'new-password'}
                        placeholder={'Create Password'}
                        className={styleSheet.inputText}
                        onChange={(e: any) => {
                            setPassText(e?.nativeEvent?.target?.value);
                        }}
                        onKeyUp={(e: any) => {
                            var key = e.keyCode || e.charCode;
                            if (key === 13) {
                                if (passTextValid) {
                                    focusPass();
                                }
                            }
                        }}
                    />

                    <img
                        src={isPassVisible ? dashEye : eye}
                        className={styleSheet.icons}
                        onClick={() => setPassVisibility(!isPassVisible)}
                    />
                </div>
                <Typography
                    color={theme.palette.black}
                    textStyle="b1"
                    style={{ fontSize: 18, paddingBottom: 20 }}
                >
                    Confirm Password
                </Typography>
                <div className={styleSheet.inputBox}>
                    <input
                        id="confirm"
                        name="pwdConfirm"
                        type={isConfirmVisible ? 'show' : 'password'}
                        value={confirmText}
                        autoComplete={'new-password'}
                        placeholder={'Confirm Password'}
                        className={styleSheet.inputText}
                        onChange={(e: any) => {
                            setConfirmText(e?.nativeEvent?.target?.value);
                        }}
                        onKeyUp={(e: any) => {
                            var key = e.keyCode || e.charCode;
                            if (key === 13) {
                                if (passTextValid) {
                                    focusConfirm();
                                }
                            }
                        }}
                    />
                    <img
                        src={isConfirmVisible ? dashEye : eye}
                        className={styleSheet.icons}
                        onClick={() => setConfirmVisibility(!isConfirmVisible)}
                    />
                </div>
                {passText !== '' && confirmText !== '' && passText !== confirmText && (
                    <Typography
                        color={theme.palette.red}
                        textStyle="b2"
                        style={{ fontSize: 18, paddingBottom: 0, alignSelf: 'center' }}
                    >
                        Passwords Do Not Match
                    </Typography>
                )}
            </div>
            <ButtonComponent
                onClick={() => registerUser()}
                disabled={passText !== confirmText || isLoading}
                fill
                width={257}
                className={styleSheet.button}
            >
                {isLoading ? 'Logging In...' : 'Log In'}
            </ButtonComponent>
        </div>
    );
}

PwdCreation.defaultProps = {};
