export default (theme: any) => {
    const dayStyles = {
        height: 26,
        width: 26,
        border: {
            width: 2,
            color: theme.palette.blue,
            radius: 2,
            style: 'solid',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    };
    return {
        header: {
            backgroundColor: theme.palette.white,
            borderBottom: {
                color: theme.palette.grey,
                style: 'solid',
                width: 1,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 15,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
        },
        restrictionContainer: {
            ...theme.paddingHorizontal(30),
            paddingBottom: 72,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 10,
        },
        footer: {
            backgroundColor: theme.palette.white,
            borderTop: {
                color: theme.palette.grey,
                style: 'solid',
                width: 1,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...theme.paddingVertical(15),
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
            position: 'absolute',
            bottom: 0,
            width: '100% ',
        },
        restrictionSelect: {
            backgroundColor: theme.palette.background,
            border: {
                width: 1,
                color: theme.palette.background,
                style: 'solid',
            },
            width: 140,
            borderRadius: 5,
            ...theme.typography.medium,
        },
        invalidTimes: {
            backgroundColor: theme.palette.lightRed,
            border: {
                width: 1,
                style: 'solid',
                color: theme.palette.red,
            },
            width: 140,
            borderRadius: 5,
            ...theme.typography.medium,
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        restrictionCard: {
            height: 100,
            borderBottom: {
                style: 'solid',
                width: 1,
                color: theme.palette.separatorGrey,
            },
            marginBottom: 20,
            width: '100%',
        },
        restrictionCardData: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 10,
        },
        blueDay: {
            ...dayStyles,
            backgroundColor: theme.palette.blue,
        },
        whiteDay: {
            ...dayStyles,
        },
        daysContainer: {
            display: 'flex',
            marginRight: 16,
        },
    };
};
