/*
 * Listing details screen
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/*------ Redux ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { getListing, isListingLoading, getSelectedView } from '../selectors';
import { fetchListingRequested } from '../actions';

/*------ Components -------*/
import Button from '../../../../components/Button';
import ListingDetailsComponent from '../DetailsComponent';
import Separator from '../../../../components/Separator';
import ScheduleScreen from '../../ShowingRequests/ScheduleScreen';
import Loader from '../../../../components/Loader';

/*------ Assets --------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*------ Utils -------*/
import { formatCurrency } from '../../../../utils/common/transforms';
import AddClientDrawer from '../../ShowingRequests/AddClientDrawer';
import AddAgentDrawer from '../../ShowingRequests/AddAgentDrawer';

import { Brokerage, Listing } from '../../../../utils/constants';
import { getSelectedBrokerage } from '../../Profile/selectors';
import { ListingParams } from '../types';

interface Props {}

export default function ListingDetails(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*------ Selectors --------*/
    const listing = useSelector(getListing);
    const listingLoading = useSelector(isListingLoading);
    const selectedView = useSelector(getSelectedView);
    const brokerage = useSelector(getSelectedBrokerage);

    /*---- State --------*/
    const [showScheduleDrawer, setShowScheduleDrawer] = useState<boolean>(false);
    const [showAddClientDrawer, setShowAddClientDrawer] = useState<boolean>(false);
    const [showAddAgentDrawer, setShowAddAgentDrawer] = useState<boolean>(false);

    /*----- Constants --------*/
    const dispatch = useDispatch();
    const connected = !!listing?.agentListing?.listingId;
    const { mlsListingId, mlsName } = useParams<ListingParams>();

    /*----- Effects --------*/
    useEffect(() => {
        // If the listing is not stored in redux, search the listing
        if (!listing?.mlsList?.some((m) => m.listingId === mlsListingId && m.mlsName === mlsName)) {
            dispatch(fetchListingRequested(mlsListingId, mlsName));
        }
    }, []);

    /*-------- Functions ---------*/
    const isBrokeragesListing = (brokerage?: Brokerage, listing?: Listing) => {
        return listing?.mlsList?.some((m) =>
            brokerage?.markets?.some((m2) => m2.brokerageId === m.brokerageId),
        );
    };

    const header = (
        <div className={styleSheet.header}>
            <div className={styleSheet.leftHeader}>
                <span className={styleSheet.price}>{'$' + formatCurrency(listing?.listPrice)}</span>
                <Separator type={'Vertical'} height={18} />
                <span className={styleSheet.address1}>
                    {listing?.address?.unit
                        ? listing?.address?.full.includes(listing?.address?.unit)
                            ? listing?.address?.full
                            : listing?.address?.full + ' ' + listing?.address?.unit
                        : listing?.address?.full}
                </span>
                <span className={styleSheet.address2}>
                    {listing?.address?.city}, {listing?.address?.state} {listing?.address?.postal}
                </span>
                {listing?.mlsList?.length && listing?.mlsList[0]?.standardStatus === 'Active' ? (
                    <span className={styleSheet.tag}>For Sale</span>
                ) : null}
            </div>
            {!connected || listing?.agentListing?.acceptingShowings ? (
                <div className={styleSheet.buttonContainer}>
                    <Button
                        customStyle={styleSheet.button}
                        onClick={() => {
                            setShowScheduleDrawer(true);
                        }}
                        widthType={'Ineherit'}
                        title={connected ? 'Schedule' : 'Log'}
                    />
                </div>
            ) : (
                <span>Currently not accepting requests</span>
            )}
        </div>
    );

    return (
        <>
            {listingLoading || !listing ? (
                <div className={styleSheet.loaderContainer}>
                    <Loader />
                    Fetching the requested listing...
                </div>
            ) : (
                <>
                    <ScheduleScreen
                        listing={listing}
                        opType={'Schedule'}
                        onClose={() => setShowScheduleDrawer(false)}
                        clientDrawerInteract={setShowAddClientDrawer}
                        agentDrawerInteract={setShowAddAgentDrawer}
                        visible={showScheduleDrawer}
                    />
                    <AddClientDrawer
                        visible={showAddClientDrawer}
                        onClose={() => setShowAddClientDrawer(false)}
                        backgroundOpacity={0}
                    />
                    <AddAgentDrawer
                        visible={showAddAgentDrawer}
                        onClose={() => setShowAddAgentDrawer(false)}
                        backgroundOpacity={0}
                        onlyBrokerageAgents={!isBrokeragesListing(brokerage, listing)}
                    />
                    <ListingDetailsComponent
                        header={selectedView === 'Listing Details' ? null : header}
                        listing={listing}
                    />
                </>
            )}
        </>
    );
}

ListingDetails.defaultProps = {};
