export default (theme) => {
    return {
        root: (props) => ({
            fontFamily: 'Poppins-SemiBold',
            paddingTop: props.removePadding ? 0 : 40,
            paddingBottom: props.removePadding ? 0 : 10,
            fontSize: 15,
        }),
        topRow: (props) => ({
            paddingLeft: props.removePadding ? 0 : 40,
            paddingRight: props.removePadding ? 0 : 35,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
        }),
        arrows: {
            width: 65,
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
        },
        arrowContainer: {
            width: 25,
            height: 25,
            backgroundColor: '#f6f6f6',
            borderRadius: 5,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            '&:active': {
                opacity: 0.5,
            },
            cursor: 'pointer',
        },
        arrow: {
            height: 13,
            width: 13,
        },
        daysRow: (props) => ({
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
            color: '#9a9a9a',
            paddingTop: 15,
            paddingBottom: 5,
            paddingLeft: props.removePadding ? 0 : 35,
            paddingRight: props.removePadding ? 0 : 35,
        }),
        dayLabel: {
            width: 23,
            textAlign: 'center',
        },
        week: (props) => ({
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            color: 'black',
            paddingTop: 15,
            paddingLeft: props.removePadding ? 0 : 35,
            paddingRight: props.removePadding ? 0 : 35,
        }),
        day: {
            '&:active': {
                opacity: 0.5,
            },
            width: 23,
            textAlign: 'center',
            cursor: 'pointer',
        },
        selectedDay: {
            '&:active': {
                opacity: 0.5,
            },
            color: '#48a4ff',
            width: 23,
            height: 23,
            backgroundColor: 'rgba(72, 164, 255, 0.2)',
            textAlign: 'center',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
            paddingLeft: 1,
            paddingRight: -1,
            marginLeft: -1,
        },
        fadedDay: {
            color: '#bcbcbc',
            width: 23,
            cursor: 'pointer',
            textAlign: 'center',
        },
    };
};
