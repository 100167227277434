/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { fetchListingRequested, setConfigDetails, changeSelectedView } from '../actions';
import { isListingLoading, getListing, getSelectedView } from '../selectors';

/*-------- Components ---------*/
import Tag from '../../../../components/Tag';
import Loader from '../../../../components/Loader';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import ListingDetails from '../DetailsScreen';
import OverviewSection from '../OverviewSection';
import ListingConfigSection from '../ListingConfigSection';

/*-------- Utils ---------*/
import { formatCurrency } from '../../../../utils/common/transforms';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';
import { ListingParams } from '../types';

interface Props {}

const SelectedListingScreen = (props: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Constants ---------*/
    const history = useHistory();
    const dispatch = useDispatch();
    const { mlsListingId, mlsName } = useParams<ListingParams>();
    const selections = ['Overview', 'Listing Details', 'Configuration'];

    /*-------- Selectors ---------*/
    const listing = useSelector(getListing);
    const loading = useSelector(isListingLoading);
    const selectedView = useSelector(getSelectedView);

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (!listing?.mlsList?.some((m) => m.listingId === mlsListingId && m.mlsName === mlsName)) {
            dispatch(fetchListingRequested(mlsListingId, mlsName));
        }
    }, []);

    /*-------- Functions ---------*/
    const header = (
        <>
            <div className={styleSheet.header}>
                Your Listing
                <div className={styleSheet.selectionContainer}>
                    {selections.map((selection, index) => {
                        return (
                            <>
                                <ClickableWithFeedback
                                    style={
                                        selection === selectedView
                                            ? {
                                                  color: indexTheme.palette.blue,
                                              }
                                            : {}
                                    }
                                    onClick={() => {
                                        dispatch(changeSelectedView(selection));
                                    }}
                                >
                                    {selection}
                                </ClickableWithFeedback>
                                {index !== 2 && <div className={styleSheet.verticalSeparator} />}
                            </>
                        );
                    })}
                </div>
            </div>

            {!loading && (
                <div className={styleSheet.priceHeader}>
                    <div className={styleSheet.leftContent}>
                        <span className={styleSheet.price}>
                            {'$'}
                            {formatCurrency(listing?.listPrice)}
                        </span>
                        <div className={styleSheet.verticalSeparator} />
                        <span className={styleSheet.address}>{listing?.address?.full}</span>
                        <span className={styleSheet.city}>
                            {listing?.address?.city}, {listing?.address?.state}{' '}
                            {listing?.address?.postal}
                        </span>
                        <div className={styleSheet.verticalSeparator} />
                        <div className={styleSheet.tagBox}>
                            <Tag
                                onClick={() => {}}
                                text={
                                    listing?.agentListing?.acceptingShowings
                                        ? 'Showings Enabled'
                                        : 'Showings Disabled'
                                }
                                background={true}
                                color={listing?.agentListing?.acceptingShowings ? 'green' : 'red'}
                            />
                            <Tag
                                onClick={() => {}}
                                background={true}
                                text={
                                    listing?.agentListing?.showToUnrepresentedConsumers !== false
                                        ? 'Syndication Enabled'
                                        : 'Syndication Disabled'
                                }
                                color={
                                    listing?.agentListing?.showToUnrepresentedConsumers !== false
                                        ? 'green'
                                        : 'red'
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    return (
        <div className={styleSheet.root}>
            {header}
            {loading ? (
                <div className={styleSheet.loadingContainer}>
                    <Loader />
                </div>
            ) : selectedView === 'Overview' ? (
                <OverviewSection listing={listing} />
            ) : selectedView === 'Listing Details' ? (
                <ListingDetails />
            ) : (
                <ListingConfigSection
                    onPressEdit={(page: number) => {
                        dispatch(setConfigDetails(listing, page));
                        history.push(
                            `/listings/configure/${
                                listing?.mlsList?.length
                                    ? listing?.mlsList[0]?.mlsName +
                                      '/' +
                                      listing?.mlsList[0]?.listingId
                                    : ''
                            }`,
                        );
                    }}
                    agentListing={listing?.agentListing}
                    listing={listing}
                />
            )}
        </div>
    );
};
export default SelectedListingScreen;
