export default (theme) => {
    return {
        vertical: {
            ...theme.marginHorizontal(10),
            border: {
                color: theme.palette.separatorGrey,
                width: 1,
                style: 'none solid',
            },
            borderRadius: 2,
        },

        horizontal: {
            ...theme.marginVertical(10),
            border: {
                color: theme.palette.separatorGrey,
                width: 1,
                style: 'solid none',
                radius: 2,
            },
        },
    };
};
