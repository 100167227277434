import { isMobile } from '../../../../utils/common';
import { ANIMATED_DRAWER_WIDTH } from '../../../../components/AnimatedDrawer';

const isMob = isMobile();

export default (theme) => {
    return {
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerContainer: {
            height: 86,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        innerHeaderContainer: {
            ...theme.marginHorizontal(48),
        },
        closeIconContainer: {
            position: 'absolute',
            right: 26.91,
            top: 26.23,
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
        },
        drawerHeaderText: {
            fontSize: 18,
            color: theme.palette.mediumGrey,
            paddingTop: 27,
        },

        searchBarContainer: {
            margin: {
                left: 48,
                right: 48,
                top: 16,
            },
            padding: { bottom: 16 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        searchBar: {
            ...theme.typography.medium,
            backgroundColor: theme.palette.background,
            height: 30,
            width: '100vw',
            color: theme.palette.darkGrey,
            fontSize: 16,
            borderRadius: 5,
            borderWidth: 0,
            paddingLeft: 10,
        },

        agentsContainer: {
            margin: {
                left: 48,
                right: 48,
            },
            overflowY: 'scroll',
        },
        agentCard: {
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        agentCardHeader: {
            height: 43,
            paddingLeft: 10,
            marginRight: 15,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        agentCardBody: {
            padding: '10px 60px',
        },
        agentInfo: {
            ...theme.typography.semiBold,
            color: theme.palette.grey,
        },
        agentInfoLabel: {
            marginLeft: 15,
            ...theme.typography.medium,
            color: theme.palette.black,
        },
        selectAgentButton: {
            display: 'flex',
            padding: '100px 10px',
            margin: '20px auto',

            fontSize: 18,
            ...theme.typography.mediumBold,
        },
        agentName: {
            fontSize: 18,
            ...theme.typography.mediumBold,
            color: theme.palette.black,
        },
        addAgentButton: {
            display: 'flex',
            ...theme.typography.semiBold,
            fontSize: 14,
            color: theme.palette.blue,
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 16,
        },
        loaderContainer: {
            height: '75%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        userType: {
            margin: {
                left: 48,
                right: 48,
                top: 20,
            },
        },
        userTypeMobile: {
            margin: {
                left: 25,
                right: 40,
                top: 20,
            },
        },
        separatorBar: {
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        container: {
            marginTop: 4,
        },
        metaRow: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: {
                top: 16,
                bottom: 16,
            },
        },
        typography: {
            height: 20,
        },
        input: {
            ...theme.typography.medium,
            backgroundColor: theme.palette.background,
            height: 30,
            width: isMob ? '100%' : 248,
            border: {
                width: 0,
                radius: 5,
            },
            fontSize: 12,
            color: theme.palette.mediumGrey,
            marginTop: -2,
            paddingLeft: 7,
            marginLeft: isMob ? 10 : 0,
        },
        mb5: {
            marginBottom: 5,
        },
        ml10: {
            marginLeft: 10,
        },
        scheduleButtonsContainer: {
            position: 'fixed',
            bottom: 0,
            width: ANIMATED_DRAWER_WIDTH,
            height: 68, // wireframe 88
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 0,
            borderTopWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        scheduleButtonsContainerMobile: {
            width: 'auto',
            height: 68, // wireframe 88
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 0,
            borderTopWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            textAlign: 'center',
            marginTop: 25,
        },
        buttonSubmit: {
            width: '200px !important',
        },
        arrowIcon: { height: 15, width: 15 },
        noResults: {
            display: 'flex',
            height: 43,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            ...theme.typography.medium,
            color: theme.palette.black,
            fontSize: 16,
            paddingLeft: 10,
            alignItems: 'center',
        },
    };
};
