import { BSON } from 'realm-web';
import {
    Agent,
    Client,
    Showing,
    ShowingStatus,
    ShowingType,
    STATUS,
} from '../../../utils/constants';
import { unverifiedUser } from './types';

export enum Actions {
    FetchShowingAvailability = '[ShowingRequests] Showing Availability',
    CreateShowing = '[ShowingRequests] Create',
    CreateUnverifiedShowing = '[ShowingRequests] Create Unverified',
    RescheduleShowing = '[ShowingRequests] Reschedule',
    ClearShowingIds = '[ShowingRequests] Clear Id',
    UnverifiedAgentData = '[ShowingRequests] Set Unverified Agent Data',
    SelectedAgent = '[ShowingRequests] Set Selected Agent',
    SelectedClient = '[ShowingRequests] Set Selected Client',
    FetchAgentClients = '[ShowingRequests] Fetch Clients',
    FetchAgentSchedule = '[ShowingRequests] Fetch Agent Schedule',
    UpdateStatus = '[ShowingRequests] Update Status',
}

/*----------- Fetch Showing Availability -----------------*/

export function fetchShowingAvailabilityRequested(
    listingId: BSON.ObjectId,
    showingId?: BSON.ObjectId,
) {
    return {
        type: Actions.FetchShowingAvailability,
        status: STATUS.Requested,
        listingId,
        showingId,
    };
}

export function fetchShowingAvailabilitySucceeded(showingAvailability: any[] = []) {
    return {
        type: Actions.FetchShowingAvailability,
        status: STATUS.Succeeded,
        showingAvailability,
    };
}

export function fetchShowingAvailabilityFailed(error: string) {
    return {
        type: Actions.FetchShowingAvailability,
        status: STATUS.Failed,
        error,
    };
}

/*-------- Clear created showing id ------*/

export function clearShowingIds() {
    return {
        type: Actions.ClearShowingIds,
        status: STATUS.Succeeded,
    };
}

/*-------- CREATE Showing ---------*/

export function createShowingRequested(
    showingType: ShowingType,
    listingId: BSON.ObjectId,
    agentStitchUserId: string,
    startTime: Date,
    endTime: Date,
    utcOffset: number,
    clientStitchUserId?: string,
) {
    return {
        type: Actions.CreateShowing,
        status: STATUS.Requested,
        showingType,
        listingId,
        agentStitchUserId,
        startTime,
        endTime,
        utcOffset,
        clientStitchUserId,
    };
}

export function createShowingSucceeded(showingId: BSON.ObjectId) {
    return {
        type: Actions.CreateShowing,
        status: STATUS.Succeeded,
        showingId,
    };
}

export function createShowingFailed(error: string) {
    return {
        type: Actions.CreateShowing,
        status: STATUS.Failed,
        error,
    };
}

/*-------- CREATE UNVERIFIED Showing ---------*/

export function createUnverifiedShowingRequested(
    listingId: BSON.ObjectId,
    startTime: Date,
    endTime: Date,
) {
    return {
        type: Actions.CreateUnverifiedShowing,
        status: STATUS.Requested,
        listingId,
        startTime,
        endTime,
    };
}

export function createUnverifiedShowingSucceeded(showingId: BSON.ObjectId) {
    return {
        type: Actions.CreateUnverifiedShowing,
        status: STATUS.Succeeded,
        showingId,
    };
}

export function createUnverifiedShowingFailed(error: string) {
    return {
        type: Actions.CreateUnverifiedShowing,
        status: STATUS.Failed,
        error,
    };
}

/*-------- RESCHEDULE SHOWING --------------------*/

export const rescheduleShowingRequested = (
    showingType: ShowingType,
    showingId: string,
    startTime: Date,
    endTime: Date,
    clientId: string | null = null,
    previousShowingType: ShowingType | null,
    delegationPrice: number | null = null,
) => {
    return <const>{
        type: Actions.RescheduleShowing,
        status: STATUS.Requested,
        showingType,
        showingId,
        startTime,
        endTime,
        clientId,
        previousShowingType,
        delegationPrice,
    };
};

export const rescheduleShowingSucceeded = (showingId: BSON.ObjectId) =>
    <const>{
        type: Actions.RescheduleShowing,
        status: STATUS.Succeeded,
        showingId,
    };

export const rescheduleShowingFailed = (showingId: string, error: string) =>
    <const>{
        type: Actions.RescheduleShowing,
        status: STATUS.Failed,
        showingId,
        error,
    };

type RescheduleShowing =
    | ReturnType<typeof rescheduleShowingRequested>
    | ReturnType<typeof rescheduleShowingSucceeded>
    | ReturnType<typeof rescheduleShowingFailed>;

/*-------- Selected Agent --------------------*/

export function setSelectedAgent(agent: Agent | null) {
    return {
        type: Actions.SelectedAgent,
        status: STATUS.Succeeded,
        agent,
    };
}

/*-------- Handle Unverified Agent Data ------*/

export function setUnverifiedAgentData(data: unverifiedUser | null) {
    return {
        type: Actions.UnverifiedAgentData,
        status: STATUS.Succeeded,
        data,
    };
}

/*-------- Selected Agent --------------------*/

export function setSelectedClient(client: Client | null) {
    return {
        type: Actions.SelectedClient,
        status: STATUS.Succeeded,
        client,
    };
}

/*-------- Fetch Agent's clients --------------------*/

export function fetchClientsRequested(agentStitchId: string) {
    return {
        type: Actions.FetchAgentClients,
        status: STATUS.Requested,
        agentStitchId,
    };
}

export function fetchClientsSucceeded(clients: Client[]) {
    return {
        type: Actions.FetchAgentClients,
        status: STATUS.Succeeded,
        clients,
    };
}

export function fetchClientsFailed(error: string) {
    return {
        type: Actions.FetchAgentClients,
        status: STATUS.Failed,
        error,
    };
}

/*-------- Agent's schedule ----------*/

export function fetchAgentScheduleRequested(agentId: BSON.ObjectId) {
    return {
        type: Actions.FetchAgentSchedule,
        status: STATUS.Requested,
        agentId,
    };
}

export function fetchAgentScheduleSucceeded(showings: Showing[]) {
    return {
        type: Actions.FetchAgentSchedule,
        status: STATUS.Succeeded,
        showings,
    };
}

export function fetchAgentScheduleFailed(error: string) {
    return {
        type: Actions.FetchAgentSchedule,
        status: STATUS.Failed,
        error,
    };
}

/*-------- Update Showing Status ----------*/

export const updateShowingStatusRequested = (
    showingId: string,
    newShowingStatus: ShowingStatus,
    callback?: any,
) =>
    <const>{
        type: Actions.UpdateStatus,
        status: STATUS.Requested,
        showingId,
        newShowingStatus,
        callback,
    };

export const updateShowingStatusSucceeded = (showing: Showing) =>
    <const>{
        type: Actions.UpdateStatus,
        status: STATUS.Succeeded,
        showing,
    };

export const updateShowingStatusFailed = (showingId: string, error: string) =>
    <const>{
        type: Actions.UpdateStatus,
        status: STATUS.Failed,
        showingId,
        error,
    };
