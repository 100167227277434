export default (theme: any) => {
    const DATE_BOX_WIDTH = 227;
    return {
        root: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        selectDateBox: {
            backgroundColor: theme.palette.background,
            border: 'none',
            width: DATE_BOX_WIDTH,
            height: 20,
            borderRadius: 5,
            ...theme.typography.medium,
            fontSize: 15,
            padding: 5,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        datePickerContainer: {
            position: 'absolute',
            display: 'flex',
            width: DATE_BOX_WIDTH - 20,
            flex: 1,
            padding: 15,
            flexDirection: 'column',
            backgroundColor: theme.palette.white,
            ...theme.cardShadow,
            zIndex: 1000,
        },
    };
};
