export default (theme) => {
    return {
        select: {
            minWidth: 125,
            padding: '5px 0px',

            fontSize: 16,

            border: {
                color: theme.palette.lightGrey,
                width: 2,
                style: 'solid',
                radius: 10,
            },
        },
    };
};
