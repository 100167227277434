/*
 * Reusable PopUp window component
 */

import React, { useEffect, useRef, useState } from 'react';

/*------- Assets ----------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*------- Utils -----------*/
import { noop } from 'lodash';
import { useOutsideClick } from '../../utils/common';

interface Props {
    visible: boolean;
    close: Function;
    children: JSX.Element;
    verticalPosition: 'Top' | 'Bottom';
    horizontalPosition: 'Left' | 'Right';
    customRef?: React.RefObject<HTMLInputElement>;
    style?: any;
}

export default function PopUpMenu(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Constants -------*/
    const { visible, close, children, verticalPosition, horizontalPosition, customRef, style } =
        props;
    const ref = useRef() as React.RefObject<HTMLDivElement>;

    /*------ State ----------*/
    const [position, setPosition] = useState({ top: 0, left: 0 });

    /*------- Use Effect ----------*/
    // When the ref is loaded set the element position based on parent ref or the custom received ref
    useEffect(() => {
        if (ref?.current) {
            const parent: any = customRef?.current ?? ref.current.parentElement;
            let x = 0;
            let y = 0;

            // Set vertical position
            if (verticalPosition === 'Top') {
                // Set the y value as the top coordinate of the parent
                y = parent?.getBoundingClientRect().top;
            } else {
                // Set the y value as the bottom coordinate of the parent
                y = parent?.getBoundingClientRect().bottom;
            }

            // If the element goes out of the screen then display to the top
            if (ref.current.getBoundingClientRect().bottom + y + 50 > window.innerHeight) {
                y = y - ref.current.getBoundingClientRect().bottom;
            }

            // Set horizontal position
            if (horizontalPosition === 'Left') {
                // Set the x value as the left coordinate of the parent - width of the content
                x = parent?.getBoundingClientRect().left - ref.current.clientWidth;
            } else {
                // Set the y value as the rigth coordinate of the parent
                x = parent?.getBoundingClientRect().right;
            }

            // If the element goes out of the screen then display to the left
            if (ref.current.getBoundingClientRect().right + x + 50 > window.innerWidth) {
                x = x - ref.current.getBoundingClientRect().right;
            }

            setPosition({
                top: y,
                left: x,
            });
        }
    }, [ref]);

    useOutsideClick(ref, close);

    return visible ? (
        <div
            className={styleSheet.root}
            style={{
                top: position.top,
                left: position.left,
                visibility: ref?.current ? 'visible' : 'hidden',
                ...style,
            }}
            ref={ref}
        >
            {children}
        </div>
    ) : (
        <></>
    );
}

PopUpMenu.defaultProps = {
    visible: false,
    close: noop,
    children: <></>,
    verticalPosition: 'Top',
    horizontalPosition: 'Left',
    customRef: null,
};
