/*----- React imports -------*/
import React from 'react';

/*----- Assets imports -------*/
import styles from './index.module.css';
import Logo from '../../images/logo.svg';

/*----- Components imports -------*/
import Button from '../Button';

/*----- Redux imports -------*/
import { useSelector } from 'react-redux';
import { getSelectedBrokerage } from '../../domains/main/Profile/selectors';

const InactiveBrokerageSuite = () => {
    const selectedBrokerage = useSelector(getSelectedBrokerage);
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <img src={Logo} className={styles.logo} alt="" />
            </div>

            <div className={styles.mainContainer}>
                <div className={styles.innerContainer}>
                    <h1 className={styles.mainHeader}>Unable to access your account</h1>
                    <div className={styles.divider}></div>
                    <h2 className={styles.message1}>
                        {selectedBrokerage?.name} has{' '}
                        <span className={styles.orangeSpan}>lost access</span> to Brokerage Suite at{' '}
                        <br /> this time.
                    </h2>
                    <h2 className={styles.message2}>
                        To enable access, please contact your Brokerage Suite admin or <br />{' '}
                        Showingly Support.
                    </h2>
                    <div className={styles.divider}></div>
                    <div className={styles.buttonContainer}>
                        <div style={{ minWidth: '247px' }}>
                            <Button
                                onClick={() => {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    window.location.reload();
                                }}
                                widthType="Full"
                                title="Sign out"
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h2 className={styles.needHelp}>Need help?</h2>
            </div>
        </div>
    );
};

export default InactiveBrokerageSuite;
