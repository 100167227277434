import { AUTH_ACTION } from './actions';
import { STATUS } from './types';

export const initialState = {
    client: null,
    posibleClient: null,
    accounts: null,
    stitchUser: null,
    isConnected: true,
    initializing: false,
    loginInProgress: false,
    isAuthenticated: false,
    errors: [],
    brokerages: null,
};
export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case AUTH_ACTION.LogIn: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loginInProgress: true,
                        errors: [],
                    };
                }
            }
        }
        case AUTH_ACTION.Register: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loginInProgress: true,
                        errors: [],
                    };
                }
                case STATUS.Succeeded: {
                    const { client } = action;
                    return {
                        ...state,
                        loginInProgress: false,
                        isAuthenticated: true,
                        posibleClient: null,
                        client,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loginInProgress: false,
                        errors,
                    };
                }
                default:
                    return state;
            }
        }
        case AUTH_ACTION.SearchEmail: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loginInProgress: true,
                        errors: [],
                    };
                }

                case STATUS.Succeeded: {
                    const { client } = action;
                    return {
                        ...state,
                        loginInProgress: false,
                        posibleClient: client,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loginInProgress: false,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.SearchBrokerages: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loginInProgress: true,
                        errors: [],
                    };
                }

                case STATUS.Succeeded: {
                    const { brokerages } = action;
                    return {
                        ...state,
                        loginInProgress: false,
                        brokerages,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loginInProgress: false,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }

        case AUTH_ACTION.LogOut: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loginInProgress: true,
                        errors: [],
                    };
                }

                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loginInProgress: false,
                        isAuthenticated: false,
                        client: null,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }
        case AUTH_ACTION.Initialization: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        initializing: true,
                        loading: true,
                        errors: [],
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        loading: false,
                        initializing: false,
                        isAuthenticated: true,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        loading: false,
                        initializing: false,
                        isAuthenticated: false,
                    };
                }
                default:
                    return state;
            }
        }
        case AUTH_ACTION.SendVerificationMsg: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        //initializing: true,
                        //loading: true,
                        errors: [],
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        //loading: false,
                        //isAuthenticated: true,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        //loading: false,
                        //isAuthenticated: false,
                    };
                }
                default:
                    return state;
            }
        }
        case AUTH_ACTION.VerifyCode: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        //initializing: true,
                        //loading: true,
                        errors: [],
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        //loading: false,
                        //isAuthenticated: true,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                    };
                }
                default:
                    return state;
            }
        }
        default: {
            return state;
        }
    }
}
