export interface TableHeader {
    name: string;
    orderable: boolean;
    align?: 'left' | 'center' | 'right';
    width?: string | number;
    icon?: any;
    itemTextAlign?: 'left' | 'center' | 'right' ;
}

export type TableData = (string | React.ReactNode)[][];

export enum SortTypeEnum {
    ASC,
    DESC,
}
