import React, { useState, useEffect } from 'react';

/*-------- Components ---------*/
import Loader from './components/Loader';
import UnauthenticatedApp from './unauthenticated-app';
import AuthenticatedApp from './navigation/Authenticated';

/*-------- Redux  ---------*/
import { useSelector, useDispatch } from 'react-redux';
import { getProfileDataRequested } from './domains/main/Profile/actions';
import { isAuthLoading, isAuthenticated } from './domains/auth/selectors';

/*-------- Utils ---------*/
import { getStitchUser } from './utils';

/*-------- Assets ---------*/
import styles from './App.module.css';

/*-------- Constants ---------*/
import { createBrowserHistory } from 'history';

function App() {
    /*----- Constants -------*/
    const history = createBrowserHistory();
    /*----- States -------*/
    const [isAppAuthenticated, setIsAppAuthenticated] = useState(false);
    /*----- Selectors -------*/
    const loading = useSelector(isAuthLoading);
    const isAuth = useSelector(isAuthenticated);
    /*----- Dispatch -------*/
    const dispatch = useDispatch();
    /*----- Use Effect -------*/
    // Mount
    useEffect(() => {
        setIsAppAuthenticated(isAuth);
    }, [isAuth]);

    // get data when app is authenticated
    useEffect(() => {
        if (isAppAuthenticated) {
            getProfileData();
        }
    }, [isAppAuthenticated]);

    useEffect(() => {
        // Page is reloaded, check brokerage auth status
        const isFirstLogin = localStorage.getItem('isFirstLogin');
        const user = getStitchUser();
        const { loggedInProviderType } = user || {};
        const stitchUserId = localStorage.getItem('stitchUserId');
        if (stitchUserId && loggedInProviderType !== 'anon-user') {
            setIsAppAuthenticated(true);
            // Do nothing, brokerage is authenticated, this is further check into at initAuth Saga
        } else {
            if (isFirstLogin === 'true') {
                if (user?.isLoggedIn) {
                    // Brokerage is logged in but did not check remember me
                    setIsAppAuthenticated(false);
                    localStorage.clear();
                    localStorage.setItem('isFirstLogin', false);
                    history.push('/dashboard');
                    window.location.reload(false);
                } else {
                    setIsAppAuthenticated(false);
                    localStorage.clear();
                    localStorage.setItem('isFirstLogin', false);
                    history.push('/auth');
                    window.location.reload();
                }
            }
        }
    }, []);
    /*----- Functions -------*/
    const getProfileData = () => dispatch(getProfileDataRequested());

    if (loading) {
        return (
            <div className={styles.loaderContainer}>
                <Loader size={100} />
            </div>
        );
    }

    if (isAppAuthenticated) {
        return <AuthenticatedApp />;
    }

    return <UnauthenticatedApp />;
}

export default App;
