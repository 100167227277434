export default (theme: any) => {
    return {
        root: {
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        moduleRoot: {
            display: 'flex',
            flexDirection: 'column',
            borderBottom: {
                style: 'solid',
                width: 1,
                color: theme.palette.grey,
            },
            marginTop: 26,
            paddingBottom: 26,
        },
        childContainer: {
            ...theme.paddingHorizontal(26),
        },
        radioButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            ...theme.marginVertical(7),
        },
        radioButtonText: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 16,
            marginTop: -3,
        },
        titleRowContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 8,
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            ...theme.cardShadow,
            width: '29%',
            minWidth: 257,
            borderRadius: 5,
            ...theme.paddingHorizontal(16),
            ...theme.paddingVertical(10),
            backgroundColor: theme.palette.white,
            marginRight: 16,
        },
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            ...theme.marginVertical(8),
            width: '100%',
            borderRadius: 5,
        },
        cardContainer: {
            backgroundColor: theme.palette.background,
            paddingLeft: 16,
            ...theme.paddingVertical(16),
            borderRadius: 5,
            ...theme.marginVertical(26),
            display: 'flex',
            flexDirection: 'row',
        },
        textEmailContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        switchStyling: {
            marginBottom: -5,
        },
        searchBox: {
            backgroundColor: theme.palette.background,
            borderRadius: 5,
            border: 'none',
            ...theme.typography.semiBold,
            width: 460,
            alignItems: 'center',
            justifyContent: 'center',
            ...theme.paddingHorizontal(5),
            alignSelf: 'center',
            ...theme.marginVertical(16),
            height: 30,
        },
        drawerRoot: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 48,
            paddingRight: 296,
        },
        user: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '100%',
            paddingLeft: 16,
            cursor: 'pointer',
        },
        connectNew: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            paddingLeft: 16,
            cursor: 'pointer',
            alignItems: 'center',
        },
        searchBarContainer: {
            margin: {
                left: 48,
                right: 48,
                top: 16,
                bottom: 16,
            },
            padding: { bottom: 16 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
        },
        searchBar: {
            ...theme.typography.medium,
            backgroundColor: theme.palette.background,
            height: 30,
            width: '100vw',
            color: theme.palette.darkGrey,
            fontSize: 16,
            borderRadius: 5,
            borderWidth: 0,
            paddingLeft: 10,
        },
        clientsContainer: {
            margin: {
                left: 48,
                right: 65,
            },
        },
        addNewUserModal: {
            padding: 48,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            alignItems: 'center',
        },
        header: {
            backgroundColor: theme.palette.white,
            borderBottom: {
                color: theme.palette.grey,
                style: 'solid',
                width: 1,
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 15,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
        },
        inputContainer: {
            background: theme.palette.background,
            border: 'none',
            borderRadius: 5,
            padding: 5,
            marginBottom: 40,
            ...theme.typography.semiBold,
            fontSize: 16,
            width: '100%',
        },
    };
};
