export default (theme: any) => {
    return {
        pageHeader: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(23),
            ...theme.paddingHorizontal(48),
            borderBottom: {
                color: theme.palette.grey,
                style: 'solid',
                width: 1,
            },
            display: 'flex',
            alignItems: 'center',
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
    };
};
