import { put } from 'redux-saga/effects';
import * as Realm from 'realm-web';
//import { initialization, initializationFailed } from '../domains/auth/actions';
import { initializationRequested, initializationFailed } from '../domains/auth/actions';

export default function* initSaga() {
    try {
        // Initialize Stitch
        Realm.getApp(process.env.REACT_APP_STITCH_APP_ID as string);
        yield put(initializationRequested());
    } catch (error) {
        yield put(initializationFailed());
    }
}
