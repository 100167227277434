import { Actions } from './actions';
import { STATUS } from '../../../utils/constants';

type LocallyStoredShowing = {
    showingDetails: any;
    currentOp: Actions | null;
    error: string | null;
};


export const initialState = {
    /*-------- Listing Availability -----------*/
    loadingShowingAvailability: false,
    showingAvailability: [],
    errorShowingAvailability: '',

    /*------- Unverified Agent Data -------*/
    unverifiedAgentData: null,

    /*------- Selected Agent -------------*/
    selectedAgent: null,

    /*------- Agent's Clients ----------*/
    clients: [],
    loadingClients: false,
    errorClients: '',

    /*------- Agent's Schedule ---------*/
    agentSchedule: [],
    loadingAgentSchedule: false,
    errorAgentSchedule: '',

    /*------- Selected Client ----------*/
    selectedClient: null,

    /*------- Create Showing ------------*/
    loadingCreateShowing: false,
    createdShowingId: null,
    errorCreateShowing: '',

    /*------- Reschedule Showing ------------*/
    // Cached Showings, mapped by their showingId.
    // showings: new Map() as Map<string, LocallyStoredShowing>,
    rescheduledShowing: null,
   
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case Actions.FetchShowingAvailability: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingShowingAvailability: true,
                        errorShowingAvailability: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { showingAvailability } = action;
                    return {
                        ...state,
                        loadingShowingAvailability: false,
                        showingAvailability,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingShowingAvailability: false,
                        errorShowingAvailability: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.CreateShowing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingCreateShowing: true,
                        errorCreateShowing: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { showingId } = action;
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        createdShowingId: showingId,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        errorCreateShowing: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.CreateUnverifiedShowing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingCreateShowing: true,
                        errorCreateShowing: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { showingId } = action;
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        createdShowingId: showingId,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        errorCreateShowing: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.UnverifiedAgentData: {
            switch (status) {
                case STATUS.Succeeded: {
                    const { data } = action;
                    return {
                        ...state,
                        unverifiedAgentData: data,
                        selectedAgent: null,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.SelectedAgent: {
            switch (status) {
                case STATUS.Succeeded: {
                    const { agent } = action;
                    return {
                        ...state,
                        selectedAgent: agent,
                        unverifiedAgentData: null,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.FetchAgentClients: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingClients: true,
                        errorClients: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { clients } = action;
                    return {
                        ...state,
                        loadingClients: false,
                        clients,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingClients: false,
                        errorClients: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.FetchAgentSchedule: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingAgentSchedule: true,
                        errorAgentSchedule: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { showings } = action;
                    return {
                        ...state,
                        loadingAgentSchedule: false,
                        agentSchedule: showings,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        loadingAgentSchedule: false,
                        errorAgentSchedule: error,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.SelectedClient: {
            switch (status) {
                case STATUS.Succeeded: {
                    const { client } = action;
                    return {
                        ...state,
                        selectedClient: client,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.ClearShowingIds: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        createdShowingId: null,
                        rescheduledShowing: null,
                    };
                }
                default:
                    return state;
            }
        }
        case Actions.RescheduleShowing:
            switch (status) {
                case STATUS.Requested: {
                    //const currentShowingState = state.showings?.get(action.showingId);
                    return {
                        ...state,
                        loadingCreateShowing: true,
                        // showings: state.showings.set(action.showingId, {
                        //     showingDetails: currentShowingState?.showingDetails ?? null,
                        //     currentOp: currentShowingState?.currentOp ?? action.type,
                        //     error: null,
                        // }),
                        rescheduledShowing: null,
                    };
                }
                case STATUS.Succeeded: {
                    const showId: any = action.showingId;
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        // showings: state.showings.set(showId?.toHexString(), {
                        //     showingDetails: action.showing,
                        //     currentOp: null,
                        //     error: null,
                        // }),
                        rescheduledShowing:  action.showingId,
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        // showings: state.showings.set(action?.showingId, {
                        //     showingDetails: state.showings.get(action?.showingId)?.showingDetails,
                        //     currentOp: null,
                        //     error: action.error,
                        // }),
                        rescheduledShowing: null,
                    };
               default:
                return state; 
            }
        case Actions.UpdateStatus:
            switch (action.status) {
                case STATUS.Requested: {
                    //const currentShowingState = state.showings.get(action.showingId);
                    return {
                        ...state,
                        loadingCreateShowing: true,
                        // showings: state.showings.set(action.showingId, {
                        //     showingDetails: currentShowingState?.showingDetails ?? null,
                        //     currentOp: currentShowingState?.currentOp ?? action.type,
                        //     error: null,
                        // }),
                    };
                }

                case STATUS.Succeeded: {
                    const {createdShowing} = action.showing
                    return {
                        ...state,
                        createdShowing,
                        loadingCreateShowing: false,
                        // showings: state.showings.set(action.showing._id.toHexString(), {
                        //     showingDetails: action.showing,
                        //     currentOp: null,
                        //     error: null,
                        // }),
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        loadingCreateShowing: false,
                        // showings: state.showings.set(action.showingId, {
                        //     showingDetails: state.showings.get(action.showingId)?.showingDetails,
                        //     currentOp: null,
                        //     error: action.error,
                        // }),
                    };
                    default:
                        return state;
            }

        default:
            return state;
    }
}
