/*----- React imports -------*/
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Components ---------*/
import Tag from '../../../../../components/Tag';
import Typography from '../../../../../components/Typography';
import ClickableWithFeedback from '../../../../../components/ClickableWithFeedback';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../../theme';

/*-------- Icons ---------*/
import EditIcon from '../../../../../images/icons/editIconBlue.svg';

/*-------- Constants ---------*/
import { DateTime } from 'luxon';

interface Props {
    restriction: any;
    onClick: Function;
}
const RestrictionCard = ({ restriction, onClick }: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Constants ---------*/
    const title = restriction.recurring ? 'Recurring Restriction' : 'One-Time Restriction';
    const neverEnds =
        DateTime.fromJSDate(
            typeof restriction.recurringDetail.endDate === 'string'
                ? new Date(restriction.recurringDetail.endDate)
                : restriction.recurringDetail.endDate,
        ).toFormat('DD') === 'Jan 1, 2400';

    const startsNow =
        DateTime.fromJSDate(new Date()).toFormat('DD') ===
        DateTime.fromJSDate(
            typeof restriction.recurringDetail.startDate === 'string'
                ? new Date(restriction.recurringDetail.startDate)
                : restriction.recurringDetail.startDate,
        ).toFormat('DD');

    return (
        <div className={styleSheet.restrictionCard}>
            <Typography color={indexTheme.palette.mediumGrey} textStyle={'h4'}>
                {title}
            </Typography>
            <div className={styleSheet.restrictionCardData}>
                {restriction.recurring ? (
                    <div className={styleSheet.daysContainer}>
                        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day: string, index: number) => {
                            const includedDay = restriction.recurringDetail.days.includes(index);
                            return (
                                <div
                                    className={
                                        includedDay ? styleSheet.blueDay : styleSheet.whiteDay
                                    }
                                >
                                    <Typography
                                        color={
                                            includedDay
                                                ? indexTheme.palette.white
                                                : indexTheme.palette.blue
                                        }
                                        textStyle={'p2'}
                                    >
                                        {day}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Tag
                        background={true}
                        onClick={() => {}}
                        color={'mediumGrey'}
                        text={`${DateTime.fromJSDate(
                            typeof restriction.startTime === 'string'
                                ? new Date(restriction.startTime)
                                : restriction.startTime,
                        ).toFormat('DD')}`}
                        style={{ ...indexTheme.marginHorizontal(8) }}
                    />
                )}
                {!restriction.recurring && <div className={styleSheet.verticalSeparator} />}
                <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                    {DateTime.fromJSDate(
                        typeof restriction.startTime === 'string'
                            ? new Date(restriction.startTime)
                            : restriction.startTime,
                    ).toFormat('t')}{' '}
                    -{' '}
                    {DateTime.fromJSDate(
                        typeof restriction.endTime === 'string'
                            ? new Date(restriction.endTime)
                            : restriction.endTime,
                    ).toFormat('t')}
                </Typography>
                {restriction.recurring && <div className={styleSheet.verticalSeparator} />}
                {restriction.recurring && (
                    <>
                        <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                            Starts {startsNow ? '' : 'on'}
                        </Typography>
                        <Tag
                            background={true}
                            onClick={() => {}}
                            color={'mediumGrey'}
                            text={`${
                                startsNow
                                    ? 'Now'
                                    : DateTime.fromJSDate(
                                          typeof restriction.recurringDetail.startDate === 'string'
                                              ? new Date(restriction.recurringDetail.startDate)
                                              : restriction.recurringDetail.startDate,
                                      ).toFormat('DD')
                            }`}
                            style={{ ...indexTheme.marginHorizontal(8) }}
                        />
                        <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                            Ends {neverEnds ? '' : 'on'}
                        </Typography>
                        <Tag
                            background={true}
                            onClick={() => {}}
                            color={'mediumGrey'}
                            text={`${
                                neverEnds
                                    ? 'Never'
                                    : DateTime.fromJSDate(
                                          typeof restriction.recurringDetail.endDate === 'string'
                                              ? new Date(restriction.recurringDetail.endDate)
                                              : restriction.recurringDetail.endDate,
                                      ).toFormat('DD')
                            }`}
                            style={{ ...indexTheme.marginHorizontal(8) }}
                        />
                    </>
                )}
                <ClickableWithFeedback
                    onClick={() => onClick()}
                    style={{ marginBottom: -8, marginLeft: restriction.recurring ? 0 : 8 }}
                >
                    <img src={EditIcon} />
                </ClickableWithFeedback>
            </div>
        </div>
    );
};
export default RestrictionCard;
