// Constant agent type that is used as a parameter in API calls.
export const USER_TYPE_AGENT = 'agent';

// The name of the view for showing requests
export const SHOWING_REQUESTS_VIEW = 'showingRequestsView';

// The name of the showing requests collection
export const SHOWING_REQUESTS = 'showingRequests';

// The name of the agents collection
export const AGENTS = 'agents';

// Fetch all agents
export const FETCH_ALL_AGENTS = 'fetchAllAgents';

// Fetch Agent Active Listings
export const FETCH_AGENTS_ACTIVE_LISTINGS = 'fetchAllBrokerageAgentListings';

// fetch Agent Showings
export const FETCH_AGENT_SHOWINGS = 'findBrokerageShowings';

// The name of the brokerages collection
export const BROKERAGE = 'brokerages';

// The name of the feedback collection
export const FEEDBACK = 'feedback';

// The name of the consumers collection
export const CONSUMERS = 'consumers';

// The name of the favorites collection
export const FAVORITES = 'favorites';

// The name of the markets collection
export const MARKETS_COLLECTION = 'markets';

// The name of the pending connections collection
export const PENDING_CONNECTIONS = 'pendingConnections';

// The name of the listings collection
export const LISTINGS_COLLECTION = 'listings';

// The name of the stripe accounts collection
export const STRIPE_ACCOUNTS_COLLECTION_NAME = 'stripeAccounts';

// The name of the markets collection
export const MARKETS_COLLECTION_NAME = 'markets';

// The name of the brokerageUsers collection
export const BROKERAGE_USERS_COLLECTION_NAME = 'brokerageUsers';

// The name of the brokerageUsers type
export const BROKERAGE_USERS_TYPE_NAME = 'brokerageUser';

// The name of the brokerages collection
export const BROKERAGES_COLLECTION = 'brokerages';

// The name of the agent listing statistics collection
export const AGENT_LISTING_STATS = 'agentsListingStats';

// The name of the brokerage listing statistics collection
export const BROKERAGE_LISTING_STATS = 'brokeragesListingStats';

// The name of the brokerage event feed collection
export const BROKERAGE_EVENT_FEED = 'brokerageEventFeed';

// The name of the market listing statistics collection
export const MARKET_LISTING_STATS = 'marketsListingStats';

// The name of the payment records collection
export const PAYMENT_RECORDS = 'paymentRecords';

export enum STATUS {
    Requested,
    Succeeded,
    Failed,
}

// Remove current listing and showing from Redux
export const CLEAR_SELECTED_LISTING_AND_SHOWING = 'CLEAR_SELECTED_LISTING_AND_SHOWING';

// Re-fetch current showing and listing via Redux
export const REFETCH_CURRENT_LISTING_AND_SHOWING = 'REFETCH_CURRENT_LISTING_AND_SHOWING';

//The number of feed items to fetch at a time.
export const NUMBER_OF_FEED_ITEMS_TO_FETCH = 25;
