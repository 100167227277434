import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/*--------- Assets --------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*------- Icons ----------------*/
import LeftArrow from '../../../../images/icons/arrow_left.svg';
import LinkIcon from '../../../../images/icons/link.svg';
import LinkGrayIcon from '../../../../images/icons/linkGray.svg';
import ComingSoonIcon from '../../../../images/icons/coming_soon.svg';

/*------------ Images-------------*/
import Analytics from '../../../../images/analytics.png';

/*-------- Components ---------*/
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import ShowingTable from '../../../../components/ShowingTable';

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';
import { fetchListingSucceeded, setConfigDetails } from '../../Listings/actions';
import {
    getAgentFetchError,
    getAgentListingFetchError,
    getAgentListingLoadingStatus,
    getAgentListings,
    getAgentLoadingStatus,
    getAgentProfile,
    getAgentShowings,
    getAgentShowingsFetchError,
    getAgentShowingsLoadingStatus,
    getShowingsCurrentPage,
} from './selectors';
import {
    fetchSelectedAgentListingsRequested,
    fetchSelectedAgentProfileRequested,
    fetchSelectedAgentShowingsRequested,
    resetSelectedAgentListings,
    selectedAgentShowingsCurrentPageSucceeded,
} from './actions';

/*------- Misc -------------*/
import { formatCurrency } from '../../../../utils/common';
import { getSelectedBrokerage } from '../../Profile/selectors';
import { ShowingFilter } from '../../Showings/types';
import { AgentProfileParams } from '../types';
import megaphoneIcon from '../../../../images/icons/megaphone.svg';
import { useLocalStorage } from '../../../../utils/common/useLocalStorage';

export default function AgentProfile() {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*----- Redux ------------*/
    const dispatch = useDispatch();

    /*----------- Selectors ------------*/
    const profile = useSelector(getAgentProfile);
    const isProfileLoading = useSelector(getAgentLoadingStatus);
    const error = useSelector(getAgentFetchError);

    const listings = useSelector(getAgentListings);
    const isListingLoading = useSelector(getAgentListingLoadingStatus);
    const listingError = useSelector(getAgentListingFetchError);

    const agentShowings = useSelector(getAgentShowings);
    const isShowingsLoading = useSelector(getAgentShowingsLoadingStatus);
    const showingsError = useSelector(getAgentShowingsFetchError);
    const showingsCurrentPage = useSelector(getShowingsCurrentPage);

    const brokerage = useSelector(getSelectedBrokerage);

    /*-------- States ---------*/
    const [tab, setTab] = useLocalStorage('tabPage', 0);

    const [showingValues, setShowingValues] = useState<any>({
        includedStatuses: [],
        confirmStatus: null,
    });

    const handleChangeValue = (element: any) => {
        setShowingValues({
            confirmStatus: element.length,
            includedStatuses: element,
        });
    };

    /*-------- Constants --------*/
    const tabPages = ['Information', 'Listings', 'Analytics', 'Showings Page'];
    const history = useHistory();
    const { agentId } = useParams<AgentProfileParams>();

    /*-------- Use Effects ---------*/
    useEffect(() => {
        if (profile?._id?.toString() !== agentId) {
            dispatch(fetchSelectedAgentProfileRequested(agentId));
        } else if (profile._id) {
            if (listings.activeListings && listings.activeListings.length === 0) {
                dispatch(fetchSelectedAgentListingsRequested());
            }
            if (agentShowings.showings && agentShowings.showings.length === 0) {
                dispatch(fetchSelectedAgentShowingsRequested());
            }
        }
    }, [profile]);

    /*-------- Components -----------*/
    const ProfileHeader = () => {
        const { firstName, lastName, status } = profile;
        const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
        return (
            <div className={styleSheet.height}>
                <div className={styleSheet.gradientHeader} />
                <div className={styleSheet.initialsContainer}>
                    <div className={styleSheet.initialsIcon}>{initials}</div>
                </div>
                <div className={styleSheet.nameAndBrokerage}>
                    {firstName} {lastName}
                    <p className={styleSheet.nameAndBrokerageP}>{brokerage.name}</p>
                </div>
                <div className={styleSheet.brokerageStatus}>
                    <ProfileStatus status={status} />
                    {status} Profile
                </div>
            </div>
        );
    };

    const ProfileStatus = (status = { status: '' }) => {
        switch (status.status) {
            case 'active':
                return <span className={`${styleSheet.status} ${styleSheet.active}`} />;
            case 'suspended':
                return <span className={`${styleSheet.status} ${styleSheet.suspended}`} />;
            default:
                return <span className={`${styleSheet.status} ${styleSheet.none}`} />;
        }
    };

    const TabContainer = () => {
        return (
            <>
                <div className={styleSheet.tabContainer}>
                    <div className={styleSheet.tab} onClick={() => setTab(0)}>
                        <div
                            className={styleSheet.tabText}
                            style={{ color: tabPages[tab] === 'Information' ? '' : '#9A9A9A' }}
                        >
                            Information
                        </div>
                        <div
                            className={styleSheet.tabLine}
                            style={{
                                backgroundColor:
                                    tabPages[tab] === 'Information' ? '#48A4FF' : '#E9E9E9',
                            }}
                        ></div>
                    </div>

                    <div className={styleSheet.tab} onClick={() => setTab(1)}>
                        <div
                            className={styleSheet.tabText}
                            style={{ color: tabPages[tab] === 'Listings' ? '' : '#9A9A9A' }}
                        >
                            Listings
                        </div>
                        <div
                            className={styleSheet.tabLine}
                            style={{
                                backgroundColor:
                                    tabPages[tab] === 'Listings' ? '#48A4FF' : '#E9E9E9',
                            }}
                        ></div>
                    </div>

                    <div className={styleSheet.tab} onClick={() => setTab(2)}>
                        <div className={styleSheet.tabText}>
                            {' '}
                            <img
                                src={ComingSoonIcon}
                                style={{
                                    height: 15,
                                }}
                            />{' '}
                            <span style={{ color: tabPages[tab] === 'Analytics' ? '' : '#9A9A9A' }}>
                                Analytics
                            </span>
                        </div>
                        <div
                            className={styleSheet.tabLine}
                            style={{
                                backgroundColor:
                                    tabPages[tab] === 'Analytics' ? '#48A4FF' : '#E9E9E9',
                            }}
                        ></div>
                    </div>

                    <div className={styleSheet.tab} onClick={() => setTab(3)}>
                        <div
                            className={styleSheet.tabText}
                            style={{ color: tabPages[tab] === 'Showings Page' ? '' : '#9A9A9A' }}
                        >
                            Showings Page
                        </div>
                        <div
                            className={styleSheet.tabLine}
                            style={{
                                backgroundColor:
                                    tabPages[tab] === 'Showings Page' ? '#48A4FF' : '#E9E9E9',
                            }}
                        ></div>
                    </div>
                </div>
                <div className={styleSheet.tabsContainer}>
                    {tabPages[tab] === 'Information' ? (
                        <div>
                            <AgentInformationTab />
                        </div>
                    ) : null}
                    {tabPages[tab] === 'Listings' ? (
                        <div>
                            {isListingLoading ? (
                                <div className={styleSheet.loaderContainer}>
                                    <Loader />
                                    Fetching Agent Requested Listings...
                                </div>
                            ) : listingError.length ? (
                                <>{listingError}</>
                            ) : (
                                <AgentListingsTab />
                            )}
                        </div>
                    ) : null}
                    {tabPages[tab] === 'Analytics' ? (
                        <div className={styleSheet.comingSoon}>
                            <img src={megaphoneIcon} />
                            <h2>Comming Soon...</h2>
                        </div>
                    ) : null}
                    {tabPages[tab] === 'Showings Page' ? (
                        <div>
                            {isShowingsLoading ? (
                                <div className={styleSheet.loaderContainer}>
                                    <Loader />
                                    Fetching Agent Requested Showings...
                                </div>
                            ) : showingsError.length ? (
                                <>{showingsError}</>
                            ) : (
                                <AgentShowingsTab />
                            )}
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    /* ----- Tabs ---- */

    const AgentInformationTab = () => {
        return (
            <div className={styleSheet.mainContainer}>
                <div className={styleSheet.subtitle}>
                    <h3>Agent Information</h3>
                    <div className={styleSheet.informationContainer}>
                        <div className={`${styleSheet.information} ${styleSheet.right}`}>
                            {' '}
                            <strong className={styleSheet.strong}>Name</strong>
                        </div>
                        <div className={styleSheet.information}>
                            {' '}
                            <span className={styleSheet.informationSpan}>
                                {profile.firstName} {profile.lastName}
                            </span>
                        </div>
                        <div className={`${styleSheet.information} ${styleSheet.right}`}>
                            {' '}
                            <strong className={styleSheet.strong}>Phone 01</strong>
                        </div>
                        <div className={styleSheet.information}>
                            {' '}
                            <span className={styleSheet.informationSpan}>
                                {profile.phoneNumber}
                            </span>
                        </div>
                        <div className={`${styleSheet.information} ${styleSheet.right}`}>
                            {' '}
                            <strong className={styleSheet.strong}>E-mail</strong>
                        </div>
                        <div className={styleSheet.information}>
                            {' '}
                            <span className={styleSheet.informationSpan}>{profile.email}</span>
                        </div>
                        <div className={`${styleSheet.information} ${styleSheet.right}`}>
                            {' '}
                            <strong className={styleSheet.strong}>Phone 02</strong>
                        </div>
                        <div className={styleSheet.information}>
                            {' '}
                            <span className={styleSheet.informationSpan}>
                                {profile.otherPhoneNumber}
                            </span>
                        </div>
                        <div className={`${styleSheet.information} ${styleSheet.right}`}>
                            {' '}
                            <strong className={styleSheet.strong}>Brokerage</strong>
                        </div>
                        <div className={styleSheet.information}>
                            {' '}
                            <span className={styleSheet.informationSpan}>
                                {' '}
                                {brokerage.name}{' '}
                            </span>{' '}
                        </div>
                        <div className={styleSheet.information}> </div>
                        <div className={styleSheet.information}> </div>
                        <div className={`${styleSheet.information} ${styleSheet.right}`}>
                            {' '}
                            <strong className={styleSheet.strong}>Agent ID</strong>
                        </div>
                        <div className={styleSheet.information}>
                            {' '}
                            <span className={styleSheet.informationSpan}>
                                {profile.markets[0]?.agentMlsId}{' '}
                            </span>{' '}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const AgentListingsTab = () => {
        let { activeListings, loading } = listings;
        return (
            <table className={styleSheet.agentListingTable}>
                <tbody style={{ display: 'table', width: '100%' }}>
                    {loading ? (
                        <tr>
                            <td colSpan={6} align={'center'}>
                                <div className={styleSheet.loaderContainer}>
                                    <Loader />
                                    <h3>Loading data</h3>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {activeListings.length === 0 && (
                                <tr>
                                    <td colSpan={5} align={'center'}>
                                        <h3>No data</h3>
                                    </td>
                                </tr>
                            )}

                            {activeListings.map((row: any) => (
                                <>
                                    <tr>
                                        <div className={styleSheet.listingDescriptionRoot}>
                                            <img
                                                alt={'image'}
                                                src={row.photos[0]}
                                                className={styleSheet.image}
                                            />
                                            <div className={styleSheet.listingDescriptionContainer}>
                                                <div className={styleSheet.topRow}>
                                                    <span>{getAddress(row.address)}</span>
                                                    <span>
                                                        {isAcceptingShowings(row.agentListing)}
                                                    </span>
                                                    <span>{getSyndicationStatus(row)}</span>
                                                </div>
                                                <div className={styleSheet.bottomRow}>
                                                    <div className={styleSheet.bottomRowItem}>
                                                        <span>{`$${formatCurrency(
                                                            row.listPrice,
                                                        )}`}</span>
                                                        <div className={styleSheet.rowDescription}>
                                                            <span>Price</span>
                                                        </div>
                                                    </div>
                                                    <div className={styleSheet.bottomRowItem}>
                                                        <span>
                                                            {' '}
                                                            {getDaysOnMarket(row.listDate)}
                                                        </span>
                                                        <div className={styleSheet.rowDescription}>
                                                            <span>Days on Market</span>
                                                        </div>
                                                    </div>
                                                    <div className={styleSheet.bottomRowItem}>
                                                        <span>
                                                            {' '}
                                                            {row.ratings?.totalShowings ?? 0}
                                                        </span>
                                                        <div className={styleSheet.rowDescription}>
                                                            <span>Total Showings</span>
                                                        </div>
                                                    </div>
                                                    <div>{getConnectedListLink(row)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </tr>
                                </>
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        );
    };

    const AgentShowingsTab = () => {
        const { loading, showings } = agentShowings;
        const callAgentShowings = (page: ShowingFilter) => {
            dispatch(selectedAgentShowingsCurrentPageSucceeded(page));
        };
        return (
            <div className={styleSheet.contentContainer}>
                <ShowingTable
                    showings={showings}
                    showingsLoading={loading}
                    allShowings={false}
                    currentPage={showingsCurrentPage}
                    callback={callAgentShowings}
                    showingValues={showingValues}
                    setShowingValues={handleChangeValue}
                />
            </div>
        );
    };

    /* ----- Functions ---- */

    const getSyndicationStatus = (listing: any) => {
        if (!listing.agentListing) {
            return '';
        }
        return listing.agentListing?.showToUnrepresentedConsumers !== false ? (
            <div className={styleSheet.showingsEnabled}>
                <span className={styleSheet.showingsEnabledText}>Syndication Enabled</span>
            </div>
        ) : (
            <div className={styleSheet.showingsDisabled}>
                <span className={styleSheet.showingsDisabledText}>Syndication Disabled</span>
            </div>
        );
    };

    const getAddress = (address: any) => {
        return address ? `${address.full}, ${address.state}, ${address.postalCode}` : '';
    };

    const getDaysOnMarket = (listDate: any) => {
        const date1 = new Date(listDate);
        const date2 = new Date();
        const timeDiff = date2.getTime() - date1.getTime();
        const dayDiff = timeDiff / (1000 * 3600 * 24);
        return dayDiff ? Math.round(dayDiff) : 'N/A';
    };

    const isAcceptingShowings = (showing: any) => {
        return showing?.acceptingShowings ? (
            <div className={styleSheet.showingsEnabled}>
                <span className={styleSheet.showingsEnabledText}>Showings Enabled</span>
            </div>
        ) : (
            <div className={styleSheet.showingsDisabled}>
                <span className={styleSheet.showingsDisabledText}>Showings Disabled</span>
            </div>
        );
    };

    const getConnectedListLink = (listing: any) => {
        const { agentListing } = listing;
        if (agentListing) {
            return (
                <div className={styleSheet.connectedListings}>
                    <div className={styleSheet.table}>
                        <div className={styleSheet.tableRow}>
                            <div
                                className={`${styleSheet.tableCol} ${styleSheet.connectedListingTitle}`}
                            >
                                Showingly
                            </div>
                            <div className={styleSheet.tableCol}>
                                <img src={LinkIcon} />
                            </div>
                        </div>
                        <div
                            className={`${styleSheet.tableRow} ${styleSheet.connectedListingText}`}
                        >
                            This listing is connected with the platform, &nbsp;
                            <Link
                                className={styleSheet.link}
                                key={`../../listings/${listing?.mlsList[0]?.listingId}`}
                                to={`../../listings/${listing?.mlsList[0]?.mlsName}/${listing?.mlsList[0]?.listingId}`}
                                onClick={() => {
                                    dispatch(fetchListingSucceeded(listing));
                                }}
                            >
                                click here &nbsp;
                            </Link>
                            to edit this listing.
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styleSheet.connectedListings}>
                    <div className={styleSheet.table}>
                        <div className={styleSheet.tableRow}>
                            <div
                                className={`${styleSheet.tableCol} ${styleSheet.unConnectedListingTitle}`}
                            >
                                Showingly
                            </div>
                            <div className={styleSheet.tableCol}>
                                <img src={LinkGrayIcon} />
                            </div>
                        </div>
                        <div
                            className={`${styleSheet.tableRow} ${styleSheet.unConnectedListingText}`}
                        >
                            <Link
                                className={styleSheet.link}
                                key={`../..listings/configure/${listing?.mlsList[0]?.listingId}`}
                                to={`../../listings/configure/${listing?.mlsList[0]?.mlsName}/${listing?.mlsList[0]?.listingId}`}
                                onClick={() => {
                                    dispatch(setConfigDetails(listing, 1));
                                }}
                            >
                                Click here &nbsp;
                            </Link>
                            to connect this listing to the Showingly platform
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={styleSheet.outerContainer}>
            <div className={styleSheet.title}>
                <span
                    className={styleSheet.back}
                    onClick={() => {
                        history.push('/agents');
                        dispatch(resetSelectedAgentListings());
                    }}
                >
                    Back
                </span>
                <img src={LeftArrow} alt={'Back'} className={styleSheet.leftArrow} /> Agent Profile
            </div>

            <div className={styleSheet.outerContainer2}>
                <div className={styleSheet.innerContainer}>
                    {error.length ? (
                        <>{error}</>
                    ) : isProfileLoading || !profile ? (
                        <div className={styleSheet.loaderContainer}>
                            <Loader />
                            Fetching agent data...
                        </div>
                    ) : (
                        <>
                            <ProfileHeader />
                            <TabContainer />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
