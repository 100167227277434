export default (theme) => {
    return {
        loadingContainer: {
            width: '100%',
            height: '100%',

            ...theme.marginVertical(10),

            display: 'flex',
            justifyContent: 'center',
            alignItmes: 'center',
            flexDirection: 'column',
        },
    };
};
