import React, { createRef, useEffect, useRef, useState } from 'react';

/*-------- Components ---------*/
import { useHistory } from 'react-router-dom';
import Separator from '../../../components/Separator';
import Button from '../../../components/Button';
import DataTable from '../../../components/DataTable';
import PopUpMenu from '../../../components/PopUpMenu';
import SearchInput from '../../../components/SearchInput';
import ClickableWithFeedback from '../../../components/ClickableWithFeedback';
import SelectInput from '../../../components/SelectInput';

/*-------- Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Icons -------------*/
import ArrowLeftIcon from '../../../images/icons/arrow_left.svg';
import ArrowDownWhiteIcon from '../../../images/icons/arrow_down_white.svg';
import DotsIcon from '../../../images/icons/dots.svg';
import EditIcon from '../../../images/icons/edit.svg';
import RemoveIcon from '../../../images/icons/remove.svg';

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';
import {
    DeleteUserRequested,
    FetchUsersRequested,
    UpdateUserRequested,
    ResendInviteRequested,
} from './actions';
import {
    getCreatedUserId,
    getUsers,
    isUsersLoading,
    isUpdatingUser,
    isDeletingUser,
    isSendingInvitation,
    getNewUserError,
    getDeleteUserError,
    getUpdateUserError,
    getUsersError,
    getResendInviteError,
    getTotalPages,
    isPaginationLoading,
    getCurrentPage,
    getUserSortIndex,
    getUserSortType,
    getUserSearchText,
} from './selectors';
import { getProfileData } from '../Profile/selectors';

/*------ Constants ---------*/
import { USER_TYPE_FILTER, HEADERS, ROLES } from './constants';
import { TablePage } from './types';
import { SortTypeEnum, TableData } from '../../../components/DataTable/types';
import { ObjectID } from 'mongodb';

/*------------ MISC -----------*/
import { minutesToTimeDisplay } from '../../../utils/common/dateTimeUtils';
import InviteUserModal from './InviteUserModal';
import EditUserModal from './EditUserModal';
import ModalConfirm from '../../../components/ModalConfim';
import { maskPhoneNumber } from '../../../utils/common/masks';
import { useSnackbar } from 'notistack';
import { BrokerageUser, BrokerageUserRole } from '../../../utils/constants';
import { Paginator } from 'web-lib';
import ProfilePicture from '../../../components/ProfilePicture';

interface Props {}

export default function Users(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Selectors --------*/
    const users = useSelector<any, TablePage[]>(getUsers);
    const usersLoading = useSelector<any, boolean>(isUsersLoading);
    const totalPages = useSelector<any, number>(getTotalPages);
    const currentPage = useSelector<any, number>(getCurrentPage);
    const paginationLoading = useSelector<any, boolean>(isPaginationLoading);

    const profileData = useSelector<any, any>(getProfileData);

    const newUserId = useSelector<any, ObjectID>(getCreatedUserId);

    const updateUserLoading = useSelector<any, boolean>(isUpdatingUser);
    const deleteUserLoading = useSelector<any, boolean>(isDeletingUser);
    const resendInviteLoading = useSelector<any, boolean>(isSendingInvitation);

    const fetchUsersError = useSelector<any, string>(getUsersError);
    const newUserError = useSelector<any, string>(getNewUserError);
    const deleteUserError = useSelector<any, string>(getDeleteUserError);
    const updateUserError = useSelector<any, string>(getUpdateUserError);
    const resendInviteError = useSelector<any, string>(getResendInviteError);
    const searchRedux = useSelector<string, string>(getUserSearchText);
    const sortReduxIndex = useSelector<any>(getUserSortIndex);
    const sortReduxType = useSelector<any>(getUserSortType);

    /*------- State -----------*/
    const [search, setSearch] = useState<string>(searchRedux);
    const [sort, setSort] = useState<Object>(null);
    const [sortIndex, setSortIndex] = useState<any>(sortReduxIndex);
    const [sortType, setSortType] = useState<any>(sortReduxType);

    // Indicates if the users filter is visible
    const [userFilterVisible, setUserFilterVisible] = useState<boolean>(false);

    // Indicates which menu user is opened (pop up menu for each row in the table)
    const [userMenuOpened, setUserMenuOpened] = useState<ObjectID>(null);

    // Indicates if the invite user modal is visible
    const [inviteUserModal, setInviteUserModal] = useState<boolean>(false);

    // Indicates which user is getting edited and obviusly if the modal is visible
    const [editingUser, setEditingUser] = useState<BrokerageUser | null>(null);

    // Indicates wich user are going to be deleted and obviusly if the confirmation modal is visible
    const [deletingUserId, setDeletingUserId] = useState<ObjectID | null>(null);

    /*------ Constants --------*/
    const history = useHistory();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const filterRef = useRef();
    const tableLoading = usersLoading;

    const paginatedUsers = users.find((e) => e.page === currentPage)?.data ?? [];
    const mountRef = useRef<boolean>(false);

    /*------- Effects ---------*/

    // useEffect(() => {
    //     fetchUsers(currentPage, true);

    //     return () => {
    //         // Clear the errors
    //     };
    // }, []);

    useEffect(() => {
        if (newUserId) {
            fetchUsers(currentPage, true);
        }
    }, [newUserId]);

    useEffect(() => {
        if (mountRef.current) {
            fetchUsers(1, true);
        }
    }, [search, sort]);

    useEffect(() => {
        mountRef.current = true;
        if (!paginatedUsers.length) {
            fetchUsers(currentPage, true);
        }
    }, []);

    // Errors listeners
    useEffect(() => {
        if (fetchUsersError.length) {
            enqueueSnackbar(fetchUsersError, { variant: 'error' });
        }
    }, [fetchUsersError]);

    useEffect(() => {
        if (newUserError.length) {
            enqueueSnackbar(newUserError, { variant: 'error' });
        }
    }, [newUserError]);

    useEffect(() => {
        if (deleteUserError.length) {
            enqueueSnackbar(deleteUserError, { variant: 'error' });
        }
    }, [deleteUserError]);

    useEffect(() => {
        if (updateUserError.length) {
            enqueueSnackbar(updateUserError, { variant: 'error' });
        }
    }, [updateUserError]);

    useEffect(() => {
        if (resendInviteError.length) {
            enqueueSnackbar(resendInviteError, { variant: 'error' });
        }
    }, [resendInviteError]);

    /*------- Functions -------*/
    const fetchUsers = (page: number, refresh: boolean = false) => {
        dispatch(
            FetchUsersRequested(
                profileData.brokerageId,
                page,
                refresh,
                search,
                sort,
                sortIndex,
                sortType,
            ),
        );
    };

    const UserFilter = () => (
        <PopUpMenu
            visible={userFilterVisible}
            close={() => setUserFilterVisible(false)}
            horizontalPosition="Right"
            verticalPosition="Bottom"
            customRef={filterRef}
        >
            <ul className={styleSheet.userFilter}>
                {USER_TYPE_FILTER.map((type, index) => (
                    <li
                        className={`${styleSheet.userFilterItem} ${
                            index < USER_TYPE_FILTER.length - 1 ? styleSheet.bottomBorder : ''
                        }`}
                        onClick={() => {
                            setUserFilterVisible(false);
                            filterTable(type);
                        }}
                    >
                        <span>{type}</span>
                    </li>
                ))}
            </ul>
        </PopUpMenu>
    );

    const UserMenu = ({ user }: { user: BrokerageUser }) => {
        return (
            <PopUpMenu
                visible={user._id == userMenuOpened}
                close={() => setUserMenuOpened(null)}
                horizontalPosition="Left"
                verticalPosition="Bottom"
            >
                <ul className={styleSheet.userMenuOptions}>
                    <li
                        className={`${styleSheet.userMenuItem}`}
                        onClick={() => {
                            setUserMenuOpened(null);
                            setEditingUser(user);
                        }}
                    >
                        Edit <img src={EditIcon} />
                    </li>
                    <Separator type={'Horizontal'} />
                    {!user.signUpCompleted && (
                        <li
                            className={`${styleSheet.userMenuItem}`}
                            onClick={() => handleSendInvitation(user._id)}
                        >
                            {!resendInviteLoading ? 'Resend Invite' : 'Loading'}
                        </li>
                    )}
                    <li
                        className={`${styleSheet.userMenuItem}`}
                        onClick={() => {
                            setUserMenuOpened(null);
                            setDeletingUserId(user._id);
                        }}
                    >
                        Remove <img src={RemoveIcon} />
                    </li>
                </ul>
            </PopUpMenu>
        );
    };

    const toggleUsersFilter = () => setUserFilterVisible((prev) => !prev);

    const createTableRow = (user: BrokerageUser) => {
        const status = user.signUpCompleted ? 'Signed up' : 'Not Signed Up';
        const lastActive = user.lastSession
            ? minutesToTimeDisplay(user.lastSession)
            : 'Invite Pending';

        return [
            <ProfilePicture user={user} size={35} />,
            <strong>{`${user.firstName} ${user.lastName}`}</strong>,
            user.email,
            maskPhoneNumber(user.phoneNumber),
            // Auth user cannot edit his role
            <SelectInput
                onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateUserRole(user._id, e.target.value as BrokerageUserRole);
                }}
                options={ROLES}
                selectedOption={user.role}
                disabled={
                    updateUserLoading || user._id.toHexString() == profileData?._id?.toHexString()
                }
            />,
            <span
                className={
                    lastActive === 'Invite Pending' ? styleSheet.blueLabel : styleSheet.grayLabel
                }
            >
                {lastActive}
            </span>,
            <span className={styleSheet.grayLabel}>{status}</span>,
            <>
                <ClickableWithFeedback
                    onClick={() => {
                        // Don't open other menu if a invitation is sending
                        if (!resendInviteLoading) {
                            setUserMenuOpened((val: ObjectID) =>
                                val == user._id ? null : user._id,
                            );
                        }
                    }}
                >
                    <img src={DotsIcon} />
                </ClickableWithFeedback>
                <UserMenu user={user} />
            </>,
        ];
    };

    const transformUsersToTableRow = (users: BrokerageUser[]): TableData => {
        return users.map((user) => createTableRow(user));
    };

    const updateUserRole = (userId: ObjectID, role: BrokerageUserRole) => {
        if (!updateUserLoading) {
            dispatch(
                UpdateUserRequested(userId, {
                    role,
                }),
            );
        }
    };

    const handleDeleteUser = (userId: ObjectID) => {
        dispatch(
            DeleteUserRequested(userId, () => {
                enqueueSnackbar('User has been successfully removed.', { variant: 'info' });
                setDeletingUserId(null);
            }),
        );
    };

    const handleSendInvitation = (userId: ObjectID) => {
        dispatch(
            ResendInviteRequested(userId, () =>
                enqueueSnackbar('Invitation sent succesfully!.', { variant: 'info' }),
            ),
        );
    };

    const filterTable = (type: string) => {
        // const filteredUsers = users.filter((user) => {
        //     switch (type) {
        //         case 'Super Admin':
        //         case 'Admin':
        //         case 'Viewer':
        //             return user.role === type;
        //         case 'Signed Up':
        //             return user.signUpCompleted;
        //         case 'Not Signed Up':
        //             return !user.signUpCompleted;
        //         default:
        //             return true;
        //     }
        // });
        // setPaginatedUsers([...filteredUsers]);
    };

    const handleTableSort = (sortIndex: number, sortType: SortTypeEnum) => {
        //  Only sort if the sortIndex is a valid index
        if (sortIndex) {
            const sortValue = sortType == SortTypeEnum.ASC ? 1 : -1;

            switch (sortIndex) {
                case 1:
                    setSort({ firstName: sortValue });
                    // Sort by name
                    break;
                case 2:
                    setSort({ email: sortValue });
                    // Sort by email
                    break;
                case 3:
                    setSort({ phoneNumber: sortValue });
                    // Sort by phone
                    break;
                case 4:
                    setSort({ role: sortValue });
                    // Sort by role
                    break;
                case 5:
                    setSort({ lastSession: sortValue });
                    // Sort by last active
                    break;
                case 6:
                    setSort({ signUpCompleted: sortValue });
                    // Sort by status
                    break;
                default:
                    return 0;
            }
            setSortIndex(sortIndex);
            setSortType(sortType);
        }
    };

    return (
        <>
            {/* Breadcumb */}
            <div className={styleSheet.breadcumbContainer}>
                <span className={styleSheet.breadcumbItemBack} onClick={() => history.goBack()}>
                    Back
                </span>
                <img src={ArrowLeftIcon} height={15} className={styleSheet.breadcumbItem} />
                <span className={styleSheet.breadcumbItem}>Manage Users</span>
            </div>
            {/* Content */}
            <div className={styleSheet.container}>
                <div className={`${styleSheet.header} ${styleSheet.row}`}>
                    <div className={`${styleSheet.row}`}>
                        <div>
                            <span>All users</span>
                            {/* TODO: implement filtering functionality */}
                            {/* <span
                                className={styleSheet.filterPopUp}
                                onClick={toggleUsersFilter}
                                ref={filterRef}
                            >
                                <img src={ArrowDownWhiteIcon} />
                            </span> */}
                            <UserFilter />
                        </div>
                        <SearchInput
                            value={search}
                            onChangeValue={setSearch}
                            placeholder={'Search Users'}
                            containerClass={styleSheet.searchInputContainer}
                        />
                    </div>
                    <Button
                        title="Add User"
                        widthType="Auto"
                        onClick={() => setInviteUserModal(true)}
                    />
                </div>
                <Separator type="Horizontal" />

                {/* Table */}
                <DataTable
                    headers={HEADERS}
                    rows={transformUsersToTableRow(paginatedUsers)}
                    loading={tableLoading}
                    onSort={handleTableSort}
                    customSortIndex={sortIndex}
                    customSortType={sortType}
                    sortingEnabled={search.length === 0}
                    height={'61vh'}
                />
                <Paginator
                    isLoading={paginationLoading}
                    numberOfPages={totalPages}
                    handleOnChange={(pageNumber) => {
                        fetchUsers(pageNumber + 1);
                    }}
                    currentPageNumber={currentPage - 1}
                />
            </div>

            <InviteUserModal open={inviteUserModal} onClose={() => setInviteUserModal(false)} />
            <EditUserModal
                open={editingUser != null}
                onClose={() => {
                    setEditingUser(null);
                }}
                user={editingUser}
                onDeleteUser={(id: ObjectID) => {
                    setEditingUser(null);
                    setDeletingUserId(id);
                }}
            />
            <ModalConfirm
                open={deletingUserId !== null}
                onClose={() => setDeletingUserId(null)}
                title="Are you sure?"
                text="Proceed to remove this admin permanently."
                loadingYes={deleteUserLoading}
                onClickNo={() => setDeletingUserId(null)}
                onClickYes={() => handleDeleteUser(deletingUserId)}
            />
        </>
    );
}

Users.defaultProps = {};
