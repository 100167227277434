export default (theme: any) => {
    const border = {
        style: 'solid',
        color: theme.palette.separatorGrey,
        width: 1,
    };
    return {
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: border,
            ...theme.paddingVertical(15),
        },
        closeButton: {
            marginRight: 15,
            cursor: 'pointer',
        },
        textAreaContainer: {
            borderTop: 'none',
            ...theme.marginHorizontal(48),
            position: 'relative',
        },
        feedbackDate: {
            width: '100%',
            paddingBottom: 15,
            paddingTop: 15,
            display: 'flex',
            justifyContent: 'space-between',
            ...theme.marginHorizontal(48),
            flexDirection: 'column',
        },
        personalInfo: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: border,
            borderBottom: border,
            ...theme.marginHorizontal(48),
        },

        info: {
            display: 'flex',
            width: '100%',
        },

        iconAndText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
        },
        icon: {
            height: 20,
            width: 20,
            marginRight: 10,
        },
        feedbackStarForm: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            ...theme.marginHorizontal(48),
        },
        feedbackStarFormRow: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            ...theme.paddingVertical(15),
            borderBottom: border,
        },
        feedbackSeparator: {
            height: 1,
            ...theme.marginHorizontal(7),
            borderBottom: '2px dotted ' + theme.palette.grey,
            flex: 1,
        },
        circularProgressContainer: { position: 'absolute', bottom: '5px', right: '5px' },
        textArea: {
            resize: 'none',
            padding: 15,
            width: '94%',
            height: '92px',
            border: border,
            outline: 'none',
            fontFamily: 'Poppins',
            borderRadius: 5,
        },
        bottomButtonContainer: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTopStyle: 'solid',
            borderTopColor: theme.palette.separatorGrey,
            borderTopWidth: 1,
            ...theme.paddingVertical(16),
            display: 'flex',
            justifyContent: 'center',
        },
      
        showingTimeDetails: {
            ...theme.marginHorizontal(48),
            borderBottom: border,
            ...theme.paddingVertical(12),
            marginTop: 15,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
        },
        inputBox: {
            backgroundColor: theme.palette.background,
            borderRadius: 5,
            border: 'none',
            ...theme.typography.semiBold,
            width: '100%',
            height: 59,
            alignSelf: 'center',
            justifyContent: 'center',
            resize: 'none',
            ...theme.paddingVertical(11),
            marginTop: 15,
            ...theme.marginHorizontal(15),
            ...theme.paddingHorizontal(15),
        },
    };
};
