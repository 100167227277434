export default (theme) => {
    const flexCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return {
        root: {
            ...flexCenter,
        },
        container: {
            backgroundColor: theme.palette.white,
            width: 600,
            borderRadius: 10,
        },

        /*------- Header ----------*/
        header: {
            backgroundColor: theme.palette.orange,
            padding: '32px 32px 0px 32px',
            borderRadius: '10px 10px 0px 0px',
        },
        closeButton: {
            textAlign: 'right',
        },
        headerContainer: {
            ...flexCenter,
            flexDirection: 'row',
            position: 'relative',
            top: 18,
        },
        profile: {
            ...flexCenter,
            ...theme.marginHorizontal(10),
            height: 150,
            width: 150,

            fontSize: 96,
            color: theme.palette.white,

            backgroundColor: theme.palette.orange,
            border: {
                color: theme.palette.white,
                width: 5,
                style: 'solid',
                radius: 150,
            },
        },
        headerInputContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            marginLeft: 10,
            paddingBottom: 35,
        },
        headerInput: {
            backgroundColor: theme.palette.orange,

            padding: '5px 10px',
            ...theme.marginVertical(5),

            fontSize: 25,
            ...theme.typography.semiBold,
            color: theme.palette.white,
            border: {
                color: theme.palette.white,
                width: 2,
                style: 'solid',
                radius: 10,
            },
        },

        /*------- Body ------------*/
        body: {
            padding: '30px 75px 75px 75px',

            fontSize: 18,
            ...theme.typography.semiBold,
        },
        roleContainer: {
            marginLeft: '50%',
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        inputContainer: {
            ...theme.marginVertical(40),
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        buttonRemove: {
            ...theme.marginHorizontal(10),
            width: '60%',
        },
        buttonSave: {
            ...theme.marginHorizontal(10),
        },
        phoneNumberInput: {
            marginLeft: '20px !important',
            width: '50% !important',
        },
        emailInput: {
            marginLeft: '20px !important',
            width: '80% !important',
        },
    };
};
