import { createSelector } from 'reselect';

export const getAgentState = (state: any) => state.agents || {};

export const getAllAgents = createSelector(getAgentState, (agents) => agents.agents);

export const getCurrentPage = createSelector(getAgentState, (agents) => agents.currentPage);

export const getTotalPages = createSelector(getAgentState, (agents: any) => agents.totalPages);

export const isRequestInProgress = createSelector(
    getAgentState,
    (agents) => agents.requestInProgress,
);

export const getAgentSearchText = createSelector(getAgentState, (agents) => agents.textSearch);
export const getAgentSortIndex = createSelector(getAgentState, (agents) => agents.sortIndex);
export const getAgentSortType = createSelector(getAgentState, (agents) => agents.sortType);

export const getGlobalRecords = createSelector(getAgentState, (agents) => agents.globalRecords);

/*-------- Search Agents ----------*/
export const getSearchedAgents = createSelector(getAgentState, (agents) => agents.searchedAgents);
export const isSearchAgentsLoading = createSelector(
    getAgentState,
    (agents) => agents.searchAgentsLoading,
);
export const getErrorSearchAgents = createSelector(
    getAgentState,
    (agents) => agents.searchAgentsError,
);
