import React, { useEffect, useState } from 'react';

/*-------- Redux ----------------*/
import { useDispatch, useSelector } from 'react-redux';
import { UpdateUserRequested } from '../actions';
import { isUpdatingUser } from '../selectors';
import { getProfileData } from '../../Profile/selectors';

/*--------- Assets --------------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*------- Icons ----------------*/
import CloseIcon from '../../../../images/icons/close_modal_white.svg';

/*------- Components -----------*/
import { Modal as FakeModal } from '@material-ui/core';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

/*------- Types / Constants ----------*/
import { ROLES } from '../constants';
import { UserFormValues } from './types';

/*------- Misc -------------*/
import { noop } from '../../../../utils/common';
import { isValidEmail, isValidPhoneNumber } from '../../../../utils/common/validations';
import { maskPhoneNumber, unmaskPhoneNumber } from '../../../../utils/common/masks';
import { useSnackbar } from 'notistack';
import { BrokerageUser } from '../../../../utils/constants';

interface Props {
    open: boolean;
    onClose: Function;
    user: BrokerageUser | null;
    onDeleteUser: Function;
}

export default function InviteUserModal(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Redux ------------*/
    const dispatch = useDispatch();

    /*----- Selectors --------*/
    const loadingUpdatingUser = useSelector<any, boolean>(isUpdatingUser);
    const profileData = useSelector<any, any>(getProfileData);

    /*-------- State ---------------*/
    const [values, setValues] = useState<UserFormValues>({});

    /*-------- Constants -----------*/
    const { open, onClose, user, onDeleteUser } = props;
    const Modal = FakeModal as any;
    const { enqueueSnackbar } = useSnackbar();
    const validInput =
        !!values?.firstName?.length &&
        !!values?.lastName?.length &&
        isValidEmail(values?.email ?? '') &&
        isValidPhoneNumber(values?.phoneNumber ?? '') &&
        !!values?.role?.length;
    const editCurrentAuthUser = user?._id?.toHexString() == profileData?._id?.toHexString();

    /*----------- Effect ------------*/
    useEffect(() => {
        if (user) {
            setValues({
                firstName: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
                email: user?.email,
                phoneNumber: maskPhoneNumber(user?.phoneNumber ?? ''),
                role: user.role ?? 'Admin',
            });
        }
    }, [user]);

    /*-------- Functions -----------*/
    const handleChangeValue = (event: React.FormEvent<HTMLInputElement>) => {
        const value =
            event.currentTarget.name === 'phoneNumber'
                ? maskPhoneNumber(event.currentTarget.value ?? '')
                : event.currentTarget.value;
        setValues({
            ...values,
            [event.currentTarget.name]: value,
        });
    };

    const handleClose = () => {
        if (!loadingUpdatingUser) {
            onClose();
        }
    };

    const handleOnSave = () => {
        if (
            user?._id &&
            values?.role &&
            values?.firstName &&
            values?.lastName &&
            values?.email &&
            values?.phoneNumber
        ) {
            let data;

            // If not updating auth user then send all the field
            // If is the auth user only send basic fields DON'T SEND ROLE
            if (!editCurrentAuthUser) {
                data = {
                    ...values,
                    phoneNumber: unmaskPhoneNumber(values.phoneNumber),
                };
            } else {
                data = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber: unmaskPhoneNumber(values.phoneNumber),
                };
            }

            dispatch(
                UpdateUserRequested(user._id, data, () => {
                    enqueueSnackbar('Changes have been successfully saved.', {
                        variant: 'info',
                    });
                    onClose();
                }),
            );
        }
    };

    return (
        <Modal open={open} onClose={handleClose} className={styleSheet.root}>
            <div className={styleSheet.container}>
                <div className={styleSheet.header}>
                    <ClickableWithFeedback className={styleSheet.closeButton} onClick={handleClose}>
                        <img src={CloseIcon} height={15} />
                    </ClickableWithFeedback>

                    <div className={styleSheet.headerContainer}>
                        <span className={styleSheet.profile}>E</span>
                        <div className={styleSheet.headerInputContainer}>
                            <input
                                name="firstName"
                                className={styleSheet.headerInput}
                                type="text"
                                value={values.firstName}
                                onChange={handleChangeValue}
                            />
                            <input
                                name="lastName"
                                className={styleSheet.headerInput}
                                type="text"
                                value={values.lastName}
                                onChange={handleChangeValue}
                            />
                        </div>
                    </div>
                </div>

                <div className={styleSheet.body}>
                    <div className={styleSheet.roleContainer}>
                        <span>Role</span>
                        <SelectInput
                            name={'role'}
                            onSelect={handleChangeValue}
                            options={ROLES}
                            selectedOption={user?.role}
                            disabled={editCurrentAuthUser}
                        />
                    </div>
                    <div className={styleSheet.inputContainer}>
                        <p>Phone</p>
                        <TextInput
                            name={'phoneNumber'}
                            type="text"
                            placeholder={'Phone Number'}
                            customStyles={styleSheet.phoneNumberInput}
                            value={values.phoneNumber}
                            onChangeValue={handleChangeValue}
                        />
                        <p>Email</p>
                        <TextInput
                            name={'email'}
                            type="email"
                            placeholder={'Email'}
                            customStyles={styleSheet.emailInput}
                            value={values.email}
                            onChangeValue={handleChangeValue}
                        />
                    </div>

                    <div className={styleSheet.buttonsContainer}>
                        <Button
                            title={'Remove User'}
                            type={'danger'}
                            customStyle={styleSheet.buttonRemove}
                            widthType={'Ineherit'}
                            onClick={() => onDeleteUser(user?._id)}
                            disabled={loadingUpdatingUser}
                        />
                        <Button
                            title={'Save Changes'}
                            customStyle={styleSheet.buttonSave}
                            onClick={handleOnSave}
                            disabled={!validInput}
                            loading={loadingUpdatingUser}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

InviteUserModal.defaultProps = {
    open: false,
    onClose: noop,
    user: null,
    onDeleteUser: noop,
};
