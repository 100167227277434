import { STATUS } from '../../auth/types';
import { FilterOptionType, MapListingObject, Region } from './types';

export enum Actions {
    FetchGeoListings = '[SearchMap] Fetch Listings by Geo',
    FetchSearchListings = '[SearchMap] Fetch Listings by Text search / Id',
}

/*----------- Fetch Listings by GEO -----------------*/

export function FetchGeoListingsRequested(
    region: Region,
    searchListings: Array<MapListingObject> = [],
    filter: FilterOptionType,
) {
    return {
        type: Actions.FetchGeoListings,
        status: STATUS.Requested,
        region,
        searchListings,
        filter,
    };
}

export function FetchGeoListingsSucceeded(listings: any) {
    return {
        type: Actions.FetchGeoListings,
        status: STATUS.Succeeded,
        listings,
    };
}

export function FetchGeoListingsFailed(error: string) {
    return {
        type: Actions.FetchGeoListings,
        status: STATUS.Failed,
        error,
    };
}

/*-------- Fetch Listings by Text search / Id ---------*/
export const FetchSearchListingsRequested = (
    searchedText: string | null = null,
    isMlsIdSearch?: boolean,
) => {
    return {
        type: Actions.FetchSearchListings,
        status: STATUS.Requested,
        searchedText,
        isMlsIdSearch,
    };
};

export const FetchSearchListingsSucceeded = (listings = []) => {
    return { type: Actions.FetchSearchListings, status: STATUS.Succeeded, listings };
};

export const FetchSearchListingsFailed = (errors: string[] = []) => {
    return { type: Actions.FetchSearchListings, status: STATUS.Failed, errors };
};
