import React from 'react';

/*------------ Assets -------------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import { noop } from '../../utils/common';

interface Props {
    value: string;
    onChangeValue: Function;
    type: 'email' | 'number' | 'password' | 'search' | 'text';
    placeholder: string;
    name: string;
    customStyles: string;
}

export default function TextInput(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- Constants -----------*/
    const { value, onChangeValue, type, placeholder, name, customStyles } = props;

    return (
        <input
            className={`${customStyles} ${styleSheet.root}`}
            value={value}
            onChange={(e) => onChangeValue(e)}
            placeholder={placeholder}
            type={type}
            name={name}
        />
    );
}

TextInput.defaultProps = {
    value: '',
    onChangeValue: noop,
    type: 'text',
    placeholder: '',
    name: '',
    customStyles: '',
};
