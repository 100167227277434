export default (theme) => {
    return {
        root: {
            width: 674,
            height: 501,
            marginTop: '12%',
            marginLeft: '5%',
            borderRadius: 5,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.white,
        },
        title: {
            fontSize: 36,
            fontWeight: 600,
            paddingBottom: 30,
            fontStyle: 'normal',
            fontFamily: theme.typography.medium.fontFamily,
            borderBottom: `1px solid ${theme.palette.separatorGrey}`,
        },
        content: {
            display: 'flex',
            paddingTop: '9%',
            alignItems: 'center',
            flexDirection: 'column',
        },
        input: {
            border: 0,
            margin: 20,
            width: 536,
            height: 40,
            fontSize: 18,
            fontWeight: 500,
            borderRadius: 5,
            fontStyle: 'normal',
            textAlign: 'center',
            background: theme.palette.background,
            fontFamily: theme.typography.semiBold.fontFamily,
        },
        buttonLogin: {
            border: 0,
            height: 40,
            fontSize: 18,
            marginTop: 35,
            fontWeight: 600,
            borderRadius: 5,
            fontStyle: 'normal',
            color: theme.palette.white,
            backgroundColor: theme.palette.blue,
            fontFamily: theme.typography.semiBold.fontFamily,
        },
        blueText: {
            fontSize: 16,
            fontWeight: 600,
            fontStyle: 'normal',
            color: theme.palette.blue,
            fontFamily: theme.typography.semiBold.fontFamily,
            cursor: 'pointer',
        },
        text: {
            fontSize: 18,
            marginLeft: 80,
            fontWeight: 500,
            fontStyle: 'normal',
            alignSelf: 'baseline',
            fontFamily: theme.typography.medium.fontFamily,
        },
        error: {
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 20,
            display: 'flex',
            fontStyle: 'normal',
            textAlign: 'center',
            alignItems: 'center',
            fontFamily: 'Poppins',
            color: theme.palette.red,
            ...theme.paddingHorizontal(80),
        },
        pwdText: {
            marginTop: 20,
            marginLeft: 80,
            alignSelf: 'baseline',
        },
        pwdNamwText: {
            marginLeft: 80,
            marginTop: '-3%',
            alignSelf: 'baseline',
        },
    };
};
