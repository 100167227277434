import * as React from 'react';

/*--------- Styles ---------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Assets ----------*/
import backArrow from '../../images/arrows/leftArrowBlack.svg';
import frontArrow from '../../images/arrows/rightArrowBlack.svg';

/*-------- Utils ----------*/
import { DateTime } from 'luxon';
import { noop } from '../../utils/common';

interface Props {
    changeMonth: Function;
    selectedDay: any;
    setSelectedDay: Function;
    removePadding?: boolean;
    alwaysSixRows?: boolean;
    disablePast?: boolean;
    startDate?: DateTime;
    blockedDates?: DateTime[];
}

export const DatePicker = (props: Props) => {
    /*--------- Theme -----------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*--------- Constants --------*/
    const {
        changeMonth,
        selectedDay,
        setSelectedDay,
        disablePast,
        alwaysSixRows,
        startDate = DateTime.local(),
        blockedDates,
    } = props;

    const now = DateTime.max(startDate, DateTime.local());
    const nowYear = now.year;
    const nowMonth = now.month;
    const nowDay = now.day;

    // Getting the number of days in last and this month to know what numbers to display
    // on the calendar
    const daysInThisMonth = selectedDay.daysInMonth;
    const daysInLastMonth = selectedDay.minus({ months: 1 }).daysInMonth;

    // Javascript weekday index (0-6) of the first day of this month
    const weekDexOfFirstDay =
        selectedDay.startOf('month').weekday === 7 ? 0 : selectedDay.startOf('month').weekday;
    // Javascript weekday index (0-6) of the last day of this month
    const weekDexOfLastDay =
        selectedDay.endOf('month').weekday === 7 ? 0 : selectedDay.endOf('month').weekday;
    const nextMonthDayNum = 6 - weekDexOfLastDay;

    // Label at top of calendar (eg: September 2021)
    const monthLabel = selectedDay.toFormat('LLLL y');

    // How many days shown on the calendar view will be in either last month or next month (grayed out)
    // since the calendar starts on sunday and ends on saturday
    const totalGrayDays = 7 - (7 - weekDexOfFirstDay) + (7 - (weekDexOfLastDay + 1));
    let weeksToShow = 5;
    // If it happens that there's enough gray days, we will need an extra row for this month
    if (35 - totalGrayDays < daysInThisMonth) {
        weeksToShow = 6;
    }

    const fadedColor = '#bcbcbc';

    // Essentially an array to hold all of the weeks (arrays of days) to display
    let dayRows = [];
    // Loop through all of the weeks, creating arrays for each and then putting them in dayRows
    for (let i = 0; i < weeksToShow; i++) {
        let dayRow = [];

        // For the first week, use the first day of this month's javascript weekday index
        // to decide how many gray days to display (from last month) in the first row
        if (i === 0) {
            let lastMonthDays = [];
            let thisMonthDays = [];
            const firstVisibleDayOfLastMonth = daysInLastMonth - (weekDexOfFirstDay - 1);
            for (let j = 0; j < weekDexOfFirstDay; j++) {
                const dateNum = firstVisibleDayOfLastMonth + j;
                const dateVal = selectedDay.minus({ months: 1 }).set({ day: dateNum });
                const lastMonthDay = {
                    dayOfMonth: dateNum,
                    value: dateVal,
                    color: fadedColor,
                };
                lastMonthDays.push(lastMonthDay);
            }
            const thisMonthDayNum = 7 - weekDexOfFirstDay;
            for (let l = 0; l < thisMonthDayNum; l++) {
                const dateNum = 1 + l;
                const dateVal = selectedDay.set({ day: dateNum });
                const thisMonthDay = {
                    dayOfMonth: dateNum,
                    value: dateVal,
                };
                thisMonthDays.push(thisMonthDay);
            }
            // Put the days from last month and this month together in the same week
            dayRow = [...lastMonthDays, ...thisMonthDays];

            // For the last week, use the last day of this month's javascript weekday index
            // to decide how many gray days to display (from next month) in the last row
        } else if (i === weeksToShow - 1) {
            let thisMonthDays = [];
            let nextMonthDays = [];
            const firstDayOfLastWeekOfMonth = daysInThisMonth - weekDexOfLastDay;
            for (let l = 0; l < weekDexOfLastDay + 1; l++) {
                const dateNum = firstDayOfLastWeekOfMonth + l;
                const dateVal = selectedDay.set({ day: dateNum });
                const thisMonthDay = {
                    dayOfMonth: dateNum,
                    value: dateVal,
                };
                thisMonthDays.push(thisMonthDay);
            }
            for (let j = 0; j < nextMonthDayNum; j++) {
                const dateNum = 1 + j;
                const dateVal = selectedDay.plus({ months: 1 }).set({ day: dateNum });
                const nextMonthDay = {
                    dayOfMonth: dateNum,
                    value: dateVal,
                    color: fadedColor,
                };
                nextMonthDays.push(nextMonthDay);
            }
            // Put the days from this month and next month together in the same week
            dayRow = [...thisMonthDays, ...nextMonthDays];
        } else {
            let thisWeekDays = [];
            const firstDayOfThisWeek = 1 + i * 7 - weekDexOfFirstDay;
            for (let j = 0; j < 7; j++) {
                const dateNum = firstDayOfThisWeek + j;
                const dateVal = selectedDay.set({ day: dateNum });
                const thisWeekDay = {
                    dayOfMonth: dateNum,
                    value: dateVal,
                };
                thisWeekDays.push(thisWeekDay);
            }
            dayRow = thisWeekDays;
        }
        dayRows.push(dayRow);
        if (weeksToShow === 5 && i === weeksToShow - 1 && alwaysSixRows) {
            let lastRow = [];
            for (let n = nextMonthDayNum; n < nextMonthDayNum + 7; n++) {
                const dateNum = 1 + n;
                const dateVal = selectedDay.plus({ months: 1 }).set({ day: dateNum });
                const nextMonthDay = {
                    dayOfMonth: dateNum,
                    value: dateVal,
                    color: fadedColor,
                };
                lastRow.push(nextMonthDay);
            }
            dayRows.push(lastRow);
        }
    }

    const dayLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const DaysRow = (
        <div
            className={styleSheet.daysRow}
            style={
                props.removePadding
                    ? {
                          height: 17, // wireframe 20
                      }
                    : {}
            }
        >
            {dayLabels.map((dayLabel) => (
                <div className={styleSheet.dayLabel}>{dayLabel}</div>
            ))}
        </div>
    );

    /*--------- Functions ----------*/
    const Day = ({ day }: any) => {
        const { dayOfMonth, value, color = null } = day;
        const styleClass =
            value.day === selectedDay.day && value.month === selectedDay.month
                ? styleSheet.selectedDay
                : color
                ? styleSheet.fadedDay
                : styleSheet.day;

        const isDisabled =
            (disablePast &&
                nowYear === value.year &&
                nowMonth === value.month &&
                value.day < nowDay) ||
            blockedDates?.find(
                (d) => d.day === value.day && d.month === value.month && d.year === value.year,
            );

        const cursorProps = isDisabled ? { cursor: 'default', color: '#bcbcbc' } : {};

        return (
            <div
                onClick={isDisabled ? () => {} : () => setSelectedDay(value)}
                className={styleClass}
                style={{ ...cursorProps }}
            >
                {dayOfMonth}
            </div>
        );
    };

    const Week = ({ days }: any) => {
        return (
            <div
                className={styleSheet.week}
                style={
                    props.removePadding
                        ? {
                              height: 17, // wireframe 20
                          }
                        : {}
                }
            >
                {days.map((day: any) => {
                    return <Day day={day} />;
                })}
            </div>
        );
    };

    return (
        <div className={styleSheet.root}>
            <div className={styleSheet.topRow}>
                <span>{monthLabel}</span>
                <div className={styleSheet.arrows}>
                    <div
                        onClick={
                            disablePast && selectedDay.month === now.month
                                ? () => {}
                                : () => changeMonth(-1)
                        }
                        className={styleSheet.arrowContainer}
                    >
                        <img src={backArrow} className={styleSheet.arrow} />
                    </div>
                    <div onClick={() => changeMonth(1)} className={styleSheet.arrowContainer}>
                        <img src={frontArrow} className={styleSheet.arrow} />
                    </div>
                </div>
            </div>
            {DaysRow}
            {dayRows?.map((week) => {
                return <Week days={week} />;
            })}
        </div>
    );
};

DatePicker.defaultProps = {
    changeMonth: noop,
    selectedDay: DateTime.now(),
    setSelectedDay: noop,
    disablePast: false,
    alwaysSixRows: false,
    startDate: DateTime.local(),
    blockedDates: [],
};
