/*
 * Explanation
component for showings table, takes showings and formats them
 */

/*----- React imports -------*/

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TableData } from '../DataTable/types';

/*-------- Components ---------*/
import Tag from '../Tag';
import Button from '../Button';
import { Paginator } from 'web-lib';
import SlidingFilter from '../SlidingFilter';
import StatusFilter from '../StatusFilter';
import SearchInput from '../SearchInput';
import DataTable from '../DataTable';

/*-------- Utils ---------*/
import { DateTime } from 'luxon';
import {
    formatEmailForDisplay,
    formatPhoneNumberForDisplay,
    getStatusInfo,
} from '../../utils/common';

/*-------- Assets ---------*/
import styles from './index.module.css';

/*-------- Constants ---------*/
import {
    SHOWINGS_FILTER,
    SHOWINGS_SELECTED_VIEW,
    ITEMS_PER_PAGE,
} from '../../domains/main/Showings/constants';
import { ShowingFilter } from '../../domains/main/Showings/types';
import { Showing } from '../../utils/constants';

/*----- Define the types of the props here -------*/
interface Props {
    showings: Showing[];
    allShowings: boolean;
    showingsLoading: boolean;
    currentPage: ShowingFilter;
    callback: Function;
    showingValues: any;
    setShowingValues: Function;
}

const HEADERS = [
    { name: 'Agent', orderable: false, width: '15%' },
    { name: 'Address', orderable: true, width: '23%' },
    { name: 'Contact', orderable: true, width: '24%' },
    { name: 'Scheduled', orderable: true, width: '16%' },
    { name: 'Status', orderable: true, width: '12%' },
    { name: '', orderable: false, width: '15%' },
];

let buyShowings: any[] = [];
let listShowings: any[] = [];
let toSearch: any[] = [];

export default function ShowingTable(props: Props) {
    const {
        showings,
        showingsLoading,
        allShowings,
        currentPage,
        callback,
        showingValues,
        setShowingValues,
    } = props;

    /*-------- States ---------*/
    //All, upcoming, needs action filter
    const [filter, setFilter] = useState<any>(SHOWINGS_FILTER.All);

    //list side and buy side filter
    const [selectedView, setSelectedView] = useState<any>(SHOWINGS_SELECTED_VIEW.ListSide);

    //paginated data
    const [paginatedData, setPaginatedData] = useState<any>([]);

    //count of pages for paginator
    const [numberOfPages, setNumberOfPages] = useState<number>(0);

    //unpaginated showings to display
    const [displayShowings, setDisplayShowings] = useState<any[]>([]);

    //search
    const [search, setSearch] = useState<string>('');

    //current page
    // const [currentFilterPage, setCurrentFilterPage] = useState<number>(0);

    /*-------- Constants ---------*/
    const history = useHistory();
    const { confirmStatus, includedStatuses } = showingValues;

    /*-------- Use Effect ---------*/
    useEffect(() => {
        const isPlus = (showing: any) => showing === '+';
        const index = showings.findIndex(isPlus);
        buyShowings = showings.slice(0, index);
        listShowings = showings.slice(index + 1);
        filterShowings();
    }, [showings]);

    useEffect(() => {
        filterShowings();
        setSearch('');
    }, [filter, selectedView, confirmStatus]);

    // Search
    useEffect(() => {
        if (toSearch.length && allShowings) {
            const searchedShowings = toSearch.filter((showing) => {
                console.log('search value', search);
                const _search = search.toLowerCase();
                const name = `${showing.firstName} ${showing.lastName}`.toLowerCase();
                const email = formatEmailForDisplay(showing?.email)?.toLowerCase();

                return (
                    name?.includes(_search) ||
                    email?.includes(_search) ||
                    showing?.phoneNumber?.includes(_search)
                );
            });
            initializePaginatedData(searchedShowings);
        }
    }, [search]);

    /*-------- Functions ---------*/
    const initializePaginatedData = (showingList: any) => {
        setDisplayShowings(showingList);

        setPaginatedData(
            showingList.slice(
                currentPage[formatFilterString(selectedView)][formatFilterString(filter)] *
                    ITEMS_PER_PAGE,
                currentPage[formatFilterString(selectedView)][formatFilterString(filter)] *
                    ITEMS_PER_PAGE +
                    ITEMS_PER_PAGE,
            ),
        );
        setNumberOfPages(Math.floor(showingList.length / ITEMS_PER_PAGE));
    };

    const formatFilterString = (filter: string) => {
        const trimmed = filter.replace(/\s+/g, '');
        return trimmed.charAt(0).toLowerCase() + trimmed.slice(1);
    };

    const filterStatusShowings = (showingList: any) => {
        if (confirmStatus !== null) {
            const statusList = showingList.filter((showing: Showing) =>
                includedStatuses.includes(showing.status),
            );
            toSearch = [...statusList];

            initializePaginatedData(statusList);
        } else {
            toSearch = [...showingList];
            initializePaginatedData(showingList);
        }
    };

    const filterShowings = (): any => {
        if (
            filter === SHOWINGS_FILTER.Upcoming &&
            selectedView === SHOWINGS_SELECTED_VIEW.ListSide
        ) {
            const today = new Date();
            const upcomingList = listShowings.filter(
                (showing: Showing) => showing.start.getTime() > today.getTime(),
            );
            // return upcomingList

            filterStatusShowings(upcomingList);
        }

        if (
            filter === SHOWINGS_FILTER.NeedsAction &&
            selectedView === SHOWINGS_SELECTED_VIEW.ListSide
        ) {
            const needsActionList = listShowings.filter(
                (showing: Showing) => showing.status === 'pending_internal',
            );
            // return needsActionList

            filterStatusShowings(needsActionList);
        }
        if (filter === SHOWINGS_FILTER.All && selectedView === SHOWINGS_SELECTED_VIEW.ListSide) {
            // return listShowings

            filterStatusShowings(listShowings);
        }

        if (
            filter === SHOWINGS_FILTER.Upcoming &&
            selectedView === SHOWINGS_SELECTED_VIEW.BuySide
        ) {
            const today = new Date();
            const upcomingList = buyShowings.filter(
                (showing: Showing) => showing.start.getTime() > today.getTime(),
            );

            // return upcomingList

            filterStatusShowings(upcomingList);
        }

        if (
            filter === SHOWINGS_FILTER.NeedsAction &&
            selectedView === SHOWINGS_SELECTED_VIEW.BuySide
        ) {
            const needsActionList = buyShowings.filter(
                (showing: Showing) =>
                    showing.status === 'requested' ||
                    (showing.status === 'completed' && !showing.feedback),
            );

            // return needsActionList

            return filterStatusShowings(needsActionList);
        }
        if (filter === SHOWINGS_FILTER.All && selectedView === SHOWINGS_SELECTED_VIEW.BuySide) {
            // return buyShowings

            filterStatusShowings(buyShowings);
        }
    };

    const transformUsersToTableRow = (showings: any): TableData => {
        const filterNullShowings = showings.filter(
            (showing: any) =>
                showing.unverifiedUser !== null &&
                showing.firstName !== null &&
                (showing.unverifiedUser || showing.firstName),
        );
        return filterNullShowings.map((showing: any) => createTableRow(showing));
    };

    const createTableRow = (showing: Showing) => [
        showing?.unverifiedUser ? (
            <span style={{ fontFamily: 'Poppins-Medium', fontSize: 14 }}>
                {showing.unverifiedUser.firstName} {showing.unverifiedUser.lastName}
            </span>
        ) : (
            <span style={{ fontFamily: 'Poppins-Medium', fontSize: 14 }}>
                {showing.firstName} {showing.lastName}
            </span>
        ),
        <div className={styles.formatValues}>
            <p>{showing.address}</p>
            <p>{showing.address2}</p>
        </div>,

        showing.unverifiedUser ? (
            <div className={styles.formatValues}>
                <p>{formatEmailForDisplay(showing.unverifiedUser.email)}</p>
                <p>
                    {showing.unverifiedUser.phoneNumber
                        ? formatPhoneNumberForDisplay(showing.unverifiedUser.phoneNumber)
                        : showing.unverifiedUser.phoneNumber}
                </p>
            </div>
        ) : (
            <div className={styles.formatValues}>
                <p>{formatEmailForDisplay(showing.email)}</p>
                <p>
                    {showing.phoneNumber
                        ? formatPhoneNumberForDisplay(showing.phoneNumber)
                        : showing.phoneNumber}
                </p>
            </div>
        ),

        <div className={styles.formatValues}>
            <p>{DateTime.fromJSDate(showing.start).toFormat('DD')} </p>
            <p>{`${DateTime.fromJSDate(showing.start).toFormat('t')} - ${DateTime.fromJSDate(
                showing.end,
            ).toFormat('t')}`}</p>
        </div>,

        <Tag
            text={getStatusInfo(showing.status).text}
            color={getStatusInfo(showing.status).color}
            background={false}
            style={{ fontSize: 14 }}
            onClick={() => {}}
        />,
        <Button
            widthType="Small"
            title="See More"
            type="white"
            onClick={() => {
                history.push({
                    pathname: `/showings/details/${showing._id}`,
                    state: { id: showing._id, view: selectedView },
                });
            }}
        />,
    ];

    const handlePageNumber = (page: any) => {
        const formattedSelectedView = formatFilterString(selectedView);
        const formattedFilter = formatFilterString(filter);
        callback({
            ...currentPage,
            [formattedSelectedView]: {
                ...currentPage[formattedSelectedView],
                [formattedFilter]: page,
            },
        });

        setPaginatedData(
            displayShowings?.slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE),
        );
    };
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <p className={styles.title}>Showings</p>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.filterContainer}>
                    <div className={styles.firstFilter}>
                        <SlidingFilter
                            filters={['All', 'Upcoming', 'Needs Action']}
                            filterNumber={3}
                            onChange={(args: any) => {
                                setFilter(() => args.filter);
                            }}
                        />

                        <StatusFilter
                            handlePage={handlePageNumber}
                            currentPage={
                                currentPage[formatFilterString(selectedView)][
                                    formatFilterString(filter)
                                ]
                            }
                            setIncludedStatuses={(e) => setShowingValues(e)}
                        />
                    </div>
                    {allShowings ? (
                        <SearchInput
                            value={search}
                            onChangeValue={setSearch}
                            placeholder={'Search Agent'}
                            containerClass={styles.searchContainer}
                        />
                    ) : (
                        <></>
                    )}

                    <SlidingFilter
                        filters={['List Side', 'Buy Side']}
                        filterNumber={2}
                        onChange={(args: any) => {
                            setSelectedView(() => args.filter);
                        }}
                    />
                </div>
                <DataTable
                    headers={HEADERS}
                    rows={transformUsersToTableRow(paginatedData)}
                    loading={showingsLoading}
                    height={'65vh'}
                />
                <Paginator
                    isLoading={false}
                    numberOfPages={numberOfPages}
                    handleOnChange={handlePageNumber}
                    currentPageNumber={
                        currentPage[formatFilterString(selectedView)][formatFilterString(filter)]
                    }
                />
            </div>
        </div>
    );
}
