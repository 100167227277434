import { STATUS } from 'web-lib';
import { PROFILE_ACTIONS } from './actions';

export const initialState = {
    /*------ Agent Profile Data -------*/
    loadingProfileData: false,
    profileData: null,

    /*----- Selected Brokerage -------*/
    selectedBrokerage: null,
    selectedBrokerageLoading: false,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case PROFILE_ACTIONS.Fetch: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loadingProfileData: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { profileData } = action;
                    return {
                        ...state,
                        loadingProfileData: false,
                        profileData,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loadingProfileData: false,
                        errors,
                    };
                }
                default:
                    return state;
            }
        }
        case PROFILE_ACTIONS.FetchSelectedBrokerage: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        selectedBrokerageLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { brokerage } = action;
                    return {
                        ...state,
                        selectedBrokerageLoading: false,
                        selectedBrokerage: brokerage,
                    };
                }
                case STATUS.Failed: {
                    return {
                        ...state,
                        selectedBrokerageLoading: false,
                    };
                }
                default: {
                    return {
                        ...state,
                    };
                }
            }
        }

        default: {
            return state;
        }
    }
}
