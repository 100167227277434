import { Actions } from './actions';
import { STATUS } from '../../../utils/constants';
import { SortTypeEnum } from '../../../components/DataTable/types';

export const initialState = {
    listings: [],
    isRequestInProgress: false,
    totalRecords: 0,
    errors: [],

    /*----- Listing details -------*/
    listing: null,
    listingLoading: false,
    listingError: '',
    /*----- Configure Listing -----*/
    agents: [],
    consumers: [],
    textSearchLoading: false,
    addClientLoading: false,
    selectedView: 'Overview',
    newClient: null,
    selectedListing: {},
    configurationDetails: {
        listing: {},
        // DO NOT MERGE IF THIS IS NOT 1. May be changed for testing purposes
        pageNumber: 1,
        listingAgents: [],
    },
    totalPages: 0,
    currentPage: 1,
    paginationLoading: false,
    globalRecords: 0,

    listingAgentsLoading: false,
    connectLoading: false,

    textSearch: '',
    sort: null,
    sortIndex: 0,
    sortType: SortTypeEnum.ASC,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case Actions.GetAllListing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        isRequestInProgress: true,
                        errors: [],
                    };
                }

                case STATUS.Succeeded: {
                    const {
                        listings,
                        currentPage,
                        totalPages,
                        globalRecords,
                        textSearch,
                        sort,
                        sortIndex,
                        sortType,
                    } = action;
                    return {
                        ...state,
                        isRequestInProgress: false,
                        listings,
                        currentPage,
                        totalPages,
                        globalRecords,
                        textSearch,
                        sort,
                        sortIndex,
                        sortType,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        isRequestInProgress: false,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }
        case Actions.FetchListing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        listingLoading: true,
                        listing: null,
                    };
                }
                case STATUS.Succeeded: {
                    const { listing } = action;
                    return {
                        ...state,
                        listingLoading: false,
                        listing,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        listingLoading: false,
                        listingError: error,
                    };
                }

                default:
                    return state;
            }
        }
        case Actions.SearchAgent: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        textSearchLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { agents } = action;
                    return {
                        ...state,
                        agents,
                        textSearchLoading: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        textSearchLoading: false,
                    };
                }
            }
        }

        case Actions.FetchAgentByMlsId: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        listingAgentsLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { listingAgents } = action;
                    return {
                        ...state,
                        configurationDetails: {
                            listing: state.configurationDetails.listing,
                            pageNumber: state.configurationDetails.pageNumber,
                            listingAgents,
                        },
                        listingAgentsLoading: false,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        listingAgentsLoading: false,
                    };
                }
            }
        }

        case Actions.SearchConsumer: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        textSearchLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { consumers } = action;
                    return {
                        ...state,
                        consumers,
                        textSearchLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        textSearchLoading: false,
                    };
                }
            }
        }

        case Actions.AddClients: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        addClientLoading: true,
                    };
                }

                case STATUS.Succeeded: {
                    const { newClient } = action;
                    return {
                        ...state,
                        newClient, //Check if dont use delete
                        addClientLoading: false,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        errors,
                        addClientLoading: false,
                    };
                }
            }
        }

        case Actions.ConnectListing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        connectLoading: true,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        connectLoading: false,
                        connectListingSucceeded: true,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        connectLoading: false,
                        connectionErrors: errors,
                    };
                }
            }
        }

        case Actions.FetchSelectedListing: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                    };
                }
                case STATUS.Succeeded: {
                    const { listings } = action;
                    return {
                        ...state,
                        loading: false,
                        listings: listings,
                    };
                }
                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        loading: false,
                        errors: errors,
                    };
                }
            }
        }

        case Actions.FetchTotalPages: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        paginationLoading: true,
                        error: '',
                    };
                }
                case STATUS.Succeeded: {
                    const { pages, totalRecords } = action;
                    return {
                        ...state,
                        paginationLoading: false,
                        totalPages: pages,
                        totalRecords,
                    };
                }
                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        paginationLoading: false,
                        error,
                    };
                }
                default:
                    return state;
            }
        }

        case Actions.StoreLockboxPhoto: {
            switch (status) {
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        lockboxImage: action.newContentData,
                    };
                }
            }
        }

        case Actions.ReserValues: {
            return {
                ...state,
                configurationDetails: {
                    listing: {},
                    pageNumber: 1,
                },
                connectListingSucceeded: false,
                connectionErrors: null,
                newClient: null,
            };
        }
        case Actions.SelectedListing: {
            return {
                ...state,
                loading: false,
                selectedListing: action.listing,
            };
        }
        case Actions.SetListingConfig: {
            const { listing, pageNumber } = action;
            return {
                ...state,
                configurationDetails: {
                    listing,
                    pageNumber,
                },
                listingLoading: false,
            };
        }
        case Actions.ChangeSelectedView: {
            const { view } = action;
            return {
                ...state,
                selectedView: view,
            };
        }

        default: {
            return state;
        }
    }
}
