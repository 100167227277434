export default (theme) => {
    return {
        breadcumbContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            ...theme.paddingVertical(20),
            ...theme.paddingHorizontal(40),

            backgroundColor: theme.palette.white,

            fontSize: 18,
        },
        breadcumbItem: {
            ...theme.typography.semiBold,
            marginRight: 12,
        },
        breadcumbItemBack: {
            cursor: 'pointer',
            color: theme.palette.blue,
            ...theme.typography.semiBold,
            marginRight: 12,
        },
        container: {
            backgroundColor: theme.palette.white,
            padding: 10,
            margin: 10,
            windth: 'auto',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        header: {
            justifyContent: 'space-between',
            alignItems: 'center',

            ...theme.paddingHorizontal(10),
        },
        filterPopUp: {
            cursor: 'pointer',
            userSelect: 'none',

            backgroundColor: theme.palette.mediumGrey,

            ...theme.marginHorizontal(10),
            ...theme.paddingHorizontal(5),
            borderRadius: 3,
        },
        userFilter: {
            listStyle: 'none',
            padding: 3,
            margin: 0,
            width: 150,
        },
        userFilterItem: {
            paddingLeft: 15,
            cursor: 'pointer',

            ...theme.paddingVertical(5),
            fontSize: 14,

            '&:hover': {
                backgroundColor: theme.palette.semiLightBlue,
            },
        },
        userMenuOptions: {
            listStyle: 'none',
            padding: 5,
            margin: 0,
            width: 150,
        },
        userMenuItem: {
            cursor: 'pointer',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            padding: 5,
            fontSize: 14,
            ...theme.typography.medium,

            '&:hover': {
                backgroundColor: theme.palette.semiLightBlue,
            },
        },
        bottomBorder: {
            borderBottom: {
                color: theme.palette.separatorGrey,
                width: 2,
                style: 'solid',
            },
        },
        searchInputContainer: {
            ...theme.marginHorizontal(30),
            width: 400,
        },
        grayLabel: {
            color: theme.palette.mediumGrey,
        },
        blueLabel: {
            color: theme.palette.blue,
        },
    };
};
