export default (theme) => {
    const border = {
        style: 'solid',
        color: theme.palette.separatorGrey,
        width: 1,
    };

    const textStyles = {
        fontFamily: 'Poppins-medium',
        fontSize: 16,
        color: theme.palette.black,
    };
    const headerStyles = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.white,
        ...theme.paddingVertical(28),
        ...theme.paddingHorizontal(48),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey,
        alignItems: 'center',
    };
    return {
        root: {
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        listingInfoBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...theme.paddingHorizontal(48),
            ...theme.paddingVertical(24),
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            backgroundColor: theme.palette.white,
            borderBottomColor: theme.palette.grey,
        },

        loaderContainer: {
            flex: 1,
            alignItems: 'center',
            paddingTop: 100,
            display: 'flex',
            flexDirection: 'column',
        },

        header: {
            ...headerStyles,
            justifyContent: 'flex-start',
            fontFamily: 'Poppins-semiBold',
            color: theme.palette.mediumGrey,
            fontSize: 18,
            alignItems: 'center',
        },
        headerItem: {
            ...theme.typography.semiBold,
            marginRight: 12,
            color: theme.palette.blue,
        },
        leftHeader: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
        },
        price: {
            ...textStyles,
            fontFamily: 'Poppins-semiBold',
            fontSize: 26,
        },
        address1: {
            ...textStyles,
            fontSize: 18,
            fontFamily: 'Poppins-semiBold',
            marginRight: 6,
        },
        address2: {
            ...textStyles,
            fontSize: 18,
            marginRight: 26,
        },
        tag: {
            backgroundColor: theme.palette.lightGreen,
            paddingLeft: 5,
            paddingRight: 5,
            fontFamily: 'Poppins-semiBold',
            borderRadius: 5,
            color: theme.palette.green,
            fontSize: 14,
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: 310,
            height: 38,
        },
        button: {
            ...theme.marginHorizontal(16),
        },

        cardsContainer: {
            // STYLE_NOTE: Headers taken into calculation of main content size
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: 10,
            ...theme.paddingHorizontal(10),

            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            marginBottom: 15,
        },
        card: {
            backgroundColor: theme.palette.white,
            height: 330,
            margin: 8,
            borderRadius: 5,
            // overflow: 'hidden',
            // ...theme.slightShadow,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
        },
        cardHeader: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 24,
            marginBottom: 5,
        },
        cardDescription: {
            fontFamily: 'Poppins-Medium',
            fontSize: 15,
            paddingRight: 50,
        },
        timeAndDate: {
            flexDirection: 'column',
            marginTop: 15,
            display: 'flex',
        },
        iconAndText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            height: 20,
            width: 20,
            marginRight: 10,
        },
        dateTimeText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
        },
        cardContent: {
            flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            ...theme.paddingHorizontal(48),
            ...theme.paddingVertical(35),
            justifyContent: 'center',
        },
        agentContent: {
            height: 330,
            display: 'flex',
            flexDirection: 'column',
        },
        cardFirstContent: { flexDirection: 'column', display: 'flex' },
        smallCardHeader: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,

            marginBottom: 8,
        },
        infoHeader: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            color: '#9a9a9a',
            marginBottom: -3,
            paddingTop: 10,
        },
        infoText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            color: theme.palette.black,
        },
        viewLocationLink: {
            fontSize: 16,
            color: theme.palette.blue,
            ...theme.typography.semiBold,
        },
        cardDescription: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            paddingRight: 50,
            wordBreak: 'break-word',
        },
        reminderHeader: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            marginBottom: 10,
        },
        reminderText: {
            fontFamily: 'Poppins-semiBold',
            fontSize: 18,
            paddingRight: 50,
            marginTop: 20,
        },
        reminderCardContent: {
            flex: 1,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            paddingLeft: 48,
            paddingTop: 50,
            justifyContent: 'flex-start',
        },
        userPhoto: {
            height: 50,
            width: 50,
            borderRadius: 60,
            marginBottom: 8,
        },
        compName: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            paddingRight: 50,
            color: '#9A9A9A',
        },

        clientTab: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 340,
            bottom: 0,
            left: 0,
            cursor: 'pointer',
            paddingLeft: 21,
            borderTop: border,
            padding: 8,
            ...theme.marginHorizontal(24),
            marginBottom: 0,
            color: '#48A4FF',
            fontSize: 12,
            paddingTop: 4,
        },
        arrow: {
            width: 14,
        },

        listDiv: {
            width: 416,
            backgroundColor: 'white',
            transition: 'top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);',
            marginLeft: 8,
            borderRadius: 5,
            marginTop: -16,
        },

        mainDiv: {
            ...theme.paddingVertical(4),
            lineHeight: 2,
        },
        name: {
            fontFamily: 'Poppins-Semibold',
            fontSize: 16,
        },
        value: {
            fontFamily: 'Poppins-Medium',
            fontSize: 12,
        },
        personalDetails: {
            marginLeft: 36,
            display: 'flex',
            flexDirection: 'row',
            lineHeight: 2,
            ...theme.marginVertical(7),
        },
        nameCity: {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 15,
            paddingTop: 11,
            alignItems: 'center',
            borderTop: border,
        },
        emailPhone: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        heading: {
            fontFamily: 'Poppins-Semibold',
            color: '#d3d3d3',
            fontSize: 14,
        },
        closeButton: {
            marginRight: 15,
            cursor: 'pointer',
        },
        lockboxPhotoContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...theme.paddingVertical(54),
        },
        lockboxPhoto: {
            borderRadius: 7.5,
        },
        feedbackDetailRow: {
            ...theme.marginHorizontal(50),
            ...theme.paddingVertical(14),
            borderBottom: '1px solid ' + theme.palette.separatorGrey,
            display: 'flex',
            alignItems: 'center',
        },
        feedbackDetailColumn: { flexDirection: 'column', display: 'flex', flex: 2 },
        feedbackSeparator: {
            height: 1,
            ...theme.marginHorizontal(7),
            borderBottom: '2px dotted ' + theme.palette.grey,
            flex: 1,
        },
        iconRow: {
            display: 'flex',
        },
        ifAgent: {
            display: 'flex',
            flexDirection: 'column',
            ...theme.slightShadow,
        },
    };
};
