import { createSelector } from 'reselect';

export const getSearchMapState = (state: any) => state.searchMap;

/*-------- Fetch GEO Listings ---------*/
export const getGeoListings = createSelector(getSearchMapState, (state: any) => state.geoListings);
export const getGeoError = createSelector(getSearchMapState, (state: any) => state.geoError);
export const isGeoLoading = createSelector(getSearchMapState, (state: any) => state.geoLoading);

/*-------- Fetch Search Listings ---------*/
export const getSearchListings = createSelector(
    getSearchMapState,
    (state: any) => state.searchListings,
);
export const getSearchError = createSelector(getSearchMapState, (state: any) => state.searchError);
export const isSearchLoading = createSelector(
    getSearchMapState,
    (state: any) => state.searchLoading,
);
