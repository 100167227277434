import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';

/*-------- Components ---------*/
import ClickableWithFeedback from '../ClickableWithFeedback';

/*-------- Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Icons -------------*/
import BedIcon from '../../images/icons/property/bed.svg';
import BathIcon from '../../images/icons/property/bath.svg';
import SqftIcon from '../../images/icons/property/sqft.svg';

/*------ Constants ---------*/

/*------------ MISC -----------*/
import { formatCurrency } from '../../utils/common';

interface Props {
    listing: any;
}

export default function TallPropertyCard(props: Props) {
    /*--------- Theme ---------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*------- Constans -------------*/
    const { listing } = props;

    /*-------- Functions ------------*/
    const IconAndText = (Icon: any, field: string, subText: string) => (
        <div className={styleSheet.iconContainer}>
            <img src={Icon} style={{ marginRight: 5 }} />
            {listing?.property[`${field}`] ?? '0'} {subText}
        </div>
    );

    return (
        <ClickableWithFeedback className={styleSheet.root}>
            <img
                src={listing.photos?.length ? listing.photos[0] : ''}
                className={styleSheet.listingImage}
                referrerPolicy={'no-referrer'}
            />
            <div className={styleSheet.addressSection}>
                {listing?.mlsList?.length && listing?.mlsList[0]?.standardStatus === 'Active' ? (
                    <div className={styleSheet.tag}>For Sale</div>
                ) : null}
                <span className={styleSheet.addressText}>
                    {'$'}
                    {formatCurrency(listing?.listPrice)}
                </span>
                <span style={{ fontSize: 14 }} className={styleSheet.addressText}>
                    {listing.address?.unit
                        ? listing.address?.full.includes(listing.address?.unit)
                            ? listing.address?.full
                            : listing.address?.full + ' ' + listing.address?.unit
                        : listing.address?.full}
                </span>
                <span className={styleSheet.cityText}>
                    {listing.address.city}, {listing.address.state} {listing.address.postalCode}
                </span>
            </div>
            <div className={styleSheet.separatorLine} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 16,
                    paddingRight: 16,
                }}
            >
                {IconAndText(BedIcon, 'bedrooms', 'bed')}
                {IconAndText(BathIcon, 'bathrooms', 'bath')}
                {IconAndText(SqftIcon, 'area', 'sqft.')}
            </div>
        </ClickableWithFeedback>
    );
}

TallPropertyCard.defaultProps = {};
