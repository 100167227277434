import { createSelector } from 'reselect';

export const getManageUsersState = (state: any) => state.manageUsers;

/*-------------- Fetch Users ----------------*/
export const getUsers = createSelector(getManageUsersState, (state: any) => state.users);
export const getTotalPages = createSelector(getManageUsersState, (state: any) => state.totalPages);
export const getCurrentPage = createSelector(
    getManageUsersState,
    (state: any) => state.currentPage,
);
export const getUserSearchText = createSelector(getManageUsersState, (state) => state.textSearch);
export const getUserSortIndex = createSelector(getManageUsersState, (state) => state.sortIndex);
export const getUserSortType = createSelector(getManageUsersState, (state) => state.sortType);

export const isUsersLoading = createSelector(getManageUsersState, (state: any) => state.loading);
export const isPaginationLoading = createSelector(
    getManageUsersState,
    (state: any) => state.paginationLoading,
);
export const getUsersError = createSelector(getManageUsersState, (state: any) => state.error);

/*------------- Create user ----------------*/
export const getCreatedUserId = createSelector(
    getManageUsersState,
    (state: any) => state.createdUserId,
);
export const getNewUserError = createSelector(
    getManageUsersState,
    (state: any) => state.newUserError,
);
export const isNewUserLoading = createSelector(
    getManageUsersState,
    (state: any) => state.newUserLoading,
);

/*-------------- Update User ----------------*/
export const isUpdatingUser = createSelector(
    getManageUsersState,
    (state: any) => state.editUserLoading,
);
export const getUpdateUserError = createSelector(
    getManageUsersState,
    (state: any) => state.editUserError,
);

/*------------- Delete User -------------------*/
export const isDeletingUser = createSelector(
    getManageUsersState,
    (state: any) => state.deleteUserLoading,
);
export const getDeleteUserError = createSelector(
    getManageUsersState,
    (state: any) => state.deleteUserError,
);

/*------------- Resend Invite -------------------*/
export const isSendingInvitation = createSelector(
    getManageUsersState,
    (state: any) => state.resendInviteLoading,
);
export const getResendInviteError = createSelector(
    getManageUsersState,
    (state: any) => state.resendInviteError,
);
