export default (theme) => {
    return {
        outerContainer: {
            width: '100%',
            fontFamily: 'Poppins',
        },
        outerContainer2: {
            padding: '20px',
        },
        innerContainer: {
            backgroundColor: 'white',
            width: '100%',
            height: 'inherit',
            borderRadius: 10,
            paddingBottom: 30,
            marginTop: 70,
        },
        height: {
            height: 200,
            paddingBottom: 20,
        },
        initialsIcon: {
            width: 120,
            height: 120,
            borderRadius: '50%',
            backgroundColor: theme.palette.lightPink,
            fontSize: 36,
            color: '#FF8238',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        initialsContainer: {
            position: 'relative',
            top: -40,
            left: 90,
            width: 'fit-content',
            height: 'fit-content',
            borderRadius: '100%',
            border: '6px solid #ffffff',
            fontFamily: 'Poppins-Medium',
        },
        nameAndBrokerage: {
            fontFamily: 'Poppins !important',
            fontSize: 32,
            fontWeight: 600,
            letterSpacing: 0,
            textAlign: 'left',
            position: 'absolute',
            top: 280,
            left: 470,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
            fontFamily: 'Poppins-Medium',
            color: '#3A3A3A',
        },
        nameAndBrokerageP: {
            color: '#BCBCBC',
            fontFamily: 'Poppins-Medium',
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left',
        },
        gradientHeader: {
            width: '100%',
            height: 100,
            backgroundImage: 'linear-gradient(91.2deg, #FF8238 0%, rgba(255, 130, 56, 0.3) 100%)',
            borderRadius: '10px 10px 0px 0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mainContainer: {
            padding: '1rem 4rem',
        },
        subtitle: {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: 3,
            letterSpacing: 0,
            textAlign: 'left',
            color: '#9A9A9A',
            borderBottom: '1px solid #E5E5E5',
            fontFamily: 'Poppins-Medium',
        },
        subtitleP: {
            color: 'black',
        },
        pointer: {
            cursor: 'pointer',
        },
        informationContainer: {
            height: '100%',
            display: 'grid',
            flexWrap: 'wrap',
            gridAutoFlow: 'row',
            color: 'black',
            textDecoration: 'none',
            gridTemplateRows: 'repeat(4, 1fr)',
            gridTemplateColumns: 'repeat(4, 1fr)',
            justifyItems: 'baseline',
        },
        information: {
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'left',
            width: '100%',
            color: theme.palette.darkGrey,
            fontFamily: 'Poppins-Medium',
        },
        strong: {
            marginRight: 20,
        },
        right: {
            textAlign: 'right',
        },
        informationSpan: {
            fontSize: 14,
            color: '#000000',
            fontFamily: 'Poppins-Medium',
        },
        informationStrong: {
            fontSize: 18,
            fontWeight: 600,
            letterSpacing: 0,
            paddingRight: 20,
            textAlign: 'right',
        },
        leftArrow: {
            padding: '0 20px',
        },
        title: {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            fontSize: 20,
            fontWeight: 'bold',
            padding: 20,
            fontFamily: 'Poppins-Medium',
            position: 'fixed',
            width: '100%',
            zIndex: 100,
        },
        back: {
            cursor: 'pointer',
            color: '#48A4FF',
        },
        tabContainer: {
            width: '100%',
            height: 50,
            backgroundColor: '#FFFFFF',
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            position: 'relative',
            top: 0,
            left: 0,
            boxSizing: 'border-box',
            padding: 30,
        },
        tabsContainer: {
            height: 'auto',
            padding: '15px 30px',
        },
        tab: {
            width: '100%',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
        },
        tabText: {
            fontFamily: 'Poppins-Medium',
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'center',
        },
        tabLine: {
            height: 3,
            backgroundColor: theme.palette.background,
            borderRadius: 10,
            position: 'relative',
            marginTop: 7,
        },
        brokerageStatus: {
            fontSize: 16,
            letterSpacing: 0,
            textAlign: 'left',
            position: 'absolute',
            top: 290,
            left: '80%',
            flexDirection: 'column',
            alignItems: 'baseline',
            textTransform: 'capitalize',
            fontFamily: 'Poppins-Medium',
        },
        status: {
            display: 'inline-block',
            height: 10,
            width: 10,
            borderRadius: 16,
            position: 'relative',
            marginRight: 10,
        },
        active: {
            color: '#fff',
            background: '#2FD2A8',
        },
        suspended: {
            color: '#fff',
            background: '#d22f2f',
        },
        none: {
            color: '#fff',
            background: '#969696',
        },
        topRow: {
            height: 20,
            fontFamily: 'Poppins-Medium',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
        },
        bottomRow: {
            fontSize: 36,
            fontWeight: 'bold',
            fontFamily: 'Poppins-Medium',
            display: 'flex',
            gap: '70px',
            height: '100%',
        },
        bottomRowItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
        },
        rowDescription: {
            fontSize: 18,
            color: '#48A4FF',
            borderTop: '1px solid #48A4FF',
            fontFamily: 'Poppins-Medium',
            fontWeight: 100,
            padding: '10px',
            width: '100%',
            textAlign: 'center',
        },
        rowElement: {
            ...theme.paddingHorizontal(5),
            ...theme.paddingVertical(10),
        },
        image: {
            width: 286,
            height: 231,
            borderLeft: '4px solid #48A4FF',
            position: 'relative',
        },
        showingsEnabled: {
            fontWeight: 'bold',
            backgroundColor: 'hsl(210, 100%, 64%, 0.15)',
            borderRadius: 5,
            margin: '10px 10px',
            textAlign: 'center',
            fontSize: '14px',
            minHeight: '26px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        showingsEnabledText: {
            color: '#48A4FF',
            fontFamily: 'Poppins-SemiBold',
        },
        showingsDisabled: {
            borderRadius: 5,
            margin: '0 10px',
            textAlign: 'center',
            backgroundColor: 'rgba(72, 164, 255, 0.15)',
            fontFamily: 'Poppins-SemiBold',
            padding: '2px 7px',
        },
        showingsDisabledText: {
            color: theme.palette.blue,
            fontFamily: 'Poppins-SemiBold',
        },
        connectedListings: {
            border: '1px solid #48A4FF',
            borderRadius: 5,
            width: 300,
            padding: 10,
        },
        connectedListingTitle: {
            fontSize: 20,
            color: '#48A4FF',
            padding: 10,
            textAlign: 'left',
        },
        connectedListingText: {
            color: '#BCBCBC',
            fontSize: 12,
        },
        unConnectedListingTitle: {
            fontSize: 20,
            color: '#BCBCBC',
            textAlign: 'left',
        },
        unConnectedListingText: {
            color: '#BCBCBC',
            fontSize: 12,
        },
        table: {
            display: 'table',
        },
        tableRow: {
            display: 'table-row',
            fontFamily: 'Poppins-Medium',
            fontWeight: 100,
        },
        tableCol: {
            display: 'table-cell',
            color: theme.palette.blue,
            fontFamily: 'Poppins-SemiBold',
        },
        link: {
            color: theme.palette.blue,
            textDecoration: 'none',
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: 500,
            fontFamily: 'Poppins-Medium',
        },
        contentContainer: {
            backgroundColor: 'white',
        },
        filterContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingTop: 17,
            paddingBottom: 17,
            borderBottom: '1px solid #e9e9e9',
        },
        firstFilter: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '30%',
        },
        searchContainer: {
            width: 452,
        },
        separator: {
            height: '3px',
            background: theme.palette.separatorGrey,
            paddingLeft: '9px',
            paddingRight: '9px',
            position: 'relative',
        },
        agentListingTable: {
            width: '100%',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderCollapse: 'collapse',
            fontFamily: 'Poppins-Medium',
        },
        listingDescriptionRoot: {
            display: 'flex',
            borderBottom: `2px solid ${theme.palette.separatorGrey}`,
        },
        listingDescriptionContainer: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '15px',
            paddingLeft: '26px',
        },
        comingSoon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Poppins-Regular',
            color: theme.palette.orange,
            gap: 12,
        },
    };
};
