import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import styles from './index.module.css';

interface Props {
    data: string[];
    selectedOption: string;
    onPressButton: Function;
    optionWidth?: number;
    passedIndex?: number;
    height?: number;
    lowerCornersSquare?: boolean;
}

const PickerComponent = (props: Props) => {
    // Use this component for any options that you must pick between
    const {
        data,
        selectedOption,
        onPressButton,
        optionWidth = 100,
        height = 25,
        lowerCornersSquare = false,
    } = props;
    const [number, setNumber] = useState<any>(data.indexOf(selectedOption));
    const selectedBarWidth = optionWidth + 20;
    const [divStyle, setDivStyle] = useSpring<any>(() => ({
        from: { x: 0, opacity: 1 },
    }));

    // useSpring does not rerender screen. If an option is selected and this component is rendered again,
    // this can be used to trigger a rerender which will show the appropriate option
    const [render, rerender] = useState<boolean>(false);

    useEffect(() => {
        setDivStyle({
            // the + 2 accounts for the divider bar adding some spacing,
            // and ensures that the selected bar will go exactly over the selected option.
            marginLeft: number * (selectedBarWidth + 2),
        });
        // re-render the component
        rerender(!render);
    }, [number]);

    // If a search menu is expanded below the component by a parent, then
    // we have the option to make the corners flush with it
    const squareCornerProps = lowerCornersSquare
        ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
          }
        : {};

    return (
        <div className={styles.filterBar} style={{ height, ...squareCornerProps }}>
            {data.map((button, index) => {
                const selected = button === selectedOption;
                return (
                    <>
                        <span
                            onClick={() => {
                                if (!selected) {
                                    onPressButton(button);
                                }
                                setNumber(index);
                            }}
                            className={styles.displayText}
                            style={{ width: optionWidth }}
                        >
                            {button}
                        </span>

                        {index !== data.length - 1 && (
                            <div
                                className={styles.divider}
                                style={{
                                    // logic for when the divider should be seen.
                                    backgroundColor:
                                        data[index + 1] !== selectedOption && !selected
                                            ? '#d3d3d3'
                                            : '#e9e9e9',
                                    height: height - 11,
                                }}
                            />
                        )}
                    </>
                );
            })}
            <animated.div
                className={styles.selectedBar}
                style={{ ...divStyle, width: selectedBarWidth, height }}
            />
        </div>
    );
};
export default PickerComponent;
