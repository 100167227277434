import { Listing } from '../../../utils/constants';

export const getUnverifiedLink = (listing: Listing) => {
    //returns the unverified link if the listing has one, returns null otherwise
    if (listing && listing.agentListing?.approvalSettings?.unverified?.allowed) {
        return `${process.env.REACT_APP_UNVERIFIED_URL}${listing.agentListing?.approvalSettings?.unverified?.hiddenId}`;
    }

    return '';
};
