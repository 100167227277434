/*----- Redux imports -------*/
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ClientActions from './actions';
import { callStitchFunction, generateSignature } from '../../../store/api/sagas';

/*------ Constants -----------*/
import { STATUS } from '../../../utils/constants';

/*----- Utils imports -------*/
import { formatPhoneNumberForDisplay, parseStitchServiceError } from '../../../utils/common';

/*----- Misc imports -------*/

export function* createClient({
    agentId,
    firstName,
    lastName,
    phoneNumber,
    callback,
}: any): Generator<any, any, any> {
    try {
        const client = {
            firstName,
            lastName,
            phoneNumber,
            displayPhone: formatPhoneNumberForDisplay(phoneNumber),
        };
        const { clientsAdded = 0, clientsFailed = 0 } = yield call(
            callStitchFunction,
            'addClients',
            [client], // Valid Numbers
            [], // Invalid Numbers
            agentId,
        );

        if (clientsAdded) {
            yield put(ClientActions.createAgentClientSucceeded());
            if (callback) {
                callback();
            }
        } else {
            yield put(ClientActions.createAgentClientFailed('Client could not be created.'));
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ClientActions.createAgentClientFailed(errorMessage));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === ClientActions.Actions.CreateAgentClient &&
                action.status === STATUS.Requested,
            createClient,
        ),
    ]);
}
