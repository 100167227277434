import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ProfileActions from './actions';
import {
    callStitchFunction,
    findRecord,
    findRecords,
    generateSignature,
    getBrokerageUserRecord,
} from '../../../store/api/sagas';
import { getStitchUser } from '../../../utils';
import { STATUS } from '../../auth/types';
import { parseStitchServiceError } from '../../../utils/common';
import { BROKERAGES_COLLECTION } from '../../../store/api/constants';

export function* fetchProfileData(): Generator<any, any, any> {
    try {
        const user = yield select(getStitchUser);
        const profileData = yield call(getBrokerageUserRecord, user.id);

        if (profileData) {
            yield put(ProfileActions.getProfileDataSucceeded(profileData));

            // TO DO: Search correct brokerage data!
            yield put(ProfileActions.fetchSelectedBrokerageRequested(profileData.brokerageId));
        } else {
            const errMsg = 'Could not fetch data, please try again';
            yield put(ProfileActions.getProfileDataFailed(errMsg));
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(ProfileActions.fetchSelectedBrokerageFailed(err));
    }
}

export function* fetchBrokerage({ brokerageId }: any): Generator<any, any, any> {
    try {
        const brokerageObject = yield call(findRecord, BROKERAGES_COLLECTION, {
            _id: brokerageId,
        });

        yield put(ProfileActions.fetchSelectedBrokerageSucceeded(brokerageObject));
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(ProfileActions.fetchSelectedBrokerageFailed(err));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === ProfileActions.PROFILE_ACTIONS.Fetch &&
                action.status === STATUS.Requested,
            fetchProfileData,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ProfileActions.PROFILE_ACTIONS.FetchSelectedBrokerage &&
                action.status === STATUS.Requested,
            fetchBrokerage,
        ),
    ]);
}
