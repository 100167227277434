import { BSON } from 'realm-web';
import { noop } from '../../../utils/common';
import { BrokerageUserRole, STATUS } from '../../../utils/constants';
import { UserFormValues } from './EditUserModal/types';
import { TablePage } from './types';
import { SortTypeEnum } from '../../../components/DataTable/types';

export enum Actions {
    FetchUsers = '[ManageUsers] Fetch',
    InviteUser = '[ManageUsers] Invite',
    UpdateUser = '[ManageUsers] Update',
    DeleteUser = '[ManageUsers] Delete',
    ResendInvite = '[ManageUsers] Resend Invite',
}

/*----------- Fetch Users -----------------*/

export function FetchUsersRequested(
    brokerageId: BSON.ObjectId,
    pageNumber: number,
    forceReload?: boolean,
    search?: string,
    sort?: Object,
    sortIndex?: number,
    sortType?: SortTypeEnum,
) {
    return {
        type: Actions.FetchUsers,
        status: STATUS.Requested,
        brokerageId,
        pageNumber,
        forceReload,
        search,
        sort,
        sortIndex,
        sortType,
    };
}

export function FetchUsersSucceeded(
    users: TablePage[],
    currentPage: number,
    totalPages: number,
    textSearch?: string,
    sort?: any,
    sortIndex?: number,
    sortType?: SortTypeEnum,
) {
    return {
        type: Actions.FetchUsers,
        status: STATUS.Succeeded,
        users,
        currentPage,
        totalPages,
        textSearch,
        sort,
        sortIndex,
        sortType,
    };
}

export function FetchUsersFailed(error: any) {
    return {
        type: Actions.FetchUsers,
        status: STATUS.Failed,
        error,
    };
}

/*----------- Invite new user -----------------*/

export function InviteUserRequested(
    brokerageId: BSON.ObjectId,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    role: BrokerageUserRole,
) {
    return {
        type: Actions.InviteUser,
        status: STATUS.Requested,
        brokerageId,
        firstName,
        lastName,
        email,
        phoneNumber,
        role,
    };
}

export function InviteUserSucceeded(userId: BSON.ObjectId) {
    return {
        type: Actions.InviteUser,
        status: STATUS.Succeeded,
        userId,
    };
}

export function InviteUserFailed(error: string) {
    return {
        type: Actions.InviteUser,
        status: STATUS.Failed,
        error,
    };
}

export function InviteUserClean() {
    return {
        type: Actions.InviteUser,
        status: STATUS.Clean,
    };
}

/*--------------- Update --------------*/

export function UpdateUserRequested(
    userId: BSON.ObjectId,
    values: UserFormValues,
    callback: Function = noop,
) {
    return {
        type: Actions.UpdateUser,
        status: STATUS.Requested,
        userId,
        values,
        callback,
    };
}

export function UpdateUserSucceeded() {
    return {
        type: Actions.UpdateUser,
        status: STATUS.Succeeded,
    };
}

export function UpdateUserFailed(error: string) {
    return {
        type: Actions.UpdateUser,
        status: STATUS.Failed,
        error,
    };
}

/*--------------- Delete ---------------*/

export function DeleteUserRequested(userId: BSON.ObjectId, callback: Function = noop) {
    return {
        type: Actions.DeleteUser,
        status: STATUS.Requested,
        userId,
        callback,
    };
}

export function DeleteUserSucceeded() {
    return {
        type: Actions.DeleteUser,
        status: STATUS.Succeeded,
    };
}

export function DeleteUserFailed(error: string) {
    return {
        type: Actions.DeleteUser,
        status: STATUS.Failed,
        error,
    };
}

/*------------ Resend invite ----------*/

export function ResendInviteRequested(userId: BSON.ObjectId, callback: Function = noop) {
    return {
        type: Actions.ResendInvite,
        status: STATUS.Requested,
        userId,
        callback,
    };
}

export function ResendInviteSucceeded() {
    return {
        type: Actions.ResendInvite,
        status: STATUS.Succeeded,
    };
}

export function ResendInviteFailed(error: string) {
    return {
        type: Actions.ResendInvite,
        status: STATUS.Failed,
        error,
    };
}
