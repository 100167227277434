export const noticeRequired: number[] = [];

for (let i = 0; i <= 1440; i += 15) {
    noticeRequired.push(i);
}

export const maxDuration: number[] = [];

for (let i = 15; i <= 120; i += 15) {
    maxDuration.push(i);
}

export const transformUser = (user: any, userType: string) => {
    const obj: any = {
        _id: user._id,
        canApprove: false,
        notificationSettings: ['text'],
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        email: user.email || '',
        profilePhotoUpload: user.profilePhotoUpload || null,
    };

    if (userType === 'agents') {
        obj.type = 'Co-Listing Agent';
    }
    return obj;
};

export const formatTimes = (h: number, m: number | string) => {
    let suffix = 'am';
    if (m === 0) {
        m = '00';
    }
    if (h >= 12) {
        suffix = 'pm';
        if (h > 12) {
            h -= 12;
        }
    }
    return `${h}:${m}${suffix}`;
};
// just switched the end time to 23
export const getRestrictionTimes = (type: 'start' | 'end') => {
    let restrictionTimes = [];
    let firstTime = type === 'start' ? 7 : 8;
    let endTime = type === 'start' ? 22 : 23;
    for (let i = firstTime; i <= endTime; i++) {
        restrictionTimes.push({ hours: i, minutes: 0, formattedTime: formatTimes(i, 0) });
        if (i !== endTime) {
            for (let j = 15; j <= 45; j += 15) {
                restrictionTimes.push({ hours: i, minutes: j, formattedTime: formatTimes(i, j) });
            }
        }
    }
    return restrictionTimes;
};
