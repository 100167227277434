/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

/*-------- Components ---------*/
import Tag from '../../../../components/Tag';
import Typography from '../../../../components/Typography';
import RadioButtons from './common/RadioButtons';
import ConfigureListingModule from './common/ConfigureListingModule';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';

/*-------- Icons ---------*/
import ExclaimIcon from '../../../../images/icons/exclaim.svg';
import ImageUploader from '../../../../components/ImageUploader';
import SuccessIcon from '../../../../images/icons/successCheck.svg';

interface Props {
    agentListing: any;
    setAgentListing: any;
    storeLockboxPhotoRequested: Function;
}
const PageTwo = ({ agentListing, setAgentListing, storeLockboxPhotoRequested }: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Constants ---------*/
    const MAX_LENGTH = 180;
    const lockType = [
        {
            label: 'Lockbox',
            value: 'Lockbox',
        },
        {
            label: 'No Lockbox',
            value: 'No Lockbox',
        },
        {
            label: 'Smart Lock (Coming Soon)',
            value: 'Smart Lock ',
            disabled: true,
        },
    ];
    const lockBrands = [
        {
            label: 'Manual',
            value: 'Other',
            extraLabelContent: (
                <>
                    {agentListing.lockboxType === 'Other' ? (
                        <>
                            <input
                                disabled={agentListing.lockboxType !== 'Other'}
                                onChange={(e) => {
                                    setAgentListing({ ...agentListing, lockCombo: e.target.value });
                                }}
                                value={agentListing.lockCombo}
                                className={styleSheet.lockCombo}
                                placeholder={'Enter Lockbox Code'}
                            />
                            {agentListing?.lockboxType === 'Other' && (
                                <Tag
                                    onClick={() => {}}
                                    text={'Required'}
                                    background={true}
                                    color={!agentListing.lockCombo?.length ? 'red' : 'green'}
                                    Icon={
                                        !agentListing.lockCombo?.length ? ExclaimIcon : SuccessIcon
                                    }
                                />
                            )}
                        </>
                    ) : null}
                </>
            ),
        },
        {
            label: 'Supra',
            value: 'Supra',
            extraLabelContent: (
                <>
                    {agentListing.lockboxType === 'Supra' ? (
                        <Typography
                            textStyle={'b2'}
                            style={{ marginLeft: 16 }}
                            color={indexTheme.palette.mediumGrey}
                        >
                            Showingly will direct buyers agents to use the Supra eKey app when they
                            arrive at the property.
                        </Typography>
                    ) : null}
                </>
            ),
        },
        {
            label: 'SentriLock',
            value: 'SentriLock',
            extraLabelContent: (
                <>
                    {agentListing.lockboxType === 'SentriLock' ? (
                        <Typography
                            textStyle={'b2'}
                            style={{ marginLeft: 16 }}
                            color={indexTheme.palette.mediumGrey}
                        >
                            Showingly will direct buyers agents to use the SentriLock app when they
                            arrive at the property.
                        </Typography>
                    ) : null}
                </>
            ),
        },
    ];

    /*-------- States ---------*/
    const [lockboxInfo, setLockboxInfo] = useState({});

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (agentListing.lockboxType !== 'None') {
            setLockboxInfo({
                lockboxType: agentListing.lockboxType,
                lockCombo: agentListing.lockCombo,
                lockboxLocation: agentListing.lockboxLocation,
                lockboxPhotoUpload: agentListing.lockboxPhotoUpload,
            });
        }
    }, [
        agentListing.lockboxType,
        agentListing.lockCombo,
        agentListing.lockboxLocation,
        agentListing.lockboxPhotoUpload,
    ]);

    return (
        <div className={styleSheet.root}>
            <ConfigureListingModule title={'Lock Type'}>
                <RadioButtons
                    data={lockType}
                    selectedValue={agentListing.lockboxType === 'None' ? 'No Lockbox' : 'Lockbox'}
                    onChangeValue={(value: string) => {
                        if (value === 'No Lockbox') {
                            setAgentListing({
                                ...agentListing,
                                lockboxType: 'None',
                                lockCombo: '',
                                lockboxLocation: '',
                                lockboxPhotoUpload: {
                                    as: '',
                                    path: '',
                                    uri: '',
                                },
                            });
                        } else {
                            setAgentListing({
                                ...agentListing,
                                ...lockboxInfo,
                            });
                        }
                    }}
                />
            </ConfigureListingModule>
            {agentListing.lockboxType !== 'None' && (
                <>
                    <ConfigureListingModule title={'Brand'}>
                        <RadioButtons
                            data={lockBrands}
                            selectedValue={agentListing.lockboxType}
                            onChangeValue={(value: string) => {
                                setAgentListing({
                                    ...agentListing,
                                    lockboxType: value,
                                    lockCombo: value !== 'Other' ? '' : agentListing.lockCombo,
                                });
                            }}
                        />
                    </ConfigureListingModule>
                    <ConfigureListingModule
                        title={'Lockbox Location'}
                        titleIcon={
                            <>
                                <div className={styleSheet.verticalSeparator} />
                                <CircularProgressbar
                                    className={styleSheet.progressBar}
                                    value={
                                        (agentListing.lockboxLocation?.length / MAX_LENGTH) * 100
                                    }
                                    strokeWidth={15}
                                    styles={buildStyles({
                                        pathColor: indexTheme.palette.blue,
                                        trailColor: indexTheme.palette.grey,
                                    })}
                                />
                            </>
                        }
                        required
                        fulfilled={agentListing.lockboxLocation?.length}
                    >
                        <textarea
                            className={styleSheet.inputBox}
                            onChange={(e) => {
                                setAgentListing({
                                    ...agentListing,
                                    lockboxLocation: e.target.value,
                                });
                            }}
                            value={agentListing.lockboxLocation}
                            placeholder={'Describe where the showing agent can find the lockbox.'}
                            draggable={false}
                            maxLength={MAX_LENGTH}
                        />
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                marginLeft: 15,
                            }}
                        >
                            <ImageUploader
                                clearUploadText
                                handleOnImageUpload={(v: any) => {
                                    storeLockboxPhotoRequested(v);
                                }}
                                value={agentListing.lockboxPhotoUpload}
                                onRemove={() =>
                                    setAgentListing({
                                        ...agentListing,
                                        lockboxPhotoUpload: {
                                            as: '',
                                            path: '',
                                            uri: '',
                                        },
                                    })
                                }
                            />
                        </div>
                    </ConfigureListingModule>
                </>
            )}

            <ConfigureListingModule
                title={'Showing Instructions'}
                titleIcon={
                    <>
                        <div className={styleSheet.verticalSeparator} />
                        <CircularProgressbar
                            className={styleSheet.progressBar}
                            value={(agentListing.showingInstructions?.length / MAX_LENGTH) * 100}
                            strokeWidth={15}
                            styles={buildStyles({
                                pathColor: indexTheme.palette.blue,
                                trailColor: indexTheme.palette.grey,
                            })}
                        />
                    </>
                }
                optional
            >
                <textarea
                    className={styleSheet.inputBox}
                    onChange={(e) => {
                        setAgentListing({ ...agentListing, showingInstructions: e.target.value });
                    }}
                    value={agentListing.showingInstructions}
                    placeholder={
                        'Let the showing agent know if there are any specific instructions you need them to follow for this showing.'
                    }
                    draggable={false}
                    maxLength={MAX_LENGTH}
                />
            </ConfigureListingModule>
        </div>
    );
};
export default PageTwo;
