import { createSelector } from 'reselect';

export const getAuthState = (state: any) => state.auth || {};

export const isLoginInProgress = createSelector(getAuthState, (auth) => auth.loginInProgress);

export const getAccounts = createSelector(getAuthState, (auth) => auth.accounts);

export const isAuthenticated = createSelector(getAuthState, (auth) => auth.isAuthenticated);

export const isAuthLoading = createSelector(getAuthState, (auth) => auth.loading);

export const getClient = createSelector(getAuthState, (auth) => auth.client);

export const getPosibleClient = createSelector(getAuthState, (auth) => auth.posibleClient);

export const getErrors = createSelector(getAuthState, (auth) => auth.errors);

export const isInitializing = createSelector(getAuthState, (auth) => auth.initializing);

export const getBrokerages = createSelector(getAuthState, (auth) => auth.brokerages);
