export default (theme) => {
    return {
        root: {
            backgroundColor: theme.palette.white,
            height: 305,
            width: 400,
            borderRadius: 5,
            overflow: 'hidden',
            shadowColor: 'black',
            shadowOffset: { width: 0, height: 5 },
            shadowOpacity: 0.15,
            shadowRadius: 15,
            elevation: 2,
        },
        addressText: {
            ...theme.typography.semiBold,
            fontSize: 16,
            color: theme.palette.black,
            paddingLeft: 16,
        },
        cityText: {
            fontSize: 14,
            color: theme.palette.black,
            paddingLeft: 16,
        },
        listingImage: {
            width: '100%',
            height: 180,
        },
        addressSection: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        separatorLine: {
            height: 1,
            backgroundColor: theme.palette.grey,
            marginLeft: 16,
            marginRight: 16,
            marginTop: 10,
            marginBottom: 10,
            borderRadius: 5,
        },
        iconContainer: {
            color: theme.palette.mediumGrey,
            fontSize: 14,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        tag: {
            alignSelf: 'flex-end',
            marginRight: 16,
            backgroundColor: 'rgba(47, 210, 168, 0.15)',
            paddingLeft: 5,
            paddingRight: 5,
            ...theme.typography.semiBold,
            borderRadius: 5,
            color: theme.palette.green,
            fontSize: 12,
            marginBottom: -20,
        },
    };
};
