import { ANIMATED_DRAWER_WIDTH } from '../../../../components/AnimatedDrawer';
import { isMobile } from '../../../../utils/common';
import { UNAVAILABLE_SLOT_BORDER_WIDTH } from '../constants';

export default (theme) => {
    return {
        headerContainer: {
            height: 86,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            ...theme.marginHorizontal(48),
        },
        closeIconContainer: {
            position: 'absolute',
            right: 26.91,
            top: 26.23,
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
        },
        drawerHeaderText: {
            fontSize: 18,
            color: theme.palette.mediumGrey,
            paddingTop: 27,
        },

        dateAndTime: {
            display: 'flex',
            flexDirection: 'row',
            ...theme.paddingHorizontal(48),
            justifyContent: 'space-between',
        },
        dateAndTimeMobile: {
            display: 'block',
            flexDirection: 'row',
            ...theme.paddingHorizontal(24),
            justifyContent: 'space-between',
        },
        calendarContainer: {
            width: 236,
            height: 297, // wireframe 347
        },
        calendarContainerMobile: {
            width: '100%',
            height: 297, // wireframe 347
        },
        timeClientContainer: {
            width: 257,
            height: 297, // wireframe 347
        },
        timeClientContainerMobile: {
            width: '100%',
            height: 297, // wireframe 347
        },
        timeSelectionTitle: {
            ...theme.typography.semiBold,
            color: theme.palette.mediumGrey,
            fontSize: 16,

            paddingTop: 5, // wireframe 20
            height: 20,
        },
        timeSelector: {
            marginTop: 10, // wireframe 20
            marginBottom: 10, // wireframe 26
        },
        timeSelectorMobile: {
            marginTop: 15, // wireframe 20
            marginBottom: 10, // wireframe 26
            padding: '0 20px',
        },
        addClientAgentButton: {
            ...theme.typography.semiBold,
            color: theme.palette.blue,
            fontSize: 16,

            height: 17, // wireframe 20
            paddingTop: 7, // wireframe 16
        },

        calendarScrollContainer: {
            backgroundColor: theme.palette.background,
            paddingTop: 10,
            overflowY: 'scroll',
            ...theme.marginHorizontal(48),
            paddingLeft: 48,
            display: 'flex',
            flexDirection: 'row',
            borderWidth: 0,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: theme.palette.grey,
            borderStyle: 'solid',
        },
        timeHeight: {
            height: 25,
            display: 'flex',
            alignItems: 'center',
            width: 50,
        },
        smallHash: {
            backgroundColor: theme.palette.mediumGrey,
            width: 6,
            height: 2,
            borderRadius: 20,
        },
        bigHash: {
            backgroundColor: theme.palette.mediumGrey,
            width: 11,
            height: 2,
            borderRadius: 20,
        },
        boldTime: {
            fontSize: 14,
            color: theme.palette.mediumGrey,
        },

        scheduleButtonsContainer: {
            position: 'fixed',
            bottom: 0,
            width: 637,
            height: 68, // wireframe 88
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        scheduleButtonsContainerMobile: {
            width: 'auto',
            height: 68,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        },
        availabilitySlots: {
            width: 395,
            position: 'relative',
        },
        hourBarContainer: {
            marginTop: 14,
        },
        hourBar: {
            height: 2,
            backgroundColor: theme.palette.dividerGrey,
            width: 395,
            marginBottom: 100,
            marginTop: -2,
        },

        thisShowingContainer: {
            width: 343,
            position: 'absolute',
            zIndex: 9,
            backgroundColor: theme.palette.white,
            ...theme.cardShadow,
            borderWidth: 0,
            borderLeftWidth: 4,
            borderStyle: 'solid',
            borderRadius: 1,
        },
        agentScheduleTimeContainer: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        thisShowingCardText: {
            color: theme.palette.orange,
            ...theme.typography.medium,
            fontSize: 12,
            marginLeft: 6,
            marginTop: 1,
            height: 18,
        },
        thisShowingCardDateText: {
            color: theme.palette.orange,
            ...theme.typography.medium,
            fontSize: 12,
        },

        unavailableSlot: {
            width: 395 - 2 * UNAVAILABLE_SLOT_BORDER_WIDTH,
            position: 'absolute',
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            border: {
                radius: 1,
                width: UNAVAILABLE_SLOT_BORDER_WIDTH,
                color: theme.palette.mediumGrey,
                style: 'solid',
            },
        },

        lightCloseIcon: {
            marginLeft: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        allClients: {
            display: 'flex',
            marginTop: 13,
        },
        clientCard: {
            backgroundColor: theme.palette.background,
            display: 'flex',
            flexDirection: 'row',
            padding: {
                left: 8,
                right: 14,
            },
            height: 26,
            alignItems: 'center',
            justifyContent: 'center',
            border: {
                width: 0,
                radius: 18,
            },
        },
        buttonsRow: {
            display: 'flex',
            width: isMobile() ? 'auto' : ANIMATED_DRAWER_WIDTH - 210,
            justifyContent: 'center',
        },
    };
};
