import React, { useState } from 'react';
/*-------- Components ---------*/
import { useHistory } from 'react-router-dom';
import ButtonComponent from '../../../components/ButtonComponent';
import MultipleBrokerage from '../MultipleBrokerage';

/*-------- Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';
import { logInRequested, searchEmailRequested } from '../actions';
import { isLoginInProgress, getPosibleClient, getErrors, getBrokerages } from '../selectors';
import { BrokerageUser } from '../../../utils/constants';

/*------ Constants ---------*/

interface Props {}

export default function LoginPage(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Selectors -------*/
    const user: BrokerageUser = useSelector(getPosibleClient);
    const error: [string] = useSelector(getErrors);
    const loginInProgress: boolean = useSelector(isLoginInProgress);
    const brokerages = useSelector(getBrokerages);

    /*----- States -------*/
    // Indicates the placeholder for the Email Input
    const [emailPHText, setEmailPHText] = useState<string>('Enter Email');

    // Indicates the placeholder for the password Input
    const [pwdPHText, setPwdPHText] = useState<string>('Enter Password');

    // Indicates the value for the Email Input
    const [emailText, setEmailText] = useState<string>('');

    // Indicates the value for the password Input
    const [pwdText, setPwdText] = useState<string>('');

    /*----- Constants -------*/
    const history = useHistory();
    const dispatch = useDispatch();
    const hasErrors =
        error.length > 0 && error[0] !== 'SomethingGoesWrong' && error[0] !== 'LoginFail';

    /*----- Functions -------*/
    const logIn = () => {
        dispatch(logInRequested(emailText, pwdText));
    };
    const searchUser = () => {
        dispatch(searchEmailRequested(emailText));
    };

    return (
        <div className={styleSheet.root}>
            <h1 className={styleSheet.title}>Welcome to Brokerage Suite.</h1>
            <div className={styleSheet.content}>
                {!user ? (
                    <>
                        <span className={styleSheet.text}>Please Enter your Email</span>
                        <input
                            placeholder={emailPHText}
                            id="email"
                            type="email"
                            autoComplete={'email'}
                            onBlurCapture={() => {
                                setTimeout(() => {
                                    setEmailPHText('Enter Email');
                                }, 100);
                            }}
                            onFocus={() => {
                                setEmailPHText('');
                            }}
                            value={emailText}
                            onChange={(e: any) => {
                                setEmailText(e?.nativeEvent?.target?.value);
                            }}
                            className={styleSheet.input}
                            style={{ width: 516, textAlign: 'center' }}
                        />
                        {error[0] === 'NoExistedUser' && (
                            <span className={styleSheet.error}>
                                {' '}
                                We couldn't find an account with this email. Please contact us
                                bellow to set up your brokerage suite ccount{' '}
                            </span>
                        )}
                        <ButtonComponent
                            onClick={() => {
                                searchUser();
                            }}
                            disabled={loginInProgress}
                            fill
                            width={257}
                            className={styleSheet.buttonLogin}
                        >
                            Next
                        </ButtonComponent>
                    </>
                ) : user.stitchUserId ? (
                    <>
                        <span className={styleSheet.pwdNamwText}>
                            {' '}
                            Signing in as: {user.firstName} {user.lastName}{' '}
                        </span>
                        <span className={styleSheet.pwdText}> Please Enter your Password</span>
                        <input
                            placeholder={pwdPHText}
                            id="pass"
                            type="password"
                            autoComplete={'new-password'}
                            onBlurCapture={() => {
                                setTimeout(() => {
                                    setPwdPHText('Enter Password');
                                }, 100);
                            }}
                            onFocus={() => {
                                setPwdPHText('');
                            }}
                            value={pwdText}
                            onChange={(e: any) => {
                                setPwdText(e?.nativeEvent?.target?.value);
                            }}
                            className={styleSheet.input}
                            style={{ width: 516, textAlign: 'center' }}
                        />
                        {error.length > 0 && (
                            <span className={styleSheet.error}>
                                {error[0] === 'BrokerageDontHaveAccess'
                                    ? `Your current brokerage don't have access to Brokerage Suite. Please contact us bellow to set up your brokerage suite ccount`
                                    : error[0] === 'SomethingGoesWrong'
                                    ? 'Something went wrong. Please try again'
                                    : ''}
                            </span>
                        )}
                        <ButtonComponent
                            onClick={() => {
                                logIn();
                            }}
                            disabled={loginInProgress || hasErrors}
                            fill
                            width={257}
                            className={styleSheet.buttonLogin}
                        >
                            {loginInProgress ? 'Logging In...' : 'Log In'}
                        </ButtonComponent>
                        <p
                            className={styleSheet.blueText}
                            onClick={() => {
                                history.push('/auth/verify');
                            }}
                        >
                            {' '}
                            Reset Password{' '}
                        </p>
                    </>
                ) : (
                    <>{history.push('/auth/verify')}</>
                )}
            </div>
        </div>
    );
}

LoginPage.defaultProps = {};
