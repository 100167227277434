import { createSelector } from 'reselect';

export const getListingState = (state: any) => state.listings || {};

export const isRequestInProgress = createSelector(
    getListingState,
    (listings) => listings.isRequestInProgress,
);

export const getAllListings = createSelector(getListingState, (listings) => listings.listings);

export const getTotalRecords = createSelector(getListingState, (listings) => listings.totalRecords);

/*----- Configure Listing -----*/
export const getSearchedAgents = createSelector(getListingState, (listings) => listings.agents);

export const getSearchedConsumers = createSelector(
    getListingState,
    (listings) => listings.consumers,
);

export const getAddClientLoading = createSelector(
    getListingState,
    (listings) => listings.addClientLoading,
);

export const getNewClient = createSelector(getListingState, (listings) => listings.newClient);

export const getConfigurationDetails = createSelector(
    getListingState,
    (listings) => listings.configurationDetails,
);

export const getConnectionErrors = createSelector(
    getListingState,
    (listings) => listings.connectionErrors,
);

export const getConnectionSuccess = createSelector(
    getListingState,
    (listings) => listings.connectListingSucceeded,
);

export const getConnectLoading = createSelector(
    getListingState,
    (listings) => listings.connectLoading,
);

export const getLockboxImage = createSelector(getListingState, (listings) => listings.lockboxImage);

export const getSelectedView = createSelector(getListingState, (listings) => listings.selectedView);

export const getListings = createSelector(getListingState, (listings) => listings.listings);

export const isConnectionLoading = createSelector(
    getListingState,
    (listings) => listings.connectLoading,
);

/*------ Fetch listing ---------*/
export const getListing = createSelector(getListingState, (state) => state.listing);

export const getListingError = createSelector(getListingState, (state) => state.listingError);

export const isListingLoading = createSelector(getListingState, (state) => state.listingLoading);

export const getTotalPages = createSelector(getListingState, (state: any) => state.totalPages);

export const getCurrentPage = createSelector(getListingState, (state: any) => state.currentPage);

export const getListingSearchText = createSelector(getListingState, (state) => state.textSearch);
export const getListingSortIndex = createSelector(getListingState, (state) => state.sortIndex);
export const getListingSortType = createSelector(getListingState, (state) => state.sortType);

export const getGlobalRecords = createSelector(
    getListingState,
    (state: any) => state.globalRecords,
);

export const isPaginationLoading = createSelector(
    getListingState,
    (state: any) => state.paginationLoading,
);

export const islistingAgentsLoading = createSelector(
    getListingState,
    (state: any) => state.listingAgentsLoading,
);
