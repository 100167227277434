/*----- Redux imports -------*/
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as ShowingsActions from './actions';
import { useSelector } from 'react-redux';

/*----- Utils imports -------*/

/*----- Misc imports -------*/
import { STATUS } from '../../../utils/constants';
import {
    createShowingFeedback,
    findRecord,
    findShowings,
    updateShowingRequest,
} from '../../../store/api/sagas';
import { SHOWING_REQUESTS } from '../../../store/api/constants';

console.log('in saga');

export function* fetchShowings(): Generator<any, any, any> {
    try {
        const showings = yield findShowings();
        yield put(ShowingsActions.fetchShowingsSucceeded(showings));
    } catch (error) {
        yield put(ShowingsActions.fetchShowingsFailed(error));
    }
}

export function* fetchShowingDetails({ showingId = null }: any): Generator<any, any, any> {
    try {
        const showingDetails = yield call(findRecord, SHOWING_REQUESTS, { _id: showingId });
        yield put(ShowingsActions.fetchShowingDetailsSucceeded(showingDetails));
    } catch (e) {
        yield put(ShowingsActions.fetchShowingDetailsFailed(e));
    }
}

export function* changeShowingStatus({
    id,
    showingStatus,
    denialReason = null,
    isUnverified = false,
    sendLockInfo = false,
}: any): Generator<any, any, any> {
    try {
        const { upsertedId, modifiedCount } = yield call(
            updateShowingRequest,
            {
                id,
                status: showingStatus,
                ...(denialReason ? { denialReason } : null),
            },
            {
                isUnverifiedApproval: isUnverified,
                sendLockInfo: sendLockInfo,
            },
        );
        if (upsertedId || modifiedCount) {
            yield put(ShowingsActions.changeShowingStatusSucceeded());
            yield put(ShowingsActions.fetchShowingDetailsRequested(id));
            yield put(ShowingsActions.fetchShowingsRequested());
        } else {
            yield put(ShowingsActions.changeShowingStatusFailed('Could not update showing'));
        }
    } catch (err) {
        yield put(ShowingsActions.changeShowingStatusFailed(err));
    }
}

export function* submitFeedback({
    priceAccuracyRating,
    locationRating,
    interestLevel,
    reviewText,
    showingId,
}: any): Generator<any, any, any> {
    try {
        const feedbackToSubmit = {
            ratings: {
                priceAccuracy: priceAccuracyRating,
                location: locationRating,
                interestLevel: interestLevel,
            },
            review: reviewText,
        };
        const { upsertedId, modifiedCount } = yield call(
            createShowingFeedback,
            showingId,
            feedbackToSubmit,
        );
        if (upsertedId || modifiedCount) {
            yield put(ShowingsActions.submitFeedbackSucceeded());
            yield put(ShowingsActions.fetchShowingDetailsRequested(showingId));
        } else {
            yield put(ShowingsActions.submitFeedbackFailed('Could not submit feedback'));
        }
    } catch (err: any) {
        yield put(ShowingsActions.submitFeedbackFailed(err));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === ShowingsActions.SHOWINGS_ACTION.Fetch &&
                action.status === STATUS.Requested,
            fetchShowings,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingsActions.SHOWINGS_ACTION.FetchDetails &&
                action.status === STATUS.Requested,
            fetchShowingDetails,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingsActions.SHOWINGS_ACTION.UpdateShowingStatus &&
                action.status === STATUS.Requested,
            changeShowingStatus,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ShowingsActions.SHOWINGS_ACTION.SubmitFeedback &&
                action.status === STATUS.Requested,
            submitFeedback,
        ),
    ]);
}
