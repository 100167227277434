/*----- Redux imports -------*/
import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import * as ListingActions from './actions';
import * as ListingSelectors from './selectors';
import { getAgentRecord, findRecord, callStitchFunction } from '../../../store/api/sagas';
import { getProfileData, getSelectedBrokerage } from '../Profile/selectors';

/*----- Utils imports -------*/
import { getStitchUser } from '../../../utils/storage';
import { transformUser } from './ConfigureListing/utils';
import { LISTINGS_COLLECTION, CONSUMERS } from '../../../store/api/constants';
import {
    parseStitchServiceError,
    formatPhoneNumberToIntl,
    imageToBase64,
} from '../../../utils/common';

/*------ Constants -----------*/
import { ITEMS_PER_PAGE } from './constants';
import { AGENTS } from '../../../store/api/constants';

/*----- Misc imports -------*/
import { TablePage } from './types';
import { STATUS } from '../../../utils/constants';
import { getListings, getTotalPages, getGlobalRecords } from './selectors';
import { SortTypeEnum } from '../../../components/DataTable/types';

export function* getAll({
    pageNumber,
    forceReload = false,
    textSearch = '',
    sort = null,
    sortIndex = 0,
    sortType = SortTypeEnum.ASC,
}: ReturnType<typeof ListingActions.fetchListingsRequested>): Generator<any, any, any> {
    try {
        const brokerage = yield select(getSelectedBrokerage);
        let listings: TablePage[] = yield select(getListings);
        let totalPages: number = yield select(getTotalPages);
        let globalRecords: number = yield select(getGlobalRecords);
        const existingIndex = listings.findIndex((e) => e.page === pageNumber);

        if (forceReload || existingIndex === -1) {
            // Fetch requested page
            const response = yield call(
                callStitchFunction,
                'fetchAllListings',
                pageNumber,
                ITEMS_PER_PAGE,
                textSearch,
                sort,
                brokerage.markets[0].brokerageId,
                brokerage.markets[0].mlsName,
            );

            if (response.length) {
                const { metadata, data } = response[0];

                if (data.length) {
                    // Calculate total pages
                    totalPages = Math.ceil(metadata[0].total / ITEMS_PER_PAGE);
                    globalRecords = Math.ceil(metadata[0].total);

                    if (forceReload) {
                        // In case force reload, remove previously stored data
                        listings = [{ page: pageNumber, data }];
                    } else {
                        listings = [...listings, { page: pageNumber, data }];
                    }
                } else {
                    yield put(ListingActions.fetchListingsSucceeded([], 1, 0, 0));
                    return;
                }
            } else {
                yield put(ListingActions.fetchListingFailed('Fetch data failed.'));
            }
        }
        yield put(
            ListingActions.fetchListingsSucceeded(
                listings,
                pageNumber,
                totalPages,
                globalRecords,
                textSearch,
                sort,
                sortIndex,
                sortType,
            ),
        );
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(ListingActions.fetchListingsFailed([err]));
    }
}

export function* fetchListing({
    mlsListingId,
    mlsName,
    isEditing = false,
}: any): Generator<any, any, any> {
    try {
        const listing = yield call(findRecord, LISTINGS_COLLECTION, {
            'mlsList.listingId': mlsListingId,
            'mlsList.mlsName': mlsName,
        });
        if (listing) {
            if (isEditing) {
                yield put(ListingActions.setConfigDetails(listing, 1));
            } else {
                yield put(ListingActions.fetchListingSucceeded(listing));
            }
        } else {
            yield put(ListingActions.fetchListingFailed('Listing not found.'));
        }
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(ListingActions.fetchListingFailed(err));
    }
}

export function* textSearchAgents({
    searchText,
    marketName,
    brokerageId,
}: ReturnType<typeof ListingActions.textSearchAgentsRequested>): Generator<any, any, any> {
    try {
        let agents = [];
        agents = yield call(
            callStitchFunction,
            'textSearchAgents',
            searchText,
            null, //projection
            [{ 'markets.brokerageId': brokerageId }],
            marketName,
            false,
            false,
        );
        if (agents) {
            yield put(ListingActions.textSearchAgentsSucceeded(agents));
        }
    } catch (errors) {
        const err = parseStitchServiceError(errors);
        yield put(ListingActions.textSearchAgentsFailed(err));
    }
}

export function* textSearchConsumers({
    searchText,
    agentId,
}: ReturnType<typeof ListingActions.textSearchConsumersRequested>): Generator<any, any, any> {
    try {
        let consumers = [];

        consumers = yield call(
            callStitchFunction,
            'textSearchConsumers',
            searchText,
            agentId,
            null,
        );
        if (consumers) {
            yield put(ListingActions.textSearchConsumersSucceeded(consumers));
        }
    } catch (errors) {
        const err = parseStitchServiceError(errors);
        yield put(ListingActions.textSearchConsumersFailed(err));
    }
}

export function* addClient({
    client,
    agentId,
}: ReturnType<typeof ListingActions.addClientRequested>): Generator<any, any, any> {
    try {
        const user = yield call(getStitchUser);
        const response = yield call(
            callStitchFunction,
            'addConsumers',
            [
                {
                    ...client,
                    phoneNumber: formatPhoneNumberToIntl(client.phoneNumber),
                },
            ],
            agentId,
        );

        if (response) {
            // Re-fetching the client to grab the _id
            // TODO: Rather than re-fetch this agent, we need to get the _id from the 'addConsumers' collection
            const newClient = yield findRecord(CONSUMERS, {
                phoneNumber: formatPhoneNumberToIntl(client.phoneNumber),
            });
            if (newClient) {
                yield put(ListingActions.addClientSucceeded(transformUser(newClient, 'consumer')));
            }
        }
    } catch (errors) {
        const err = parseStitchServiceError(errors);
        yield put(ListingActions.addClientFailed(err));
    }
}

export function* connectListing({
    agentListing,
    listing,
}: ReturnType<typeof ListingActions.connectListingRequested>): Generator<any, any, any> {
    try {
        const response = yield call(callStitchFunction, 'agentConnectListing', {
            listingId: agentListing.listingId,
            agentListing,
        });

        if (response) {
            const listings = yield select(ListingSelectors.getAllListings);
            const newListingConfigured = { ...listing, agentListing };
            yield put(ListingActions.fetchListingSucceeded(newListingConfigured));
            yield put(ListingActions.connectListingSucceeded());
            yield put(ListingActions.setSelectedListing(newListingConfigured));
            if (listings.length) {
                const pageNumber = yield select(ListingSelectors.getCurrentPage);
                let allListings = listings;
                allListings[pageNumber - 1].data[
                    listings[pageNumber - 1].data.findIndex(
                        (e) => e._id.toString() === listing._id.toString(),
                    )
                ] = newListingConfigured;
                yield put(
                    ListingActions.fetchListingsSucceeded(
                        allListings,
                        yield select(ListingSelectors.getCurrentPage),
                        yield select(ListingSelectors.getTotalPages),
                        yield select(ListingSelectors.getGlobalRecords),
                    ),
                );
            }
        }
    } catch (errors) {
        const err = parseStitchServiceError(errors);
        yield put(ListingActions.connectListingFailed(err));
    }
}

export function* storeLockboxPhoto({
    file,
}: ReturnType<typeof ListingActions.storeLockboxPhotoRequested>): Generator<any, any, any> {
    try {
        let base64Image = yield imageToBase64(file);
        const newPhotoData = yield call(
            callStitchFunction,
            'storePhoto',
            'lockbox',
            base64Image.split(',')[1],
            `${file.name}`,
            file.type,
        );
        let newContentData = {
            image: {
                as: file.name,
                uri: newPhotoData
                    ? `https://lockbox-photos.s3.amazonaws.com/${newPhotoData?.key}`
                    : '',
                path: newPhotoData
                    ? `https://lockbox-photos.s3.amazonaws.com/${newPhotoData?.key}`
                    : '',
            },
        };
        if (newPhotoData) {
            yield put(ListingActions.storeLockboxPhotoSucceeded(newContentData));
        }
    } catch (errors) {
        const err = parseStitchServiceError(errors);
        yield put(ListingActions.storeLockboxPhotoFailed(err));
    }
}

export function* getAgentByMlsId({
    agentMlsId,
    brokerageMlsId,
}: ReturnType<typeof ListingActions.fetchMlsAgentRequested>): Generator<any, any, any> {
    try {
        const query = { 'markets.agentMlsId': agentMlsId, 'markets.brokerageId': brokerageMlsId };
        const agentRecord = yield call(findRecord, AGENTS, query, {}, {});
        const agent = [
            {
                _id: agentRecord._id,
                canApprove: true,
                notificationSettings: ['text'],
                firstName: agentRecord.firstName,
                lastName: agentRecord.lastName,
                phoneNumber: agentRecord.phoneNumber,
                email: agentRecord.email || '',
                profilePhotoUpload: agentRecord.profilePhotoUpload,
                type: 'Listing Agent',
            },
        ];
        yield put(ListingActions.fetchMlsAgentSucceeded(agent));
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(ListingActions.fetchListingsFailed([err]));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.GetAllListing &&
                action.status === STATUS.Requested,
            getAll,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.FetchListing &&
                action.status === STATUS.Requested,
            fetchListing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.SearchAgent &&
                action.status === STATUS.Requested,
            textSearchAgents,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.SearchConsumer &&
                action.status === STATUS.Requested,
            textSearchConsumers,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.AddClients &&
                action.status === STATUS.Requested,
            addClient,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.ConnectListing &&
                action.status === STATUS.Requested,
            connectListing,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.StoreLockboxPhoto &&
                action.status === STATUS.Requested,
            storeLockboxPhoto,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ListingActions.Actions.FetchAgentByMlsId &&
                action.status === STATUS.Requested,
            getAgentByMlsId,
        ),
    ]);
}
