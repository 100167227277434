import React, { useState, useEffect } from 'react';

/*-------- Components ---------*/
import Typography from '../Typography';

/*-------- Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

interface VerifyInputProps {
    disabled: boolean;
    enteredCode: any;
    setEnteredCode: Function;
    style: any;
    onCodeFilled: Function;
    onError: boolean;
}

const VerifyInput = (props: VerifyInputProps) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- Constants ---------*/
    const { onCodeFilled, enteredCode, setEnteredCode } = props;
    const width = 536;
    const values = enteredCode?.split('') || [];
    const INPUT_SANITIZATION_REPLACEMENT_REG_EXP = /[^0-9]/gi;

    /*-------- Variables ---------*/
    let displayValues = [];
    let digitInputElement: any = document.getElementById('digitInput');

    /*-------- States ---------*/
    const [focused, setFocused] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    /*-------- Selectors ---------*/

    /*-------- Use Effect ---------*/
    useEffect(() => {
        digitInputElement = document.getElementById('digitInput');
    }, []);

    useEffect(() => {
        if (enteredCode !== null) {
            if (enteredCode.length === 6) {
                onCodeFilled();
                digitInputElement.value = '';
            }
        }
    }, [enteredCode]);

    /*-------- Functions ---------*/
    for (let i = 0; i < 6; i++) {
        if (values?.length > i) {
            displayValues.push(values[i]);
        } else displayValues.push(null);
    }

    const onChangeIndex = (i: number) => {
        setSelectedIndex(i);
    };

    const Digit = ({ value = null }: any) => {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    style={{
                        color: value ? '#3a3a3a' : '#bcbcbc',
                        textAlign: 'center',
                    }}
                    textStyle="h4"
                >
                    {value || '0'}
                </Typography>
            </div>
        );
    };

    const Divider = (
        <div className={styleSheet.dividerContainer}>
            <div className={styleSheet.divider} />
        </div>
    );
    const OtherDivider = (
        <div className={styleSheet.otherDividerContainer}>
            <div className={styleSheet.divider} />
        </div>
    );
    const BlankDivider = <div style={{ flex: 1 }} />;

    return (
        <>
            <div
                className={styleSheet.inputRoot}
                onClick={
                    enteredCode?.length > 5
                        ? () => {
                              setEnteredCode('');
                              onChangeIndex(0);
                              digitInputElement?.focus();
                          }
                        : () => {
                              digitInputElement?.focus();
                          }
                }
            >
                <div
                    className={`${styleSheet.digitInputContainer} ${
                        props.onError ? styleSheet.errorBorder : ''
                    }`}
                >
                    {displayValues.map((dv, index) => {
                        const displayValue = index === selectedIndex && focused ? ' ' : dv;
                        return <Digit value={displayValue} />;
                    })}
                    <div className={styleSheet.threeDividerContainer}>
                        {Divider}
                        {Divider}
                        {Divider}
                    </div>
                    <div className={styleSheet.twoDividerContainer}>
                        {OtherDivider}
                        {OtherDivider}
                        {BlankDivider}
                    </div>
                    {focused && (
                        <div
                            className={styleSheet.blink}
                            style={{
                                height: 36,
                                width:
                                    selectedIndex === 2 || selectedIndex === 4
                                        ? width / 6
                                        : width / 6 - 2,
                                // border: '2px groove rgb(188, 188, 188)',
                                // backgroundColor: 'rgba(188, 188, 188, 0.3)',
                                // borderRadius: 5,
                                // borderBottomRightRadius: 0,
                                // borderTopRightRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: 0,
                                left:
                                    selectedIndex === 2 || selectedIndex === 4
                                        ? selectedIndex * (width / 6) - 1
                                        : selectedIndex * (width / 6),
                            }}
                        >
                            <div
                                style={{
                                    height: 28,
                                    width: 2,
                                    backgroundColor: '#0080ff',
                                    marginLeft: 4 - selectedIndex,
                                    marginTop: 4,
                                }}
                            />
                        </div>
                    )}
                </div>
                {/* <DigitInput /> */}
                <input
                    className={styleSheet.digitInput}
                    style={{
                        left: selectedIndex * (width / 6) + 2,
                        width: width / 6,
                    }}
                    type={'number'}
                    onKeyDown={(e: any) => {
                        var key = e.keyCode || e.charCode;
                        if (key == 8 || key == 46) {
                            onChangeIndex(selectedIndex === 0 ? selectedIndex : selectedIndex - 1);
                            if (selectedIndex === 0) {
                                setEnteredCode('');
                            } else {
                                setEnteredCode(enteredCode?.slice(0, -1));
                            }
                        }
                    }}
                    id="digitInput"
                    onChange={(e: any) => {
                        const char = e?.nativeEvent?.data || '';
                        if (char.length > 1) {
                            onChangeIndex(char.length);
                            setEnteredCode(char);
                        } else if (char.length) {
                            // Remove any invalid characters from the text that has been entered.
                            const sanitizedChar = char.replace(
                                INPUT_SANITIZATION_REPLACEMENT_REG_EXP,
                                '',
                            );
                            // Concatenate that onto the current value and trim down to codeLength
                            const newValue = `${enteredCode || ''}${sanitizedChar || ''}`.slice(
                                0,
                                6,
                            );
                            // Indicate onChange with the newValue
                            onChangeIndex(selectedIndex + 1);
                            setEnteredCode(newValue);
                        }
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
                {props.onError && (
                    <Typography
                        color={theme.palette.red}
                        textStyle="p3"
                        style={{ fontSize: 18, padding: 5, marginLeft: 180, marginBottom: 10 }}
                    >
                        Invalid Verification Code
                    </Typography>
                )}
            </div>
        </>
    );
};

export default VerifyInput;
