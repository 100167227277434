/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { animated, useTransition } from 'react-spring';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import {
    connectListingRequested,
    fetchSelectedListingRequested,
    resetValues,
    storeLockboxPhotoRequested,
    changeSelectedView,
    fetchListingRequested,
} from '../actions';
import {
    getListings,
    getLockboxImage,
    isListingLoading,
    getConnectLoading,
    getConnectionErrors,
    getConnectionSuccess,
    getConfigurationDetails,
} from '../selectors';

/*-------- Components ---------*/
import Header from '../../../../components/Header';
import Loader from '../../../../components/Loader';
import Typography from '../../../../components/Typography';
import ButtonComponent from '../../../../components/ButtonComponent';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import PageFour from './PageFour';
import PageFive from './PageFive';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';

/*-------- Icons ---------*/
import NextArrow from '../../../../images/icons/nextArrow.svg';

/*-------- Constants ---------*/
import { useHistory, useParams } from 'react-router-dom';

import { ListingParams } from '../types';
import { useLocalStorage } from '../../../../utils/common/useLocalStorage';

interface Props {}

const ConfigureListing = ({}: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Selectors ---------*/
    const listings = useSelector(getListings);
    const configurationDetails = useSelector(getConfigurationDetails);
    const isLoading = useSelector(isListingLoading);
    const connectionErrors = useSelector(getConnectionErrors);
    const connectListingSuccess = useSelector(getConnectionSuccess);
    const loading = useSelector(getConnectLoading);
    const lockboxImage = useSelector(getLockboxImage);

    /*-------- Constants ---------*/
    const history = useHistory();
    const dispatch = useDispatch();
    const objId = window.location.pathname.split('configure/')[1];

    const { mlsListingId, mlsName } = useParams<ListingParams>();
    const { pageNumber, listing } = configurationDetails;

    const isEditing = !!listing?.agentListing;
    const checkConfigureDisabled = () => {
        let configuredListings: number = 0;
        let configuredListingsIDs: Array<Object> = [];
        listings?.map((listing: any) => {
            if (listing?.agentListing) {
                configuredListings++;
                configuredListingsIDs.push(listing?._id);
            }
        });
        // Any user with no configured listings can configure one
        if (configuredListings == 0) return false;
        // If the user subTier is zero, and already have one configured listing, we make sure they are opening the right one.
        if (configuredListings == 1 && configuredListingsIDs[0] == listing?._id) return false;
        let alreadyConnected = false;
        listings?.every((e: any) => {
            if (JSON.stringify(e.agentListing?.listingId) === JSON.stringify(listing._id)) {
                alreadyConnected = true;
                return false;
            }
            return true;
        });
        if (alreadyConnected) return false;
    };
    const defaultAgentListing = {
        listingId: listing?._id,
        approvalSettings: {
            unverified: {
                // This will always be true
                allowed: true,
                hiddenId: Math.floor(Math.random() * 10000000000000000000).toString(),
            },
            approvalType: 'none',
        },
        noticeRequired: 0,
        maxDuration: 15,
        allowOverlap: false,
        type: 'go_and_show',
        lockboxLocation: '',
        lockboxType: 'Other',
        lockCombo: '',
        lockboxPhotoUpload: {
            as: '',
            uri: '',
            path: '',
        },
        consumers: [],
        showingsStartDate: new Date(),
        blockedTimes: [],
        acceptingShowings: true,
        showingInstructions: '',
        showToUnrepresentedConsumers: true,
    };

    /*-------- States ---------*/
    const [load, setLoad] = useState(false);
    const [page, setPage] = useLocalStorage('configNumberPage', pageNumber);
    const [agentListing, setAgentListing] = useLocalStorage(
        'configDefaultAgentListing',
        defaultAgentListing,
    );

    /*-------- Use Effect ---------*/
    useEffect(() => {
        // If the listing is not stored in redux, search the listing
        if (!listing?.mlsList?.some((m) => m.listingId === mlsListingId && m.mlsName === mlsName)) {
            dispatch(fetchListingRequested(mlsListingId, mlsName, true));
        }
    }, [listing]);

    useEffect(() => {
        if (checkConfigureDisabled()) {
            history.replace('/listings');
        }

        if (!listing?._id) {
            dispatch(fetchSelectedListingRequested(objId, true));
        }
    }, []);

    useEffect(() => {
        if (connectListingSuccess && isEditing) {
            dispatch(fetchSelectedListingRequested(objId));
            history.push(`/listings/${objId}`);
            window.localStorage.removeItem('configNumberPage');
            window.localStorage.removeItem('configDefaultAgentListing');
            dispatch(resetValues());
            window.scrollTo(0, 0);
        }
    }, [connectListingSuccess]);

    // Ensure the listing id is being put on the agentListing
    useEffect(() => {
        if (listing.agentListing) {
            setAgentListing({ ...listing.agentListing });
        } else {
            setAgentListing({
                ...agentListing,
                listingId: listing?._id,
            });
        }
    }, [listing?._id]);

    useEffect(() => {
        if (lockboxImage) {
            setAgentListing({
                ...agentListing,
                lockboxPhotoUpload: {
                    ...lockboxImage.image,
                },
            });
        }
    }, [lockboxImage]);

    /*-------- Functions ---------*/
    const getDisabled = () => {
        if (page === 2) {
            if (agentListing.lockboxType !== 'None') {
                if (agentListing.lockboxType === 'Other' && !agentListing.lockCombo) {
                    return true;
                }
                if (!agentListing.lockboxLocation) {
                    return true;
                }
            }
        }
        return false;
    };

    const transitions = useTransition(page, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
    });

    const BreadCrumbs = () => {
        return (
            <div className={styleSheet.breadCrumbs}>
                {['General Info', 'Lockbox', 'Users & Approval', 'Availability', 'Finish'].map(
                    (title, index) => {
                        return (
                            <div className={styleSheet.individualContainer}>
                                <Typography
                                    style={{ alignItems: 'center' }}
                                    textStyle={'h4'}
                                    color={
                                        page - 1 === index
                                            ? indexTheme.palette.blue
                                            : indexTheme.palette.mediumGrey
                                    }
                                >
                                    {title}
                                </Typography>
                                {index !== 4 && (
                                    <img
                                        style={{ ...indexTheme.marginHorizontal(26) }}
                                        src={NextArrow}
                                    />
                                )}
                            </div>
                        );
                    },
                )}
            </div>
        );
    };

    return transitions((style: any) => (
        <div className={styleSheet.root}>
            {load || isLoading ? (
                <div className={styleSheet.pageLoader}>
                    <Loader />
                    <span>Fetching your data</span>
                </div>
            ) : (
                <>
                    <Header
                        title={'Configure Your Listing'}
                        extraTitleContent={
                            <>
                                <Typography color={indexTheme.palette.mediumGrey} textStyle={'h4'}>
                                    {listing?.address?.full}
                                </Typography>
                                <Typography
                                    style={{ ...indexTheme.typography.medium, marginLeft: 5 }}
                                    color={indexTheme.palette.mediumGrey}
                                    textStyle={'h4'}
                                >
                                    {listing?.address?.city}, {listing?.address?.state}{' '}
                                    {listing?.address?.postalCode}
                                </Typography>
                            </>
                        }
                    />
                    <BreadCrumbs />
                    <div className={styleSheet.pageRoot}>
                        <animated.div style={style} className={styleSheet.pageContainer}>
                            {page === 1 ? (
                                <PageOne
                                    agentListing={agentListing}
                                    setAgentListing={setAgentListing}
                                />
                            ) : page === 2 ? (
                                <PageTwo
                                    storeLockboxPhotoRequested={(file: any) =>
                                        dispatch(storeLockboxPhotoRequested(file))
                                    }
                                    agentListing={agentListing}
                                    setAgentListing={setAgentListing}
                                />
                            ) : page === 3 ? (
                                <PageThree
                                    agentListing={agentListing}
                                    setAgentListing={setAgentListing}
                                    isEditing={isEditing}
                                />
                            ) : page === 4 ? (
                                <PageFour
                                    agentListing={agentListing}
                                    setAgentListing={setAgentListing}
                                />
                            ) : page === 5 ? (
                                <PageFive
                                    loading={loading}
                                    errors={connectionErrors}
                                    success={connectListingSuccess}
                                />
                            ) : null}
                        </animated.div>

                        {!isEditing ? (
                            <div
                                style={{
                                    justifyContent:
                                        page > 1 && page < 5 ? 'space-between' : 'flex-end',
                                }}
                                className={styleSheet.buttonContainer}
                            >
                                {page > 1 && page < 5 ? (
                                    <ButtonComponent
                                        width={152}
                                        onClick={() => {
                                            setPage(page - 1);
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Back
                                    </ButtonComponent>
                                ) : null}
                                <ButtonComponent
                                    className={styleSheet.button}
                                    fill
                                    onClick={() => {
                                        if (page < 5) {
                                            if (page === 4) {
                                                dispatch(
                                                    connectListingRequested(agentListing, listing),
                                                );
                                            }
                                            setPage(page + 1);
                                            window.scrollTo(0, 0);
                                        } else {
                                            dispatch(fetchSelectedListingRequested(objId));
                                            window.localStorage.removeItem('configNumberPage');
                                            window.localStorage.removeItem(
                                                'configDefaultAgentListing',
                                            );
                                            dispatch(resetValues());
                                            history.push(`/listings/${objId}`);
                                        }
                                    }}
                                    width={152}
                                    disabled={getDisabled()}
                                >
                                    {page === 4 ? 'Finish' : page === 5 ? 'View Listing' : 'Next'}
                                </ButtonComponent>
                            </div>
                        ) : (
                            <div
                                style={{ justifyContent: 'space-between' }}
                                className={styleSheet.buttonContainer}
                            >
                                <ButtonComponent
                                    width={152}
                                    onClick={() => {
                                        history.push(`/listings/${objId}`);
                                        window.scrollTo(0, 0);
                                        dispatch(changeSelectedView('Configuration'));
                                    }}
                                >
                                    Cancel
                                </ButtonComponent>
                                <ButtonComponent
                                    className={styleSheet.button}
                                    fill
                                    onClick={() => {
                                        setLoad(true);
                                        dispatch(connectListingRequested(agentListing, listing));
                                    }}
                                    width={152}
                                    disabled={getDisabled()}
                                >
                                    Save
                                </ButtonComponent>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    ));
};
export default ConfigureListing;
