import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/*--------- Assets --------------*/
import styles from './styles.module.css';

/*------- Icons ----------------*/
import RightArrow from '../../../images/icons/rightBlueArrow.svg';

/*------- Components -----------*/
import ContactUsModal from './ContactUsModal';

/*------- Misc -------------*/
import { getProfileData, getSelectedBrokerage } from './selectors';

export default function Profile() {
    /* selectors */
    const [contactModalOpened, setContactModalOpened] = useState(false);

    /*----------- Effect ------------*/
    const profile = useSelector(getProfileData);
    const brokerage = useSelector(getSelectedBrokerage);

    /*-------- Components -----------*/
    const ProfileHeader = () => {
        const { firstName, lastName } = profile;
        const { name } = brokerage;
        const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
        return (
            <div className={styles.height}>
                <div className={styles.gradientHeader} />
                <div className={styles.initialsContainer}>
                    <div className={styles.initialsIcon}>{initials}</div>
                </div>
                <div className={styles.nameAndBrokerage}>
                    {firstName} {lastName}
                    <p>{name}</p>
                </div>
            </div>
        );
    };

    const AgentInformationTab = () => {
        return (
            <div className={styles.mainContainer}>
                <div className={styles.subtitle}>
                    <h3>Your Information</h3>
                    <div className={styles.informationContainer}>
                        <div className={styles.information}>
                            {' '}
                            <strong>E-mail</strong> {profile.email}{' '}
                        </div>
                        <div className={styles.information}>
                            {' '}
                            <strong>Phone</strong> {profile.phoneNumber}{' '}
                        </div>
                    </div>
                </div>
                <div className={styles.subtitle}>
                    <h3>Manage Plan</h3>
                    <p className={styles.subtitleP}>
                        Need to request a change to your Brokerage Suite Plan?
                    </p>
                    <span
                        className={styles.pointer}
                        onClick={() => {
                            setContactModalOpened(true);
                        }}
                    >
                        Contact us{' '}
                        <img src={RightArrow} className={styles.leftArrow} alt={'arrow'} />
                    </span>
                </div>
                <div className={styles.subtitle}>
                    <h3>Showingly+ for Agents</h3>
                    <p className={styles.subtitleP}>
                        Showingly+ for Agents is Active. If you need to request a change to your
                        plan, email us.
                    </p>
                    <span
                        className={styles.pointer}
                        onClick={() => {
                            setContactModalOpened(true);
                        }}
                    >
                        Contact us{' '}
                        <img src={RightArrow} className={styles.leftArrow} alt={'arrow'} />
                    </span>
                </div>
                {/*<div className={styles.subtitle}>
                <h3>Invoice History</h3>
                <p className={styles.subtitleP}>
                    View all your invoices and order history.
                </p>
                <span>
                View invoice History <img src={RightArrow} className={styles.leftArrow} alt={'arrow'} />
            </span>
            </div>*/}
            </div>
        );
    };

    return (
        <div className={styles.outerContainer}>
            <h3 className={styles.title}>Profile Settings</h3>
            <div className={styles.outerContainer2}>
                <div className={styles.innerContainer}>
                    <ProfileHeader />
                    <AgentInformationTab />
                </div>
                <ContactUsModal
                    open={contactModalOpened}
                    onClose={() => setContactModalOpened(false)}
                />
            </div>
        </div>
    );
}
