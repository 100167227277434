import { all, call, put, takeLatest } from 'redux-saga/effects';
import { callStitchFunction } from '../../../store/api/sagas';
import { parseStitchServiceError } from '../../../utils/common';
import { STATUS } from '../../auth/types';
import * as SearchMapActions from './actions';
import { LISTINGS_FIND_PROJECTION_FOR_SEARCH } from './constants';

export function* fetchMapListings({
    region,
    searchListings,
    filter,
}: any): Generator<any, any, any> {
    try {
        var query: any = {
            $and: [
                { listPrice: { $gte: filter.minPrice } },
                { listPrice: { $lte: filter.maxPrice } },
            ],
        };

        // Sub Types
        if (filter.types.length > 0) {
            // Take the user facing label and transform it to internal subtypes
            const internalSubTypes = filter.types
                .map((type: string) => {
                    switch (type) {
                        case 'House':
                            return [
                                'SingleFamilyResidence',
                                'Single Family Residence',
                                'Residential',
                            ];
                        case 'Condo':
                            return ['Condominium'];
                        case 'Townhome':
                            return ['Townhouse'];
                        case 'Apartment':
                            return ['Apartment'];
                        case 'Multifamily':
                            return ['MultiFamily', 'Duplex'];
                    }
                })
                .flat();
            query.$and.push({ 'property.subTypeText': { $in: [...internalSubTypes] } });
            // If the user has selected "Other" search for all other types as well
            if (filter.types.includes('Other')) {
                query.$and.push({
                    'property.subTypeText': {
                        $nin: [
                            'SingleFamilyResidence',
                            'Single Family Residence',
                            'Residential',
                            'Condominium',
                            'Townhouse',
                            'Apartment',
                            'MultiFamily',
                            'Duplex',
                        ],
                    },
                });
            }
        }

        // Status
        if (filter.statuses.length > 0) {
            query.$and.push({ 'mlsList.standardStatus': { $in: filter.statuses } });
        }

        // // Beds, Baths, Parking
        // if (filter.beds !== 'Any') {
        //     query.$and.push({
        //         'property.bedrooms': { $gte: PickerOptionsToNumbers[filter.beds as any] },
        //     });
        // }
        // if (filter.baths !== 'Any') {
        //     query.$and.push({
        //         'property.bathrooms': { $gte: PickerOptionsToNumbers[filter.baths as any] },
        //     });
        // }
        // if (filter.parking !== 'Any') {
        //     query.$and.push({
        //         'property.parking.spaces': { $gte: PickerOptionsToNumbers[filter.parking as any] },
        //     });
        // }

        // Only listings configured with showingly
        if (filter.onlyConfigured) {
            query['$and'].push({ agentListing: { $exists: true } });
        }

        const statuses: Array<string> = [];
        const minCoords = [region?.minCoords?.lng, region?.minCoords?.lat];
        const maxCoords = [region?.maxCoords?.lng, region?.maxCoords?.lat];
        const listings = yield call(
            callStitchFunction,
            'getMapDataByGeo',
            query,
            LISTINGS_FIND_PROJECTION_FOR_SEARCH.projection,
            LISTINGS_FIND_PROJECTION_FOR_SEARCH.sort,
            LISTINGS_FIND_PROJECTION_FOR_SEARCH.limit,
            'listings',
            minCoords,
            maxCoords,
        );

        if (listings && listings.length) {
            let listingsWithStringIds = listings.map((d: any) => {
                return {
                    ...d,
                    _id: d._id.toString(),
                };
            });
            let totalListings = [...listingsWithStringIds, ...searchListings];
            let listingSet = totalListings.filter(
                (item, index) =>
                    totalListings.indexOf(totalListings.find((d) => d._id == item._id)) === index,
            );
            console.log();

            yield put(SearchMapActions.FetchGeoListingsSucceeded(listingSet));
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(SearchMapActions.FetchGeoListingsFailed(errorMessage));
    }
}

export function* fetchSearchListings({
    searchedText = '',
    isMlsIdSearch = false,
}: any): Generator<any, any, any> {
    try {
        let listings = [];
        const listingsLimit = 25;
        const market = null; // <- Selected market (as array) in the login

        if (searchedText) {
            const listingMlsId = searchedText;
            listings = yield call(
                callStitchFunction,
                'textSearchListings',
                listingMlsId,
                true, // isExtraData
                null, // brokerageId
                listingsLimit,
                market,
                isMlsIdSearch,
            );
        }
        yield put(SearchMapActions.FetchSearchListingsSucceeded(listings));
    } catch (error: any) {
        console.log(error);

        const errorMessage = parseStitchServiceError(error);
        yield put(SearchMapActions.FetchSearchListingsFailed(errorMessage));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        takeLatest(
            (action: any) =>
                action.type === SearchMapActions.Actions.FetchGeoListings &&
                action.status === STATUS.Requested,
            fetchMapListings,
        ),
        takeLatest(
            (action: any) =>
                action.type === SearchMapActions.Actions.FetchSearchListings &&
                action.status === STATUS.Requested,
            fetchSearchListings,
        ),
    ]);
}
