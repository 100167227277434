/*----- React imports -------*/
import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { fetchListingRequested, fetchMlsAgentRequested } from '../actions';
import { getSelectedBrokerage } from '../../Profile/selectors';
import { getConfigurationDetails, islistingAgentsLoading } from '../selectors';

/*-------- Components ---------*/
import Typography from '../../../../components/Typography';
import UserSection from './common/UserSection';
import RadioButtons from './common/RadioButtons';
import ConfigureListingModule from './common/ConfigureListingModule';

/*-------- Assets ---------*/
import styles from './styles';
import { useParams } from 'react-router-dom';
import { ListingParams } from '../types';

interface Props {
    agentListing: any;
    setAgentListing: any;
    isEditing: boolean;
}
type Approvals = 'both' | 'either' | 'agent' | 'client' | 'none';

const PageThree = (props: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Selectors ---------*/
    const isDataLoading = useSelector(islistingAgentsLoading);
    const selectedBrokerage = useSelector(getSelectedBrokerage);
    const configurationDetails = useSelector(getConfigurationDetails);

    /*-------- Constants ---------*/
    const dispatch = useDispatch();
    const { listing, listingAgents } = configurationDetails;
    const { mlsListingId, mlsName } = useParams<ListingParams>();

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (props.agentListing.type === 'go_and_show') {
            // IF the type if go and show there are no approval settings.
            setNewApprovals('none');
        } else if (getApprovals('agents')?.length && getApprovals('consumers')?.length) {
            // If both an agent and consumer are toggled true, set it to both. From there the user can change it to either.
            setNewApprovals('both');
        } else if (getApprovals('agents')?.length) {
            // if it's just the agent, set the approval to agent
            setNewApprovals('agent');
        } else if (getApprovals('consumers')?.length) {
            // If it's just the consumer set the approval to client
            setNewApprovals('client');
        } else {
            setNewApprovals('none');
        }
    }, [props.agentListing?.consumers]);

    useEffect(() => {
        if (listing?._id && !props.isEditing) {
            if (!listingAgents?.length) {
                const agentMls = listing?.mlsList.find((m) =>
                    selectedBrokerage.markets.some((m2) => m2.brokerageId === m.brokerageId),
                );
                dispatch(fetchMlsAgentRequested(agentMls.agentMlsId, agentMls.brokerageId));
            } else {
                props.setAgentListing({ ...props.agentListing, agents: listingAgents });
            }
        }
    }, [listingAgents]);

    useEffect(() => {
        if (!listing?.mlsList?.some((m) => m.listingId === mlsListingId && m.mlsName === mlsName)) {
            dispatch(fetchListingRequested(mlsListingId, mlsName));
        }
    }, [listing]);

    /*-------- Functions ---------*/
    const setNewApprovals = (approvalType: Approvals) => {
        props.setAgentListing({
            ...props.agentListing,
            approvalSettings: {
                ...props.agentListing.approvalSettings,
                approvalType,
            },
        });
    };

    const getApprovals = (userType: string) => {
        // This function takes a user type and returns a list of who has canApprove: true on the users configuration.
        let users: any = [];
        props?.agentListing[userType]?.map((user: any) => {
            if (user.canApprove) {
                users.push(user);
            }
        });
        return users;
    };

    const EBRadios = () => {
        return (
            <RadioButtons
                onChangeValue={(value: Approvals) => setNewApprovals(value)}
                selectedValue={props.agentListing.approvalSettings.approvalType}
                data={[
                    { label: 'Either party can approve.', value: 'either' },
                    { label: 'Require both parties to approve', value: 'both' },
                ]}
            />
        );
    };

    const ApprovalSection = () => {
        if (props.agentListing.type === 'go_and_show') {
            return (
                <Typography textStyle={'b1'}>
                    This listing has Automatic Approval configured.
                </Typography>
            );
        }
        if (props.agentListing.type === 'accompanied') {
            return (
                <Typography textStyle={'b1'}>
                    Listing Agent approves before seller is notified for approval.
                </Typography>
            );
        }
        switch (props.agentListing.approvalSettings.approvalType) {
            case 'both':
                return <EBRadios />;
            case 'agent':
                return (
                    <Typography textStyle={'b1'}>
                        The Listing Agent can approve showings on this listing.
                    </Typography>
                );
            case 'client':
                return <Typography textStyle={'b1'}>Client must approve.</Typography>;
            case 'either':
                return <EBRadios />;
            default:
                return <Typography textStyle={'b1'}>None</Typography>;
        }
    };

    const formatUsers = (users: any, type: 'agents' | 'consumers') => {
        /**
         * This function takes a users array and the type for that users array
         * It then determines if a users canApprove toggle should be disabled to prevent
         * a situation where no one has canApprove true. It then returns the user back to the list.
         */
        const oppositeType: string = type === 'agents' ? 'consumers' : 'agents';
        return users?.map((user: any, index: number) => {
            if (props.agentListing.type === 'accompanied' && index === 0 && type === 'agents') {
                // If the showing type is accompanied the listing agent must always approve
                return { ...user, disabledToggle: true };
            }
            if (
                props.agentListing.type !== 'go_and_show' &&
                // Listing is not set to Go And Show
                getApprovals(type).length === 1 &&
                // Only one person in the array can approve
                user.canApprove &&
                // This exact user has can approve selected (important for when there's co listing agents that can't approve).
                !getApprovals(oppositeType).length
                // No one in the other list can approve.
            ) {
                //Disable the Toggle
                return { ...user, disabledToggle: true };
            } else {
                return {
                    ...user,
                    disabledToggle: false,
                };
            }
        });
    };

    return (
        <div style={{ marginTop: 26 }}>
            <UserSection
                users={formatUsers(props.agentListing.agents, 'agents')}
                userType={'agents'}
                isDataLoading={!props.isEditing && isDataLoading}
                marketName={listing?._id ? listing?.marketName : ''}
                brokerageId={listing?._id ? listing?.mlsList[0]?.brokerageId : ''}
                {...props}
            />
            <UserSection
                users={formatUsers(props.agentListing.consumers, 'consumers')}
                userType={'consumers'}
                isDataLoading={false}
                marketName={listing?._id ? listing?.marketName : ''}
                brokerageId={listing?._id ? listing?.mlsList[0]?.brokerageId : ''}
                {...props}
            />
            <ConfigureListingModule title={'Approval Settings'}>
                {ApprovalSection()}
            </ConfigureListingModule>
        </div>
    );
};
export default PageThree;
