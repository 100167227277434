import React, {
    forwardRef,
    ReactChild,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useState,
} from 'react';

/*------ Components ------*/
import { RemoveScroll } from 'react-remove-scroll';
import { animated, useSpring } from 'react-spring';

/*------ Assets -------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*----- Utils ------*/
import { isMobile } from '../../utils/common';

/*------ Constants ------*/
export const ANIMATED_DRAWER_WIDTH: number = 637;

interface Props {
    onClose: Function;
    children: ReactChild;
    backgroundOpacity?: number;
}

const AnimatedDrawer = forwardRef((props: Props, ref?) => {
    /*---- Styles ------*/
    const useStyles = createUseStyles(styles);
    const theme: any = useTheme();
    const styleSheet = useStyles({ theme });

    /*----- State ------*/
    // used to trigger drawer open/close animations
    const [isOpen, setOpen] = useState<boolean>(false);
    const [screenWidth, setScreenWidth] = useState<number>(0);

    /*----- Constants ------*/
    const { onClose, children } = props;

    const animationProps: any = useSpring({
        left: isOpen
            ? (screenWidth || window.innerWidth) - ANIMATED_DRAWER_WIDTH
            : screenWidth || window.innerWidth,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: `${ANIMATED_DRAWER_WIDTH}px`,
    });

    const animationPropsMobile: any = useSpring({
        left: isOpen
            ? (screenWidth || window.innerWidth) - ANIMATED_DRAWER_WIDTH + 247
            : screenWidth || window.innerWidth,
    });

    const backgroundAnimatedProps: any = useSpring({
        backgroundColor: isOpen
            ? `rgba(0,0,0,${
                  0.8 * (typeof props?.backgroundOpacity === 'number' ? props.backgroundOpacity : 1)
              })`
            : 'transparent',
    });

    const scheduleClass =
        /*isMobile()
        ? styleSheet.scheduleContainerMobile
        :*/ styleSheet.scheduleContainer;
    const scheduleStyle = isMobile() ? animationPropsMobile : animationProps;

    /*----- Effects ------*/
    useEffect(() => {
        setOpen(true);
        return () => {};
    }, []);

    /**
     * Make sure that the schedule drawer repositions when the screen
     * is resized.
     */
    useLayoutEffect(() => {
        window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
        setScreenWidth(window.innerWidth);
        return () => window.removeEventListener('resize', () => setScreenWidth(window.innerWidth));
    }, []);

    /*----- Functions ------*/
    const closeDrawer = () => {
        setOpen(false);
        setTimeout(() => {
            onClose();
        }, 350);
    };

    useImperativeHandle(ref, () => ({ closeDrawer }));

    return (
        <RemoveScroll>
            <animated.div
                className={styleSheet.root}
                style={backgroundAnimatedProps}
                onClick={() => {
                    setOpen(false);
                    setTimeout(() => {
                        onClose();
                    }, 350);
                }}
            >
                <animated.div
                    className={scheduleClass}
                    style={scheduleStyle}
                    onClick={(e) => {
                        e.stopPropagation(); // dont close the drawer
                    }}
                >
                    {children}
                </animated.div>
            </animated.div>
        </RemoveScroll>
    );
});

AnimatedDrawer.defaultProps = {};

export default AnimatedDrawer;
