import { BSON } from 'realm-web';
import { Brokerage } from '../../../utils/constants';
import { STATUS } from '../../auth/types';

export enum PROFILE_ACTIONS {
    Fetch = '[Profile] Fetch Agent Profile Data',
    FetchSelectedBrokerage = '[Profile] Fetch Selected Brokerage',
}

/*----- Agent profile data --------*/

export function getProfileDataRequested() {
    return {
        type: PROFILE_ACTIONS.Fetch,
        status: STATUS.Requested,
    };
}

// TODO: import type for profileData
export function getProfileDataSucceeded(profileData: any) {
    return {
        type: PROFILE_ACTIONS.Fetch,
        status: STATUS.Succeeded,
        profileData,
    };
}

export function getProfileDataFailed(error: string) {
    return {
        type: PROFILE_ACTIONS.Fetch,
        status: STATUS.Failed,
        error,
    };
}

/*----- Fetch selected brokerage -----*/
export function fetchSelectedBrokerageRequested(brokerageId: BSON.ObjectId) {
    return {
        type: PROFILE_ACTIONS.FetchSelectedBrokerage,
        status: STATUS.Requested,
        brokerageId,
    };
}

export function fetchSelectedBrokerageSucceeded(brokerage: Brokerage) {
    return {
        type: PROFILE_ACTIONS.FetchSelectedBrokerage,
        status: STATUS.Succeeded,
        brokerage,
    };
}

export function fetchSelectedBrokerageFailed(error: string) {
    return {
        type: PROFILE_ACTIONS.FetchSelectedBrokerage,
        status: STATUS.Failed,
        error,
    };
}
