/*------- Assets ----------*/

import React from 'react';
import styles from './index.module.css';

interface Props {
    filters: String[];
    filterNumber: any;
    onChange: Function;
}

export default function SlidingFilter(props: Props) {
    const { filters, filterNumber } = props;

    const slider = filters.map((filter, index) => {
        return index === 0 ? (
            <>
                <input
                    type="radio"
                    id={`radio${filterNumber}-${index}`}
                    name={`tabs${filterNumber}`}
                    onClick={() => {
                        props.onChange({
                            filter,
                        });
                    }}
                    defaultChecked
                />
                <label className={styles.tab3} htmlFor={`radio${filterNumber}-${index}`}>
                    {filter}
                </label>
            </>
        ) : (
            <>
                <input
                    type="radio"
                    id={`radio${filterNumber}-${index}`}
                    name={`tabs${filterNumber}`}
                    onClick={() => {
                        props.onChange({
                            filter,
                        });
                    }}
                />
                <label className={styles.tab3} htmlFor={`radio${filterNumber}-${index}`}>
                    {filter}
                </label>{' '}
            </>
        );
    });

    return (
        <div className={styles.tabs3}>
            {slider}
            {filterNumber === 3 ? (
                <span className={styles.glider3}></span>
            ) : (
                <span className={styles.glider2}></span>
            )}
        </div>

        // <label className={styles.tab3} htmlFor="radio3-2">Upcoming</label>
        // <input type="radio" id="radio3-3" name="tabs3" />
        // <label className={styles.tab3} htmlFor="radio3-3">Needs Action</label>
        //
    );
}
