import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

/*-------- Components ---------*/
import LoginPage from './domains/auth/Login';
import PwdCreation from './domains/auth/PwdCreation';
import VerificationMethod from './domains/auth/VerificationMethod';

/*-------- Redux ---------*/
import { useSelector } from 'react-redux';
import { getClient, isAuthenticated, isInitializing } from './domains/auth/selectors';

/*-------- Utils ---------*/
import { routerRef } from './utils';
import { isMobile } from './utils/common';

/*-------- Assets ---------*/
import styles from './utils/commonStyles.module.css';

/*-------- Icons ---------*/
import laptopIcon from './images/icons/laptopIcon.svg';
import brokerageSuiteIcon from './images/icons/brokerageSuiteIcon.svg';

/*-------- Constants ---------*/

function UnauthenticatedApp() {
    /*----- Constants -------*/
    const itemsInUrl = window.location.href.split('/');

    /*----- Selectors -------*/
    const authUser = useSelector(getClient);
    const initializingLoading = useSelector(isInitializing);

    /*----- Functions -------*/
    const Header = () => {
        const className = isMobile() ? styles.headerMobile : styles.header;
        return (
            <div className={className}>
                <img src={brokerageSuiteIcon} className={styles.wordmark} />
            </div>
        );
    };

    useEffect(() => {
        console.log(authUser, initializingLoading);
        if (itemsInUrl[3] !== 'auth' && !authUser && !initializingLoading) {
            window.location.assign('/auth');
        }
    }, [initializingLoading]);

    return (
        <BrowserRouter
            basename={process.env.PUBLIC_URL}
            ref={(e) => {
                routerRef.current = e;
            }}
        >
            <Header />
            <div style={{ backgroundColor: '#E5E5E5', flexDirection: 'row', display: 'flex' }}>
                <img src={laptopIcon} className={styles.laptopIcon} />
                <div className={styles.authBox}>
                    <Switch>
                        {/* CHANGE flip sginup and auth */}
                        <Route exact path="/auth">
                            <LoginPage />
                        </Route>
                        <Route exact path="/auth/password">
                            <PwdCreation />
                        </Route>
                        <Route exact path="/auth/verify">
                            <VerificationMethod />
                        </Route>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return <Redirect to="/auth" />;
                            }}
                        />
                    </Switch>
                    <a
                        className={styles.needHelp}
                        href={'https://www.support.showingly.com/knowledge'}
                    >
                        {' '}
                        Need help?
                    </a>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default UnauthenticatedApp;
