/*
 * Reusable separator component
 */

import React from 'react';

/*------ Assets --------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

interface Props {
    type: 'Vertical' | 'Horizontal';
    height: number;
    width: number;
}

export default function Separator(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Constants --------*/
    const { type, height, width } = props;

    return (
        <div
            className={type === 'Vertical' ? styleSheet.vertical : styleSheet.horizontal}
            style={{ height: height > 0 ? height : 'auto', width: width > 0 ? width : 'auto' }}
        ></div>
    );
}

Separator.defaultProps = {
    type: 'Vertical',
    height: 0,
    width: 0,
};
