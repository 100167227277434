import React from 'react';

/*------- Assets -------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*----- Types ----------*/
import { SelectOption } from './types';
import { noop } from '../../utils/common';

interface Props {
    options: SelectOption[];
    selectedOption?: string;
    onSelect: Function;
    name: string;
    disabled: boolean;
}

export default function SelectInput(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*------ Constants -------*/
    const { options, selectedOption, onSelect, name, disabled } = props;

    return (
        <select
            name={name}
            className={styleSheet.select}
            onChange={(e) => onSelect(e)}
            disabled={disabled}
        >
            {options.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                    selected={option.value === selectedOption}
                >
                    {option.text}
                </option>
            ))}
        </select>
    );
}

SelectInput.defaultProps = {
    options: [],
    selectedOption: '',
    onSelect: noop,
    name: '',
    disabled: false,
};
