import { AgentsType } from '../types';
import { STATUS } from '../../../../utils/constants';
import { ShowingFilter } from '../../Showings/types';

export enum SELECTED_AGENT_ACTION {
    GetSelectedAgent = '[Get Selected Agent] Agent',
    GetSelectedAgentListings = '[Get Selected Agent Listings] Agent',
    SetSelectedAgent = '[Set Selected Agent] Agent',
    RemoveSelectedAgent = '[Remove Selected Agent] Agent',
    RemoveSelectedAgentListings = '[Remove Selected Agent] Agent Listings',
    FetchSelectedAgentShowings = '[Agent] Fetch Agent Showings',
    FetchAgentProfile = '[Agent] Fetch Agent Profile',
    SetShowingsCurrentPage = '[Agent] Set Agent Showings Current Page',
}

/*----- Redux Action Logic For get all the listings -----*/
export const setSelectedAgent = (agent: AgentsType) =>
    <const>{
        type: SELECTED_AGENT_ACTION.SetSelectedAgent,
        agent,
    };

export const getSelectedAgent = (agent: AgentsType) =>
    <const>{
        type: SELECTED_AGENT_ACTION.GetSelectedAgent,
        agent,
    };

/*----------- Fetch Selected Agent Listings ------------*/

export const fetchSelectedAgentListingsRequested = () =>
    <const>{
        type: SELECTED_AGENT_ACTION.GetSelectedAgentListings,
        status: STATUS.Requested,
    };

export const fetchSelectedAgentListingsSucceeded = (activeListings: []) =>
    <const>{
        type: SELECTED_AGENT_ACTION.GetSelectedAgentListings,
        status: STATUS.Succeeded,
        activeListings,
    };

export const fetchSelectedAgentListingsFailed = (listingError: string) =>
    <const>{
        type: SELECTED_AGENT_ACTION.GetSelectedAgentListings,
        status: STATUS.Failed,
        listingError,
    };

/*----------- Fetch Selected Agent Showings ------------*/

export const fetchSelectedAgentShowingsRequested = () =>
    <const>{
        type: SELECTED_AGENT_ACTION.FetchSelectedAgentShowings,
        status: STATUS.Requested,
    };

export const fetchSelectedAgentShowingsSucceeded = (showings: []) =>
    <const>{
        type: SELECTED_AGENT_ACTION.FetchSelectedAgentShowings,
        status: STATUS.Succeeded,
        showings,
    };

export const fetchSelectedAgentShowingsFailed = (showingsError: string) =>
    <const>{
        type: SELECTED_AGENT_ACTION.FetchSelectedAgentShowings,
        status: STATUS.Failed,
        showingsError,
    };

/*----------- Fetch Selected Agent Showings Current Pgae ------------*/
export const selectedAgentShowingsCurrentPageSucceeded = (page: ShowingFilter) =>
    <const>{
        type: SELECTED_AGENT_ACTION.SetShowingsCurrentPage,
        status: STATUS.Succeeded,
        page,
    };

/*----------- Fetch Selected Agent Profile ------------*/

export const fetchSelectedAgentProfileRequested = (agentId: string) =>
    <const>{
        type: SELECTED_AGENT_ACTION.FetchAgentProfile,
        status: STATUS.Requested,
        agentId,
    };

export const fetchSelectedAgentProfileSucceeded = (agent: AgentsType) =>
    <const>{
        type: SELECTED_AGENT_ACTION.FetchAgentProfile,
        status: STATUS.Succeeded,
        agent,
    };

export const fetchSelectedAgentProfileFailed = (error: string) =>
    <const>{
        type: SELECTED_AGENT_ACTION.FetchAgentProfile,
        status: STATUS.Failed,
        error,
    };

/*----------- Reset Selected Agent Listings ------------*/
export const resetSelectedAgentListings = () =>
    <const>{
        type: SELECTED_AGENT_ACTION.RemoveSelectedAgentListings,
        activeListings: [],
        showings: [],
    };

export const removeSelectedAgent = (agent: AgentsType) =>
    <const>{
        type: SELECTED_AGENT_ACTION.RemoveSelectedAgent,
        agent,
    };

type getAllListingsActions =
    | ReturnType<typeof setSelectedAgent>
    | ReturnType<typeof getSelectedAgent>
    | ReturnType<typeof fetchSelectedAgentListingsRequested>
    | ReturnType<typeof fetchSelectedAgentListingsSucceeded>
    | ReturnType<typeof fetchSelectedAgentListingsFailed>
    | ReturnType<typeof removeSelectedAgent>
    | ReturnType<typeof fetchSelectedAgentShowingsRequested>
    | ReturnType<typeof fetchSelectedAgentShowingsSucceeded>
    | ReturnType<typeof fetchSelectedAgentShowingsFailed>;
/*----- End of Redux Action Logic For get all the listings -----*/

/*----- Exportation of All the Actions -----*/
export type AgentsAction = getAllListingsActions;
/*----- End of Exportation of All the Actions -----*/
