/*----- React imports -------*/
import React, { ReactNode } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Components ---------*/
import Tag from '../../../../components/Tag';
import Typography from '../../../../components/Typography';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import UserSection from './UserSection';
import ShowingRestrictionCard from '../ShowingRestrictionCard';

/*-------- Utils ---------*/
import {
    formatApprovalType,
    formatMinutes,
    formatLockBoxType,
    formatShowingType,
} from '../../../../utils/common/transforms';
import { AgentListing } from '../../../../utils/constants';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';

/*-------- Icons ---------*/
import CameraIcon from '../../../../images/icons/cameraIconBlue.svg';

/*-------- Constants ---------*/
import { DateTime } from 'luxon';

interface Props {
    agentListing: AgentListing;
    onPressEdit: Function;
    listing: any;
}
const ListingConfigSection = ({ agentListing, onPressEdit, listing }: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Functions ---------*/
    const InfoModule = ({
        title,
        onPressEdit,
        children,
    }: {
        title: string;
        onPressEdit: Function;
        children: ReactNode[];
    }) => {
        return (
            <div className={styleSheet.infoModule}>
                <div className={styleSheet.infoHeader}>
                    {title}
                    <ClickableWithFeedback
                        className={styleSheet.editButton}
                        onClick={() => onPressEdit()}
                    >
                        Edit
                    </ClickableWithFeedback>
                </div>
                {children.map((child, index) => (
                    <>
                        <div className={styleSheet.childContainer}>{child}</div>
                        {index !== children?.length - 1 ? (
                            <div className={styleSheet.separator} />
                        ) : null}
                    </>
                ))}
            </div>
        );
    };

    const TextModule = ({
        title,
        description,
        bottomContent = null,
    }: {
        title: string;
        description?: string;
        bottomContent?: ReactNode | null;
    }) => {
        return (
            <>
                <Typography textStyle={'b1'}>{title}</Typography>
                {description ? (
                    <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                        {description}
                    </Typography>
                ) : null}
                {bottomContent}
            </>
        );
    };

    const TextAndTag = ({
        title,
        tagDescription,
        tagColor = 'mediumGrey',
    }: {
        title: string;
        tagDescription: string;
        tagColor?: string;
    }) => {
        return (
            <>
                <Typography textStyle={'b1'} style={{ marginRight: 26 }}>
                    {title}
                </Typography>
                <Tag background={true} onClick={() => {}} text={tagDescription} color={tagColor} />
            </>
        );
    };

    const DoubleBooking = ({ title, isAllow }: { title: string; isAllow: any }) => {
        const fillcircle = (
            <a style={{ paddingLeft: '20px', paddingRight: '50px' }}>
                <svg
                    width="15"
                    height="15"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="6" cy="6" r="6" fill="#9A9A9A" />
                </svg>
            </a>
        );
        const circle = (
            <a style={{ paddingLeft: '20px', paddingRight: '50px' }}>
                <svg
                    width="15"
                    height="15"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="8" cy="8" r="7" stroke="#9A9A9A" stroke-width="2" />
                </svg>
            </a>
        );

        return (
            <>
                <Typography textStyle={'b1'} style={{ marginRight: 26 }}>
                    {title}
                </Typography>
                <div>
                    <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                        {' '}
                        Yes{' '}
                    </Typography>
                    {isAllow ? fillcircle : circle}
                    <Typography textStyle={'b1'} color={indexTheme.palette.mediumGrey}>
                        {' '}
                        No{' '}
                    </Typography>
                    {isAllow ? circle : fillcircle}
                </div>
            </>
        );
    };

    const ShowingInfoSection = () => {
        return (
            <div className={styleSheet.textAndTagContainer}>
                <TextAndTag
                    title="Showing Type:"
                    tagDescription={formatShowingType(agentListing?.type)}
                />
                <div className={styleSheet.verticalSeparator} />
                <TextAndTag
                    title="Notice Required:"
                    tagDescription={formatMinutes(agentListing?.noticeRequired)}
                />
                <div className={styleSheet.verticalSeparator} />
                <TextAndTag
                    title="Max. Showing Duration:"
                    tagDescription={formatMinutes(agentListing?.maxDuration)}
                />
            </div>
        );
    };

    const generalInfo = [<ShowingInfoSection />];

    const lockbox = [
        <TextModule
            title={'Lockbox Type'}
            description={formatLockBoxType(agentListing?.lockboxType)}
        />,
    ];

    if (agentListing && agentListing?.lockboxType !== 'None') {
        lockbox.push(
            <TextModule
                title={'Lockbox Location'}
                description={agentListing?.lockboxLocation}
                bottomContent={
                    agentListing?.lockboxPhotoUpload?.as ? (
                        <div className={styleSheet.photoUploadContainer}>
                            <img src={CameraIcon} />
                            <p>{agentListing?.lockboxPhotoUpload?.as}</p>
                        </div>
                    ) : null
                }
            />,
        );
    }

    if (agentListing && agentListing?.showingInstructions) {
        lockbox.push(
            <TextModule
                title={'Showing Instructions'}
                description={agentListing?.showingInstructions}
            />,
        );
    }

    const usersAndApproval = [
        <TextModule
            title={'Approval'}
            description={formatApprovalType(
                agentListing?.approvalSettings?.approvalType,
                agentListing?.type,
            )}
        />,
        <UserSection userType={'Listing Agents'} users={agentListing?.agents} />,
    ];

    if (agentListing?.consumers?.length) {
        usersAndApproval.push(
            <UserSection userType={'Seller Clients'} users={agentListing?.consumers} />,
        );
    }

    const approvalSection = [
        <div className={styleSheet.textAndTagContainer}>
            <TextAndTag
                title={'Available for Showings Starting:'}
                tagDescription={DateTime.fromJSDate(agentListing?.showingsStartDate).toFormat('DD')}
            />
        </div>,
        <DoubleBooking
            title={'Allow double booking'}
            isAllow={agentListing && agentListing.allowOverlap ? true : false}
        />,
    ];

    agentListing &&
        agentListing?.blockedTimes?.forEach((restriction: any) => {
            approvalSection.push(<ShowingRestrictionCard restriction={restriction} />);
        });

    return (
        <div className={styleSheet.root}>
            <InfoModule
                title={'General Info'}
                onPressEdit={() => onPressEdit(1)}
                children={generalInfo}
            />
            <InfoModule title={'Lockbox'} onPressEdit={() => onPressEdit(2)} children={lockbox} />
            <InfoModule
                title={'Users & Approval'}
                onPressEdit={() => onPressEdit(3)}
                children={usersAndApproval}
            />
            <InfoModule
                title={'Availability'}
                onPressEdit={() => onPressEdit(4)}
                children={approvalSection}
            />
        </div>
    );
};
export default ListingConfigSection;
