import React, { useEffect, useState } from 'react';

/*-------- Components ---------*/
import ShowingTable from '../../../components/ShowingTable';

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';
import { fetchShowingsRequested, setCurrentPageSucceded } from './actions';
import { getCurrentPage, getShowings, isShowingsLoading } from './selectors';
import { ShowingFilter } from './types';

export default function Showings() {
    /*----- Selectors --------*/
    const showings = useSelector<any, []>(getShowings);
    const showingsLoading = useSelector<any, boolean>(isShowingsLoading);
    const currentPage = useSelector<any, ShowingFilter>(getCurrentPage);

    /*------ Constants --------*/
    const dispatch = useDispatch();

    /*------- useState ---------*/
    const [showingValues, setShowingValues] = useState<any>({
        includedStatuses: [],
        confirmStatus: null,
    });

    const handleChangeValue = (element: any) => {
        setShowingValues({
            confirmStatus: element.length,
            includedStatuses: element,
        });
    };
    /*------- Effects ---------*/
    // On Load
    useEffect(() => {
        if (showings.length === 0) dispatch(fetchShowingsRequested());
    }, []);

    const currentPageSetting = (page: ShowingFilter) => {
        dispatch(setCurrentPageSucceded(page));
    };

    return (
        <ShowingTable
            showings={showings}
            showingsLoading={showingsLoading}
            allShowings={true}
            currentPage={currentPage}
            callback={currentPageSetting}
            showingValues={showingValues}
            setShowingValues={handleChangeValue}
        />
    );
}
