/*----- React imports -------*/
import React, { useEffect, useState } from 'react';

/*----- Components imports -------*/
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import { Link, RouteComponentProps, useHistory, withRouter, useLocation } from 'react-router-dom';

/*----- Utils imports -------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*----- Assets imports -------*/
import ANALYTICS_UNSELECTED from '../../../images/icons/sidebar/analytics_unselected.svg';
import ANALYTICS_SELECTED from '../../../images/icons/sidebar/analytics_selected.svg';
import SHOWINGS_SELECTED from '../../../images/icons/sidebar/showings_selected.svg';
import SHOWINGS_UNSELECTED from '../../../images/icons/sidebar/showings_unselected.svg';
import LISTINGS_SELECTED from '../../../images/icons/sidebar/listings_selected.svg';
import LISTINGS_UNSELECTED from '../../../images/icons/sidebar/listings_unselected.svg';
import AGENTS_SELECTED from '../../../images/icons/sidebar/agents_selected.svg';
import AGENTS_UNSELECTED from '../../../images/icons/sidebar/agents_unselected.svg';
import ACTIVITY_FEED_SELECTED from '../../../images/icons/sidebar/activity_feed_selected.svg';
import ACTIVITY_FEED_UNSELECTED from '../../../images/icons/sidebar/activity_feed_unselected.svg';

/*----- Misc imports -------*/
import { SidebarItem } from './types';

const MENU_ITEMS: SidebarItem[] = [
    // {
    //     title: 'Analytics',
    //     link: '/dashboard',
    //     icon_unselected: ANALYTICS_UNSELECTED,
    //     icon_selected: ANALYTICS_SELECTED,
    // },
    {
        title: 'Showings',
        link: '/showings',
        icon_unselected: SHOWINGS_UNSELECTED,
        icon_selected: SHOWINGS_SELECTED,
    },
    {
        title: 'Listings',
        link: '/listings',
        icon_unselected: LISTINGS_UNSELECTED,
        icon_selected: LISTINGS_SELECTED,
    },
    {
        title: 'Agents',
        link: '/agents',
        icon_unselected: AGENTS_UNSELECTED,
        icon_selected: AGENTS_SELECTED,
    },
    // {
    //     title: 'Activity Feed',
    //     link: '/activity-feed',
    //     icon_unselected: ACTIVITY_FEED_UNSELECTED,
    //     icon_selected: ACTIVITY_FEED_SELECTED,
    // },
];

interface MenuItemProps {
    item: SidebarItem;
}

interface Props {}

export default function Sidebar(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*----- Constants -------*/
    const history = useHistory();
    const { pathname } = useLocation();
    const splitedPathname = pathname.split('/');

    /*----- Functions -------*/
    const MenuItem = ({ item }: MenuItemProps) => {
        const isSelected = splitedPathname.includes(item.link.replace('/', ''));
        return (
            <Link
                key={item.link}
                to={item.link}
                className={styleSheet.linkItem}
                onClick={() => {
                    history.push(item.link);
                }}
            >
                <ListItem
                    button={true}
                    classes={{ selected: styleSheet.listItemSelected }}
                    selected={isSelected}
                >
                    <div className={styleSheet.iconAndName}>
                        <ListItemIcon>
                            <img
                                src={isSelected ? item.icon_selected : item.icon_unselected}
                                alt={item.link + '_icon'}
                                height={30}
                                width={30}
                                className={styleSheet.icon}
                            />
                        </ListItemIcon>
                        <span
                            className={
                                isSelected ? styleSheet.pageNameSelected : styleSheet.pageName
                            }
                        >
                            {item.title}
                        </span>
                    </div>
                </ListItem>
            </Link>
        );
    };

    return (
        <div className={styleSheet.root}>
            <List>
                {MENU_ITEMS.map((item) => (
                    <MenuItem item={item} />
                ))}
            </List>
        </div>
    );
}
