import { createSelector } from 'reselect';

export const getProfileState = (state: any) => state.profile;

/*----- Current User Data -------*/
export const isProfileLoading = createSelector(
    getProfileState,
    (profileState) => profileState.loading,
);

export const getProfileData = createSelector(
    getProfileState,
    (profileState) => profileState.profileData,
);

export const getProfile = createSelector(
    getProfileState,
    (profileState) => profileState
);

/*------ Selected Brokerage from the user -------*/
export const getSelectedBrokerage = createSelector(
    getProfileState,
    (agent) => agent.selectedBrokerage,
);

export const isBrokerageLoading = createSelector(
    getProfileState,
    (agent) => agent.selectedBrokerageLoading,
);
