import { AGENTS_ACTION } from './actions';
import { STATUS } from '../../../utils/constants';
import { SortTypeEnum } from '../../../components/DataTable/types';

export const initialState = {
    /*-------- Fetch -----------*/
    requestInProgress: false,
    agents: [],
    totalPages: 0,
    currentPage: 1,
    errors: [],
    globalRecords: 0,
    textSearch: '',
    sort: null,
    sortIndex: 0,
    sortType: SortTypeEnum.ASC,
};

export default function (state = initialState, action: any) {
    const { type, status } = action;
    switch (type) {
        case AGENTS_ACTION.GetAllAgentsRequested: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        requestInProgress: true,
                        errors: [],
                    };
                }

                case STATUS.Succeeded: {
                    const {
                        agents,
                        currentPage,
                        totalPages,
                        globalRecords,
                        textSearch,
                        sort,
                        sortIndex,
                        sortType,
                    } = action;
                    return {
                        ...state,
                        requestInProgress: false,
                        agents,
                        currentPage,
                        totalPages,
                        globalRecords,
                        textSearch,
                        sort,
                        sortIndex,
                        sortType,
                    };
                }

                case STATUS.Failed: {
                    const { errors } = action;
                    return {
                        ...state,
                        requestInProgress: false,
                        errors,
                    };
                }

                default:
                    return state;
            }
        }
        case AGENTS_ACTION.SearchAgents: {
            switch (status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        searchAgentsLoading: true,
                        searchAgentserror: '',
                    };
                }

                case STATUS.Succeeded: {
                    const { agents } = action;
                    return {
                        ...state,
                        searchAgentsLoading: false,
                        searchedAgents: agents,
                    };
                }

                case STATUS.Failed: {
                    const { error } = action;
                    return {
                        ...state,
                        searchAgentsLoading: false,
                        searchAgentserror: error,
                    };
                }

                default:
                    return state;
            }
        }
        case AGENTS_ACTION.SetCurrentAgentsPage:
            const { currentPage } = action;
            return {
                ...state,
                currentPage,
            };
        default: {
            return state;
        }
    }
}
