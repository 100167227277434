import React from 'react';

/*------ Components -------*/
import { Modal as FakeModal } from '@material-ui/core';
import Button from '../Button';

/*------ Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

/*------- Misc -------*/
import { noop } from '../../utils/common';

interface Props {
    open: boolean;
    onClose: Function;

    title: string;
    text: string;

    onClickYes: Function;
    loadingYes: boolean;

    onClickNo: Function;
    loadingNo: boolean;
}

export default function ModalConfirm(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*-------- Constants -----------*/
    const { open, onClose, title, text, onClickYes, loadingYes, onClickNo, loadingNo } = props;
    const Modal = FakeModal as any;

    /*-------- Functions -----------*/

    return (
        <Modal open={open} onClose={() => onClose()} className={styleSheet.root}>
            <div className={styleSheet.container}>
                <p className={styleSheet.title}>{title}</p>

                {text.length && <p className={styleSheet.text}>{text}</p>}

                <div className={styleSheet.buttonsContainer}>
                    <Button
                        customStyle={styleSheet.button}
                        title="Yes"
                        loading={loadingYes}
                        onClick={() => onClickYes()}
                    />
                    <Button
                        type="white"
                        customStyle={styleSheet.button}
                        title="No"
                        loading={loadingNo}
                        onClick={() => onClickNo()}
                    />
                </div>
            </div>
        </Modal>
    );
}

ModalConfirm.defaultProps = {
    open: false,
    onClose: noop,

    title: '',
    text: '',

    onClickYes: noop,
    loadingYes: false,

    onClickNo: noop,
    loadingNo: false,
};
