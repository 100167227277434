export const USER_TYPE_FILTER = [
    'Super Admin',
    'Admin',
    'Viewer',
    'Signed Up',
    'Not Signed Up',
    'All',
];

export const HEADERS = [
    { name: '', orderable: false, width: '5%' },
    { name: 'Name', orderable: true, width: '20%' },
    { name: 'Email', orderable: true, width: '25%' },
    { name: 'Phone', orderable: true, width: '15%' },
    { name: 'Role', orderable: true, width: '10%' },
    { name: 'Last Active', orderable: true, width: '10%' },
    { name: 'Status', orderable: true, width: '10%' },
    { name: '', orderable: false },
];

export const ROLES = [
    { text: 'Super Admin', value: 'Super Admin' },
    { text: 'Admin', value: 'Admin' },
    // { text: 'Viewer', value: 'Viewer' },
];

export const ITEMS_PER_PAGE = 15;
