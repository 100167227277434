export default (theme: any) => {
    return {
        root: {
            backgroundColor: 'rgba(0,0,0,0.8)',
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            // should cover up everything
            zIndex: 99999,
            display: 'flex',
            justifyContent: 'center',
        },
        modal: ({ style }: any) => ({
            backgroundColor: theme.palette.white,
            top: 50,
            width: 488,
            alignSelf: 'center',
            borderRadius: 5,
            position: 'fixed',
            minHeight: 670,
            ...style,
        }),
    };
};