export default (theme) => {
    return {
        default: {
            backgroundColor: theme.palette.blue,
            ...theme.paddingVertical(5),
            ...theme.paddingHorizontal(10),

            borderRadius: 5,
            border: 'none',
            cursor: 'pointer',
            color: theme.palette.white,

            '&:active': {
                backgroundColor: '#48a3ffb9',
            },
        },
        danger: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(5),
            ...theme.paddingHorizontal(10),

            cursor: 'pointer',
            color: theme.palette.red,

            border: {
                color: theme.palette.red,
                width: 2,
                style: 'solid',
                radius: 5,
            },

            '&:active': {
                backgroundColor: '#ff43438f',
                color: theme.palette.white,
            },
        },
        white: {
            backgroundColor: theme.palette.white,
            ...theme.paddingVertical(5),
            ...theme.paddingHorizontal(10),

            cursor: 'pointer',
            color: theme.palette.blue,

            border: {
                color: theme.palette.blue,
                width: 2,
                style: 'solid',
                radius: 5,
            },

            '&:active': {
                backgroundColor: '#48a3ffb9',
                color: theme.palette.white,
            },
        },
        text: {
            fontSize: 18,
            ...theme.typography.medium,
        },
        widthAuto: {
            width: 'auto',
            ...theme.paddingVertical(5),
            ...theme.paddingHorizontal(40),
        },

        widthFull: {
            width: '100%',
        },
        disabled: {
            pointerEvents: 'none',
            backgroundColor: theme.palette.grey,
            color: theme.palette.white,

            border: {
                color: theme.palette.separatorGrey,
                width: 2,
                style: 'solid',
            },
        },
        loading: {
            pointerEvents: 'none',
            backgroundColor: theme.palette.semiLightBlue,
        },
        widthSmall: {
           
            ...theme.paddingVertical(5),
            ...theme.paddingHorizontal(20),
            border: '1px solid #48a3ffb9 ',
        },
        smallText: {
            fontSize: 14,
            ...theme.typography.medium,
           
        },
    };
};
