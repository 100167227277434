export default (theme: any) => {
    return {
        headerContainer: {
            height: 47, // wireframe 86
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor: theme.palette.separatorGrey,
            borderStyle: 'solid',
            ...theme.marginHorizontal(48),
        },
        closeIconContainer: {
            position: 'absolute',
            right: 16.91, // wireframe 26.91
            top: 11.23, // wireframe 26.23
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
        },
        drawerHeaderText: {
            fontFamily: 'Poppins-medium',
            fontSize: 18,
            color: theme.palette.mediumGrey,
            paddingTop: 10, // wireframe 27
        },
    };
};
