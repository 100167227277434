export default (theme) => {
    const flexCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return {
        root: {
            ...flexCenter,
        },
        container: {
            backgroundColor: theme.palette.white,
            width: 1018,
            borderRadius: 10,
            padding: 30,
        },

        /*------- Header ----------*/
        header: {
            display: 'table',
            width: '100%'
        },
        title: {
            display: 'table-row',
            color: theme.palette.blue,
            fontSize: 20
        },
        col: {
            display: 'table-cell'
        },

        /*------- Body ------------*/
        textContainer: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 18,
            lineHeight: '27px',
            color: '#3A3A3A',
            height: '100%',
            flexWrap: 'wrap',
            gridAutoFlow: 'row',
            textDecoration: 'none',
            textAlign: 'justify',
            padding: '10px 40px'
        },
        infoContainer: {
            width: '90%',
            display: 'table',
            flexWrap: 'wrap',
            gridAutoFlow: 'row',
            color: 'black',
            gridTemplateRows: 'repeat(2, 1fr)',
            gridTemplateColumns: 'repeat(2, 1fr)',
            justifyItems: 'baseline',
            padding: '0 50px 0 50px',
            lineHeight: '34px',
            fontWeight: 500,
            fontSize: 22,
        },
        right: {
            textAlign: 'right',
            paddingRight: 20,
            paddingBottom: 10
        },
        infoContainerRow: {
            display: 'table-row',
        },
        blueText: {
            color: theme.palette.blue
        },
        center: {
            textAlign: 'center'
        },
        button: {
            width: '436px !important'
        },
        closeButton: {
            textAlign: 'right',
        },
    };
};
