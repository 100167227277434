import { SHOWINGS_SELECTED_VIEW } from './constants';
import { SHOWINGS_ACTION } from './actions';
import { STATUS } from '../../../utils/constants';

export const initialState = {
    loading: false,
    filter: SHOWINGS_SELECTED_VIEW.ListSide,
    error: '',
    showingsFetchedSuccessfully: false,
    showingDetailsFetchSuccess: false,
    showings: [],
    showing: null,
    updatedShowing: '',
    updatedShowingError: '',
    submittingFeedback: false,
    feedbackError: null,
    currentPage: {
        listSide: { all: 0, upcoming: 0, needsAction: 0 },
        buySide: { all: 0, upcoming: 0, needsAction: 0 },
    },
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SHOWINGS_ACTION.Fetch:
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                        showingsFetchedSuccessfully: false,
                    };
                }
                case STATUS.Succeeded: {
                    const showings = action.showings;
                    const filter = action.filter;
                    return {
                        ...state,
                        loading: false,
                        error: '',
                        showings,
                        filter,
                        showingsFetchedSuccessfully: true,
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        error: action.error,
                    };
                default:
                    return state;
            }
        case SHOWINGS_ACTION.FetchDetails:
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        loading: true,
                        showingDetailsFetchSuccess: false,
                    };
                }
                case STATUS.Succeeded: {
                    const showing = action.showing;
                    return {
                        ...state,
                        loading: false,
                        error: '',
                        showing,
                        showingDetailsFetchSuccess: true,
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        loading: false,
                        error: action.error,
                    };
                default:
                    return state;
            }
        case SHOWINGS_ACTION.UpdateShowingStatus:
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        updatedShowing: action.id.toString(),
                        updatedShowingError: '',
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        updatedShowing: '',
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        updatedShowingError: action.error,
                    };
                default:
                    return state;
            }
        case SHOWINGS_ACTION.SubmitFeedback:
            switch (action.status) {
                case STATUS.Requested: {
                    return {
                        ...state,
                        submittingFeedback: true,
                        feedbackError: false,
                    };
                }
                case STATUS.Succeeded: {
                    return {
                        ...state,
                        submittingFeedback: false,
                    };
                }
                case STATUS.Failed:
                    return {
                        ...state,
                        feedbackError: action.error,
                    };
                default:
                    return state;
            }
        case SHOWINGS_ACTION.SetCurrentPage:
            switch (action.status) {
                case STATUS.Succeeded: {
                    const { page } = action;
                    return {
                        ...state,
                        currentPage: page,
                    };
                }
            }

        default:
            return state;
    }
}
