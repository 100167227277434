import { combineReducers } from 'redux';
// import auth from '../domains/auth/reducer';
import auth from '../domains/auth/reducer';
// import agent from '../domains/main/AgentDirectory/reducer';
// import profile from '../domains/main/Profile/reducer';
// import dash from '../domains/main/Dashboard/reducer';
// import brokerage from '../domains/main/Brokerage/reducer';
// import widgets from '../components/Widgets/reducer';
import manageUsers from '../domains/main/Users/reducer';
import listings from '../domains/main/Listings/reducer';
import showings from '../domains/main/Showings/reducer';
import searchMap from '../domains/main/SearchMap/reducer';
import showingRequests from '../domains/main/ShowingRequests/reducer';
import profile from '../domains/main/Profile/reducer';
import clients from '../domains/main/Clients/reducer';

import agents from '../domains/main/Agents/reducer';
import selectedAgent from '../domains/main/Agents/AgentProfile/reducer';

// DEVELOPER NOTE: Please keep the reducers in alphabetical order.
export default combineReducers({
    // agent,
    auth,
    // dash,
    // brokerage,
    manageUsers,
    listings,
    profile,
    showings,
    searchMap,
    showingRequests,
    agents,
    selectedAgent,
    clients,
    // widgets,
});
