const timeContainerStyles = {
    marginLeft: 10,
    padding: 4,
    borderRadius: 5,
    minWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    marginVertical: 10,
};
export default (theme: any) => {
    return {
        root: {},
        smallWeekDay: {
            border: {
                color: theme.palette.blue,
                radius: 3,
                width: 2,
                style: 'solid',
            },
            width: 18,
            height: 18,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 1.5,
            marginRight: 1.5,
        },
        smallWeekDayText: {
            color: theme.palette.blue,
            ...theme.typography.semiBold,
            fontSize: 12,
        },
        titleText: {
            ...theme.typography.medium,
            fontSize: 15,
            color: theme.palette.mediumGrey,
        },
        noticeTimeContainer: {
            ...timeContainerStyles,
            backgroundColor: theme.palette.separatorGrey,
            borderColor: theme.palette.separatorGrey,
        },
        timeContainerText: {
            ...theme.typography.semiBold,
            color: theme.palette.darkGrey,
            fontSize: 15,
        },
        daySectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            ...theme.marginVertical(5),
        },
    };
};
