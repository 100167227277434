import React, { useState, useEffect, useRef } from 'react';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchListingsRequested,
    fetchListingSucceeded,
    changeSelectedView,
    setConfigDetails,
} from './actions';
import {
    getAllListings,
    getTotalPages,
    getCurrentPage,
    isRequestInProgress,
    getGlobalRecords,
    getListingSearchText,
    getListingSortIndex,
    getListingSortType,
} from './selectors';

/*-------- Components ---------*/
import { Paginator } from 'web-lib';
import DataTable from '../../../components/DataTable';
import SearchInput from '../../../components/SearchInput';

/*-------- Assets ---------*/
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import configure from '../../../images/icons/configureListing.svg';
import nonConfigure from '../../../images/icons/nonConfigureListing.svg';

/*-------- Constants  ---------*/
import { Listing, Brokerage } from '../../../utils/constants';
import { ITEMS_PER_PAGE, HEADERS } from './constants';

/*-------- Misc  ---------*/
import { useHistory } from 'react-router-dom';
import { TablePage } from './types';
import { SortTypeEnum, TableData } from '../../../components/DataTable/types';
import { convertIntoAddress, splitCamelCaseToString } from '../../../utils/common';

export default function Listings() {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Selectors ---------*/
    const totalPages = useSelector<any, number>(getTotalPages);
    const currentPage = useSelector<any, number>(getCurrentPage);
    const isLoading = useSelector<any, boolean>(isRequestInProgress);
    const listings = useSelector<any, TablePage[]>(getAllListings);
    const globalRecords = useSelector<any, number>(getGlobalRecords);

    const searchRedux = useSelector<string, string>(getListingSearchText);
    const sortReduxIndex = useSelector<any>(getListingSortIndex);
    const sortReduxType = useSelector<any>(getListingSortType);

    /*-------- Constants & Variables ---------*/
    const history = useHistory();
    const dispatch = useDispatch();
    const paginatedListings = listings.find((e) => e.page === currentPage)?.data ?? [];
    const mountRef = useRef<boolean>(false);

    /*-------- States ---------*/
    const [search, setSearch] = useState<string>(searchRedux);
    const [sort, setSort] = useState(null);
    const [sortIndex, setSortIndex] = useState<any>(sortReduxIndex);
    const [sortType, setSortType] = useState<any>(sortReduxType);

    /*-------- Use Effect ---------*/
    // Search
    useEffect(() => {
        if (mountRef.current) {
            fetchListings(1, true);
        }
    }, [search, sort]);

    useEffect(() => {
        mountRef.current = true;
        if (!paginatedListings.length) {
            fetchListings(currentPage, true);
        }
    }, []);

    /*-------- Functions ---------*/
    const fetchListings = (page: number, refresh: boolean = false) => {
        dispatch(fetchListingsRequested(page, refresh, search, sort, sortIndex, sortType));
    };

    const getPrice = (price) => {
        return price ? `$${price.listPrice}` : `$0`;
    };

    const handleTableSort = (sortIndex: number, sortType: SortTypeEnum) => {
        if (sortIndex) {
            const sort = sortType === SortTypeEnum.ASC ? 1 : -1;
            switch (sortIndex) {
                case 1:
                    // Sort by address
                    setSort({ ['address.full']: sort });
                    break;
                case 2: // Sort by Last Session
                    setSort({ ['agent.lastName']: sort });
                    break;
                default:
                    return 0;
            }
            setSortIndex(sortIndex);
            setSortType(sortType);
        }
    };

    const navigation = (rowListing) => {
        dispatch(changeSelectedView('Overview'));
        const clickedListing = paginatedListings.find(
            (listing) => listing?.mlsList[0]?.listingId === rowListing[7],
        );
        const isConfig = rowListing[8].props.src == '/static/media/configureListing.45c4f35e.svg';
        localStorage.removeItem('configDefaultAgentListing');
        isConfig
            ? dispatch(fetchListingSucceeded(clickedListing))
            : dispatch(setConfigDetails(clickedListing, 1));
        history.push(
            `/listings/${isConfig ? '' : 'configure/'}${
                clickedListing?.mlsList?.length
                    ? clickedListing?.mlsList[0]?.mlsName +
                      '/' +
                      clickedListing?.mlsList[0]?.listingId
                    : ''
            }`,
        );
    };

    const createTableRow = (listing) => [
        <></>,
        `${convertIntoAddress(listing.address)}`,
        `${listing.agent.firstName} ${listing.agent.lastName}`,
        `${getPrice(listing)}`,
        `${splitCamelCaseToString(listing.mls.status)}`,
        `${listing.mls.daysOnMarket}`,
        `${listing?.ratings?.totalShowings ?? '-'}`,
        `${listing?.mlsList[0]?.listingId ?? '-'}`,
        listing.agentListing ? <img src={configure} /> : <img src={nonConfigure} />,
        <></>,
    ];

    const transformUsersToTableRow = (listings: Listing[]): TableData => {
        return listings.map((listing: any) => createTableRow(listing));
    };

    return (
        <>
            <div className={styleSheet.root}>
                <div className={styleSheet.header}>
                    <h4 className={styleSheet.heading}>Listings</h4>
                    <div className={styleSheet.row}>
                        <SearchInput
                            value={search}
                            onChangeValue={setSearch}
                            placeholder={'Search Listings'}
                            containerClass={styleSheet.searchInputContainer}
                        />
                    </div>
                    <span className={styleSheet.numberOfPage}>
                        {!isLoading && paginatedListings?.length > 0
                            ? `${currentPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE + 1} - ${
                                  currentPage * ITEMS_PER_PAGE -
                                  ITEMS_PER_PAGE +
                                  paginatedListings?.length
                              } of ${globalRecords}`
                            : paginatedListings?.length == 0
                            ? 'No data'
                            : 'Loading'}
                    </span>
                </div>
            </div>
            <DataTable
                headers={HEADERS}
                rows={transformUsersToTableRow(paginatedListings)}
                loading={isLoading}
                onSort={handleTableSort}
                customSortIndex={sortIndex}
                customSortType={sortType}
                sortingEnabled={search?.length === 0}
                wrapperStyles={styleSheet.wrapperStyles}
                headerStyle={styleSheet.headerStyle}
                itemStyle={styleSheet.rowitemStyle}
                onHoverClick={navigation}
                height={'61vh'}
            />
            <Paginator
                isLoading={isLoading}
                numberOfPages={totalPages}
                handleOnChange={(pageNumber) => {
                    fetchListings(pageNumber + 1);
                }}
                currentPageNumber={currentPage - 1}
            />
        </>
    );
}
