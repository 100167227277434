import * as Realm from 'realm-web';
/*----- Redux imports -------*/
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ManageUsersActions from './actions';
import { getProfileData } from '../Profile/selectors';
import { getProfileDataSucceeded } from '../Profile/actions';
import { getCurrentPage, getTotalPages, getUsers } from './selectors';

/*------ Constants -----------*/
import { STATUS } from '../../../utils/constants';
import { BROKERAGE_USERS_COLLECTION_NAME } from '../../../store/api/constants';
import { ITEMS_PER_PAGE } from './constants';
import { TablePage } from './types';

/*----- Utils imports -------*/
import {
    callStitchFunction,
    deleteBrokerageUser,
    findRecord,
    getRealmClient,
    updateBrokerageUser,
} from '../../../store/api/sagas';

/*----- Misc imports -------*/
import { parseStitchServiceError } from '../../../utils/common';
import { SortTypeEnum } from '../../../components/DataTable/types';

export function* fetchUsers({
    brokerageId = null,
    search = '',
    sort = null,
    pageNumber = 1,
    forceReload = false,
    sortIndex = 0,
    sortType = SortTypeEnum.ASC,
}: any): Generator<any, any, any> {
    try {
        let users: TablePage[] = yield select(getUsers);
        let totalPages: number = yield select(getTotalPages);
        const existingIndex = users.findIndex((e) => e.page === pageNumber);

        if (forceReload || existingIndex === -1) {
            // Fetch requested page
            const response = yield call(
                callStitchFunction,
                'fetchAllBrokerageUsers',
                pageNumber,
                ITEMS_PER_PAGE,
                search,
                brokerageId,
                sort,
            );

            if (response.length) {
                const { metadata, data } = response[0];

                if (data.length) {
                    // Calculate total pages
                    totalPages = Math.ceil(metadata[0].total / ITEMS_PER_PAGE);

                    if (forceReload) {
                        // In case force reload, remove previously stored data
                        users = [{ page: pageNumber, data }];
                    } else {
                        users = [...users, { page: pageNumber, data }];
                    }
                } else {
                    yield put(ManageUsersActions.FetchUsersSucceeded([], 1, 0));
                    return;
                }
            } else {
                yield put(ManageUsersActions.FetchUsersFailed('Fetch data failed.'));
            }
        }

        yield put(
            ManageUsersActions.FetchUsersSucceeded(
                users,
                pageNumber,
                totalPages,
                search,
                sort,
                sortIndex,
                sortType,
            ),
        );
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ManageUsersActions.FetchUsersFailed(errorMessage));
    }
}

export function* inviteUser({
    brokerageId,
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
}: any): Generator<any, any, any> {
    try {
        const response = yield call(
            callStitchFunction,
            'createBrokerageUser',
            brokerageId,
            firstName,
            lastName,
            email,
            phoneNumber,
            role, //'Admin', // momentarily hardcode the role
        );
        if (response?.insertedId) {
            yield put(ManageUsersActions.InviteUserSucceeded(response.insertedId));
        } else {
            yield put(
                ManageUsersActions.InviteUserFailed(
                    "The user couln't be created, try again later.",
                ),
            );
        }
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ManageUsersActions.InviteUserFailed(errorMessage));
    }
}

export function* updateUser({ userId, values, callback }: any): Generator<any, any, any> {
    try {
        const profileData = yield select(getProfileData);
        const currentPage = yield select(getCurrentPage);
        let unset = null;

        // Get the full record of the user
        const userRecord = yield call(findRecord, BROKERAGE_USERS_COLLECTION_NAME, { _id: userId });

        // If the email is updated, need to verify again the email and create a new realm user.
        if (values.email?.length && values.email != userRecord.email) {
            values = {
                ...values,
                signUpCompleted: false,
                status: 'unverified',
            };

            unset = {
                signUpDate: '',
                stitchUserId: '',
            };
        }

        yield call(updateBrokerageUser, userId, values, unset);

        // If the user is the current auth user then update profile data
        if (profileData._id.toHexString() == userId.toHexString()) {
            const updatedUserRecord = yield call(findRecord, BROKERAGE_USERS_COLLECTION_NAME, {
                _id: userId,
            });
            yield put(getProfileDataSucceeded(updatedUserRecord));
        }

        // Reload data table
        yield put(
            ManageUsersActions.FetchUsersRequested(profileData?.brokerageId, currentPage, true),
        );

        if (callback) {
            callback();
        }

        // Call success Action
        yield put(ManageUsersActions.UpdateUserSucceeded());
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ManageUsersActions.UpdateUserFailed(errorMessage));
    }
}

export function* deleteUser({ userId, callback }: any): Generator<any, any, any> {
    try {
        const profileData = yield select(getProfileData);
        const currentPage = yield select(getCurrentPage);
        const client = getRealmClient();

        if (profileData._id.toHexString() == userId.toHexString()) {
            yield put(
                ManageUsersActions.DeleteUserFailed('Cannot delete the current user account.'),
            );
        } else {
            const userRecord = yield call(findRecord, BROKERAGE_USERS_COLLECTION_NAME, {
                _id: userId,
            });

            if (userRecord.stitchUserId) {
                // Delete from realm
            }

            yield call(deleteBrokerageUser, userId);
        }

        // Reload data table
        yield put(
            ManageUsersActions.FetchUsersRequested(profileData?.brokerageId, currentPage, true),
        );

        if (callback) {
            callback();
        }

        // Call success Action
        yield put(ManageUsersActions.DeleteUserSucceeded());
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ManageUsersActions.DeleteUserFailed(errorMessage));
    }
}

export function* resendInvitation({ userId, callback }: any): Generator<any, any, any> {
    try {
        yield call(callStitchFunction, 'sendBrokerageSuiteNotification', 'new user', userId);

        if (callback) {
            callback();
        }
        // Call success Action
        yield put(ManageUsersActions.ResendInviteSucceeded());
    } catch (error) {
        const errorMessage = parseStitchServiceError(error);
        yield put(ManageUsersActions.ResendInviteFailed(errorMessage));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === ManageUsersActions.Actions.FetchUsers &&
                action.status === STATUS.Requested,
            fetchUsers,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ManageUsersActions.Actions.InviteUser &&
                action.status === STATUS.Requested,
            inviteUser,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ManageUsersActions.Actions.UpdateUser &&
                action.status === STATUS.Requested,
            updateUser,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ManageUsersActions.Actions.DeleteUser &&
                action.status === STATUS.Requested,
            deleteUser,
        ),
        yield takeLatest(
            (action: any) =>
                action.type === ManageUsersActions.Actions.ResendInvite &&
                action.status === STATUS.Requested,
            resendInvitation,
        ),
    ]);
}
