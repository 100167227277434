import React, { useRef } from 'react';

/*-------- Components ---------*/

/*-------- Assets ------------*/
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*-------- Icons -------------*/

/*------ Redux -------------*/
import { useDispatch, useSelector } from 'react-redux';

/*------ Constants / Types ---------*/
import { FilterOptionType } from '../../types';

/*------------ MISC -----------*/
import { noop } from '../../../../../utils/common';
import AnimatedDrawer from '../../../../../components/AnimatedDrawer';

interface FilterDrawerProps {
    filterVisible: boolean;
    setFilterVisible: Function;
    filterOptions: FilterOptionType;
    setFilterOptions: Function;
}

export default function FilterDrawer(props: FilterDrawerProps) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, FilterDrawerProps, any>(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ ...props, theme });

    /*------ Constants --------*/
    const { filterVisible, setFilterVisible, filterOptions, setFilterOptions } = props;

    // used to close the animated drawer
    const closeDrawerRef = useRef<any>();

    return filterVisible ? (
        <AnimatedDrawer
            onClose={() => {
                // setUnconfirmedFilterOptions(cloneDeep(filterOptions));
                setFilterVisible(false);
            }}
            ref={closeDrawerRef}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 20,
                    height: '100%',
                }}
            >
                Coming soon
            </div>
        </AnimatedDrawer>
    ) : (
        <></>
    );
}

FilterDrawer.defaultProps = {
    filterVisible: false,
    setFilterVisible: noop,
    filterOptions: {},
    setFilterOptions: noop,
};
