/*----- Redux imports -------*/
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as AgentsAction from './actions';
import { callStitchFunction, textSearchAgents } from '../../../store/api/sagas';

/*----- Utils imports -------*/
import { parseStitchServiceError } from '../../../utils/common';
import { FETCH_ALL_AGENTS } from '../../../store/api/constants';

/*----- Misc imports -------*/
import { STATUS } from '../../../utils/constants';
import { getSelectedBrokerage } from '../Profile/selectors';
import { getAllAgents, getTotalPages, getGlobalRecords } from './selectors';
import { ITEMS_PER_PAGE } from './constants';
import { SortTypeEnum } from '../../../components/DataTable/types';

export function* getAll({
    forceReload = false,
    pageNumber = 1,
    textSearch = '',
    sort = null,
    sortIndex = 0,
    sortType = SortTypeEnum.ASC,
}: any): Generator<any, any, any> {
    try {
        const brokerage = yield select(getSelectedBrokerage);
        let agents = yield select(getAllAgents);
        let totalPages: number = yield select(getTotalPages);
        let globalRecords: number = yield select(getGlobalRecords);
        const existingIndex = agents.findIndex((e) => e.page === pageNumber);

        if (forceReload || existingIndex === -1) {
            // Fetch requested page
            const response = yield call(
                callStitchFunction,
                FETCH_ALL_AGENTS,
                pageNumber, //pageNumber
                ITEMS_PER_PAGE, //items per page
                textSearch, //text to search
                sort, //sort
                brokerage.markets[0].brokerageId,
                brokerage.markets[0].mlsName,
            );

            if (response.length) {
                const { metadata, data } = response[0];

                if (data.length) {
                    // Calculate total pages
                    totalPages = Math.ceil(metadata[0].total / ITEMS_PER_PAGE);
                    globalRecords = Math.ceil(metadata[0].total);

                    if (forceReload) {
                        // In case force reload, remove previously stored data
                        agents = [{ page: pageNumber, data }];
                    } else {
                        agents = [...agents, { page: pageNumber, data }];
                    }
                } else {
                    yield put(AgentsAction.getAllAgentsSucceeded([], 1, 0, 0));
                    return;
                }
            } else {
                yield put(AgentsAction.getAllAgentsFailed(['Fetch data failed.']));
            }
        }

        yield put(
            AgentsAction.getAllAgentsSucceeded(
                agents,
                pageNumber,
                totalPages,
                globalRecords,
                textSearch,
                sort,
                sortIndex,
                sortType,
            ),
        );
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AgentsAction.getAllAgentsFailed([err]));
    }
}

export function* searchAgents({
    search = '',
    onlyBrokerageAgents = false,
}: any): Generator<any, any, any> {
    try {
        const brokerage = yield select(getSelectedBrokerage);
        const matchQuery = onlyBrokerageAgents
            ? [
                  {
                      'markets.brokerageId': brokerage.markets[0].brokerageId,
                  },
              ]
            : null;
        const searchedAgents = yield call(textSearchAgents, search, matchQuery);
        yield put(AgentsAction.searchAgentsSucceeded(searchedAgents));
    } catch (error) {
        const err = parseStitchServiceError(error);
        yield put(AgentsAction.searchAgentsFailed(err));
    }
}

export default function* (): Generator<any, any, any> {
    yield all([
        yield takeLatest(
            (action: any) =>
                action.type === AgentsAction.AGENTS_ACTION.GetAllAgentsRequested &&
                action.status === STATUS.Requested,
            getAll,
        ),

        yield takeLatest(
            (action: any) =>
                action.type === AgentsAction.AGENTS_ACTION.SearchAgents &&
                action.status === STATUS.Requested,
            searchAgents,
        ),
    ]);
}
