import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

/*----- Components --------*/
import AnimatedDrawer from '../../../../components/AnimatedDrawer';
import AnimatedDrawerHeader from '../../../../components/AnimatedDrawerHeader';
import Loader from '../../../../components/Loader';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';
import Typography from '../../../../components/Typography';
import RadioButtons from '../../../../components/RadioButtons';
import Button from '../../../../components/Button';

/*--------- Redux ----------*/
import { useDispatch, useSelector } from 'react-redux';

/*--------- Styles -----------*/
import theme from '../../../../theme';
import { createUseStyles, useTheme } from 'react-jss';
import styles from './styles';

/*------- Constants -------*/
import { radioButtonData } from './constants';

/*------- Assets -------*/
import ArrowDownIcon from '../../../../images/arrows/arrowDownBlack.svg';
import ArrowUpIcon from '../../../../images/arrows/arrowUpBlack.svg';

/*-------- Utils -------*/
import { formatEmailForDisplay, isMobile, noop } from '../../../../utils/common';
import { isEmpty } from 'lodash';
import { isValidEmail, isValidPhoneNumber } from '../../../../utils/common/validations';
import { maskPhoneNumber, unmaskPhoneNumber } from '../../../../utils/common/masks';
import { setSelectedAgent, setUnverifiedAgentData } from '../actions';
import { Agent } from '../../../../utils/constants';
import { getSearchedAgents, isSearchAgentsLoading } from '../../Agents/selectors';
import { searchAgentsRequested } from '../../Agents/actions';
import ProfilePicture from '../../../../components/ProfilePicture';

interface Props {
    visible: boolean;
    onClose: Function;
    backgroundOpacity: number;
    onlyBrokerageAgents: boolean;
}

export default function AddAgentDrawer(props: Props) {
    /*----- Theme -----------*/
    const useStyles = createUseStyles<any, Props, any>(styles);
    const themeStyle = useTheme();
    const styleSheet = useStyles({ ...props, theme: themeStyle });

    /*----- Constants -----*/
    const { onClose, backgroundOpacity, visible, onlyBrokerageAgents } = props;

    const closeDrawerRef = useRef<any>(null);
    const dispatch = useDispatch();
    const isMob = isMobile();
    const AnimatedTypography = animated(Typography);

    const userTypeClass = isMob ? styleSheet.userTypeMobile : styleSheet.userType;
    const buttonClassName = isMob
        ? styleSheet.scheduleButtonsContainerMobile
        : styleSheet.scheduleButtonsContainer;

    const inputOptions: any = {
        other: {
            placeholder: 'If other, please specify',
            onChange: (e: any) => setOtherText(e),
            onFocus: () => setRadioOption('Other'),
            style: { width: 223 },
        },
        firstName: {
            placeholder: 'First Name',
            onChange: (e: string) => setUserData({ ...userData, firstName: e }),
        },
        lastName: {
            placeholder: 'Last Name',
            onChange: (e: string) => setUserData({ ...userData, lastName: e }),
        },
        phone: {
            placeholder: 'Phone Number',
            onChange: (e: string) => setUserData({ ...userData, phone: maskPhoneNumber(e) }),
        },
        email: {
            placeholder: 'Email',
            onChange: (e: string) => setUserData({ ...userData, email: e }),
        },
        company: {
            placeholder: 'Brokerage/Company Name',
            onChange: (e: string) => setUserData({ ...userData, company: e }),
        },
        license: {
            placeholder: 'License Number',
            onChange: (e: string) => setUserData({ ...userData, license: e }),
        },
    };

    /*-------- State -----------*/
    const [searchText, setSearchText] = useState<string>('');
    const [isUnverifiedAgent, setIsUnverifiedAgent] = useState<boolean>(false);
    const [screenHeight, setScreenHeight] = useState<number>(0);
    const [otherText, setOtherText] = useState<string>('');
    const [radioOption, setRadioOption] = useState<string>('Licensed Agent');
    const [userData, setUserData] = useState<any>({});
    const [invalidFields, setInvalidFields] = useState<any>({});
    const [openedAgent, setOpenedAgent] = useState<Agent | null>(null);

    /*-------- Selectors --------*/
    const loading = useSelector<any, boolean>(isSearchAgentsLoading);
    const agents = useSelector<any, Agent[]>(getSearchedAgents);

    /*-------- Effects ----------*/
    /**
     * Make sure that the agent scroll view resizes when the screen
     * is resized.
     */
    useLayoutEffect(() => {
        window.addEventListener('resize', () => setScreenHeight(window.innerHeight));
        setScreenHeight(window.innerHeight);
        return () =>
            window.removeEventListener('resize', () => setScreenHeight(window.innerHeight));
    }, []);

    useEffect(() => {
        if (searchText.length) {
            dispatch(searchAgentsRequested(searchText, onlyBrokerageAgents));
        }
    }, [searchText]);

    useEffect(() => {
        // State's cleanup
        if (visible) {
            setSearchText('');
            setIsUnverifiedAgent(false);
            setUserData({});
            setOpenedAgent(null);
        }
    }, [visible]);

    /*------- Functions --------*/

    const searchBarComponent = (
        <div className={styleSheet.searchBarContainer}>
            <input
                placeholder={'Search for Existing Buyer Agent'}
                onChange={(e: any) => {
                    setSearchText(e.target.value);
                }}
                className={styleSheet.searchBar}
            />
        </div>
    );

    const agentsComponent = (
        <>
            {loading ? (
                <div className={styleSheet.loaderContainer}>
                    <Loader size={50} />
                    <p>Searching agents</p>
                </div>
            ) : (
                <>
                    <div
                        className={styleSheet.agentsContainer}
                        style={agents?.length ? { height: screenHeight - 250 } : {}}
                    >
                        {agents?.length ? (
                            agents.map((e) => {
                                const isOpened =
                                    e._id.toHexString() === openedAgent?._id.toHexString();

                                return (
                                    <div className={styleSheet.agentCard}>
                                        <ClickableWithFeedback
                                            onClick={() => {
                                                setOpenedAgent(isOpened ? null : e);
                                            }}
                                        >
                                            <div className={styleSheet.agentCardHeader}>
                                                <div className={styleSheet.row}>
                                                    <ProfilePicture user={e} size={30} />
                                                    <span
                                                        className={styleSheet.agentName}
                                                    >{`${e.firstName} ${e.lastName}`}</span>
                                                </div>
                                                {isOpened ? (
                                                    <img
                                                        src={ArrowUpIcon}
                                                        className={styleSheet.arrowIcon}
                                                    />
                                                ) : (
                                                    <img
                                                        src={ArrowDownIcon}
                                                        className={styleSheet.arrowIcon}
                                                    />
                                                )}
                                            </div>
                                        </ClickableWithFeedback>

                                        {isOpened && (
                                            <div className={styleSheet.agentCardBody}>
                                                <div className={styleSheet.agentInfo}>
                                                    Email
                                                    <span className={styleSheet.agentInfoLabel}>
                                                        {formatEmailForDisplay(e.email)}
                                                    </span>
                                                </div>
                                                <div className={styleSheet.agentInfo}>
                                                    Phone
                                                    <span className={styleSheet.agentInfoLabel}>
                                                        {maskPhoneNumber(e.phoneNumber)}
                                                    </span>
                                                </div>
                                                <div className={styleSheet.agentInfo}>
                                                    States
                                                    <span className={styleSheet.agentInfoLabel}>
                                                        {e.markets
                                                            ?.map((market) => market.marketName)
                                                            .join(', ')}
                                                    </span>
                                                </div>
                                                <Button
                                                    title={'Select Agent'}
                                                    widthType={'Auto'}
                                                    customStyle={styleSheet.selectAgentButton}
                                                    onClick={() => {
                                                        setOpenedAgent(null);
                                                        dispatch(setSelectedAgent(e));
                                                        closeDrawerRef.current.closeDrawer();
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <div className={styleSheet.noResults}>
                                {searchText.length ? 'No agents' : 'Search for an Agent'}
                            </div>
                        )}
                    </div>
                    <div className={styleSheet.addAgentButton}>
                        <ClickableWithFeedback onClick={() => setIsUnverifiedAgent(true)}>
                            Create New Agent
                        </ClickableWithFeedback>
                    </div>
                </>
            )}
        </>
    );

    /*------ Unverified agent -------*/

    // check that the user has filled in all necessary fields
    const disabledConditions = () => {
        // check the necessary fields exist
        var invalid: any = {};
        if (radioOption === 'Other' && !otherText?.length) {
            invalid['other'] = true;
        }

        // skip certain fields based on user type
        var skip: string[] = ['other'];
        if (['Unrepresented Homebuyer', 'Other'].includes(radioOption)) {
            skip.push('company', 'license');
        }

        Object.keys(inputOptions).map((e: any) => {
            if (!skip.includes(e) && !userData[e]?.length) {
                invalid[e] = true;
            }
        });

        // check if valid email
        if (!isValidEmail(userData['email'])) {
            invalid['email'] = true;
        }
        if (!isValidPhoneNumber(userData['phone'])) {
            invalid['phone'] = true;
        }

        setInvalidFields(invalid);
        if (isEmpty(invalid)) {
            dispatch(
                setUnverifiedAgentData({
                    ...userData,
                    phoneNumber: unmaskPhoneNumber(userData.phone),
                    occupationType: radioOption,
                    otherOccupation: otherText,
                }),
            );
            closeDrawerRef?.current?.closeDrawer();
        }
    };

    const animatedStyleSheet: any = {
        bottomOptionVisible: useSpring({
            opacity: ['Unrepresented Homebuyer', 'Other'].includes(radioOption) ? 0 : 1,
        }),
    };

    const getInputOption = (type: string) => {
        var optStyle = inputOptions[type].style || {};
        if (invalidFields[type]) {
            optStyle = {
                ...optStyle,
                border: '1px solid red',
            };
        }
        return (
            <input
                className={styleSheet.input}
                placeholder={inputOptions[type].placeholder}
                onChange={(e: any) => inputOptions[type].onChange(e.target.value)}
                onFocus={inputOptions[type].onFocus || null}
                style={optStyle}
                value={userData[type]}
            />
        );
    };

    const userTypeComponent = (
        <>
            <Typography textStyle="p1" color={theme.palette.mediumGrey} style={{ height: 20 }}>
                Who Are You?
            </Typography>
            <RadioButtons
                data={radioButtonData.map((e: any) =>
                    e.label ? e : { ...e, extraLabelContent: getInputOption('other') },
                )}
                selectedValue={radioOption}
                onChangeValue={(e: string) => setRadioOption(e)}
            />
            <div className={styleSheet.separatorBar} />
        </>
    );

    const userMetaData = (
        <div className={styleSheet.container}>
            {/* Name */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Name
            </Typography>
            <div className={styleSheet.metaRow}>
                {getInputOption('firstName')}
                {getInputOption('lastName')}
            </div>
            {/* Contact */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Contact
            </Typography>
            <div className={styleSheet.metaRow}>
                {getInputOption('phone')}
                {getInputOption('email')}
            </div>
            {/* Company & License */}
            <AnimatedTypography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
                style={animatedStyleSheet.bottomOptionVisible}
            >
                {'Company & License'}
            </AnimatedTypography>
            <animated.div
                className={styleSheet.metaRow}
                style={animatedStyleSheet.bottomOptionVisible}
            >
                {getInputOption('company')}
                {getInputOption('license')}
            </animated.div>
        </div>
    );

    const userMetaDataMobile = (
        <div className={styleSheet.container}>
            {/* Name */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Name
            </Typography>
            <div className={styleSheet.mb5}>{getInputOption('firstName')}</div>
            <div>{getInputOption('lastName')}</div>
            {/* Contact */}
            <Typography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
            >
                Contact
            </Typography>
            <div className={styleSheet.mb5}>{getInputOption('phone')}</div>
            <div>{getInputOption('email')}</div>
            {/* Company & License */}
            <AnimatedTypography
                textStyle="p1"
                color={theme.palette.mediumGrey}
                className={styleSheet.typography}
                style={animatedStyleSheet.bottomOptionVisible}
            >
                {'Company & License'}
            </AnimatedTypography>
            <animated.div style={animatedStyleSheet.bottomOptionVisible}>
                <div className={styleSheet.mb5}>{getInputOption('company')}</div>
                <div>{getInputOption('license')}</div>
            </animated.div>
        </div>
    );

    const bottomButton = (
        <div className={buttonClassName}>
            <Button
                onClick={() => {
                    disabledConditions();
                }}
                title={'Submit'}
                customStyle={styleSheet.buttonSubmit}
            />
        </div>
    );

    const userMetaDataResponsive = isMob ? userMetaDataMobile : userMetaData;

    if (!visible) {
        return <></>;
    }

    return (
        <AnimatedDrawer
            onClose={onClose}
            ref={closeDrawerRef}
            backgroundOpacity={backgroundOpacity}
        >
            <>
                <AnimatedDrawerHeader
                    title={'Add a Buyer Agent'}
                    onClosePress={() => closeDrawerRef?.current?.closeDrawer()}
                />
                {isUnverifiedAgent ? (
                    <>
                        <div className={styleSheet.addAgentButton}>
                            <ClickableWithFeedback onClick={() => setIsUnverifiedAgent(false)}>
                                Back to Search for Agent
                            </ClickableWithFeedback>
                        </div>
                        <div className={userTypeClass}>
                            {userTypeComponent}
                            {userMetaDataResponsive}
                        </div>
                        {bottomButton}
                    </>
                ) : (
                    <>
                        {searchBarComponent}
                        {agentsComponent}
                    </>
                )}
            </>
        </AnimatedDrawer>
    );
}

AddAgentDrawer.defaultProps = {
    visible: false,
    onClose: noop,
    backgroundOpacity: 0,
    onlyBrokerageAgents: false,
};
