export interface MyAuthState {
    errors: [any];
    agent: any;
    client: any;
    authMls: any;
    authAgent: any;
    stitchUser: any;
    authMarket: any;
    loading: boolean;
    isConnected: boolean;
    initializing: boolean;
    loginRejected: boolean;
    loginInProgress: boolean;
    isAuthenticated: boolean;
    passResetEmailSent: boolean;
    passwordResetActive: boolean;
    isSendingPasswordResetEmail: boolean;
}

export enum STATUS {
    Requested,
    Succeeded,
    Failed,
}
