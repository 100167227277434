export default (theme: any) => {
    return {
        root: {
            backgroundColor: theme.palette.white,
            paddingBottom: 48,
        },
        infoModule: {},
        infoHeader: {
            backgroundColor: theme.palette.background,
            ...theme.paddingVertical(10),
            ...theme.typography.medium,
            fontSize: 16,
            color: theme.palette.mediumGrey,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 48,
        },
        editButton: {
            color: theme.palette.blue,
            ...theme.typography.semiBold,
            fontSize: 16,
            marginLeft: 10,
            cursor: 'pointer',
        },
        childContainer: {
            paddingLeft: 48,
            display: 'flex',
            flexDirection: 'column',
            ...theme.marginVertical(16),
        },
        separator: {
            height: 1,
            backgroundColor: theme.palette.separatorGrey,
            ...theme.marginHorizontal(48),
        },
        copySection: {
            display: 'flex',
            alignItems: 'center',
            width: 100,
        },
        linkText: {
            color: theme.palette.mediumGrey,
            marginLeft: 10,
        },
        textAndTagContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        photoUploadContainer: {
            alignItems: 'center',
            display: 'flex',
            '& p': {
                color: theme.palette.blue,
                ...theme.typography.semiBold,
                marginLeft: 10,
            },
        },
        userCard: {
            marginRight: 100,
            display: 'flex',
            flexDirection: 'column',
        },
    };
};
