/*----- React imports -------*/
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { connectListingRequested } from '../actions';
import { isConnectionLoading } from '../selectors';

/*-------- Components ---------*/
import Stars from '../../../../components/Stars';
import Switch from '../../../../components/Switch';
import Loader from '../../../../components/Loader';
import Typography from '../../../../components/Typography';
import ClickableWithFeedback from '../../../../components/ClickableWithFeedback';

/*-------- Utils ---------*/
import { getUnverifiedLink } from '../utils';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';

/*-------- Icons ---------*/
import CopyIcon from '../../../../images/icons/copyBlue.svg';
import GreenDoor from '../../../../images/icons/greenDoor.svg';
import BlueArrow from '../../../../images/icons/forwardArrow.svg';

interface Props {
    listing: any;
}
const OverviewSection = ({ listing }: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- Constants ---------*/
    const dispatch = useDispatch();
    const history = useHistory();
    // Calculate days on market
    var date1 = new Date(listing?.listDate);
    var date2 = new Date();
    var timeDiff = date2.getTime() - date1.getTime();
    var dayDiff = timeDiff / (1000 * 3600 * 24);

    /*-------- States ---------*/
    const [messageSelected, setMessageSelected] = useState(false);
    const [isAcceptingShowings, updateAcceptingShowings] = useState(true);
    const [isListingSyndication, updateListingSyndication] = useState(true);

    /*-------- Selectors ---------*/
    const loading = useSelector(isConnectionLoading);

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (!loading) {
            updateAcceptingShowings(false);
            updateListingSyndication(false);
        }
    }, [loading, isAcceptingShowings, isListingSyndication]);

    /*-------- Functions ---------*/
    //props are needed for size
    const AcceptingShowings = (props: any) => (
        <>
            <div style={{ color: indexTheme.palette.black }} className={styleSheet.header}>
                Accepting Showings
                <span style={{ display: 'flex' }}>
                    {loading && isAcceptingShowings && (
                        <div style={{ marginTop: -12 }}>
                            <Loader size={20} />
                        </div>
                    )}
                    <Switch
                        checked={listing?.agentListing?.acceptingShowings}
                        onToggle={() => {
                            updateAcceptingShowings(true);
                            dispatch(
                                connectListingRequested(
                                    {
                                        ...listing?.agentListing,
                                        acceptingShowings:
                                            !listing?.agentListing?.acceptingShowings,
                                    },
                                    listing,
                                ),
                            );
                        }}
                    />
                </span>
            </div>
            <span className={styleSheet.subText}>
                We sync your listing with the MLS and if it goes under contract or is sold we will
                change the status. Independently of this, you can enable or disable showings on your
                listing here.
            </span>
        </>
    );
    // props are needed for size
    const ListingSyndication = (props: any) => (
        <>
            <div style={{ color: indexTheme.palette.black }} className={styleSheet.header}>
                Listing Syndication
                <span style={{ display: 'flex' }}>
                    {loading && isListingSyndication && (
                        <div style={{ marginTop: -12 }}>
                            <Loader size={20} />
                        </div>
                    )}
                    <Switch
                        checked={listing?.agentListing?.showToUnrepresentedConsumers}
                        onToggle={() => {
                            updateListingSyndication(true);
                            dispatch(
                                connectListingRequested(
                                    {
                                        ...listing?.agentListing,
                                        showToUnrepresentedConsumers:
                                            !listing?.agentListing?.showToUnrepresentedConsumers,
                                    },
                                    listing,
                                ),
                            );
                        }}
                    />
                </span>
            </div>
            <span className={styleSheet.subText}>
                Allows Unrepresented Consumer to view and schedule on your listing. You can enable
                or disable this option here.
            </span>
        </>
    );

    const StatSection = ({
        mainStat,
        statTitle,
        comingSoon = false,
    }: {
        mainStat: string | number;
        statTitle: string;
        comingSoon?: boolean;
    }) => (
        <div className={styleSheet.statSection}>
            {comingSoon ? (
                <span className={styleSheet.comingSoonText}>Coming Soon</span>
            ) : (
                <span className={styleSheet.mainStat}>{mainStat}</span>
            )}
            <div className={styleSheet.miniSeparator} />
            <span className={styleSheet.statTitle}>{statTitle}</span>
        </div>
    );

    const RatingsSection = ({ value, title }: { value: number; title: string }) => {
        return (
            <div className={styleSheet.statSection}>
                <div className={styleSheet.starRow}>
                    <Stars value={value} />
                </div>
                <div className={styleSheet.miniSeparator} />
                <span className={styleSheet.statTitle}>{title}</span>
            </div>
        );
    };

    const LinkSection = (props: any) => (
        <div style={{ ...indexTheme.rowStyles, height: '100%' }}>
            <div style={{ marginRight: 70, alignSelf: 'flex-start' }}>
                <div style={{ color: indexTheme.palette.black }} className={styleSheet.header}>
                    {props.title}
                </div>
                {props.comingSoon ? (
                    <span className={styleSheet.comingSoonText}>Coming Soon</span>
                ) : (
                    <span className={styleSheet.subText}>{props.subTitle}</span>
                )}
                {props.link ? (
                    <div
                        onClick={() => navigator.clipboard.writeText(getUnverifiedLink(listing))}
                        className={styleSheet.linkContainer}
                    >
                        <ClickableWithFeedback
                            onClick={() =>
                                navigator.clipboard.writeText(getUnverifiedLink(listing))
                            }
                        >
                            <img src={CopyIcon} />
                        </ClickableWithFeedback>
                        <Typography color={indexTheme.palette.blue} textStyle={'p1'}>
                            {props.link}
                        </Typography>
                    </div>
                ) : null}
            </div>
            <img
                style={{
                    position: 'absolute',
                    right: 20,
                    alignSelf: 'center',
                }}
                src={props.icon}
            />
        </div>
    );

    let overviewPageData = [
        <AcceptingShowings large />,
        <ListingSyndication large />,

        <StatSection
            mainStat={dayDiff ? Math.round(dayDiff) : 'N/A'}
            statTitle={'Days on Market'}
        />,
        <StatSection mainStat={listing?.views?.length ?? 0} statTitle={'Total Listing Views'} />,
        <StatSection
            mainStat={listing?.ratings?.totalShowings ?? 0}
            statTitle={'Total Showings'}
        />,
        <RatingsSection value={listing?.ratings?.overall || 0} title={'Overall Rating'} />,
        <RatingsSection value={listing?.ratings?.priceAccuracy || 0} title={'Price Accuracy'} />,
        <RatingsSection value={listing?.ratings?.location || 0} title={'Location'} />,
        <RatingsSection value={listing?.ratings?.interestLevel || 0} title={'Interest Level'} />,
        <LinkSection
            large
            link={getUnverifiedLink(listing)}
            title={'Allow Non-MLS Members & Professionals to Request Showings'}
            subTitle={
                'Agents who are members of the MLS can request showings through Showingly. Share this link with Non-MLS members to allow them to request showings as well.'
            }
            icon={GreenDoor}
        />,
    ];

    return (
        <div className={styleSheet.root}>
            {/* 
                Treat the cards like widgets with flex wrap, so if one card 
                needs to be removed it doesn't break the whole page layout.
            */}
            {overviewPageData.map((children: any) => (
                <div
                    className={
                        children && children.props?.large ? styleSheet.largeCard : styleSheet.card
                    }
                >
                    {children}
                </div>
            ))}
        </div>
    );
};
export default OverviewSection;
