import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import indexTheme from '../../theme';

interface ButtonTypes {
    onClick?: Function;
    children?: any;
    width?: number;
    color?: string;
    fill?: boolean;
    className?: any;
    noop?: boolean;
    disabled?: boolean;
    height?: number;
    editStyle?: any;
    screenWidth?: any;
}

const ButtonComponent = ({
    onClick = () => {},
    children,
    width = 200,
    color = indexTheme.palette.blue,
    fill = false,
    className,
    noop,
    disabled,
    height = 42,
    editStyle,
    screenWidth,
}: ButtonTypes) => {
    const [clicked, set] = useState(false);
    const { scale } = useSpring({ scale: clicked ? 0.93 : 1 });

    const animatedButtonStyle: any = useSpring({
        color: disabled ? indexTheme.palette.white : fill ? indexTheme.palette.white : color,
        backgroundColor: disabled ? indexTheme.palette.separatorGrey : fill ? color : 'transparent',
        borderColor: disabled ? indexTheme.palette.separatorGrey : color,
    });

    return (
        <animated.button
            onMouseDown={() => set(true)}
            onMouseUp={() => set(false)}
            onMouseLeave={() => set(false)}
            style={{
                height: height,
                width: width,
                borderWidth: 2.5,
                borderStyle: 'solid',
                borderRadius: 5,
                fontFamily: 'Poppins-semiBold',
                fontSize: screenWidth <= 1310 ? 12 : 18,
                cursor: 'pointer',
                transform: scale.to((s) => `scale(${s})`),
                userSelect: 'none',
                ...animatedButtonStyle,
                ...editStyle,
            }}
            children={children}
            onClick={() => (noop ? {} : onClick())}
            className={className}
            disabled={disabled}
        />
    );
};

export default ButtonComponent;
