import React from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';

import BlueStar from '../../images/icons/fullBlueStar.svg';
import HalfStar from '../../images/icons/halfBlueStar.svg';
import HalfBlackStar from '../../images/icons/halfBlackStar.svg';
import EmptyStar from '../../images/icons/emptyBlueStar.svg';
import EmptyBlackStar from '../../images/icons/emptyBlackStar.svg';
import BlackStar from '../../images/icons/fullBlackStar.svg';

interface Props {
    value: number;
    starStyle?: Object;
    variant?: 'blue' | 'black';
    onStarClick?: Function;
}
const Stars = ({
    value,
    starStyle,
    variant = 'blue',
    onStarClick = (e: any, i: number) => {},
}: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const GenerateStars = () => {
        const starsArr = [];
        for (let i = 0; i < 5; i++) {
            if (value - i > 0) {
                if (value - i >= 1) {
                    starsArr.push(
                        <img
                            onClick={(e: any) => onStarClick(e, i + 1)}
                            className={styleSheet.starStyle}
                            src={variant === 'blue' ? BlueStar : BlackStar}
                            style={starStyle}
                        />,
                    );
                } else {
                    starsArr.push(
                        <img
                            onClick={(e: any) => onStarClick(e, i + 1)}
                            className={styleSheet.starStyle}
                            src={variant === 'blue' ? HalfStar : HalfBlackStar}
                            style={starStyle}
                        />,
                    );
                }
            } else {
                starsArr.push(
                    <img
                        onClick={(e: any) => onStarClick(e, i + 1)}
                        className={styleSheet.starStyle}
                        src={variant === 'blue' ? EmptyStar : EmptyBlackStar}
                        style={starStyle}
                    />,
                );
            }
        }
        return starsArr;
    };
    return <>{GenerateStars()}</>;
};
export default Stars;
