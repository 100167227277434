/*----- React imports -------*/
import React from 'react';

/*-------- Components ---------*/

/*-------- Redux  ---------*/

/*-------- Components ---------*/
import Typography from '../../../../components/Typography';

/*-------- Utils ---------*/

/*-------- Assets ---------*/

/*-------- Icons ---------*/

/*-------- Constants ---------*/

/*-------- Misc  ---------*/

import styles from './styles';

import { DateTime } from 'luxon';

import indexTheme from '../../../../theme';
import { createUseStyles, useTheme } from 'react-jss';
import Tag from '../../../../components/Tag';

interface Props {
    restriction: any;
    disabled?: boolean;
    onPressRestriction?: Function;
}

const ShowingRestrictionCard = (props: Props) => {
    const { restriction, disabled = false, onPressRestriction = () => {} } = props;

    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const daySection = (
        <div className={styleSheet.daySectionContainer}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 16,
                }}
            >
                {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => {
                    const selectedDay = restriction.recurringDetail.days.includes(index);

                    return (
                        <div
                            style={
                                selectedDay
                                    ? {
                                          backgroundColor: indexTheme.palette.blue,
                                      }
                                    : {}
                            }
                            className={styleSheet.smallWeekDay}
                        >
                            <span
                                style={
                                    selectedDay
                                        ? {
                                              color: indexTheme.palette.white,
                                          }
                                        : {}
                                }
                                className={styleSheet.smallWeekDayText}
                            >
                                {day}
                            </span>
                        </div>
                    );
                })}
            </div>
            <span className={styleSheet.titleText}>
                {DateTime.fromJSDate(
                    typeof restriction.startTime === 'string'
                        ? new Date(restriction.startTime)
                        : restriction.startTime,
                ).toFormat('t')}{' '}
                -{' '}
                {DateTime.fromJSDate(
                    typeof restriction.endTime === 'string'
                        ? new Date(restriction.endTime)
                        : restriction.endTime,
                ).toFormat('t')}
            </span>
            {/* {!disabled && (
            <ClickableWithFeedback
                onClick={() => {
                    onPressRestriction();
                }}
            >
                <EditIcon />
            </ClickableWithFeedback>
        )} */}
        </div>
    );
    const startEndSection = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ...indexTheme.marginVertical(16),
            }}
        >
            <span className={styleSheet.titleText}>Starts On</span>
            <Tag
                background={true}
                onClick={() => {}}
                color={'mediumGrey'}
                text={DateTime.fromJSDate(
                    typeof restriction.recurringDetail.startDate === 'string'
                        ? new Date(restriction.recurringDetail.startDate)
                        : restriction.recurringDetail.startDate,
                ).toFormat('DD')}
                style={{ ...indexTheme.marginHorizontal(10) }}
            />
            <span className={styleSheet.titleText}>Ends On</span>
            <Tag
                background={true}
                onClick={() => {}}
                color={'mediumGrey'}
                text={
                    DateTime.fromJSDate(
                        typeof restriction.recurringDetail.endDate === 'string'
                            ? new Date(restriction.recurringDetail.endDate)
                            : restriction.recurringDetail.endDate,
                    ).toFormat('DD') === 'Jan 1, 2400'
                        ? 'Never'
                        : DateTime.fromJSDate(
                              typeof restriction.recurringDetail.endDate === 'string'
                                  ? new Date(restriction.recurringDetail.endDate)
                                  : restriction.recurringDetail.endDate,
                          ).toFormat('DD')
                }
                style={{ ...indexTheme.marginHorizontal(10) }}
            />
        </div>
    );

    if (restriction.recurring) {
        return (
            <div
                onClick={() => {
                    onPressRestriction();
                }}
            >
                <Typography textStyle={'b1'}>Recurring Restriction</Typography>
                {startEndSection}
                {daySection}
            </div>
        );
    } else {
        return (
            <div
                onClick={() => {
                    onPressRestriction();
                }}
            >
                <Typography textStyle={'b1'}>One-time Restriction</Typography>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 16,
                    }}
                >
                    <Tag
                        background={true}
                        onClick={() => {}}
                        color="mediumGrey"
                        text={DateTime.fromJSDate(
                            typeof restriction.startTime === 'string'
                                ? new Date(restriction.startTime)
                                : restriction.startTime,
                        ).toFormat('DD')}
                        style={{ marginRight: 16 }}
                    />
                    <span className={styleSheet.titleText}>
                        {DateTime.fromJSDate(
                            typeof restriction.startTime === 'string'
                                ? new Date(restriction.startTime)
                                : restriction.startTime,
                        ).toFormat('t')}{' '}
                        -{' '}
                        {DateTime.fromJSDate(
                            typeof restriction.endTime === 'string'
                                ? new Date(restriction.endTime)
                                : restriction.endTime,
                        ).toFormat('t')}
                    </span>
                    {/* {!disabled && (
                        <ClickableWithFeedback
                            onClick={() => {
                                onPressRestriction();
                            }}
                        >
                            <EditIcon />
                        </ClickableWithFeedback>
                    )} */}
                </div>
            </div>
        );
    }
};
export default ShowingRestrictionCard;
