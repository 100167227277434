export default (theme) => {
    return {
        filterButton: {
            position: 'absolute',
            display: 'flex',
            zIndex: 5,
            backgroundColor: theme.palette.white,

            margin: 25,
            padding: '5px 30px',

            borderRadius: 4,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            boxShadow: '0px 0px 24px -9px #000000',
        },
        filterText: {
            marginLeft: 5,
        },
        loadingBar: {
            position: 'absolute',
            top: '-28%',
            left: '-4%',
            borderRadius: 30,
            boxShadow: '2px 2px #888888',
            width: 'auto',
            backgroundColor: theme.palette.white,
        },
        loadingText: {
            padding: '10px 15px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            fontSize: 14,
            ...theme.typography.mediumBold,
        },
    };
};
