/*----- React imports -------*/
import React, { useLayoutEffect, useRef, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { BSON } from 'realm-web';

/*-------- Redux  ---------*/
import { useDispatch, useSelector } from 'react-redux';
import { textSearchAgentsRequested, textSearchConsumersRequested } from '../../actions';
import { getSearchedAgents, getSearchedConsumers } from '../../selectors';

/*-------- Components ---------*/
import Typography from '../../../../../components/Typography';
import AnimatedDrawer from '../../../../../components/AnimatedDrawer';
import AnimatedDrawerHeader from '../../../../../components/AnimatedDrawerHeader';
import ClickableWithFeedback from '../../../../../components/ClickableWithFeedback';

/*-------- Utils ---------*/
import { transformUser } from '../utils';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../../theme';

interface Props {
    userType: string;
    onCloseDrawer: Function;
    onPressSet: Function;
    includedUsers: any;
    onPressConnectNew: Function;
    marketName: string;
    brokerageId: string;
    agentListingId: BSON.ObjectId;
}

const UserSearchDrawer = ({
    userType,
    onCloseDrawer,
    onPressSet,
    includedUsers,
    onPressConnectNew,
    marketName,
    brokerageId,
    agentListingId,
}: Props) => {
    const searchedAgents = useSelector(getSearchedAgents);
    const searchedConsumers = useSelector(getSearchedConsumers);
    const dispatch = useDispatch();
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    const closeDrawerRef = useRef<any>(null);

    useLayoutEffect(() => {
        if (userType === 'agents' && marketName && brokerageId) {
            dispatch(textSearchAgentsRequested(' ', marketName, brokerageId));
        } else if (agentListingId) {
            dispatch(textSearchConsumersRequested(' ', agentListingId));
        }
    }, [agentListingId]);

    const populateUsersList = () => {
        const usersArr: any = userType === 'agents' ? searchedAgents : searchedConsumers;
        const users: any[] = [];
        usersArr.forEach((user: any) => {
            if (!includedUsers.includes(JSON.stringify(user?._id))) {
                users.push(user);
            }
        });
        return users;
    };

    const [screenHeight, setScreenHeight] = useState<number>(0);
    useLayoutEffect(() => {
        window.addEventListener('resize', () => setScreenHeight(window.innerHeight));
        setScreenHeight(window.innerHeight);
        return () =>
            window.removeEventListener('resize', () => setScreenHeight(window.innerHeight));
    }, []);

    return (
        <AnimatedDrawer ref={closeDrawerRef} onClose={onCloseDrawer}>
            <>
                <AnimatedDrawerHeader
                    title={`Connect ${
                        userType === 'agents' ? 'Co-Listing Agent' : 'Seller Client'
                    }`}
                    onClosePress={() => closeDrawerRef.current.closeDrawer()}
                />
                <div className={styleSheet.searchBarContainer}>
                    <input
                        onChange={(e) => {
                            if (userType === 'agents') {
                                dispatch(
                                    textSearchAgentsRequested(
                                        e.target.value,
                                        marketName,
                                        brokerageId,
                                    ),
                                );
                            } else {
                                dispatch(
                                    textSearchConsumersRequested(e.target.value, agentListingId),
                                );
                            }
                        }}
                        className={styleSheet.searchBar}
                        placeholder={
                            userType === 'agents'
                                ? 'Search For an Agent'
                                : 'Search For An Existing Client'
                        }
                    />
                </div>
                <div
                    className={styleSheet.clientsContainer}
                    style={populateUsersList()?.length ? { height: screenHeight - 250 } : {}}
                >
                    {userType === 'consumers' && (
                        <ClickableWithFeedback
                            className={styleSheet.connectNew}
                            onClick={() => {
                                onPressConnectNew();
                            }}
                        >
                            <Typography
                                style={{ marginLeft: 8 }}
                                color={indexTheme.palette.blue}
                                textStyle={'p1'}
                            >
                                Connect New Client
                            </Typography>
                            <div className={styleSheet.separator} />
                        </ClickableWithFeedback>
                    )}
                    {populateUsersList()?.length ? (
                        populateUsersList()?.map((user: any) => {
                            return (
                                <ClickableWithFeedback
                                    className={styleSheet.user}
                                    onClick={() => {
                                        onPressSet(transformUser(user, userType));
                                    }}
                                >
                                    <Typography style={{ marginLeft: 8 }} textStyle={'b1'}>
                                        {user?.firstName} {user?.lastName}
                                    </Typography>
                                    <div className={styleSheet.separator} />
                                </ClickableWithFeedback>
                            );
                        })
                    ) : (
                        <Typography textStyle={'h4'}>
                            No {userType === 'agents' ? 'Agents' : 'Clients'} Found
                        </Typography>
                    )}
                </div>
            </>
        </AnimatedDrawer>
    );
};
export default UserSearchDrawer;
