export default (theme) => {
    return {
        root: {
            width: '100%',
            margin: '10px 0px',
            padding: '5px 10px',

            fontSize: 18,
            border: {
                color: theme.palette.lightGrey,
                width: 2,
                style: 'solid',
                radius: 10,
            },
        },
    };
};
