import { BSON } from 'realm-web';
import { STATUS } from '../../utils/constants';

export enum AUTH_ACTION {
    LogIn = '[Auth] LogIn',
    LogOut = '[Auth] LogOut',
    Register = '[Auth] Register',
    VerifyCode = '[Auth] VerifyCode',
    SearchEmail = '[Auth] SearchEmail',
    Initialization = '[Auth] Initialization',
    SearchBrokerages = '[Auth] SearchBrokerages',
    SendVerificationMsg = '[Auth] SendVerificationMsg',
}

/*----- Redux Action Logic For verificated the code  -----*/
export const registerUserRequested = (
    userEmail: string,
    password: string,
    userObjectId: BSON.ObjectID,
) =>
    <const>{
        type: AUTH_ACTION.Register,
        status: STATUS.Requested,
        userEmail,
        password,
        userObjectId,
    };

type RegisterActions = ReturnType<typeof registerUserRequested>;
/*----- End of Redux Action Logic For verificated the code -----*/

/*----- Redux Action Logic For verificated the code  -----*/
export const verifyCodeRequested = (
    method: 'email' | 'phone',
    code: any,
    history: Function,
    emailOrPhone?: string,
    linkingAgentObjId?: any,
) =>
    <const>{
        type: AUTH_ACTION.VerifyCode,
        status: STATUS.Requested,
        code,
        method,
        emailOrPhone,
        linkingAgentObjId,
        history,
    };

export const verifyCodeSucceeded = (client: any) =>
    <const>{
        type: AUTH_ACTION.SearchEmail,
        status: STATUS.Succeeded,
        client,
    };

export const verifyCodeFailed = (errors: string[] = []) =>
    <const>{
        type: AUTH_ACTION.SearchEmail,
        status: STATUS.Failed,
        errors,
    };

type verifyCodeActions =
    | ReturnType<typeof verifyCodeRequested>
    | ReturnType<typeof verifyCodeSucceeded>
    | ReturnType<typeof verifyCodeFailed>;
/*----- End of Redux Action Logic For verificated the code -----*/

/*----- Redux Action Logic For search the email -----*/
export const searchEmailRequested = (email: string) =>
    <const>{
        type: AUTH_ACTION.SearchEmail,
        status: STATUS.Requested,
        email,
    };

export const searchEmailSucceeded = (client: any) =>
    <const>{
        type: AUTH_ACTION.SearchEmail,
        status: STATUS.Succeeded,
        client,
    };

export const searchEmailFailed = (errors: string[] = []) =>
    <const>{
        type: AUTH_ACTION.SearchEmail,
        status: STATUS.Failed,
        errors,
    };

type searchEmailActions =
    | ReturnType<typeof searchEmailRequested>
    | ReturnType<typeof searchEmailSucceeded>
    | ReturnType<typeof searchEmailFailed>;
/*----- End of Redux Action Logic For search the email -----*/

/*----- Redux Action Logic For search the email -----*/
export const searchBrokeragesRequested = (email: string) =>
    <const>{
        type: AUTH_ACTION.SearchBrokerages,
        status: STATUS.Requested,
        email,
    };

export const searchBrokeragesSucceeded = (brokerages: any) =>
    <const>{
        type: AUTH_ACTION.SearchBrokerages,
        status: STATUS.Succeeded,
        brokerages,
    };

export const searchBrokeragesFailed = (errors: string[] = []) =>
    <const>{
        type: AUTH_ACTION.SearchBrokerages,
        status: STATUS.Failed,
        errors,
    };

type searchBrokeragesActions =
    | ReturnType<typeof searchBrokeragesRequested>
    | ReturnType<typeof searchBrokeragesSucceeded>
    | ReturnType<typeof searchBrokeragesFailed>;
/*----- End of Redux Action Logic For search the email -----*/

/*----- Redux Action Logic For LogIn -----*/
export const logInRequested = (email: string, password: string) =>
    <const>{
        type: AUTH_ACTION.LogIn,
        status: STATUS.Requested,
        email,
        password,
    };

export const logInSucceeded = (client: any) =>
    <const>{
        type: AUTH_ACTION.LogIn,
        status: STATUS.Succeeded,
        client,
    };

export const logInFailed = (errors: string[] = []) =>
    <const>{
        type: AUTH_ACTION.LogIn,
        status: STATUS.Failed,
        errors,
    };

type LogInActions =
    | ReturnType<typeof logInRequested>
    | ReturnType<typeof logInSucceeded>
    | ReturnType<typeof logInFailed>;
/*----- End of Redux Action Logic For LogIn -----*/

/*----- Redux Action Logic For LogOut -----*/
export const logOutRequested = () =>
    <const>{
        type: AUTH_ACTION.LogOut,
        status: STATUS.Requested,
    };

export const logOutSucceeded = () =>
    <const>{
        type: AUTH_ACTION.LogOut,
        status: STATUS.Succeeded,
    };

export const logOutFailed = () =>
    <const>{
        type: AUTH_ACTION.LogOut,
        status: STATUS.Failed,
    };

type LogOutActions =
    | ReturnType<typeof logInRequested>
    | ReturnType<typeof logInSucceeded>
    | ReturnType<typeof logInFailed>;
/*----- Redux Action Logic For LogOut -----*/

/*----- Redux Action Logic For the User persistency -----*/
export const initializationRequested = () =>
    <const>{
        type: AUTH_ACTION.Initialization,
        status: STATUS.Requested,
        initializing: true,
    };

export const initializationSucceeded = () =>
    <const>{
        type: AUTH_ACTION.Initialization,
        status: STATUS.Succeeded,
        isAuthenticated: true,
        initializing: false,
    };

export const initializationFailed = (errors: any[] = []) =>
    <const>{
        type: AUTH_ACTION.Initialization,
        status: STATUS.Failed,
        isAuthenticated: false,
        initializing: false,
        errors,
    };

type InitializationActions =
    | ReturnType<typeof initializationRequested>
    | ReturnType<typeof initializationSucceeded>
    | ReturnType<typeof initializationFailed>;
/*----- End of Redux Action Logic For the User persistency -----*/

/*----- Redux Action Logic For the User verification account -----*/
export const sendVerificationMsgRequested = (
    method: 'email' | 'phone',
    receiver: string,
    isResend: boolean,
) =>
    <const>{
        type: AUTH_ACTION.SendVerificationMsg,
        status: STATUS.Requested,
        method,
        receiver,
        isResend,
    };

export const sendVerificationMsgSucceeded = () =>
    <const>{
        type: AUTH_ACTION.SendVerificationMsg,
        status: STATUS.Succeeded,
    };

export const sendVerificationMsgFailed = (errors: any[] = []) =>
    <const>{
        type: AUTH_ACTION.SendVerificationMsg,
        status: STATUS.Failed,
        errors,
    };

type SendVerificationMsgActions =
    | ReturnType<typeof initializationRequested>
    | ReturnType<typeof initializationSucceeded>
    | ReturnType<typeof initializationFailed>;
/*----- End of Redux Action Logic For the User persistency -----*/

/*----- Exportation of All the Actions -----*/
export type AuthenticationAction =
    | LogInActions
    | LogOutActions
    | RegisterActions
    | verifyCodeActions
    | searchEmailActions
    | InitializationActions
    | searchBrokeragesActions
    | SendVerificationMsgActions;
/*----- End of Exportation of All the Actions -----*/
