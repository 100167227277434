import { TableHeader } from '../../../components/DataTable/types';

export const ITEMS_PER_PAGE = 20; /* --->Length of item on the table*/

export const HEADERS: TableHeader[] = [
    { name: '', orderable: false, width: '1%' },
    { name: 'Address', orderable: true, width: '25%' },
    {
        name: 'Listing Agent',
        orderable: true,
        width: '12%',
        align: 'center',
        itemTextAlign: 'center',
    },
    { name: 'Price', orderable: false, width: '8%', align: 'center', itemTextAlign: 'center' },
    { name: 'Status', orderable: false, width: '13%', align: 'center', itemTextAlign: 'center' },
    {
        name: 'Days on Market',
        orderable: false,
        width: '11%',
        align: 'center',
        itemTextAlign: 'center',
    },
    {
        name: 'Total Showings',
        orderable: false,
        width: '11%',
        align: 'center',
        itemTextAlign: 'center',
    },
    {
        name: 'Listing ID',
        orderable: false,
        width: '10%',
        align: 'center',
        itemTextAlign: 'center',
    },
    {
        name: 'Configuration',
        orderable: false,
        width: '8%',
        align: 'center',
        itemTextAlign: 'center',
    },
    { name: '', orderable: false, width: '1%' },
];
