import React, { ReactNode } from 'react';
import styles from './styles';
import { createUseStyles, useTheme } from 'react-jss';
import pretypedTheme from '../../theme';
import Remove from '../../images/icons/close_modal_white.svg';
const indexTheme: any = pretypedTheme;

interface Props {
    text: string;
    color: string;
    customTextColor?: string;
    style?: any;
    Icon?: any;
    background?: boolean;
    filter?: boolean;
    onClick: Function;
}

const Tag = (props: Props) => {
    const {
        text,
        color = '#2FD2A8',
        customTextColor,
        style,
        Icon,
        background = false,
        filter,
    } = props;
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    const colorProps = {
        backgroundColor: background
            ? color === 'mediumGrey'
                ? indexTheme.palette.background
                : indexTheme.palette['light' + `${color[0].toUpperCase() + color.slice(1)}`]
            : indexTheme.palette['transparent'],
        color: customTextColor ?? indexTheme.palette[color],
    };
    return filter ? (
        <div style={{ ...colorProps, ...style }} className={styleSheet.tag}>
            {Icon ? <img src={Icon} style={{ marginRight: 6 }} /> : null}
            {text}
            <div
                className={styleSheet.cancel}
                style={{ backgroundColor: indexTheme.palette[color] }}
                onClick={() => props.onClick({})}
            >
                <img src={Remove} height={12} color="white" />
            </div>
        </div>
    ) : (
        <div
            style={{ ...colorProps, ...style }}
            className={styleSheet.tag}
            onClick={() => props.onClick({})}
        >
            {Icon ? <img src={Icon} /> : null}
            {text}
        </div>
    );
};
export default Tag;
