import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { STATUS_DISPLAY_LABELS } from '../constants';

/**
 * Takes the phone number format stored in stitch and returns the number in
 * the format `+1 (012) 345-6789` or w/o international code if none is given.
 * @param {string} phoneNumber in the format `+10123456789` or w/o intl code
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function formatPhoneNumberForDisplay(
    phoneNumber: string | number,
    includeDialingCode = false,
): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match && match.length > 4) {
        const intlCode = match[1] ? '+1 ' : '';
        return includeDialingCode
            ? [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
            : ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber.toString();
}

/**
 * Convert image to base64, thanks stack overflow
 *
 */
export const imageToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

/**
 * Takes the phone number format stored in most brokerage CSVs and returns the number in
 * the format `+10123456789`.
 * @param {string} phoneNumber in the format `(101) 234-5678`
 * @returns {string}
 */
export function formatPhoneNumberToIntl(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = '+1';
        return [intlCode, match[2], match[3], match[4]].join('');
    }
    return `${phoneNumberString} is not a valid Number`;
}

export function formatEmailForDisplay(email: any) {
    var formattedEmail = email;
    if (email?.indexOf('+')) {
        const front = email.substr(0, email.indexOf('+'));
        const end = email.substr(email.indexOf('@'), email.length);
        if (front?.length && end?.length) {
            formattedEmail = front + end;
        }
    }
    return formattedEmail;
}


export function convertIntoAddress(address: any) {
    return `${address.full}, ${address.city}, ${address.state}`;
};

export function splitCamelCaseToString(string: string) {
    return (string.split(/(?=[A-Z])/).join(' '));
}

/**
 * Checks if email given is valid
 * @param {string} potential email
 * @returns {boolean}
 */
export function isValidEmail(email: string) {
    return email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        ? email
        : `${email} is not a valid Email`;
}

// returns a formatted version of a "regular" date object
// for example: 2020-12-21T22:04:36.826Z to 12/21/2020
export const formatDate = (date: Date) => {
    let month = date.getUTCMonth() + 1; //months from 1-12
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();

    return '' + year + '/' + month + '/' + day;
};

// converts the date object and time string into 1 date object
// for example, 12/21/2020 and 02:00PM to 2020-12-21T22:02:00.000Z
export const getNewTime = (date: Date, time: string) => {
    let newDate = new Date(date);
    let splitTime: any = time.split(':');
    splitTime[0] = parseInt(splitTime[0]);

    // if the time is pm, account for that in the hour
    if (splitTime[1].charAt(2) === 'P') splitTime[0] += 12;

    newDate.setHours(splitTime[0], parseInt(splitTime[1]), 0);
    return newDate;
};

export const noop = () => { };

export const maskCardNumber = (cardNumber: string) => {
    const number = cardNumber?.split('');

    number[0] = '*';
    number[1] = '*';
    number[2] = '*';
    number[3] = '*';
    number[4] = '*';
    number[5] = '*';
    number[6] = '*';
    number[7] = '*';
    number[8] = '*';
    number[9] = '*';
    number[10] = '*';
    number[11] = '*';

    return number.join('');
};

// This takes a stripe Price object and converts it into a string to display to users
export const formatStripePrice = (price: any, per: boolean = false) => {
    // accomodate different formats for the stripe price (plan/price format)
    const {
        recurring = null,
        interval: recurringInterval = null,
        unit_amount = null,
        amount = null,
    } = price;

    const dollarAmount = unit_amount ? unit_amount / 100 : amount / 100;
    const dollarAndCents = dollarAmount.toFixed(2);
    const withCommas = dollarAndCents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const separator = per ? 'per' : '/';
    const interval = recurring ? recurring.interval : recurringInterval;
    const intervalString = interval ? ` ${separator} ${interval}` : '';

    const priceDisplayString = `$${withCommas}${intervalString}`;

    return priceDisplayString;
};
/**
 * Formats a date string from the initialFormat to the newFormat.
 *
 * @param dateString the actual date as a string
 * @param initialFormat the format the dateString is currently in
 * @param newFormat the new format you want the date to be returned in
 * @returns {string} the reformatted date
 */
export const formatDateString = (
    dateString: any,
    initialFormat = 'MM/dd/yyyy',
    newFormat = 'MMMM dd, yyyy',
) => {
    return DateTime.fromFormat(dateString, initialFormat).toFormat(newFormat) || 'undefined';
};

/**
 * Remove dashes, spaces, and other special characters from phone number.
 * Optionally adds International dialing code
 * @param {string|number} phoneNumber
 * @param {string} dialingCode - defaults to U.S.
 * @param {boolean} includeDialingCode - Whether or not to add the country code
 * @returns {string}
 */
export function scrubAndFormatPhoneNumber(
    phoneNumber: string,
    dialingCode = '1',
    includeDialingCode = true,
) {
    const scrubbedPhoneNumber = `${phoneNumber}`.replace(/[^\d]/g, '');
    return includeDialingCode ? `+${dialingCode}${scrubbedPhoneNumber}` : `${scrubbedPhoneNumber}`;
}

/* Attempt to parse the error for its string message.
 * If that fails, fall back to error name or as a last resort, show generic error message.
 * @param stitchError Instance of error object `StitchServiceError` caught from API
 * @returns {string}
 */
export function parseStitchServiceError(stitchError: any) {
    try {
        const parsedError = JSON.parse(stitchError.message);
        return parsedError.message;
    } catch {
        if (stitchError?.message && typeof stitchError.message === 'string') {
            return stitchError.message;
        } else {
            return stitchError?.name || 'Something Went Wrong.';
        }
    }
}

/**
 * Remove extra spaces and lowercase all characters in email
 * @param {string} email
 * @returns {string}
 */
export function scrubEmail(email: string) {
    return email.trim().toLocaleLowerCase();
}

// returns an array of strings containing timeOptions for a timeSelector dropdown
// thanks stackOverflow
export const getTimeOptions = (startHour: number, endTime: string, includeMidnight: boolean) => {
    let x = 15; //minutes interval
    let timeOptions = [];
    let tt = startHour * 60; // start time
    let ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (let i = 0; tt < 24 * 60; i++) {
        let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        let mm = tt % 60; // getting minutes of the hour in 0-55 format

        // make sure 8pm is the limit
        if (
            ('0' + (hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)] ===
            endTime
        )
            break;

        // ensures that times such as 00:00pm are returned as 12:00PM
        let h: any = ('0' + (hh % 12)).slice(-2) === '00' ? '12' : ('0' + (hh % 12)).slice(-2);
        timeOptions[i] = h + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }

    if (includeMidnight) {
        timeOptions.push('12:00am');
    }

    // remove 0 if it's the first number
    timeOptions = timeOptions.map((option: string) =>
        option.charAt(0) === '0' ? option.slice(1) : option,
    );

    return timeOptions;
};

export const getTimeDateShowing = (showing: any) => {
    if (showing) {
        let min = Math.ceil(showing?.start.getMinutes() / 15) * 15;
        let hours = showing.start.getHours();
        if (min >= 60) {
            min = 0;
            hours++;
        }
        const hoursLabel = hours > 12 ? hours - 12 : hours;

        const end = DateTime.fromJSDate(showing.end);
        const start = DateTime.fromJSDate(showing.start);
        const diffMinutes: any = end.diff(start, 'minutes').toObject();
        const diffHours: any = end.diff(start, 'hours').toObject();
        let diffLabel = '';
        if (diffHours.hours >= 1) {
            const hour = Math.trunc(diffHours.hours);
            const hourLabel = hour > 1 ? 'hours' : 'hour';
            diffLabel =
                diffMinutes.minutes % 60 === 0
                    ? `${hour} ${hourLabel}`
                    : `${hour} ${hourLabel} & ${diffMinutes.minutes % 60} minutes`;
        } else {
            diffLabel = `${diffMinutes.minutes} minutes`;
        }

        return {
            date: new Date(showing.start),
            defaultTime: {
                hours: { label: hoursLabel.toString(), value: hours },
                minutes: {
                    label: min ? min.toString() : '00',
                    value: min,
                },
                meridian: {
                    label: hours < 12 ? 'AM' : 'PM',
                    value: hours < 12 ? 'am' : 'pm',
                },
                selectedValue: {
                    label: formatAMPM(showing?.start),
                    value: parseInt(`${hours}${min ? min.toString() : '00'}`),
                },
            },
            defaultDuration: {
                selectedValue: {
                    label: diffLabel,
                    value: diffMinutes.minutes,
                },
            },
        };
    }
};

function formatAMPM(date: any) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ampm;
}

/**
 * Abbreviates long numbers into thousands, millions, billions and trillions
 * @param {number} value
 * @returns {string}
 */
//Modified from stack overflow
//https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export function abbreviateNumber(value: number) {
    let newValue: any = value;
    const suffixes = ['', 'k', 'm', 'b', 't'];
    let suffixNum = 0;
    while (newValue >= 1000) {
        newValue /= 1000;
        suffixNum++;
    }
    newValue = parseFloat(newValue.toFixed(1));
    newValue += suffixes[suffixNum];
    return newValue;
}

/**
 * Add commas to number for display
 * @param {number} num
 * @returns {string}
 */
export const numberWithCommas = (num: number) => {
    if (!num && num !== 0) return '--';

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function useOutsideClick(ref: any, doStuff: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                doStuff();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

// export const generateSignature = () => {
//     const secret = process.env.REACT_APP_CLIENT_VALIDATION;
//     const timestamp = new Date().toISOString();
//     const hash = sha256(`${timestamp} ${secret}`).toString();
//     return { hash, timestamp };
// };

export const formatPrice = (price: number) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    let suffix = 'k';
    if (price >= 1000000) {
        suffix = 'm';
    }
    if (price >= 1000000000) {
        suffix = 'b';
    }
    if (price >= 1000000000000) {
        suffix = 't';
    }
    const priceString = `${formatter.format(price).split(',')[0]}`;
    return priceString + suffix;
};

/**
 * Function to determine if the user is on mobile or not
 */
export const isMobile = () => {
    var check = false;
    ((a) => {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4),
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

/**
 * Returns the given string with the first letter capitalized.
 *
 * @param string
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Give a status and a display value for that status will be returned.
 * @param {string} status
 * @returns {string} the label for status from STATUS_DISPLAY_LABELS or if not found in there, the status with the first letter capitalized
 */
export const getStatusForDisplay = (status: string) => {
    const statusDisplayLabel = STATUS_DISPLAY_LABELS.find(
        ({ status: labelStatus }) => status === labelStatus,
    );
    return statusDisplayLabel ? statusDisplayLabel.label : capitalizeFirstLetter(status);
};

/*
 * Formats a number with the input amount of decimal places, two by default.
 * @param amount
 * @param decimalCount The number of decimals to display
 * @returns {string}
 */
export const formatCurrency = (amount: any, decimalCount = 0) => {
    try {
        decimalCount = Math.abs(decimalCount);
        amount =
            isNaN(decimalCount) || decimalCount === 0
                ? amount
                : parseFloat(amount).toFixed(decimalCount);

        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch {
        return 0;
    }
};

/*
 * takes a status and gives it its appropriate color
 * @param status
 * @returns text and color of tag
 */
export const getStatusInfo = (status: any) => {
    switch (status) {
        case 'logged':
            return {
                text: 'Logged',
                color: 'aqua',
            };
        case 'completed':
            return { text: 'Completed', color: 'blue' };
        case 'pending_internal':
            return { text: 'Pending', color: 'yellow' };
        case 'pending_external':
            return { text: 'Pending', color: 'yellow' };
        case 'requested':
            return { text: 'Client Request', color: 'orange' };
        case 'confirmed':
            return { text: 'Confirmed', color: 'green' };
        case 'denied':
            return { text: 'Denied', color: 'maroon' };
        case 'cancelled':
            return { text: 'Cancelled', color: 'red' };
        default:
            return { text: 'Confirmed', color: 'green' };
    }
};

