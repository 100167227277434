/*----- React imports -------*/
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Components ---------*/
import Typography from '../../../../../components/Typography';
import ClickableWithFeedback from '../../../../../components/ClickableWithFeedback';

/*-------- Assets ---------*/
import styles from './styles';

/*-------- Icons ---------*/
import SelectedRadio from '../../../../../images/icons/blueRadioSelected.svg';
import UnselectedRadio from '../../../../../images/icons/blueRadioUnselected.svg';

interface Props {
    set: Function;
    selected: any;
    data: {
        label: string;
        value: any;
    }[];
}
const RadioRow = ({ set, selected, data }: Props) => {
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });
    return (
        <div style={{ display: 'flex' }}>
            {data.map((dataItem) => {
                return (
                    <ClickableWithFeedback
                        onClick={() => set(dataItem.value)}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <img src={selected === dataItem.value ? SelectedRadio : UnselectedRadio} />{' '}
                        <Typography style={{ marginLeft: 5, marginRight: 25 }} textStyle={'b1'}>
                            {dataItem.label}
                        </Typography>
                    </ClickableWithFeedback>
                );
            })}
        </div>
    );
};
export default RadioRow;
