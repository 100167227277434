export default (theme: any) => {
    const headerStyles = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.white,
        ...theme.paddingVertical(28),
        ...theme.paddingHorizontal(48),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey,
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const textStyle = {
        fontFamily: 'Poppins-semiBold',
        color: theme.palette.black,
        fontSize: 18,
    };
    return {
        root: {
            width: '100%',
            // default min height so the scroll bar doesn't make the screen dance.
            minHeight: '100vh',
            backgroundColor: theme.palette.background,
            overflow: 'hidden',
        },
        header: {
            ...headerStyles,
            justifyContent: 'space-between',
            fontFamily: 'Poppins-semiBold',
            color: theme.palette.mediumGrey,
            fontSize: 18,
        },
        selectionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        verticalSeparator: {
            height: 18,
            width: 2,
            borderRadius: 5,
            backgroundColor: theme.palette.grey,
            ...theme.marginHorizontal(26),
        },
        priceHeader: {
            ...headerStyles,
            ...theme.paddingVertical(20),
        },
        leftContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        price: {
            ...textStyle,
            fontSize: 26,
        },
        address: {
            ...textStyle,
        },
        city: {
            ...textStyle,
            fontFamily: 'Poppins-medium',
            marginLeft: 6,
        },
        loadingContainer: {
            alignSelf: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: 150,
        },
        headerBar: {
            height: 62,
            borderWidth: 0,
            borderColor: theme.palette.separatorGrey,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: { left: 15, right: 17.91 },
        },
        closeIcon: {
            height: 26.6,
            width: 26.6,
            marginBottom: -3,
        },
        buttonRow: {
            margin: 'auto',
            ...theme.paddingHorizontal(20),
            ...theme.paddingVertical(15),
            borderWidth: 0,
        },
        newCon: {
            justifyContent: 'center',
        },
        tagBox: {
            display: 'flex',
            gap: '1rem',
        },
    };
};
