/*----- React imports -------*/
import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

/*-------- Components ---------*/
import Loader from '../../../../components/Loader';
import Typography from '../../../../components/Typography';

/*-------- Assets ---------*/
import styles from './styles';
import indexTheme from '../../../../theme';

/*-------- Constants ---------*/
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

interface Props {
    loading: boolean;
    errors: any;
    success: boolean;
}
type IncludeData = {
    title: string;
    value: string;
    special?: string;
};
const PageFive = ({ loading, errors, success }: Props) => {
    /*-------- Theme ---------*/
    const useStyles = createUseStyles(styles);
    const theme = useTheme();
    const styleSheet = useStyles({ theme });

    /*-------- States ---------*/
    const [showBar, setShowBar] = useState(true);

    /*-------- Use Effect ---------*/
    useEffect(() => {
        if (success) {
            setShowBar(true);
            setTimeout(() => {
                setShowBar(false);
            }, 3500);
        }
    }, [success]);

    /*-------- Functions ---------*/
    function Alert(props: any) {
        return <MuiAlert color="info" elevation={6} variant="filled" {...props} />;
    }

    const includeMls = (data: IncludeData) => {
        return (
            <div>
                <Typography style={{ fontSize: 18 }} textStyle="b1">
                    {data.title}
                </Typography>
                {data.special && (
                    <Typography style={{ fontSize: 18 }} textStyle="b1">
                        {data.special}
                    </Typography>
                )}
                <Typography style={{ fontSize: 18 }} color={indexTheme.palette.blue} textStyle="b1">
                    {data.value}
                </Typography>
            </div>
        );
    };

    const includeMlsData: IncludeData[] = [
        {
            title: 'Showing Phone#: ',
            value: '+1 (303) 970-0454',
        },
        {
            title: 'Showing Email: ',
            value: 'support@showingly.com',
        },
        {
            title: 'Broker Remarks: ',
            special: 'Please request all showings through Showingly at ',
            value: 'https://agent.showingly.com',
        },
    ];

    return (
        <div
            style={{
                height: window.innerHeight / 2,
                justifyContent: 'center',
                display: 'flex',
            }}
            className={styleSheet.root}
        >
            {loading ? (
                <Loader />
            ) : errors ? (
                <div>
                    There was an error setting up your listing. Please contact support: +1 (833)
                    217-7578
                </div>
            ) : success ? (
                <div>
                    <Typography textStyle={'h2'}>
                        There’s just one last step to finish setting up your listing.
                    </Typography>
                    <div style={{ marginTop: 30 }}>
                        <Typography textStyle={'h3'}>
                            Please update your listing in the MLS to include the following:
                        </Typography>
                        <div>
                            {includeMlsData.map((data) => (
                                <>{includeMls(data)}</>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 30,
                            backgroundColor: indexTheme.palette.background,
                            padding: 20,
                            borderRadius: 5,
                        }}
                    >
                        <div>
                            <Typography textStyle="h3">
                                Remember to disable ShowingTime or any other showing providers.
                            </Typography>
                        </div>
                        <div>
                            <Typography style={{ fontSize: 18 }} textStyle="b1">
                                If you do not complete this step, you may run into agents trying to
                                schedule showings through multiple showing providers making it hard
                                to manage your listings.
                            </Typography>
                        </div>
                    </div>
                    <Snackbar
                        open={showBar}
                        autoHideDuration={3500}
                        onClose={() => setShowBar(false)}
                        style={{ backgroundColor: indexTheme.palette.blue }}
                    >
                        <Alert className={styleSheet.snackbarAlert} severity={'success'}>
                            <div>Listing Configured</div>
                        </Alert>
                    </Snackbar>
                </div>
            ) : (
                <div>Cannot find this page</div>
            )}
        </div>
    );
};
export default PageFive;
